import styled from 'styled-components';
import { Flex, grayPalette } from '@lama/design-system';

export const SectionAlertContainer = styled(Flex)<{ disabled: boolean }>`
  user-select: none;
  padding: 12px 16px;
  border-radius: 8px;
  flex-direction: row;
  gap: 8px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  position: relative;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  ${({ disabled }) =>
    !disabled
      ? ` 
    &:hover {
    background: ${grayPalette[50]};
    ::after {
      content: '>';
      font-size: 16px;
      color: ${grayPalette[700]};
      position: absolute;
      right: 16px;
    }
  }`
      : ''}
`;
