import type { EvaluatedOpportunityRequirement, VirtualAssets } from '@lama/contracts';
import React, { useContext } from 'react';
import { Stack, Typography } from '@mui/material';

import type { FC } from 'react';
import { Flex, Grid } from '@lama/design-system';
import type { InfoLineProps } from '@lama/app-components';
import { KeyValuePair } from '@lama/app-components';
import { SegmentContainer } from '../../../Application/shared/SegmentContainer';
import { ApplicationContext } from '../../../Application/ApplicationContext';
import { RequirementPrintContainer } from '../RequirementPrintContainer';

const VirtualAssetSegment: FC<{ virtualAsset: VirtualAssets }> = ({ virtualAsset }) => {
  const { howDigitalAssetsStored, virtualAssetsType } = virtualAsset;
  const info: InfoLineProps[] = [
    {
      label: 'Digital Assets Storage Method',
      values: howDigitalAssetsStored,
    },
  ];

  return (
    <SegmentContainer title={virtualAssetsType}>
      <Flex flexDirection={'column'} gap={4}>
        <Flex flexDirection={'column'} gap={4}>
          <Grid columns={1}>
            {info.map((line) => (
              <KeyValuePair key={line.label} name={line.label} value={line.values} type={line.type} />
            ))}
          </Grid>
        </Flex>
      </Flex>
    </SegmentContainer>
  );
};

export const VirtualAssetView = ({ requirement }: { requirement: EvaluatedOpportunityRequirement }) => {
  const { application } = useContext(ApplicationContext);

  if (!application.virtualAssets) {
    return (
      <RequirementPrintContainer title={requirement.name}>
        <Typography variant={'body2'}>{'No information found'}</Typography>
      </RequirementPrintContainer>
    );
  }

  return (
    <RequirementPrintContainer title={requirement.name}>
      <Stack gap={2}>
        {application.virtualAssets.map((virtualAsset) => (
          <VirtualAssetSegment key={virtualAsset.id} virtualAsset={virtualAsset} />
        ))}
      </Stack>
    </RequirementPrintContainer>
  );
};
