/* eslint-disable @typescript-eslint/naming-convention */
import { Stack } from '@mui/material';
import { useIsMobile, ConfirmLeave, FormikMoneyInputField, ButtonWithSaveIndication } from '@lama/app-components';
import type { FC } from 'react';
import React, { useMemo, useContext, useCallback } from 'react';
import type { FormikHelpers, FormikProps } from 'formik';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import type { FinancialData } from '@lama/contracts';
import { getApplicationEntityByType } from '@lama/properties';
import { ApplicationContext } from '../../../../ApplicationContext';
import { UserDetailsContext } from '../../../../../../shared/context/UserDetailsContext';
import type { RequirementScreenProps } from '../types';
import { getFinancialPayload, getFinancialFieldByYear } from './financialFieldUtils';
import { useSubmitFinancialsMutation } from './hooks/useSubmitFinancialsMutation';

const validationSchema = yup.object({
  gross1: yup.number().min(0),
  totalCreditLines: yup.number().optional(),
  currentCreditLinesBalance: yup.number().optional(),
});

interface StreamlinedRevenueScreenInnerFormProps {
  formikProps: FormikProps<any>;
  savingFinancial: boolean;
  includeDebtFinancials?: boolean;
  financialsSuccessfullyUpdated: boolean;
}

const StreamlinedRevenueScreenInnerForm: FC<StreamlinedRevenueScreenInnerFormProps> = ({
  formikProps: { dirty, isValid, handleSubmit },
  savingFinancial,
  includeDebtFinancials,
  financialsSuccessfullyUpdated,
}) => {
  const isMobile = useIsMobile();

  return (
    <ConfirmLeave shouldBlock={dirty}>
      <Form onSubmit={handleSubmit}>
        <Stack gap={4}>
          <Stack gap={3} direction={isMobile ? 'column' : 'row'}>
            <FormikMoneyInputField name={'gross1'} label={'Net Income'} />
          </Stack>
          {includeDebtFinancials ? (
            <Stack gap={3} direction={isMobile ? 'column' : 'row'}>
              <FormikMoneyInputField name={'totalCreditLines'} label={'Revolving Credit Limit'} />
              <FormikMoneyInputField name={'currentCreditLinesBalance'} label={'Revolving Credit Balance'} />
            </Stack>
          ) : null}
          <ButtonWithSaveIndication
            loading={savingFinancial}
            onSave={handleSubmit}
            disabled={!dirty || !isValid}
            hasBeenSaved={financialsSuccessfullyUpdated}
          />
        </Stack>
      </Form>
    </ConfirmLeave>
  );
};

interface RevenueValues {
  gross1: string;
  totalCreditLines?: string;
  currentCreditLinesBalance?: string;
}

interface StreamlinedRevenueScreenProps extends RequirementScreenProps {
  includeDebtFinancials?: boolean;
}

export const StreamlinedRevenueScreen: FC<StreamlinedRevenueScreenProps> = ({ requirement, includeDebtFinancials }) => {
  const {
    application,
    opportunity: { referenceYear, id: opportunityId },
  } = useContext(ApplicationContext);
  const { userId } = useContext(UserDetailsContext);
  const {
    isPending: savingFinancials,
    mutateAsync: updateFinancialData,
    isSuccess: financialsSuccessfullyUpdated,
  } = useSubmitFinancialsMutation(requirement.entityId, requirement.entityType, opportunityId);

  const entityFinancials = useMemo<FinancialData[]>(
    () =>
      getApplicationEntityByType(application, requirement.entityType, ['all']).find(({ id }) => id === requirement.entityId)?.financials ??
      [],
    [application, requirement.entityId, requirement.entityType],
  );

  // "Annual Debt Payments" pulled for backwards compatibility
  const initialValues: RevenueValues = useMemo(
    () => ({
      gross1: getFinancialFieldByYear(entityFinancials, referenceYear, 'Net Operating Income')?.value?.toString() ?? '',
      ...(includeDebtFinancials
        ? {
            totalCreditLines:
              getFinancialFieldByYear(entityFinancials, referenceYear, 'Business Revolving Credit Limit')?.value?.toString() ?? '',

            currentCreditLinesBalance:
              getFinancialFieldByYear(entityFinancials, referenceYear, 'Business Revolving Credit Balance')?.value?.toString() ?? '',
          }
        : {}),
    }),
    [entityFinancials, includeDebtFinancials, referenceYear],
  );

  const submitFinancial = useCallback(
    async (newValues: RevenueValues, { resetForm }: FormikHelpers<any>) => {
      const { gross1, totalCreditLines, currentCreditLinesBalance } = newValues;


      resetForm({ values: newValues });

      const revenuePayload = userId
        ? [
            ...getFinancialPayload({
              entityId: requirement.entityId,
              financials: entityFinancials,
              year: referenceYear,
              fieldName: 'Net Operating Income',
              value: gross1,
              userId,
            }),
            ...(includeDebtFinancials
              ? [
                  ...getFinancialPayload({
                    entityId: requirement.entityId,
                    financials: entityFinancials,
                    year: referenceYear,
                    fieldName: 'Business Revolving Credit Limit',
                    value: totalCreditLines,
                    userId,
                  }),
                  ...getFinancialPayload({
                    entityId: requirement.entityId,
                    financials: entityFinancials,
                    year: referenceYear,
                    fieldName: 'Business Revolving Credit Balance',
                    value: currentCreditLinesBalance,
                    userId,
                  }),
                ]
              : []),
          ]
        : [];

      if (revenuePayload.length) {
        await updateFinancialData(revenuePayload);
      }
    },
    [userId, requirement.entityId, entityFinancials, referenceYear, includeDebtFinancials, updateFinancialData],
  );

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitFinancial}>
      {(formikProps) => (
        <StreamlinedRevenueScreenInnerForm
          includeDebtFinancials={includeDebtFinancials}
          formikProps={formikProps}
          savingFinancial={savingFinancials}
          financialsSuccessfullyUpdated={financialsSuccessfullyUpdated}
        />
      )}
    </Formik>
  );
};
