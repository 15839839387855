import { v4 as uuidv4 } from 'uuid';
import type { UnderwritingNoteType } from '@lama/contracts';
import { Bookmark, BookmarkAdded } from '@mui/icons-material';
import type { FC } from 'react';
import React, { useState, useMemo, useCallback, useContext } from 'react';
import { AddNotePopover, HideOnPrintWrapper } from '@lama/app-components';
import { Flex } from '@lama/design-system';
import { keyBy } from 'lodash-es';
import { ApplicationContext } from '../ApplicationContext';
import { SecondaryActionButton } from '../../../shared/components/SecondaryActionButton';
import { useCreateNoteMutation } from '../../../shared/hooks/react-query/opportunity/useCreateNoteMutation';
import { useUpdateNoteMutation } from '../../../shared/hooks/react-query/opportunity/useUpdateNoteMutation';
import { UserDetailsContext } from '../../../shared/context/UserDetailsContext';
import { DismissedFlagsCard } from '../CreditMemo/Sections/components/Notes/ExceptionCard';
import { FlagCard } from '../CreditMemo/Sections/components/Notes/FlagCard';
import { useUsersByPartnerQuery } from '../../../shared/hooks/react-query/user/useUsersByPartnerQuery';
import { getUnderwritingNoteRelatedItemProperty, type UnderwritingNoteRelatedSectionType } from './noteUtils';

interface SectionDecisioningProps {
  sectionId: string;
  sectionType: UnderwritingNoteRelatedSectionType;
}

const displayedNoteTypes = new Set<UnderwritingNoteType>(['flag', 'exception']);

export const SectionDecisioning: FC<SectionDecisioningProps> = ({ sectionId, sectionType }) => {
  const { opportunity } = useContext(ApplicationContext);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { userId, partner } = useContext(UserDetailsContext);
  const { data: users } = useUsersByPartnerQuery(partner);

  const { mutateAsync: createNote } = useCreateNoteMutation(opportunity.id, userId);
  const { mutateAsync: updateNote } = useUpdateNoteMutation(opportunity.id, userId);

  const usersById = useMemo(() => keyBy(users, 'id'), [users]);
  const currentNote = useMemo(() => {
    const sectionIdProperty = getUnderwritingNoteRelatedItemProperty(sectionType);
    return opportunity.underwriting?.notes?.find((note) => displayedNoteTypes.has(note.type) && note[sectionIdProperty] === sectionId);
  }, [opportunity.underwriting?.notes, sectionId, sectionType]);

  const [activeButton, setActiveButton] = useState<UnderwritingNoteType | null>(currentNote?.type ?? null);

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
    setActiveButton(null);
  }, []);

  const onDecisionClick = useCallback(
    (type: UnderwritingNoteType, button: HTMLButtonElement) => {
      if (activeButton === type) {
        setActiveButton(null);
        return;
      }
      setAnchorEl(button);
      setActiveButton(type);
    },
    [activeButton],
  );

  const onClickException = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onDecisionClick('exception', event.currentTarget);
    },
    [onDecisionClick],
  );

  const onClickFlag = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onDecisionClick('flag', event.currentTarget);
    },
    [onDecisionClick],
  );

  const onSubmitClick = useCallback(
    async (text: string) => {
      if (!activeButton) {
        return;
      }

      const sectionIdProperty = getUnderwritingNoteRelatedItemProperty(sectionType);
      await (currentNote
        ? updateNote({
            noteId: currentNote.id,
            notePayload: {
              type: activeButton,
              text,
            },
          })
        : createNote({
            id: uuidv4(),
            [sectionIdProperty]: sectionId,
            text,
            type: activeButton,
          }));
    },
    [activeButton, currentNote, updateNote, createNote, sectionType, sectionId],
  );

  return (
    <>
      <Flex flexDirection={'column'} gap={4}>
        <HideOnPrintWrapper>
          <Flex gap={4}>
            <SecondaryActionButton
              variant={'warning'}
              label={'Exception'}
              icon={BookmarkAdded}
              onClick={onClickException}
              selected={currentNote?.type === 'exception'}
            />
            <SecondaryActionButton
              variant={'danger'}
              label={'Flag Issue'}
              icon={Bookmark}
              onClick={onClickFlag}
              selected={currentNote?.type === 'flag'}
            />
          </Flex>
        </HideOnPrintWrapper>
        {currentNote ? (
          currentNote.type === 'exception' ? (
            <DismissedFlagsCard key={currentNote.id} note={currentNote} user={usersById[currentNote.updatedBy]} showSectionCrumbs={false} />
          ) : currentNote.type === 'flag' ? (
            <FlagCard key={currentNote.id} flag={currentNote} user={usersById[currentNote.createdBy]} showSectionCrumbs={false} />
          ) : null
        ) : null}
      </Flex>
      <AddNotePopover
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        onSubmit={onSubmitClick}
        initialText={currentNote?.text}
        placeholder={'Leave a Comment...'}
      />
    </>
  );
};
