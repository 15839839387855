import type { FC } from 'react';
import React from 'react';
import type { Entity } from '@lama/common-types';
import type { LabeledValue, HierarchicalOption } from '@lama/contracts';
import type { SourcedProperty } from '@lama/properties';
import type { ApplicationApiModel, OpportunityApiModel } from '@lama/clients';
import {
  PropertyFormikInput,
  FormikEmailInputField,
  FormikCheckbox,
  FormikDatePicker,
  FormikPhoneNumberField,
  FormikPicker,
  FormikWebsiteField,
  FormikMoneyInputField,
  FormikMultiPicker,
} from '../FormikFields/index.js';
import { GenericObjectForm } from './GenericObjectForm.js';

export interface PropertyComponentProps {
  property: SourcedProperty;
  submitted?: boolean;
  initialValue?: any;
  entityType: Entity;
  application: ApplicationApiModel;
  opportunity?: OpportunityApiModel;
  sourceToValues?: Record<string, LabeledValue[] | string[] | readonly string[]>;
  sourceToHierarchicalValues?: Record<string, HierarchicalOption[]>;
}

export const typeToComponent: Record<SourcedProperty['type'], FC<PropertyComponentProps>> = {
  string: ({ property: { fieldName, displayName, optional, helperText, propertyValues }, submitted }) => (
    <PropertyFormikInput
      name={fieldName}
      label={displayName}
      highlight={submitted}
      required={!optional}
      helperText={helperText}
      sourcesValues={propertyValues}
    />
  ),
  email: ({ property: { fieldName, displayName, optional, helperText, propertyValues }, submitted }) => (
    <FormikEmailInputField
      name={fieldName}
      label={displayName}
      highlight={submitted}
      required={!optional}
      helperText={helperText}
      sourcesValues={propertyValues}
    />
  ),
  addresses: () => null,
  number: ({ property: { fieldName, displayName, optional, helperText, propertyValues }, submitted }) => (
    <PropertyFormikInput
      name={fieldName}
      label={displayName}
      highlight={submitted}
      required={!optional}
      helperText={helperText}
      sourcesValues={propertyValues}
      type={'number'}
    />
  ),
  array: ({
    property: { fieldName, valuesSource, displayName, optional, helperText, maxItems, valueOptions, propertyValues },
    submitted,
    sourceToValues,
  }) => (
    <FormikMultiPicker
      name={fieldName}
      highlight={submitted}
      options={valueOptions ?? sourceToValues?.[valuesSource ?? ''] ?? []}
      label={displayName}
      required={!optional}
      helperText={helperText}
      maxItems={maxItems}
      sourcesValues={propertyValues}
    />
  ),
  boolean: ({ property: { fieldName, displayName, optional, helperText } }) => (
    <FormikCheckbox name={fieldName} label={displayName} required={!optional} helperText={helperText} />
  ),
  currency: ({ property: { fieldName, displayName, optional, helperText, propertyValues }, submitted }) => (
    <FormikMoneyInputField
      name={fieldName}
      label={displayName}
      highlight={submitted}
      required={!optional}
      helperText={helperText}
      sourcesValues={propertyValues}
    />
  ),
  date: ({ property: { fieldName, displayName, optional, helperText, propertyValues }, submitted }) => (
    <FormikDatePicker
      name={fieldName}
      label={displayName}
      highlight={submitted}
      disableFuture={false}
      required={!optional}
      helperText={helperText}
      sourcesValues={propertyValues}
    />
  ),
  document: () => null,
  phoneNumber: ({ property: { fieldName, displayName, optional, helperText, propertyValues }, submitted }) => (
    <FormikPhoneNumberField
      name={fieldName}
      label={displayName}
      highlight={submitted}
      required={!optional}
      helperText={helperText}
      sourcesValues={propertyValues}
    />
  ),
  object: (props) => <GenericObjectForm {...props} />,
  table: () => null,
  list: ({
    property: { fieldName, displayName, valuesSource, optional, propertyValues, helperText, valueOptions },
    submitted,
    sourceToValues,
  }) => (
    <FormikPicker
      values={valueOptions ?? sourceToValues?.[valuesSource ?? ''] ?? []}
      name={fieldName}
      label={displayName}
      required={!optional}
      sourcesValues={propertyValues}
      helperText={helperText}
      highlight={submitted}
    />
  ),
  address: () => null,
  website: ({ property: { fieldName, displayName, optional, helperText, propertyValues }, submitted }) => (
    <FormikWebsiteField
      name={fieldName}
      label={displayName}
      highlight={submitted}
      required={!optional}
      helperText={helperText}
      sourcesValues={propertyValues}
    />
  ),
};
