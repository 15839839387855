import type { BusinessApiModel } from '@lama/business-service-client';
import { v4 as uuidv4 } from 'uuid';
import { sub } from 'date-fns';

export const jpMorganDemoBusinesses: BusinessApiModel[] = [
  {
    id: uuidv4(),
    dba: 'Stumptown Coffee Roasters',
    industryTypes: ['Cafe', 'Restaurant', 'Food'],
    phoneNumber: '(503) 808-9080',
    website: 'https://www.stumptowncoffee.com/',
    addresses: [
      {
        state: 'Oregon',
        city: 'Portland',
        address1: '100 SE Salmon St',
        zip: '97214',
      },
    ],
    socialMediaHandles: [
      {
        platform: 'tiktok',
        url: 'https://www.tiktok.com/@stumptowncoffee',
      },
      {
        platform: 'pinterest',
        url: 'https://www.pinterest.com/stumptowncoffee/',
      },
      {
        platform: 'twitter',
        url: 'https://x.com/stumptowncoffee',
      },
      {
        platform: 'facebook',
        url: 'https://www.facebook.com/stumptowncoffee/',
      },
      {
        platform: 'instagram',
        url: 'https://www.instagram.com/stumptowncoffee/?hl=en',
      },
    ],
    startDate: sub(new Date(), { years: 24, months: 6 }).toISOString(),
    applicationId: '',
    partnerId: '',
    subsidiariesIds: [],
    sisterCompaniesIds: [],
    people: [],
    financials: [],
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  {
    id: uuidv4(),
    dba: 'Blue Bottle Coffee',
    industryTypes: ['Cafe', 'Restaurant', 'Food'],
    phoneNumber: '(510) 661-3510',
    website: 'https://bluebottlecoffee.com/us/eng',
    addresses: [
      {
        state: 'California',
        city: 'Oakland',
        address1: '476 9th Street',
      },
    ],
    socialMediaHandles: [
      {
        platform: 'youtube',
        url: 'https://www.youtube.com/channel/UCyki4e6RG84BT_xzi4oYkRw',
      },
      {
        platform: 'instagram',
        url: 'https://www.instagram.com/bluebottle',
      },
      {
        platform: 'twitter',
        url: 'https://x.com/bluebottleroast',
      },
      {
        platform: 'facebook',
        url: 'https://www.facebook.com/bluebottlecoffee',
      },
    ],
    startDate: sub(new Date(), { years: 22, months: 3 }).toISOString(),
    applicationId: '',
    partnerId: '',
    subsidiariesIds: [],
    sisterCompaniesIds: [],
    people: [],
    financials: [],
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
];
