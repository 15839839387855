import type { TechnologyPlatformAndSystems } from '@lama/business-service-client';
import { Flex, Grid, Text } from '@lama/design-system';
import type { FC } from 'react';
import React from 'react';
import { isNil } from 'lodash-es';
import { KeyValuePair } from '../../KeyValuePair';
import type { InfoLineProps } from '../../Information';

interface TechnologyPlatformAndSystemItemProps {
  item: TechnologyPlatformAndSystems;
}

export const TechnologyPlatformAndSystemItem: FC<TechnologyPlatformAndSystemItemProps> = ({
  item: { platformName, technologySupport },
}) => {
  const itemData: InfoLineProps[] = [
    ...(!isNil(technologySupport)
      ? [{ label: 'How does the technology support the business operations?', values: technologySupport, type: 'boolean' as const }]
      : []),
  ];

  return (
    <Flex gap={4} flexDirection={'column'}>
      <Text variant={'body1'} weight={700} color={'secondary'}>
        {platformName}
      </Text>
      <Grid columns={1}>
        {itemData.map((line) => (
          <KeyValuePair key={line.label} name={line.label} value={line.values} type={line.type} />
        ))}
      </Grid>
    </Flex>
  );
};
