import type { FC } from 'react';
import React, { useMemo, useContext, useCallback } from 'react';
import { Button, Flex, Text } from '@lama/design-system';
import { compact } from 'lodash-es';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { UserDetailsContext } from '../../../../shared/context/UserDetailsContext';
import { ApplicationContext } from '../../ApplicationContext';
import type { UnderwritingNoteRelatedSectionType } from '../noteUtils';
import { getUnderwritingNoteRelatedItemProperty } from '../noteUtils';
import { ApplicationAssignees } from '../../ApplicationHeader/ApplicationAssignees';
import { useUsersByPartnerQuery } from '../../../../shared/hooks/react-query/user/useUsersByPartnerQuery';
import { useUpdateOpportunityMutation } from '../../../../shared/hooks/react-query/opportunity/useUpdateOpportunityMutation';
import Check from './assets/check.svg';
import Uncheck from './assets/uncheck.svg';
import { useAddReviewedUnderwritingNoteMutation } from './hooks/useAddReviewedUnderwritingNoteMutation';
import { useDeleteUnderwritingNoteMutation } from './hooks/useDeleteUnderwritingNoteMutation';

interface SectionTitleProps {
  sectionId: string;
  title: string;
  sectionType: UnderwritingNoteRelatedSectionType;
}

export const SectionTitle: FC<SectionTitleProps> = ({ sectionId, title, sectionType }) => {
  const { opportunity } = useContext(ApplicationContext);
  const { userId } = useContext(UserDetailsContext);
  const { isHuntingtonDemo } = useFlags();
  const { mutate: addReviewedNote } = useAddReviewedUnderwritingNoteMutation(sectionId, sectionType, opportunity.id, userId!);
  const { mutate: deleteNote } = useDeleteUnderwritingNoteMutation(opportunity.id);
  const { mutateAsync: updateOpportunity } = useUpdateOpportunityMutation(opportunity.id);
  const { data: users, isLoading: loadingUsers } = useUsersByPartnerQuery(opportunity.partnerId);
  const sectionAssignees = useMemo(
    () =>
      compact(
        opportunity.taskAssignees
          ?.filter(({ taskRelatedSectionId }) => taskRelatedSectionId === sectionId)
          .map(({ userId: assignedUserId }) => users?.find((user) => user.id === assignedUserId)),
      ) ?? [],
    [opportunity.taskAssignees, sectionId, users],
  );

  const sectionReviewedNote = useMemo(() => {
    const sectionIdProperty = getUnderwritingNoteRelatedItemProperty(sectionType);
    return opportunity.underwriting?.notes?.find(
      (note) => note[sectionIdProperty] === sectionId && note.type === 'reviewed' && note.createdBy === userId,
    );
  }, [opportunity.underwriting?.notes, sectionType, sectionId, userId]);

  const onReadClick = useCallback(() => {
    if (!userId) {
      return;
    }

    if (sectionReviewedNote) {
      deleteNote(sectionReviewedNote.id);
      return;
    }

    addReviewedNote();
  }, [userId, sectionReviewedNote, addReviewedNote, deleteNote]);

  const updateSectionAssignees = useCallback(
    async (assigneeIds: string[]) => {
      if (!assigneeIds.length) {
        return;
      }
      const otherSectionAssigneees =
        opportunity.taskAssignees?.filter(({ taskRelatedSectionId }) => taskRelatedSectionId !== sectionId) ?? [];
      await updateOpportunity({
        taskAssignees: [
          ...otherSectionAssigneees,
          { userId: assigneeIds[0]!, taskRelatedSectionId: sectionId, sectionType: 'underwritingSection' },
        ],
      });
    },
    [opportunity.taskAssignees, sectionId, updateOpportunity],
  );

  return (
    <Flex justifyContent={'space-between'} alignItems={'center'} flexDirection={'row'} gap={1}>
      <Text variant={'h5'}>{title}</Text>
      <Flex gap={2}>
        {isHuntingtonDemo && !loadingUsers ? (
          <ApplicationAssignees
            assignees={sectionAssignees}
            users={users ?? []}
            allowMultipleAssignees={false}
            onUpdateAssignees={updateSectionAssignees}
          />
        ) : null}
        <Button
          variant={'secondary'}
          color={'neutral'}
          size={'m'}
          selected={!!sectionReviewedNote}
          startIcon={sectionReviewedNote ? Check : Uncheck}
          onClick={onReadClick}
        >
          {'Reviewed'}
        </Button>
      </Flex>
    </Flex>
  );
};
