import type { FC } from 'react';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import type { InputValue } from '@lama/design-system';
import { RichInput } from '@lama/design-system';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useToggle } from 'react-use';
import { useDebouncedCallback } from 'use-debounce';
import { isNil } from 'lodash-es';
import { ApplicationContext } from '../../ApplicationContext';
import { UserDetailsContext } from '../../../../shared/context/UserDetailsContext';
import { useCreateNoteMutation } from '../../../../shared/hooks/react-query/opportunity/useCreateNoteMutation';
import { useUpdateNoteMutation } from '../../../../shared/hooks/react-query/opportunity/useUpdateNoteMutation';
import { SegmentContainer } from '../SegmentContainer';
import { useUploadImage } from './hooks/useGetImageUploadUrl';

type UnsavedNotesByOpportunity = Record<string, Record<string, string | undefined>>;

const debounceTimeToStoreUnsavedNotes = 300;

const unsavedNotesByOpportunityLocalStorageKey = 'unsavedNotesByOpportunity';

export const InlineNote: FC<{
  title: string;
  noteName: string;
  valueTemplate?: string;
  placeholder?: string;
  backwardCompatibleNoteName?: string;
  entityId?: string;
}> = ({ title, noteName, valueTemplate, placeholder, backwardCompatibleNoteName, entityId }) => {
  const { allowImageUploadInInlineNotes } = useFlags();
  const { opportunity } = useContext(ApplicationContext);
  const { userId } = useContext(UserDetailsContext);
  const { mutateAsync: addNote } = useCreateNoteMutation(opportunity.id, userId);
  const { mutateAsync: updateNote } = useUpdateNoteMutation(opportunity.id, userId);
  const { mutateAsync: uploadImage } = useUploadImage();

  const unsavedNoteIdentifier = useMemo(() => `${noteName}${entityId ? `_${entityId}` : ''}`, [entityId, noteName]);
  const [unsavedNotesByOpportunity, setUnsavedNotesByOpportunity] = useState(
    JSON.parse(localStorage.getItem(unsavedNotesByOpportunityLocalStorageKey) ?? '{}') as UnsavedNotesByOpportunity,
  );
  const unsavedNote = useMemo(
    () => unsavedNotesByOpportunity[opportunity.id]?.[unsavedNoteIdentifier],
    [unsavedNotesByOpportunity, opportunity.id, unsavedNoteIdentifier],
  );

  const [editMode, toggleEditMode] = useToggle(!!unsavedNote);

  const sectionNote = useMemo(
    () =>
      opportunity.underwriting?.notes?.find(
        ({ title: noteTitle, entityId: noteEntityId }) =>
          noteName &&
          noteTitle === noteName &&
          (entityId ? noteEntityId === entityId || noteEntityId === backwardCompatibleNoteName : true),
      ),
    [backwardCompatibleNoteName, entityId, noteName, opportunity.underwriting?.notes],
  );

  const onImageSelected = useCallback(
    async (img: File) => {
      const src = await uploadImage({ opportunityId: opportunity.id, filename: img.name, fileContents: img });
      return { src };
    },
    [opportunity.id, uploadImage],
  );

  const updateOpportunityUnsavedNotes = useCallback(
    (value?: string) => {
      const updatedUnsavedNotesByOpportunity: UnsavedNotesByOpportunity = {
        ...unsavedNotesByOpportunity,
        [opportunity.id]: {
          ...unsavedNotesByOpportunity[opportunity.id],
          [unsavedNoteIdentifier]: value,
        },
      };
      localStorage.setItem(unsavedNotesByOpportunityLocalStorageKey, JSON.stringify(updatedUnsavedNotesByOpportunity));
      setUnsavedNotesByOpportunity(updatedUnsavedNotesByOpportunity);
    },
    [unsavedNoteIdentifier, opportunity.id, unsavedNotesByOpportunity],
  );

  const onNoteSubmit = useCallback(
    async ({ value }: InputValue) => {
      await (sectionNote
        ? updateNote({
            noteId: sectionNote?.id,
            notePayload: { text: value, entityId, title: noteName },
          })
        : addNote({
            id: uuidv4(),
            text: value,
            title: noteName,
            entityId,
            type: 'inline',
          }));
      updateOpportunityUnsavedNotes();
    },
    [sectionNote, updateNote, entityId, noteName, addNote, updateOpportunityUnsavedNotes],
  );

  const value = useMemo(() => unsavedNote ?? sectionNote?.text ?? valueTemplate, [sectionNote?.text, unsavedNote, valueTemplate]);

  const debouncedOnChange = useDebouncedCallback(updateOpportunityUnsavedNotes, debounceTimeToStoreUnsavedNotes);

  const onCancel = useCallback(() => {
    updateOpportunityUnsavedNotes();
  }, [updateOpportunityUnsavedNotes]);

  return (
    <SegmentContainer padding={'12px'} title={title}>
      <RichInput
        onImageSelected={allowImageUploadInInlineNotes ? onImageSelected : undefined}
        placeholder={placeholder ?? 'Type here'}
        value={value}
        onSubmit={onNoteSubmit}
        allowEmptyValue
        editMode={editMode}
        toggleEditMode={toggleEditMode}
        onChange={debouncedOnChange}
        hasUnsavedChanged={!isNil(unsavedNote)}
        onCancel={onCancel}
      />
    </SegmentContainer>
  );
};
