import React, { useMemo, useCallback, useContext, useState } from 'react';
import { LoadingPage } from '@lama/app-components';
import type { ApplicationCreateApiModel } from '@lama/clients';
import type { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { orderBy } from 'lodash-es';
import { Button, Flex, Modal, ModalContent } from '@lama/design-system';
import type { LabeledValue } from '@lama/contracts';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Autocomplete, TextField } from '@mui/material';
import { UserDetailsContext } from '../../shared/context/UserDetailsContext';
import { useProductsByPartnerQuery } from '../../shared/hooks/react-query/product/useProductsByPartnerQuery';
import { UserPartnerSelection } from '../../shared/components/UserPartnerSelection';
import { useCreateApplicationMutation } from './hooks/useCreateApplicationMutation';

const renderProductInput = (params: any) => <TextField {...params} label={'Product'} variant={'outlined'} fullWidth />;

const isOptionEqualToValue = (option: { value: string }, value: { value: string }) => option.value === value.value;

interface AddApplicationDialogProps {
  open: boolean;
  handleClose: (applicationId?: string) => void;
}

export const AddApplicationDialog: FC<AddApplicationDialogProps> = ({ open, handleClose }) => {
  const { enablePartnerSelection } = useFlags();
  const { partner: userPartner } = useContext(UserDetailsContext);
  const [partnerId, setPartnerId] = useState<string | null>(userPartner ?? null);
  const [productId, setProductId] = useState<string | null>(null);
  const { data: allPartnerProducts, isPending: loadingPartnerProducts } = useProductsByPartnerQuery(partnerId ?? undefined);
  const { mutateAsync: createApplication, isPending: creatingApplication } = useCreateApplicationMutation();

  const productOptions: LabeledValue[] = useMemo(
    () =>
      orderBy(allPartnerProducts, ['prettyName'], ['asc'])?.map((product) => ({
        value: product.id,
        label: product.prettyName,
      })) ?? [],
    [allPartnerProducts],
  );

  const currentProductOption = useMemo(() => productOptions.find((p) => p.value === productId) ?? null, [productOptions, productId]);

  const onPartnerChange = useCallback(
    (event: any, value: { label: string; value: string } | null) => {
      if (value?.value) {
        setProductId('');
        setPartnerId(value.value);
      }
    },
    [setPartnerId, setProductId],
  );

  const onProductChange = useCallback(
    (event: any, value: { label: string; value: string } | null) => {
      if (value?.value) {
        setProductId(value.value);
      }
    },
    [setProductId],
  );

  const onSumbit = useCallback(async () => {
    if (!productId || !partnerId) {
      return;
    }

    const createApplicationPayload: ApplicationCreateApiModel = {
      id: uuidv4(),
      originalProductId: productId,
      originatingPartner: partnerId,
      relatedBusinesses: [],
      relatedPeople: [],
    };

    await createApplication({ createApplicationPayload });

    handleClose(createApplicationPayload.id);
  }, [createApplication, handleClose, partnerId, productId]);

  return (
    <Modal size={'s'} open={open} onClose={handleClose} alignTitle={'center'} title={'Create Application'} fullWidth>
      <ModalContent>
        {loadingPartnerProducts ? (
          <LoadingPage />
        ) : (
          <Flex flexDirection={'column'} width={'100%'} gap={4}>
            {enablePartnerSelection ? (
              <UserPartnerSelection partnerId={partnerId} onPartnerChange={onPartnerChange} permissionFilter={'write'} />
            ) : null}
            <Autocomplete
              value={currentProductOption}
              options={productOptions}
              renderInput={renderProductInput}
              onChange={onProductChange}
              loading={loadingPartnerProducts}
              isOptionEqualToValue={isOptionEqualToValue}
            />
          </Flex>
        )}
      </ModalContent>
      <Flex justifyContent={'center'}>
        <Button size={'l'} variant={'secondary'} onClick={onSumbit} loading={creatingApplication} disabled={!productId || !partnerId}>
          {'Create'}
        </Button>
      </Flex>
    </Modal>
  );
};
