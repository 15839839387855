import { displayToast } from '@lama/app-components';
import type { OpportunityApiModel } from '@lama/clients';
import type { FormikHelpers } from 'formik';

import { isNil, uniqBy } from 'lodash-es';
import { useMemo, useContext, useCallback } from 'react';
import type { Period } from '@lama/selectors';
import { UserDetailsContext } from '../../../shared/context/UserDetailsContext';
import { getFinancialPayloadAction } from '../../Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/financialFieldUtils';
import { useSubmitFinancialsMutation } from '../../Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/hooks/useSubmitFinancialsMutation';
import { getAttributePeriod, type FormAttributesWithFinanacialData } from '../Forms/TaxReturns/formFinancialsUtils';

import { SpreadingDocumentContext } from '../SpreadingDocumentContext';
import { useGetAllFinancialsForCurrentDocument } from './useGetAllFinancialsForCurrentDocument';
import { useGetAttributesDocumentFunction } from './useGetAttributesDocumentFunction';
import { useUpdateSpreadingDocumentMutation } from './useUpdateSpreadingDocumentMutation';

export const useSubmitDocumentFinancials = (
  allFinancials: FormAttributesWithFinanacialData[],
  filter: string,
  opportunity: OpportunityApiModel | null,
  onSuccess: () => Promise<void>,
) => {
  const { userId } = useContext(UserDetailsContext);
  const { setSaving, currentDocument, currentPage } = useContext(SpreadingDocumentContext);
  const { mutateAsync: updateFinancialData } = useSubmitFinancialsMutation(
    currentDocument.relatedEntityId,
    currentDocument.relatedEntityType,
    opportunity?.id,
  );
  const { mutateAsync: updateDocument } = useUpdateSpreadingDocumentMutation({ applicationId: currentDocument.applicationId });
  const filteredFinancialAttributes = useMemo(() => {
    if (filter === 'missing') {
      return allFinancials.filter(({ financialData }) => isNil(financialData?.value));
    }

    if (filter === 'all') {
      return allFinancials;
    }

    return [];
  }, [filter, allFinancials]);

  const getAttributeDocumentPage = useGetAttributesDocumentFunction();
  const { fetchingData } = useGetAllFinancialsForCurrentDocument();
  return useCallback(
    async (
      newValues: Record<string, number | string | undefined> & { formTypeToPeriod: Record<string, Period | undefined> },
      { resetForm }: FormikHelpers<any>,
    ) => {
      try {
        if (!userId || fetchingData) {
          return;
        }

        setSaving(true);

        const { formTypeToPeriod } = newValues;

        const financialsPayload = uniqBy(filteredFinancialAttributes, 'id')
          .filter(({ attribute: { financialData } }) => financialData?.type && financialData?.startDate)
          .flatMap((attributeWithFinancialData) => {
            const currentFinancial = allFinancials.find(({ id }) => id === attributeWithFinancialData.id);
            const currentFormType = currentFinancial?.attribute?.formTypeId;

            if (!currentFormType) {
              return [];
            }

            const period = formTypeToPeriod[currentFormType];

            const { id, attribute, financialData: financialDataFromForm } = attributeWithFinancialData;

            const attributePeriod = getAttributePeriod(attribute, period);

            try {
              const payload = getFinancialPayloadAction({
                entityId: currentDocument.relatedEntityId,
                fieldToUpdate: financialDataFromForm,
                year: new Date(attributePeriod.startDate).getUTCFullYear(),
                fieldName: attribute.financialData!.type,
                value: newValues[id],
                userId,
                documentId: currentDocument.id,
                currentFormType,
                documentPageNumber: getAttributeDocumentPage(attributeWithFinancialData) ?? currentPage + 1,
                unverified: currentDocument.status !== 'Processed',
                startDate: attributePeriod?.startDate,
                endDate: attributePeriod?.endDate,
              });
              return payload;
            } catch (error) {
              console.error(error);
              return [];
            }
          });

        if (financialsPayload.length) {
          await updateFinancialData(financialsPayload);
        }

        if (currentDocument.documentPages?.length) {
          const allPagesWithUpdatedPeriod = currentDocument.documentPages.map((page) => ({
            ...page,
            formPeriod: formTypeToPeriod[page.formType] ?? page.formPeriod,
          }));

          await updateDocument({ documentId: currentDocument.id, update: { documentPages: allPagesWithUpdatedPeriod } });
        }

        await onSuccess();
      } catch {
        displayToast('Failed to save financial data', 'error');
      } finally {
        resetForm({ values: newValues });
        setSaving(false);
      }
    },
    [
      allFinancials,
      currentDocument.id,
      currentDocument.relatedEntityId,
      currentDocument.status,
      currentPage,
      fetchingData,
      filteredFinancialAttributes,
      getAttributeDocumentPage,
      onSuccess,
      setSaving,
      updateFinancialData,
      userId,
      updateDocument,
      currentDocument.documentPages,
    ],
  );
};
