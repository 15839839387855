import type { FC } from 'react';
import React, { useCallback, useState } from 'react';
import type { DocumentPage } from '@lama/document-service-client';
import type { OpportunityApiModel } from '@lama/clients';
import { Collapse, Flex, grayPalette, Text } from '@lama/design-system';
import styled from 'styled-components';
import type { FormAttributesWithFinanacialData } from '../../Forms/TaxReturns/formFinancialsUtils';
import ExpandButton from '../../../../shared/components/ExpandButton';
import { FormikFormPeriod } from '../../Forms/TaxReturns/components/FormikFormPeriod';

export interface SpreadingFormByDocumentTypeProps {
  opportunity?: OpportunityApiModel;
  formDocumentPages: DocumentPage[];
  formData?: FormAttributesWithFinanacialData[];
}

const StyledCard = styled(Flex)`
  background: white;
  flex-direction: column;
  transition: background 0.1s ease-in-out;
  transition: box-shadow 0.2s ease-in-out;
`;

const SpreadingContentHeader: FC<{ header: string; open: boolean; formType?: string; onClick: () => void }> = ({
  header,
  open,
  formType,
  onClick,
}) => (
  <Flex px={4} justifyContent={'space-between'} alignItems={'center'}>
    <Flex gap={4}>
      <Text variant={'h6'} color={'primary'}>
        {header}
      </Text>
      {formType ? <FormikFormPeriod formType={formType} /> : null}
    </Flex>
    <ExpandButton expand={open} sx={{ color: grayPalette[500] }} onClick={onClick} />
  </Flex>
);

export const SpreadingFormCard: FC<{ header: string; formType?: string; children: React.ReactNode }> = ({ header, formType, children }) => {
  const [open, setOpen] = useState(true);

  const onHeaderClick = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  return (
    <StyledCard>
      <SpreadingContentHeader header={header} open={open} formType={formType} onClick={onHeaderClick} />
      <Collapse expanded={open}>{children}</Collapse>
    </StyledCard>
  );
};
