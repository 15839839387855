import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from '@tanstack/react-query';
import type { SelectedEntity } from '@lama/fiserv-service-client';
import { searchExistingEntitiesClient } from '../../../clients/searchExistingCustomers';

export const useConvertExistingCustomerToEntity = (partnerId: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const selectExistingCustomer = useCallback<(externalIdentifier: string) => Promise<SelectedEntity>>(
    async (externalIdentifier: string) => {
      if (!partnerId) {
        return {} as SelectedEntity;
      }
      const token = await getAccessTokenSilently();

      const results = await searchExistingEntitiesClient.selectExistingEntity(externalIdentifier, partnerId, token);
      return results?.data;
    },
    [getAccessTokenSilently, partnerId],
  );

  return useMutation({
    mutationFn: selectExistingCustomer,
  });
};
