import type { EvaluatedOpportunityRequirement, OnlineBanking } from '@lama/contracts';
import React, { useContext } from 'react';
import { Stack, Typography } from '@mui/material';

import type { FC } from 'react';
import { Flex, Grid } from '@lama/design-system';
import type { InfoLineProps } from '@lama/app-components';
import { KeyValuePair } from '@lama/app-components';
import { SegmentContainer } from '../../../Application/shared/SegmentContainer';
import { ApplicationContext } from '../../../Application/ApplicationContext';
import { RequirementPrintContainer } from '../RequirementPrintContainer';

const AccountOpeningSegment: FC<{ onlineBanking: OnlineBanking }> = ({ onlineBanking }) => {
  const { wireCapability, fxWireLimit, wireLimit } = onlineBanking;
  const info: InfoLineProps[] = [
    {
      label: 'Wire Limit',
      values: wireLimit,
    },
    {
      label: 'FX Wire Limit',
      values: fxWireLimit,
    },
  ];

  return (
    <SegmentContainer title={wireCapability}>
      <Flex flexDirection={'column'} gap={4}>
        <Flex flexDirection={'column'} gap={4}>
          <Grid columns={3}>
            {info.map((line) => (
              <KeyValuePair key={line.label} name={line.label} value={line.values} type={line.type} />
            ))}
          </Grid>
        </Flex>
      </Flex>
    </SegmentContainer>
  );
};

export const OnlineBankingView = ({ requirement }: { requirement: EvaluatedOpportunityRequirement }) => {
  const { application } = useContext(ApplicationContext);

  if (!application.onlineBanking) {
    return (
      <RequirementPrintContainer title={requirement.name}>
        <Typography variant={'body2'}>{'No information found'}</Typography>
      </RequirementPrintContainer>
    );
  }

  return (
    <RequirementPrintContainer title={requirement.name}>
      <Stack gap={2}>
        {application.onlineBanking.map((onlineBanking) => (
          <AccountOpeningSegment key={onlineBanking.id} onlineBanking={onlineBanking} />
        ))}
      </Stack>
    </RequirementPrintContainer>
  );
};
