import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import type { AlertV2 } from '@lama/alerts-service-client';
import { alertsServiceClient } from '../../../clients/alertsServiceClient';

export const useGetOpportunityAlertsQuery = (opportunityId: string, options?: Partial<UseQueryOptions<AlertV2[]>>) => {
  const { getAccessTokenSilently } = useAuth0();

  const { getAlertsFromAlertsService, isHuntingtonDemo } = useFlags();

  const getOpportunityAlerts = useCallback(async () => {
    const token = await getAccessTokenSilently();

    const alerts = await (isHuntingtonDemo
      ? alertsServiceClient.getAlertsV2(opportunityId, token)
      : alertsServiceClient.getAlerts(opportunityId, token));

    return alerts ?? [];
  }, [getAccessTokenSilently, opportunityId, isHuntingtonDemo]);

  const query = useQuery<AlertV2[]>({
    queryKey: ['opportunityAlerts', opportunityId, getAlertsFromAlertsService],
    queryFn: getOpportunityAlerts,
    ...options,
  });

  return query;
};
