import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import type { EvaluatedOpportunityRequirement } from '@lama/contracts';
import type { DocumentWithIssues } from '@lama/document-service-client';
import { Flex } from '@lama/design-system';
import { getEnabledFileUploadSources } from '@lama/evaluations';
import { DocumentBox } from '../../../shared/components/DocumentBox/DocumentBox';
import { ApplicationContext } from '../ApplicationContext';
import { useIndependentDocumentsMoveToTree } from '../../DocumentHub/hooks/useIndependentDocumentsMoveToTree';

interface RequirementDrawerProps {
  requirement: EvaluatedOpportunityRequirement;
  requirementDocuments: DocumentWithIssues[];
}

export const RequirementDocuments: FC<RequirementDrawerProps> = ({ requirement, requirementDocuments: documents }) => {
  const { application, opportunity } = useContext(ApplicationContext);
  const { sources, entityId, entityType, applicationId, referenceRequirementId, key } = requirement;

  const moveToTreeData = useIndependentDocumentsMoveToTree({
    applicationId: application.id,
    opportunityId: opportunity.id,
    grouping: 'entity',
  });

  const documentBoxes = useMemo(() => {
    const enabledSources = getEnabledFileUploadSources({ sources: sources.uploadFilesSource ?? [], entityId, application });

    const predefinedDocumentsComponents =
      enabledSources.map(({ name: documentName, topic, dismissDataByEntity, extractable }) => (
        <DocumentBox
          key={documentName}
          description={documentName}
          topic={topic}
          document={documents.find((document) => document.topic === topic && document.relatedEntityId === entityId)}
          requirement={requirement}
          sharedRequirementId={referenceRequirementId}
          requirementKey={key}
          entityId={entityId}
          entityType={entityType}
          applicationId={applicationId}
          dismissed={dismissDataByEntity?.[entityId]}
          extractable={!!extractable}
          moveToTreeData={moveToTreeData}
        />
      )) ?? [];

    const topics = new Set(enabledSources.map((file) => file.topic));
    const arbitraryDocumentsComponents = documents
      .filter((document) => !document.topic || !topics.has(document.topic))
      .map((document) => (
        <DocumentBox
          key={document.id}
          description={document.filename}
          topic={document.topic}
          document={document}
          requirement={requirement}
          sharedRequirementId={referenceRequirementId}
          requirementKey={key}
          entityId={entityId}
          entityType={entityType}
          applicationId={applicationId}
          moveToTreeData={moveToTreeData}
        />
      ));

    return [...predefinedDocumentsComponents, ...arbitraryDocumentsComponents];
  }, [
    application,
    applicationId,
    documents,
    entityId,
    entityType,
    key,
    moveToTreeData,
    referenceRequirementId,
    requirement,
    sources.uploadFilesSource,
  ]);

  return documentBoxes.length ? (
    <Flex flexDirection={'column'} gap={4}>
      {documentBoxes}
    </Flex>
  ) : null;
};
