import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { Button, Flex, Input, Modal, ModalContent, ModalFooter } from '@lama/design-system';
import { useAsyncFn } from 'react-use';
import { isNil } from 'lodash-es';
import type { OnMetadataUpdate } from './types.js';

interface RenameDocumentModalProps {
  onSubmit?: OnMetadataUpdate;
  onClose: () => void;
  placeholder: string;
  fileName?: string;
  open: boolean;
}

export const RenameDocumentModal: FC<RenameDocumentModalProps> = ({
  onSubmit,
  onClose,
  open,
  placeholder: initialPlaceholder,
  fileName: initialFilename,
}) => {
  const initialFilenameWithoutExtension = useMemo(() => initialFilename?.split('.').slice(0, -1).join('.'), [initialFilename]);
  const [fileName, setFileName] = useState(initialFilenameWithoutExtension);
  const [placeholder, setPlaceholder] = useState(initialPlaceholder);

  const [{ loading }, handleSubmit] = useAsyncFn(async () => {
    await onSubmit?.({ description: placeholder, filename: fileName });
    onClose();
  }, [onSubmit, placeholder, fileName]);

  const onPlaceholderChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPlaceholder(e.target.value);
  }, []);

  const onFileNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFileName(e.target.value);
  }, []);

  const noDocument = useMemo(() => isNil(fileName), [fileName]);

  const saveButtonDisabled = useMemo(() => !placeholder || (!noDocument && !fileName), [placeholder, fileName, noDocument]);

  return (
    <Modal onClose={onClose} title={'Rename'} open={open} alignTitle={'center'} size={'s'} fullWidth>
      <ModalContent>
        <Flex flexDirection={'column'} gap={4} width={'100%'}>
          <Input label={'Label'} name={'Label'} value={placeholder} onChange={onPlaceholderChange} />
          {noDocument ? null : <Input label={'File Name'} name={'File Name'} value={fileName} onChange={onFileNameChange} />}
        </Flex>
      </ModalContent>
      <ModalFooter>
        <Button variant={'tertiary'} color={'neutral'} onClick={onClose}>
          {'Cancel'}
        </Button>
        <Button onClick={handleSubmit} loading={loading} disabled={saveButtonDisabled}>
          {'Save'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
