import React, { useCallback, useMemo, useState } from 'react';
import type { OpportunityClosingTask } from '@lama/contracts';
import type { Document } from '@lama/document-service-client';
import { Flex, Text, grayPalette, Collapse } from '@lama/design-system';
import type { FC } from 'react';
import { styled } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { CommentsSegment } from '../../../../shared/components/Comments/CommentsSegment';
import { closingTasksComponentsByType } from './ClosingTasksTypes/closingTasksComponentsMap';
import { TaskCardHeader } from './TaskCardHeader';
import { TaskCardFooter } from './TaskCardFooter';
import { TaskDocumentsSegment } from './TaskDocumentsSegment';

interface ClosingTaskCardProps {
  task: OpportunityClosingTask;
  taskDocuments?: Document[];
}

const StyledCard = styled(Flex)<{ open: boolean }>(
  ({ open }) => `
    border-radius: 8px;
    border: 1px solid;
    background: white;
    flex-direction: column;
    border-color: ${grayPalette[300]};
    transition: background 0.1s ease-in-out;
    transition: box-shadow 0.2s ease-in-out;
    ${open && 'box-shadow: 0px 8px 25px 0px rgba(56, 0, 100, 0.08);'}
`,
);

export const ClosingTaskCard: FC<ClosingTaskCardProps> = ({ task }) => {
  const { hideAdditionalDocumentsSection, id: taskId, description } = task;

  const [searchParams, setSearchParams] = useSearchParams();
  const closingTaskId = searchParams.get('task');

  const [open, setOpen] = useState(closingTaskId === taskId);

  const TaskComponent = useMemo(() => closingTasksComponentsByType[task.type], [task]);

  const onClick = useCallback((_clickedTaskId: string) => {
    setOpen((prev) => !prev);
  }, []);

  const onTransitionStateChange = useCallback(
    (state: 'collapseEnd' | 'collapseStart' | 'collapsing' | 'expandEnd' | 'expanding' | 'expandStart') => {
      if (state === 'expandEnd') {
        setSearchParams({ task: taskId }, { replace: true, preventScrollReset: true });
      } else if (state === 'collapseEnd') {
        setSearchParams({}, { replace: true, preventScrollReset: true });
      }
    },
    [setSearchParams, taskId],
  );

  return (
    <StyledCard open={open}>
      <TaskCardHeader open={open} onClick={onClick} task={task} />
      <Collapse expanded={open} onTransitionStateChange={onTransitionStateChange}>
        <Flex flexDirection={'column'} gap={6} p={6}>
          {description ? (
            <Text variant={'body1'} color={'secondary'}>
              {description}
            </Text>
          ) : null}
          {TaskComponent ? <TaskComponent task={task} /> : null}
          {hideAdditionalDocumentsSection ? null : <TaskDocumentsSegment task={task} />}
          <TaskCardFooter task={task} />
          <CommentsSegment relatedItemId={taskId} relatedItemType={'closingTask'} showExportHelperText={false} />
        </Flex>
      </Collapse>
    </StyledCard>
  );
};
