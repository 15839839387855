import { canUserApproveOpportunity, canUserRecommendOpportunity } from '@lama/approval-flows';
import type { OpportunityApiModel } from '@lama/clients';
import type { ProductApiModel } from '@lama/contracts';
import type { User } from '@lama/user-service-client';

export const isUserDecisionMaker = ({
  user,
  opportunity,
  product,
}: {
  user: User;
  opportunity: OpportunityApiModel;
  product?: ProductApiModel;
}) => canUserApproveOpportunity(user, { opportunity, product });

export const isUserRecommender = ({
  user,
  opportunity,
  product,
}: {
  user: User;
  opportunity: OpportunityApiModel;
  product?: ProductApiModel;
}) => canUserRecommendOpportunity(user, { opportunity, product });
