import React, { useCallback, useContext, useMemo, type FC } from 'react';
import { Autocomplete, IconButton, TextField } from '@mui/material';
import { compact, uniq, sortBy, isNil, maxBy } from 'lodash-es';
import { Flex, Text, greyPalette } from '@lama/design-system';
import styled from 'styled-components';
import type { Entity } from '@lama/common-types';
import type { TopicAnalysisParams } from '@lama/contracts';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import type { PageNavigationPlugin, RenderGoToPageProps } from '@react-pdf-viewer/page-navigation';
import ReactTextTransition from 'react-text-transition';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LoadingPage, useConfirmModal } from '@lama/app-components';
import { financialStatementFormNames } from '@lama/spreading';
import { useGetFormTypesQuery } from '../../Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/hooks/useGetFormTypesQuery';
import { SpreadingDocumentContext } from '../SpreadingDocumentContext';
import { useGetAllFinancialsForCurrentDocument } from '../hooks/useGetAllFinancialsForCurrentDocument';
import { getRelevantFinancialsForFormType } from '../hooks/useGetAllDocumentAttributesWithFinancialData';
import { useDocumentLineItemsQuery } from '../../../shared/hooks/react-query/document/lineItems/useDocumentLineItemsQuery';
import { useUpdateDocumentLineItemsMutation } from '../../../shared/hooks/react-query/document/lineItems/useUpdateDocumentLineItemsMutation';
import { useDeleteFinancialsMutation } from '../../Application/CreditMemo/Spreads/hooks/useDeleteFinancialsMutation';
import { useUpdateSpreadingDocumentMutation } from '../hooks/useUpdateSpreadingDocumentMutation';

const StyledToolbarContainer = styled(Flex)<{ open: boolean }>`
  position: absolute;
  left: 50%;
  bottom: 5%;
  transform: translate(-50%, ${({ open }) => (open ? '10%' : '200%')});
  transition: transform 0.3s ease-in-out;
  min-width: 85%;
  min-height: 50px;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 13px 0px rgba(0, 0, 0, 0.33);
  opacity: 0.7;
  transition: opacity 0.3s ease-in-out;
  &:hover {
    opacity: 1;
  }
`;

const DEFAULT_MAX_NUMBER_OF_PAGES = 6;

const getFirstRelevantFormTypeByEntityType = (formTypes: TopicAnalysisParams[], entityType?: string) =>
  formTypes.find(({ entityType: formTypeEntityType }) => formTypeEntityType === entityType);

const getFormTypeToUpdate = (
  allFormTypes: TopicAnalysisParams[],
  formTypeValue: string,
  documentEntityType: Entity,
  currentFormType?: TopicAnalysisParams,
  selectedYear?: string,
) => {
  const relevantFormTypes = allFormTypes.filter(({ baseFormType, id }) => baseFormType === formTypeValue || id === formTypeValue) ?? [];

  const documentPagesWithYears = relevantFormTypes.filter(({ year }) => !isNil(year));

  if (!documentPagesWithYears.length) {
    return relevantFormTypes[0];
  }

  const yearValue = selectedYear ?? currentFormType?.year;

  const currentRelevantYearFormTypes = documentPagesWithYears.filter(({ year }) => year === yearValue);

  if (currentRelevantYearFormTypes.length) {
    return (
      getFirstRelevantFormTypeByEntityType(currentRelevantYearFormTypes, currentFormType?.entityType ?? documentEntityType) ??
      currentRelevantYearFormTypes[0]
    );
  }

  const latestRelevantFormTypeYear = maxBy(documentPagesWithYears, ({ year }) => year)?.year;
  const latestYearFormTypes = documentPagesWithYears.filter(({ year }) => year === latestRelevantFormTypeYear);

  return (
    getFirstRelevantFormTypeByEntityType(latestYearFormTypes, currentFormType?.entityType ?? documentEntityType) ?? latestYearFormTypes[0]
  );
};

const renderInput = (placeholder: string, customParams?: any) => (params: any) =>
  (
    <TextField
      variant={'standard'}
      // we add this because the element is inaccessible in tests
      // eslint-disable-next-line @typescript-eslint/naming-convention
      sx={{ '& *': { pointerEvents: 'auto' } }}
      {...params}
      {...customParams}
      placeholder={placeholder}
      label={placeholder}
    />
  );

const renderFormTypeInput = renderInput('Page Form Type');
const renderYearInput = renderInput('Form Year');
const renderPageInput = renderInput('Form Page');

interface DocumentToolbarProps {
  open: boolean;
  pageNavigationPluginInstance: PageNavigationPlugin;
  documetHasMultiplePages?: boolean;
}

export const DocumentToolbar: FC<DocumentToolbarProps> = ({ open, pageNavigationPluginInstance, documetHasMultiplePages }) => {
  const { currentDocument, currentPage, currentFormType, applicationId, getPeriodByFormType } = useContext(SpreadingDocumentContext);
  const { data: allFormTypes } = useGetFormTypesQuery();
  const { spreadingBackOfficeMode } = useFlags();
  const { entityFinancials, fetchingData: loadingDocumentFinancials } = useGetAllFinancialsForCurrentDocument();
  const { data: documentLineItems, isPending: loadingDocumentLineItems } = useDocumentLineItemsQuery(
    currentDocument.id,
    !!spreadingBackOfficeMode,
  );
  const { mutateAsync: updateDocumentLineItems } = useUpdateDocumentLineItemsMutation();
  const { mutateAsync: deleteDocumentFinancials } = useDeleteFinancialsMutation();
  const { mutateAsync: updateDocument, isPending: updatingDocument } = useUpdateSpreadingDocumentMutation({ applicationId });
  const { confirm } = useConfirmModal();

  const currentDocumentPage = useMemo(
    () => currentDocument.documentPages?.find(({ page }) => page === currentPage + 1),
    [currentDocument.documentPages, currentPage],
  );

  const loadingRequiredData = useMemo(
    () => loadingDocumentFinancials || loadingDocumentLineItems,
    [loadingDocumentFinancials, loadingDocumentLineItems],
  );

  const deleteCurrentPageFinancials = useCallback(async () => {
    if (!currentDocument.id || !currentFormType?.id || !entityFinancials?.length) {
      return;
    }
    const financialsToDelete = getRelevantFinancialsForFormType(currentDocument.id, entityFinancials ?? [], currentFormType.id, [
      currentPage + 1,
    ]);

    const lineItemsToDelete = documentLineItems?.filter(({ page, attribute }) => page === currentPage + 1 && attribute);

    if (!financialsToDelete.length && !lineItemsToDelete?.length) {
      return;
    }

    await confirm({
      title: 'Delete Financials & Line Items',
      type: 'danger',
      message: `Found ${financialsToDelete.length} financials and ${lineItemsToDelete?.length} line items on this page. Are you sure you want to delete them?`,
      confirmText: 'Delete',
      cancelText: 'Cancel',
    });

    if (lineItemsToDelete?.length) {
      const lineItemUpdates = lineItemsToDelete.map((lineItem) => ({ id: lineItem.id, attribute: null }));

      await updateDocumentLineItems({ documentId: currentDocument.id, lineItemUpdateBody: { lineItemUpdates } });
    }

    if (!lineItemsToDelete?.length && financialsToDelete.length) {
      const financialIds = financialsToDelete.map(({ id }) => id);
      const financialsEntityIds = financialsToDelete.find(({ entityId }) => entityId);
      const financialsEntityType = financialsToDelete.find(({ entityType }) => entityType);

      if (!financialsEntityIds?.entityId || !financialsEntityType?.entityType) {
        return;
      }

      await deleteDocumentFinancials({
        entityId: financialsEntityIds.entityId,
        entityType: financialsEntityType.entityType,
        financialIds,
      });
    }
  }, [
    currentDocument.id,
    entityFinancials,
    currentFormType?.id,
    currentPage,
    documentLineItems,
    confirm,
    updateDocumentLineItems,
    deleteDocumentFinancials,
  ]);

  const onSelectedFormTypeChange = useCallback(
    async (_event: any, value: string | null) => {
      if (!allFormTypes?.formTypes.length) {
        return;
      }

      const otherPagesFormTypes = currentDocument.documentPages?.filter(({ page }) => page !== currentPage + 1) ?? [];
      const prevPageFormType = currentDocument.documentPages?.find(({ page }) => page === currentPage);

      await deleteCurrentPageFinancials();

      if (!value) {
        await updateDocument({ documentId: currentDocument.id, update: { documentPages: otherPagesFormTypes } });
        return;
      }

      const selectedFormType = getFormTypeToUpdate(allFormTypes.formTypes, value, currentDocument.relatedEntityType, currentFormType);

      if (!selectedFormType) {
        return;
      }

      const updatedDocumentPages = [
        ...otherPagesFormTypes,
        {
          page: currentPage + 1,
          formType: selectedFormType.id,
          formPage: prevPageFormType?.formType === selectedFormType.id ? prevPageFormType.formPage + 1 : 1,
          year: selectedFormType.year,
          formPeriod: getPeriodByFormType(selectedFormType.id),
        },
      ];

      await updateDocument({ documentId: currentDocument.id, update: { documentPages: updatedDocumentPages } });
    },
    [
      allFormTypes?.formTypes,
      currentDocument.documentPages,
      currentDocument.id,
      currentDocument.relatedEntityType,
      currentFormType,
      currentPage,
      deleteCurrentPageFinancials,
      updateDocument,
      getPeriodByFormType,
    ],
  );

  const onSelectedYearChange = useCallback(
    async (_event: any, newYear?: string | null) => {
      if (!allFormTypes?.formTypes.length) {
        return;
      }

      if (!newYear || !currentFormType) {
        return;
      }

      const prevPageFormType = currentDocument.documentPages?.find(({ page }) => page === currentPage);
      const otherPagesFormTypes = currentDocument.documentPages?.filter(({ page }) => page !== currentPage + 1) ?? [];

      await deleteCurrentPageFinancials();

      const selectedFormType = getFormTypeToUpdate(
        allFormTypes.formTypes,
        currentFormType.baseFormType ?? currentFormType.id,
        currentDocument.relatedEntityType,
        currentFormType,
        newYear,
      );

      if (!selectedFormType) {
        return;
      }

      const updatedDocumentPages = [
        ...otherPagesFormTypes,
        {
          page: currentPage + 1,
          formType: selectedFormType.id,
          formPage: prevPageFormType?.formType === currentFormType.id ? prevPageFormType.formPage + 1 : 1,
          year: newYear,
          formPeriod: getPeriodByFormType(selectedFormType.id),
        },
      ];

      await updateDocument({ documentId: currentDocument.id, update: { documentPages: updatedDocumentPages } });
    },
    [
      allFormTypes?.formTypes,
      currentFormType,
      currentDocument.documentPages,
      currentDocument.relatedEntityType,
      currentDocument.id,
      deleteCurrentPageFinancials,
      currentPage,
      updateDocument,
      getPeriodByFormType,
    ],
  );

  const onSelectFormPage = useCallback(
    async (_event: any, newPage?: string) => {
      if (!currentDocument || !newPage) {
        return;
      }

      const updatedDocumentPages = currentDocument.documentPages?.map((documentPage) =>
        documentPage.page === currentPage + 1 ? { ...documentPage, formPage: Number(newPage) } : documentPage,
      );

      await updateDocument({ documentId: currentDocument.id, update: { documentPages: updatedDocumentPages } });
    },
    [currentDocument, currentPage, updateDocument],
  );

  const formTypeOptions = useMemo(
    () => sortBy(uniq(allFormTypes?.formTypes?.map(({ baseFormType, id }) => baseFormType ?? id))),
    [allFormTypes?.formTypes],
  );

  const yearOptions = useMemo(
    () =>
      sortBy(
        uniq(
          compact(
            allFormTypes?.formTypes.filter(({ baseFormType }) => baseFormType === currentFormType?.baseFormType).map(({ year }) => year),
          ),
        ),
      ),
    [allFormTypes?.formTypes, currentFormType?.baseFormType],
  );

  const formPageOptions = useMemo(
    () =>
      Array.from({ length: currentFormType?.numberOfPagesInForm ?? DEFAULT_MAX_NUMBER_OF_PAGES }).map((_, index) => (index + 1).toString()),
    [currentFormType?.numberOfPagesInForm],
  );

  const currentBaseFormType = useMemo(() => currentFormType?.baseFormType ?? currentFormType?.id, [currentFormType]);

  const formPageDisabled = useMemo(
    () =>
      !currentFormType ||
      (!yearOptions.length && currentFormType.baseFormType && !financialStatementFormNames.includes(currentFormType.baseFormType)) ||
      updatingDocument ||
      loadingRequiredData,
    [currentFormType, yearOptions, updatingDocument, loadingRequiredData],
  );

  const formYearDisabled = useMemo(
    () => !currentFormType || !yearOptions.length || updatingDocument || loadingRequiredData,
    [currentFormType, yearOptions.length, updatingDocument, loadingRequiredData],
  );

  if (loadingRequiredData) {
    return <LoadingPage />;
  }

  if (!allFormTypes?.formTypes?.length) {
    return null;
  }

  return (
    <StyledToolbarContainer gap={8} px={3} py={2} open={open} flex={1}>
      <Flex flexDirection={'row'} gap={2} alignItems={'center'} justifyContent={'center'}>
        <Flex width={'70px'}>
          <Text variant={'body1'}>
            <ReactTextTransition inline>{`Page ${currentPage + 1}`}</ReactTextTransition>
          </Text>
        </Flex>
        {documetHasMultiplePages ? (
          <>
            <pageNavigationPluginInstance.GoToPreviousPage>
              {({ onClick, isDisabled }: RenderGoToPageProps) => (
                <IconButton sx={{ width: '24px', height: '24px' }} onClick={onClick} disabled={isDisabled} disableRipple>
                  <ExpandLess sx={{ color: greyPalette[isDisabled ? 400 : 700] }} />
                </IconButton>
              )}
            </pageNavigationPluginInstance.GoToPreviousPage>
            <pageNavigationPluginInstance.GoToNextPage>
              {({ onClick, isDisabled }: RenderGoToPageProps) => (
                <IconButton sx={{ width: '24px', height: '24px' }} onClick={onClick} disabled={isDisabled} disableRipple>
                  <ExpandMore sx={{ color: greyPalette[isDisabled ? 400 : 700] }} />
                </IconButton>
              )}
            </pageNavigationPluginInstance.GoToNextPage>
          </>
        ) : null}
      </Flex>
      <Flex flex={1} gap={6}>
        <Autocomplete
          fullWidth
          options={formTypeOptions}
          renderInput={renderFormTypeInput}
          value={currentBaseFormType ?? ''}
          onChange={onSelectedFormTypeChange}
          disabled={updatingDocument}
        />
        <Autocomplete
          fullWidth
          options={yearOptions}
          renderInput={renderYearInput}
          value={currentFormType?.year ?? ''}
          disabled={formYearDisabled}
          onChange={onSelectedYearChange}
          disableClearable
          sx={{ maxWidth: '80px' }}
        />
        <Autocomplete
          fullWidth
          options={formPageOptions}
          renderInput={renderPageInput}
          value={currentDocumentPage?.formPage.toString() ?? ''}
          disabled={formPageDisabled}
          onChange={onSelectFormPage}
          hidden={!spreadingBackOfficeMode}
          disableClearable
          sx={{ maxWidth: '80px' }}
        />
      </Flex>
    </StyledToolbarContainer>
  );
};
