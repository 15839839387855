import React, { useMemo, useContext, forwardRef } from 'react';
import {
  guarantorsSelector,
  relatedBusinessesByRelationsSelector,
  parseDateWithoutTime,
  parseUTCDateToString,
} from '@lama/data-formatters';
import { LoadingPage } from '@lama/app-components';
import { Flex, Text } from '@lama/design-system';
import type { SpreadComponentConfiguration } from '@lama/contracts';
import type { Entity } from '@lama/common-types';
import type { ApplicationRelatedBusinessApiModel, ApplicationRelatedPersonApiModel } from '@lama/clients';
import type { BusinessApiModel, RelatedPersonApiModel } from '@lama/business-service-client';
import { compact, groupBy, uniq } from 'lodash-es';
import { getApplicationEntityByType } from '@lama/properties';
import { max } from 'date-fns';
import { ApplicationContext } from '../../ApplicationContext';
import type { EntitiesByType } from '../../CreditMemo/Spreads/components/SpreadTable/types';
import { SpreadTable } from '../../CreditMemo/Spreads/components/SpreadTable';
import { useGetMultiEntityFinancialsQuery } from '../../../../shared/hooks/react-query/financials/useGetMultiEntityFinancialsQuery';
import { useSpreadConfigurationById } from '../Spreads/hooks/useSpreadConfiguration';
import type { SpreadTableRef } from '../../CreditMemo/Spreads/hooks/types';

interface GenericSpreadSegmentProps {
  spreadConfiguration: SpreadComponentConfiguration;
  entity?: Record<string, any> & { id: string };
  entityType?: Entity;
  onGridReady?: () => void;
}

export const GenericSpread = forwardRef<SpreadTableRef, GenericSpreadSegmentProps>(
  (
    {
      spreadConfiguration: { spreadId, name, spreadEntityStructure, relationByEntityType, spreadType, showEntityNames, title },
      entity,
      entityType,
      onGridReady,
    },
    spreadTableRef,
  ) => {
    const { application, opportunity, properties } = useContext(ApplicationContext);

    // TODO: simplify this calculation once all people are returned in a flat array on the application api model
    const relevantPeople: ApplicationRelatedPersonApiModel[] = useMemo(() => {
      if (spreadEntityStructure === 'singleEntity' && entity) {
        if (entityType === 'business') {
          return guarantorsSelector(entity as BusinessApiModel, application).map<ApplicationRelatedPersonApiModel>((p) => ({
            person: p,
            relations: ['guarantor' as const],
          }));
        }

        if (entityType === 'person') {
          return [{ person: entity as RelatedPersonApiModel, relations: [] }];
        }
      }

      if (spreadEntityStructure === 'multiEntity') {
        const people = relationByEntityType?.person?.flatMap((r) =>
          getApplicationEntityByType(application, 'person', [r]).map((p) => ({ person: p as RelatedPersonApiModel, relations: [r] })),
        );

        const peopleByPersonId = groupBy(people ?? [], (p) => p.person.id);
        const peopleWithGroupedRelations = compact(
          Object.values(peopleByPersonId).map<ApplicationRelatedPersonApiModel | null>((group) => {
            const person = group[0]?.person;

            if (!person) {
              return null;
            }

            const relations = uniq(group.flatMap((p) => p.relations));
            return { person, relations };
          }),
        );

        return peopleWithGroupedRelations;
      }

      return [];
    }, [application, entity, entityType, relationByEntityType?.person, spreadEntityStructure]);

    const relevantBusinesses: ApplicationRelatedBusinessApiModel[] = useMemo(() => {
      if (spreadEntityStructure === 'singleEntity' && entity && entityType === 'business') {
        return [application.relatedBusinesses.find((b) => b.business.id === entity.id)!];
      }

      if (spreadEntityStructure === 'multiEntity') {
        return relatedBusinessesByRelationsSelector(application, relationByEntityType?.business ?? []);
      }

      return [];
    }, [application, entity, entityType, relationByEntityType?.business, spreadEntityStructure]);

    const relevantEntityIds = useMemo(
      () => [...relevantPeople.map((p) => p.person.id), ...relevantBusinesses.map((b) => b.business.id)].map((id) => ({ entityId: id })),
      [relevantBusinesses, relevantPeople],
    );

    const { financials, loading } = useGetMultiEntityFinancialsQuery(relevantEntityIds, false);

    const spreadConfiguration = useSpreadConfigurationById(spreadId);

    const spreadEntities: EntitiesByType = useMemo(
      () => ({
        business: relevantBusinesses,
        person: relevantPeople,
        application: [application],
        opportunity: [opportunity],
      }),
      [relevantBusinesses, relevantPeople, application, opportunity],
    );

    const latestFinancialDate = useMemo(() => {
      if (entity && entityType === 'business' && spreadType === 'balanceSheet') {
        const business = entity as BusinessApiModel;
        return business.financials.length ? max(business.financials.map(({ endDate }) => parseDateWithoutTime(endDate))) : undefined;
      }

      return;
    }, [entity, entityType, spreadType]);

    if (loading) {
      return <LoadingPage />;
    }

    if (!spreadConfiguration) {
      return <Text variant={'body1'}>{'Spreads are not configured, please contact support.'}</Text>;
    }

    return (
      <Flex flexDirection={'column'} gap={2}>
        {latestFinancialDate ? (
          <Flex justifyContent={'flex-end'}>
            <Text variant={'body2'}>{`As of ${parseUTCDateToString(latestFinancialDate)}`}</Text>
          </Flex>
        ) : null}
        <SpreadTable
          entities={spreadEntities}
          properties={properties}
          applicationId={application.id}
          opportunity={opportunity}
          spreadsSettings={opportunity.spreadsSettings}
          spreadConfig={spreadConfiguration}
          financials={financials}
          spreadName={name}
          ref={spreadTableRef}
          periodDisplayFormat={spreadType === 'balanceSheet' ? 'balance sheet' : 'income statement'}
          showEntityNames={!!showEntityNames}
          onGridReady={onGridReady}
          title={title}
        />
      </Flex>
    );
  },
);
