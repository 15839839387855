import React, { useContext, useMemo } from 'react';
import type { OpportunityClosingTask } from '@lama/contracts';
import { personNameWithRelationsSelector, businessNameWithRelationsSelector } from '@lama/data-formatters';
import { Flex, Text } from '@lama/design-system';
import { getOpportunityEntityByType } from '@lama/properties';
import type { FC } from 'react';
import { useApplicationDocumentsQuery } from '../../../../shared/hooks/react-query/application/useApplicationDocumentsQuery';
import { ApplicationContext } from '../../ApplicationContext';
import { AdditionalDocumentsSection } from './AdditionalDocumentsSection';

export const TaskDocumentsSegment: FC<{ task: OpportunityClosingTask }> = ({ task }) => {
  const { documentIds, requiredDocuments, entityType, entityGroups } = task;
  const { opportunity, application } = useContext(ApplicationContext);
  const { data: relatedDocuments } = useApplicationDocumentsQuery({ applicationId: application.id });
  const taskDocuments = useMemo(
    () => relatedDocuments?.filter((document) => documentIds?.includes(document.id)) ?? [],
    [relatedDocuments, documentIds],
  );

  const taskEntities = useMemo(
    () =>
      entityType && entityGroups
        ? getOpportunityEntityByType(opportunity, entityType, entityGroups).map((e) => {
            const entityName =
              entityType === 'application' || entityType === 'opportunity'
                ? null
                : entityType === 'person'
                ? personNameWithRelationsSelector(e.id, application)
                : businessNameWithRelationsSelector(e.id, application);
            return { entity: e, entityName };
          })
        : [],
    [application, entityGroups, entityType, opportunity],
  );

  return taskEntities.length ? (
    <Flex flexDirection={'column'} gap={4}>
      {taskEntities.map(({ entity, entityName }) => (
        <Flex key={entity.id} flexDirection={'column'} gap={4}>
          <Text variant={'body2'} color={'secondary'}>
            {entityName}
          </Text>
          <AdditionalDocumentsSection
            documents={taskDocuments}
            taskDocumentRequirements={requiredDocuments}
            relatedEntityId={entity.id}
            relatedEntityType={entityType}
            closingTask={task}
          />
        </Flex>
      ))}
    </Flex>
  ) : (
    <AdditionalDocumentsSection documents={taskDocuments} closingTask={task} taskDocumentRequirements={requiredDocuments} />
  );
};
