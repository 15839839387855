import { v4 as uuidv4 } from 'uuid';
import type { CreateDocumentLineItemApiModel, DocumentLineItem, SpreadingDocument } from '@lama/document-service-client';
import type { Period } from '../components/FinancialStatementsSpreadingContent';

type FinancialStatementNewRow = Record<string, string> & {
  attribute?: string;
  page: string;
  parentId?: string;
  text?: string;
};

export const createNewLineItemFromRow = (
  values: Partial<DocumentLineItem>,
  { id: documentId, relatedEntityId: entityId, relatedEntityType: entityType }: SpreadingDocument,
  currentFormType: string,
  allPeriods: Period[],
): CreateDocumentLineItemApiModel => {
  // react-material-table + TS force us to use DocumentLineItem as the template type for the row, when in fact it is a FinancialStatementNewRow
  const actualValues = values as unknown as FinancialStatementNewRow;
  const { text, parentId, attribute, page } = actualValues;

  return {
    id: uuidv4(),
    text: text ?? '',
    parentId: parentId || undefined,
    attribute: attribute || undefined,
    documentId,
    entityId,
    entityType,
    page: +page,
    formType: currentFormType,
    unverified: true,
    values: allPeriods.map(({ startDate, endDate }) => {
      const parsedValue = Number(actualValues[`${startDate}-${endDate}`]);
      const itemStartDate = startDate.toISOString();
      const itemEndDate = endDate.toISOString();
      return {
        startDate: itemStartDate,
        endDate: itemEndDate,
        value: Number.isNaN(parsedValue) ? undefined : parsedValue,
      };
    }),
  };
};
