import React, { Suspense, useContext, useMemo, useState } from 'react';
import type { FC } from 'react';
import { compact, isNil, orderBy, uniq } from 'lodash-es';
import { format } from 'date-fns';
import { Flex, Text, greyPalette } from '@lama/design-system';
import type { AuditLabel } from '@lama/audit-service-client';
import { ApplicationContext } from '../ApplicationContext';
import { useOpportunityAuditQueryV2 } from '../../../shared/hooks/react-query/audit/getOpportunityAuditV2Query';
import { consecutiveGroupBy } from '../../../shared/components/Audit/consecutiveGroupBy';
import { AuditLoading, StyledAuditFeedContainer } from '../../../shared/components/Audit';
import { GroupedAudit } from '../../../shared/components/Audit/AuditV2/GroupedAudit';
import { AuditLabelsFilters } from './AuditLabelsFilters';

const EmptyStateComponent: FC = () => (
  <Text variant={'body2'} color={greyPalette[700]}>
    {'No Results'}
  </Text>
);

export const EngagementHubPageDemo: FC = () => {
  const { opportunity } = useContext(ApplicationContext);
  const { data: auditEntries } = useOpportunityAuditQueryV2(opportunity.id, { staleTime: 30_000 });

  const [checkedLabels, setCheckedLabels] = useState<AuditLabel[] | null>(null);
  const allAuditLabels = useMemo(() => uniq(auditEntries?.flatMap((audit) => audit.labels ?? []) ?? []), [auditEntries]);

  const groupedAudits = useMemo(() => {
    if (!auditEntries?.length) {
      return [];
    }

    const groups = orderBy(
      Object.values(
        consecutiveGroupBy(auditEntries, (auditEntry) =>
          [
            format(new Date(auditEntry.timestamp), 'yyyy/MM/dd:HH'),
            auditEntry.action,
            auditEntry.originType === 'user' ? auditEntry.user?.id : 'system',
            auditEntry.entityId,
          ].join('_'),
        ),
      ).map((groupedAuditEntries) => orderBy(groupedAuditEntries, (x) => x.timestamp, 'desc')),
      (x) => x[0]?.timestamp,
      'desc',
    );

    const groupsWithUnifiedLabels = groups.map((group) => {
      const chosenLabel = compact(group.flatMap(({ labels }) => labels ?? [])).at(0) ?? 'borrowerEngagement';

      return group.map((entry) => (chosenLabel ? { ...entry, labels: [chosenLabel] } : entry));
    });

    const groupWithValidEntries = groupsWithUnifiedLabels.map((group) =>
      group.filter(
        (entry) => !((entry.originType === 'user' && (!entry.user?.firstName || !entry.user.lastName)) || !entry.labels?.length),
      ),
    );

    const groupsWithEntries = groupWithValidEntries.filter((group) => group.length);

    return groupsWithEntries;
  }, [auditEntries]);

  const filteredGroupedAudits = useMemo(
    () =>
      isNil(checkedLabels)
        ? groupedAudits
        : groupedAudits.filter((group) => {
            const groupLabel = compact(group.flatMap(({ labels }) => labels))?.at(0);

            return groupLabel && checkedLabels.includes(groupLabel);
          }),
    [groupedAudits, checkedLabels],
  );

  return (
    <Flex flex={1} minHeight={'90vh'}>
      <Suspense fallback={AuditLoading}>
        {/* <Flex borderRight={'1px solid #E0E0E0'} flexDirection={'column'} flex={1} p={10} maxWidth={'80%'}> */}
        <Flex flexDirection={'column'} flex={1} p={2} pl={6} maxWidth={'80%'}>
          <StyledAuditFeedContainer flexDirection={'column'} gap={8}>
            {filteredGroupedAudits.length ? (
              filteredGroupedAudits.map((hourAudits) => <GroupedAudit key={hourAudits[0]?.id} groupedAudits={hourAudits} />)
            ) : (
              <EmptyStateComponent />
            )}
          </StyledAuditFeedContainer>
        </Flex>
        {/* <Flex pt={10} pl={10}> */}
        <Flex pt={2}>
          <AuditLabelsFilters labels={allAuditLabels} onLabelsChange={setCheckedLabels} />
        </Flex>
      </Suspense>
    </Flex>
  );
};
