import type { FC } from 'react';
import React from 'react';
import { Flex, Grid, Text } from '@lama/design-system';
import type { AccountOpening } from '@lama/contracts';
import { isNil } from 'lodash-es';
import { KeyValuePair } from '../../KeyValuePair';
import type { InfoLineProps } from '../../Information';

interface AccountItemProps {
  item: AccountOpening;
}

export const AccountItem: FC<AccountItemProps> = ({ item: { requestedProducts, transactionVolume } }) => {
  const itemData: InfoLineProps[] = [
    ...(!isNil(transactionVolume) ? [{ label: 'Transaction Volume', values: transactionVolume, type: 'currency' as const }] : []),
  ];

  return (
    <Flex gap={4} flexDirection={'column'}>
      <Text variant={'body1'} weight={700} color={'secondary'}>
        {requestedProducts}
      </Text>
      <Grid columns={1}>
        {itemData.map((line) => (
          <KeyValuePair key={line.label} name={line.label} value={line.values} type={line.type} />
        ))}
      </Grid>
    </Flex>
  );
};
