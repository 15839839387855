import React, { useContext } from 'react';
import type { SpreadingDocument } from '@lama/document-service-client';
import { Divider } from '@mui/material';
import type { FC } from 'react';
import { Modal } from '@lama/design-system';
import { ApplicationContext } from '../../Application/ApplicationContext';
import { SpreadingDocumentProvider } from '../SpreadingDocumentContext';
import { SpreadingDocumentHighlightProvider } from '../SpreadingDocumentHighlightContext';
import { SpreadingDialogContent } from './SpreadingContent';
import { SpreadingToolbar } from './SpreadingToolbar/SpreadingToolbar';

interface DocumentFinancialDataDialogProps {
  toggleDialog: () => void;
  open: boolean;
  documents: SpreadingDocument[];
}

export const SpreadingDialog: FC<DocumentFinancialDataDialogProps> = ({ open, toggleDialog, documents }) => {
  const {
    application: { id: applicationId },
    opportunity: { id: opportunityId },
  } = useContext(ApplicationContext);

  return (
    <Modal open={open} p={0} fullscreen hideCloseButton>
      {open && documents.length ? (
        <SpreadingDocumentHighlightProvider>
          <SpreadingDocumentProvider documents={documents} applicationId={applicationId} opportunityId={opportunityId}>
            <SpreadingToolbar onClose={toggleDialog} />
            <Divider />
            <SpreadingDialogContent />
          </SpreadingDocumentProvider>
        </SpreadingDocumentHighlightProvider>
      ) : null}
    </Modal>
  );
};
