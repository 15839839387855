import React, { useContext, useMemo } from 'react';
import type { FC, FunctionComponent } from 'react';
import ReactMarkdown from 'react-markdown';
import { isNil } from 'lodash-es';
import { capitalCase } from 'change-case-all';
import { Avatar } from '@lama/app-components';
import type { AuditLabel } from '@lama/audit-service-client';
import { Badge, Box } from '@mui/material';
import { Flex, Text, greyPalette } from '@lama/design-system';
import { applicationImportantPeopleSelector, formatValue, personPrimaryRelation } from '@lama/data-formatters';
import LamaAiAvatar from '../../../shared/icons/lamaAi.svg';
import type { AuditLogProps } from '../../../shared/components/Audit/AuditLogProps';
import { OmniCaptureEmailDemo } from '../OmniCaptureEmailDemo/OmniCaptureEmailDemo';
import { ApplicationContext } from '../ApplicationContext';
import { AuditLabelColorDot } from './AuditLabelColorDot';

const AuditConnector = () => (
  <Box
    className={'audit-connector'}
    sx={{
      mt: 1,
      position: 'absolute',
      left: '12px',
      top: '24px',
      height: '120%',
      borderLeft: '2px solid #f4f4f9',
      marginTop: '0px',
      overflow: 'hidden',
    }}
  />
);

const DotDivider = () => (
  <Text variant={'body2'} color={greyPalette[500]}>
    {' • '}
  </Text>
);

const AuditContent: FC<{ text: string; label: AuditLabel }> = ({ text, label }) => (
  <Flex gap={2} alignItems={'center'} marginLeft={8} width={'100%'} overflow={'hidden'}>
    <AuditLabelColorDot label={label} size={'8px'} />
    <ReactMarkdown components={{ a: OmniCaptureEmailDemo }}>{text}</ReactMarkdown>
  </Flex>
);

const AuditInfo: FC<AuditLogProps> = ({ user, timestamp, badge, originType }) => {
  const { application } = useContext(ApplicationContext);

  const userName = useMemo(
    () => (originType === 'system' ? 'Lama AI' : `${user?.firstName ?? ''} ${user?.lastName ?? ''}`),
    [originType, user?.firstName, user?.lastName],
  );

  const absoluteDateTime = useMemo(() => formatValue(timestamp, 'datetime'), [timestamp]);
  const isLamaAi = useMemo(() => originType === 'system', [originType]);

  const role = useMemo(() => {
    if (isLamaAi) {
      return 'Bot';
    }

    if (!user?.id) {
      return null;
    }

    if (user.type === 'lender') {
      const person = applicationImportantPeopleSelector(application).find((p) => p.person.id === user.personId)?.person;

      return person?.position ?? 'Lender';
    }

    return personPrimaryRelation({
      personId: user.id,
      application,
      relationsPriority: ['borrower', 'co-borrower', 'guarantor', 'owner', 'executive', 'principal', 'guest'],
    });
  }, [isLamaAi, user, application]);

  return (
    <Flex flexDirection={'row'} justifyContent={'start'} gap={2} alignItems={'center'} width={'100%'} overflow={'hidden'}>
      <Badge overlap={'circular'} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} badgeContent={badge}>
        {isLamaAi ? (
          <LamaAiAvatar />
        ) : (
          <Avatar
            size={24}
            imgSize={'24px'}
            fontSize={'12px'}
            firstName={userName.split(' ')[0] ?? ''}
            lastName={userName.split(' ')[1] ?? ''}
            alt={userName}
          />
        )}
      </Badge>
      <Text variant={'body2'} color={greyPalette[700]} noWrap>
        {userName}
      </Text>
      <DotDivider />
      {isNil(role) ? null : (
        <>
          <Text variant={'body3'} color={greyPalette[500]} noWrap>
            {capitalCase(role)}
          </Text>
          <DotDivider />
        </>
      )}
      <Text variant={'body3'} color={greyPalette[500]} noWrap>
        {absoluteDateTime}
      </Text>
    </Flex>
  );
};

export const EngagementAuditLog: FunctionComponent<AuditLogProps> = (props) => {
  const { labels, originType, user, customText, text } = props;

  const label = useMemo(() => labels?.[0] ?? null, [labels]);

  if ((originType === 'user' && (!user.firstName || !user.lastName)) || isNil(label)) {
    return null;
  }

  return (
    <Flex
      flexDirection={'column'}
      position={'relative'}
      alignItems={'start'}
      gap={2}
      width={'100%'}
      className={'audit-log'}
      minWidth={'350px'}
    >
      <AuditInfo {...props} />
      <AuditContent label={label} text={customText || text} />
      <AuditConnector />
    </Flex>
  );
};
