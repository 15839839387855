import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { monthlyProposedDebtServiceSelector } from '@lama/selectors';
import type { InformationTypes } from '@lama/app-components';
import type { CellContent, CellHeaderContent } from '../../creditMemoTable/CreditMemoTable';
import { CreditMemoTable } from '../../creditMemoTable/CreditMemoTable';
import { ApplicationContext } from '../../../../ApplicationContext';

const headers: CellHeaderContent[] = Array.from({ length: 13 }, (_, i) => ({
  text: (i === 0 ? 'Month' : i).toString(),
  textVariant: 'body4',
}));

const calcMonthlyWorkingCapital = (statedWc: number, netCashFlow: number) => {
  const result = [];
  for (let i = 0; i < 12; i++) {
    const beginning: number = (i === 0 ? statedWc : result[i - 1]?.ending) ?? 0;
    const ending = beginning + netCashFlow;
    result.push({
      beginning,
      ending,
    });
  }
  return result;
};

const nameCellStyle: Partial<CellContent> = {
  textVariant: 'body4',
  noWrap: false,
};

const currencyCellStyle: Partial<CellContent> = {
  noWrap: true,
  textVariant: 'body4',
};

export const GrasshopperWorkingCapitalAnalysis: FC = () => {
  const { application } = useContext(ApplicationContext);
  const rows = useMemo<CellContent[][]>(() => {
    const proposedDebtService =
      monthlyProposedDebtServiceSelector({
        application,
        entity: application,
        entityType: 'application',
        periodToFinancialsByEntityId: {},
        selectedPeriod: null,
        selectedYear: null,
      }) ?? 0;

    const projectedNetIncome = application.projectedNetIncome ?? 0;
    const netCashFlow = projectedNetIncome - proposedDebtService;
    const workingCapital = calcMonthlyWorkingCapital(application?.beginningWorkingCapital ?? 0, netCashFlow);

    return [
      [
        { value: 'Projected Net Income', ...nameCellStyle },
        ...Array.from({ length: 12 }, () => ({
          type: 'currencyCompact' as InformationTypes,
          value: projectedNetIncome,
          ...currencyCellStyle,
        })),
      ],
      [
        { value: 'Loan Payment', ...nameCellStyle },
        ...Array.from({ length: 12 }, () => ({
          type: 'currencyCompact' as InformationTypes,
          value: proposedDebtService,
          ...currencyCellStyle,
        })),
      ],
      [
        { value: 'Net Cash Flow', ...nameCellStyle },
        ...Array.from({ length: 12 }, () => ({
          type: 'currencyCompact' as InformationTypes,
          value: netCashFlow,
          ...currencyCellStyle,
        })),
      ],
      [
        { value: 'Beginning Working Capital', ...nameCellStyle },
        ...workingCapital.map((wc) => ({
          type: 'currencyCompact' as InformationTypes,
          value: wc.beginning,
          ...currencyCellStyle,
        })),
      ],
      [
        { value: 'Ending Working Capital', ...nameCellStyle },
        ...workingCapital.map((wc) => ({
          type: 'currencyCompact' as InformationTypes,
          value: wc.ending,
          ...currencyCellStyle,
        })),
      ],
    ];
  }, [application]);

  return <CreditMemoTable rows={rows} headers={headers} />;
};
