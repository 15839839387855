import React from 'react';
import type { FC } from 'react';
import type { ColDef, ColGroupDef } from '@ag-grid-community/core';
import type { SpreadRowPeriodValue, SpreadRow } from '@lama/spreads-generator-client';
import { Flex } from '@lama/design-system';
import type { SpreadTooltipProps } from '../SpreadTooltip';
import { AdjustmentFooterActions } from './AdjustmentFooterActions';
import { FinancialChildFooterActions } from './FinancialChildFooterActions';

export interface SpreadTooltipFooterProps extends SpreadTooltipProps {
  propertyKey?: string;
  entityId: string;
  currentValue: SpreadRowPeriodValue | null;
  colDef?: ColDef<SpreadRow> | ColGroupDef<SpreadRow> | null;
  allowRowAdjustments?: boolean;
  allowAddingChildren?: boolean;
  opportunityId: string;
  parentPropertyKey?: string;
  rowDisplayName: string;
  childrenNames?: string[];
}

export const SpreadTooltipFooter: FC<SpreadTooltipFooterProps> = ({
  propertyKey,
  colDef,
  applicationId,
  endDate,
  startDate,
  entityId,
  currentValue,
  hideTooltipCallback,
  allowRowAdjustments,
  allowAddingChildren,
  opportunityId,
  parentPropertyKey,
  rowDisplayName,
  childrenNames,
}) => (
  <Flex flexDirection={'column'} gap={2}>
    <AdjustmentFooterActions
      existingAdjustment={currentValue?.financialAttribute?.selectedSource.adjustment}
      propertyKey={propertyKey}
      colDef={colDef}
      applicationId={applicationId}
      endDate={endDate}
      startDate={startDate}
      entityId={entityId}
      currentValue={currentValue}
      hideTooltipCallback={hideTooltipCallback}
      allowRowAdjustments={allowRowAdjustments}
      opportunityId={opportunityId}
    />
    <FinancialChildFooterActions
      propertyKey={propertyKey}
      endDate={endDate}
      startDate={startDate}
      entityId={entityId}
      currentValue={currentValue}
      hideTooltipCallback={hideTooltipCallback}
      opportunityId={opportunityId}
      allowAddingChildren={allowAddingChildren}
      parentPropertyKey={parentPropertyKey}
      rowDisplayName={rowDisplayName}
      childrenNames={childrenNames}
    />
  </Flex>
);
