import type { EvaluatedOpportunityRequirement } from '@lama/contracts';
import React, { useContext, useMemo } from 'react';
import { Flex, Text, greyPalette } from '@lama/design-system';
import { applicationCollateralSelector } from '@lama/data-formatters';
import { ApplicationContext } from '../../../Application/ApplicationContext';
import { CollateralSummaryTable } from '../../../Application/CreditMemo/Sections/CollateralSection/SouthStateCollateralSection/CollateralSummaryTable';
import { RequirementPrintContainer } from '../RequirementPrintContainer';
import { CollateralSegment } from './Segments/CollateralSegment';

export const CollateralView = ({ requirement }: { requirement: EvaluatedOpportunityRequirement }) => {
  const { application } = useContext(ApplicationContext);

  const collateral = useMemo(() => applicationCollateralSelector(application), [application]);

  if (!collateral) {
    return (
      <RequirementPrintContainer title={requirement.name}>
        <Text variant={'body2'}>{'No information found'}</Text>
      </RequirementPrintContainer>
    );
  }

  if (!collateral.length) {
    return (
      <RequirementPrintContainer title={requirement.name}>
        <Text variant={'body2'}>{'Applicant marked no collateral'}</Text>
      </RequirementPrintContainer>
    );
  }

  return (
    <RequirementPrintContainer title={requirement.name}>
      <Flex flexDirection={'column'} gap={4}>
        <Text variant={'h6'} color={greyPalette[500]}>
          {'Summary'}
        </Text>
        <CollateralSummaryTable />
      </Flex>
      <Flex flexDirection={'column'} gap={4}>
        <Text variant={'h6'} color={greyPalette[500]}>
          {'Breakdown'}
        </Text>
        <Flex flexDirection={'column'} gap={4}>
          {collateral.map((c) => (
            <CollateralSegment key={c.id} collateral={c} />
          ))}
        </Flex>
      </Flex>
    </RequirementPrintContainer>
  );
};
