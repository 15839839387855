import type { ReactNode, SyntheticEvent } from 'react';
import React, { useCallback, useState } from 'react';
import { Button, Flex, Text } from '@lama/design-system';
import { DocumentBox } from '@lama/app-components';
import type { Document } from '@lama/document-service-client';
import { Divider } from '@mui/material';
import MagicTextIcon from './magicTextIcon.svg';
import MagicDocumentIcon from './magicDocumentIcon.svg';

const demoApplicationLinesData = [
  { label: 'Borrower Name', value: 'Jane Bernier', icon: <MagicTextIcon /> },
  { label: 'Co-Borrower Name', value: 'John Doe', icon: <MagicTextIcon /> },
  { label: 'Business Legal Name', value: 'Moore, Benier and Bednar', icon: <MagicTextIcon /> },
  { label: 'Loan Amount', value: '$250,000', icon: <MagicTextIcon /> },
  { label: 'Use Of Funds', value: 'Equipment Financing', icon: <MagicTextIcon /> },
  { label: 'Annual Revenue', value: '$1,250,000', icon: <MagicDocumentIcon /> },
  { label: 'Credit Score', value: '740', icon: <MagicDocumentIcon /> },
];

const fakeDocumentDetails: Document[] = [
  {
    applicationId: '',
    description: 'Credit Report',
    filename: 'Credit Report.pdf',
    id: '',
    relatedEntityId: '',
    relatedEntityType: 'application',
    s3Bucket: '',
    s3Key: '',
    s3Region: '',
    status: 'Processed',
    createdAt: '2025-01-01T00:00:00Z',
    updatedAt: '2025-01-01T00:00:00Z',
    uploaderId: '',
    topic: 'Some Topic',
    extractable: true,
  },
  {
    applicationId: '',
    description: 'Financial Statement',
    filename: 'Financial Statement.pdf',
    id: '',
    relatedEntityId: '',
    extractable: true,
    relatedEntityType: 'application',
    s3Bucket: '',
    s3Key: '',
    s3Region: '',
    status: 'Processed',
    createdAt: '2025-01-01T00:00:00Z',
    updatedAt: '2025-01-01T00:00:00Z',
    uploaderId: '',
    topic: 'Some Topic',
  },
  {
    applicationId: '',
    description: "Driver's License",
    filename: 'license.pdf',
    id: '',
    relatedEntityId: '',
    extractable: true,
    relatedEntityType: 'application',
    s3Bucket: '',
    s3Key: '',
    s3Region: '',
    status: 'Processed',
    createdAt: '2025-01-01T00:00:00Z',
    updatedAt: '2025-01-01T00:00:00Z',
    uploaderId: '',
    topic: 'Some Topic',
  },
];

const ApplicationDetailsLine = ({ label, value, icon }: { label: string; value: string; icon: ReactNode }) => (
  <Flex flexDirection={'column'} gap={1}>
    <Flex justifyContent={'space-between'} alignItems={'center'}>
      <Flex gap={2} alignItems={'center'}>
        {icon}
        <Text variant={'body1'}>{label}</Text>
      </Flex>
      <Text variant={'body1'}>{value}</Text>
    </Flex>
    <Divider />
  </Flex>
);

const generatedMailSubject = 'Next Steps for Your Loan Application';

const generatedMailBody =
  'Hi John,%0A' +
  'Thank you for your email.%0A%0A' +
  "We've received your loan request.%0A" +
  'In order to continue with your application, please log in to the portal via https://hungtintonbank.lamabanking.com/login,%0A' +
  'or simply reply to this email and attach the following documents:%0A%0A' +
  '- Bank Statements for the last 6 months%0A' +
  '- Business Tax Returns %0A%0A' +
  "Once we receive these, we'll proceed with the review.%0A%0A" +
  'Thank you,%0A' +
  'Emma Markobs%0A' +
  'Your Banker, Huntington Bank';

export const DemoApplicationDetails = () => {
  const [generating, setGenerating] = useState(false);
  const onGenerateClick = useCallback((e: SyntheticEvent) => {
    setGenerating(true);
    setTimeout(() => {
      setGenerating(false);
      window.location.href = `mailto:jane.b@mooreberbed.com?subject=${generatedMailSubject}&body=${generatedMailBody}`;
    }, 2500);

    e.preventDefault();
  }, []);

  return (
    <Flex flexDirection={'column'} gap={6} height={'100%'}>
      <Flex flexDirection={'column'} gap={4}>
        <Text variant={'h5'}>{'New Application Details'}</Text>
        <Flex flexDirection={'column'} gap={6}>
          {demoApplicationLinesData.map((d) => (
            <ApplicationDetailsLine key={d.label} label={d.label} value={d.value} icon={d.icon} />
          ))}
        </Flex>
      </Flex>
      <Flex flexDirection={'column'} gap={4}>
        <Text variant={'h6'}>{'Attachments'}</Text>
        <Flex flexDirection={'column'} gap={2}>
          {fakeDocumentDetails.map((d) => (
            <DocumentBox key={d.filename} document={d} showExtractionStatus />
          ))}
        </Flex>
      </Flex>
      <Flex flex={1} alignItems={'flex-end'} justifyContent={'flex-end'} pb={4}>
        <Button onClick={onGenerateClick} height={'50px'} variant={'primary'} loading={generating}>
          {'Generate Response'}
        </Button>
      </Flex>
    </Flex>
  );
};
