import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import type { DocumentLineItem } from '@lama/document-service-client';
import { toast } from 'react-toastify';
import { documentServiceClient } from '../../../../clients/documentServiceClient';

interface DeleteDocumentLineItem {
  documentId: string;
  lineItemId: string;
}

export const useDeleteDocumentLineItemMutation = (options?: UseMutationOptions<void, unknown, DeleteDocumentLineItem>) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  const deleteDocumentLineItemMutation = useCallback(
    async ({ documentId, lineItemId }: DeleteDocumentLineItem) => {
      const token = await getAccessTokenSilently();

      await documentServiceClient.deleteDocumentLineItem(documentId, lineItemId, token);
    },
    [getAccessTokenSilently],
  );

  return useMutation({
    mutationFn: deleteDocumentLineItemMutation,
    onMutate: async ({ documentId, lineItemId }: DeleteDocumentLineItem) => {
      await queryClient.cancelQueries({ queryKey: ['documentLineItems', documentId] });

      const previousLineItems = queryClient.getQueryData(['documentLineItems', documentId]);

      queryClient.setQueryData(['documentLineItems', documentId], (old: any) =>
        old.filter((lineItem: DocumentLineItem) => lineItem.id !== lineItemId),
      );

      await queryClient.invalidateQueries({ queryKey: ['documentLineItems', documentId] });

      return { previousLineItems, documentId };
    },
    onError: (error, variables, context: any) => {
      if (context?.previousLineItems && context?.documentId) {
        queryClient.setQueryData(['documentLineItems', context.documentId], context.previousLineItems);
      }
      toast.error('Failed to delete line item');
    },
    onSettled: (b, a, { documentId }) => {
      void queryClient.invalidateQueries({ queryKey: ['documentLineItems', documentId] });
    },
    ...options,
  });
};
