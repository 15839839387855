import type { BusinessApiModel } from '@lama/business-service-client';
import { naicsCodes } from '@lama/contracts';
import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import {
  tangibleNetWorth,
  twoYearAverageNetOperatingIncome,
  getLongestPeriodByYearForEntity,
  getPeriodToFinancialsByEntityId,
} from '@lama/selectors';
import { isNil } from 'lodash-es';
import { formatValue } from '@lama/data-formatters';
import { Grid } from '@lama/design-system';
import { KeyValuePair } from '@lama/app-components';
import { ApplicationContext } from '../../ApplicationContext';
import { SegmentContainer } from '../SegmentContainer';

export const RelatedCompanySegment: FC<{ relatedCompany: BusinessApiModel }> = ({ relatedCompany }) => {
  const {
    application,
    opportunity: { referenceYear },
  } = useContext(ApplicationContext);

  const periodToFinancialsByEntityId = useMemo(() => getPeriodToFinancialsByEntityId(application), [application]);

  const formattedAverageNetOperatingIncome = useMemo(() => {
    const twoYearAvgNetOperatingIncome = twoYearAverageNetOperatingIncome({
      application,
      entity: relatedCompany,
      entityType: 'business',
      periodToFinancialsByEntityId,
      selectedYear: null,
      selectedPeriod: getLongestPeriodByYearForEntity({
        periodToFinancialsByEntityId,
        selectedYear: referenceYear,
        entity: relatedCompany,
      }),
    });
    return isNil(twoYearAvgNetOperatingIncome) ? '-' : formatValue(twoYearAvgNetOperatingIncome, 'currency');
  }, [relatedCompany, application, periodToFinancialsByEntityId, referenceYear]);

  const formattedTangibleNetWorth = useMemo(() => {
    const value = tangibleNetWorth({
      application,
      entity: relatedCompany,
      entityType: 'business',
      periodToFinancialsByEntityId,
      selectedYear: null,
      selectedPeriod: getLongestPeriodByYearForEntity({
        periodToFinancialsByEntityId,
        selectedYear: referenceYear,
        entity: relatedCompany,
      }),
    });
    return isNil(value) ? '-' : formatValue(value, 'currency');
  }, [relatedCompany, application, periodToFinancialsByEntityId, referenceYear]);

  const formattedNaicsCode = useMemo(
    () => naicsCodes.find((naicsCode) => naicsCode.value === relatedCompany.naicsCodes?.at(0))?.label ?? '-',
    [relatedCompany],
  );

  return (
    <SegmentContainer title={relatedCompany.legalName}>
      <Grid columns={3}>
        <KeyValuePair name={'2-Year Avg. Net Income'} value={formattedAverageNetOperatingIncome} />
        <KeyValuePair name={'Tangible Net Worth'} value={formattedTangibleNetWorth} />
        <KeyValuePair name={'NAICS Code'} value={formattedNaicsCode} />
      </Grid>
    </SegmentContainer>
  );
};
