import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Flex, greyPalette, Text } from '@lama/design-system';
import { ReportsFilterContext, ReportsFilterProvider } from './ReportsFilterContext';
import { ReportsFilters } from './ReportsFilters';
import { AanReport } from './Reports/AanReport';
import { HmdaReport } from './Reports/HmdaReport';
import { NoaReport } from './Reports/NoaReport';
import { OfacResultsReport } from './Reports/OfacResultsReport';
import { PipelineReport } from './Reports/PipelineReport';
import { RegBReport } from './Reports/RegBReport';
import { WithdrawlReport } from './Reports/WithdrawlReport';
import { CreditAdminReport } from './Reports/CreditAdminReport';
import { EmptyReportCardState } from './EmptyReportCardState';
import { ReportCard } from './ReportCard';
import { HmdaReportableReport } from './Reports/HmdaReportableReport';
import { Section1071ReportableReport } from './Reports/Section1071ReportableReport';

const NoReportSelected: FC = () => (
  <ReportCard>
    <Flex flexDirection={'column'} gap={2} alignItems={'center'} justifyContent={'center'}>
      <EmptyReportCardState />
      <Text textAlign={'center'} variant={'body2'} color={greyPalette[600]}>
        {'No report selected'}
      </Text>
    </Flex>
  </ReportCard>
);

const reportNameToComponentMap: Record<string, FC> = {
  pipeline: PipelineReport,
  aan: AanReport,
  withdrawal: WithdrawlReport,
  hmda: HmdaReport,
  ofac: OfacResultsReport,
  noa: NoaReport,
  regB: RegBReport,
  creditAdmin: CreditAdminReport,
  hmdaReportable: HmdaReportableReport,
  section1071ApplicableApplications: Section1071ReportableReport,
};

const Report: FC = () => {
  const { report } = useContext(ReportsFilterContext);
  const ReportComponent = useMemo(() => reportNameToComponentMap[report] ?? NoReportSelected, [report]);

  return (
    <Flex flexDirection={'column'} width={'100%'} height={'100%'}>
      <ReportComponent />
    </Flex>
  );
};

export const ReportsPage: FC = () => (
  <ReportsFilterProvider>
    <Flex width={'100%'} height={'100%'} flexDirection={'column'} px={10} py={10} gap={10} backgroundColor={'#FAFAFA'}>
      <Flex flexDirection={'column'} gap={6}>
        <Text variant={'h4'}>{'Reports'}</Text>
        <ReportsFilters />
      </Flex>
      <Report />
    </Flex>
  </ReportsFilterProvider>
);
