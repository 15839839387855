import type { Editor, UseEditorOptions } from '@tiptap/react';
import { useEditor, mergeAttributes, Extension } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import MentionPlugin from '@tiptap/extension-mention';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import type { SuggestionProps } from '@tiptap/suggestion';
import Fuse from 'fuse.js';
import { useEffect, useRef, type MutableRefObject } from 'react';
import type { SuggestionOption } from './types';

const extensions = [StarterKit];

const filterAndSortNames = (query: string, names: SuggestionOption[]): SuggestionOption[] => {
  const fuse = new Fuse(names, {
    keys: ['label'],
    threshold: 0.3,
  });

  const result = fuse.search(query);

  return result.map(({ item }) => item) ?? [];
};

export interface MentionSuggestionsHandle {
  onKeyDown: (event: KeyboardEvent) => boolean;
}

export const mentionListKeyStrokes = ['ArrowUp', 'ArrowDown', 'Enter'];

interface EditModeTextProps extends UseEditorOptions {
  enableMentions?: boolean;
  currentUserId?: string;
  suggestions?: SuggestionOption[];
  suggestionsListRef?: MutableRefObject<any>;
  setSuggestionProps?: (props: SuggestionProps | null) => void;
  submit?: (editor: Editor) => Promise<void>;
}
export const useRichEditor = ({
  suggestionsListRef,
  enableMentions,
  currentUserId,
  suggestions = [],
  setSuggestionProps,
  submit,
  editorProps,
  content,
  ...restOfProps
}: EditModeTextProps) => {
  const cursor = useRef<number>();

  const editor = useEditor(
    {
      extensions: [
        Image.configure({
          inline: true,
          allowBase64: true,
          HTMLAttributes: {
            style: 'max-width: 100%;',
          },
        }),
        Extension.create({
          addKeyboardShortcuts: () => ({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Cmd-Enter': ({ editor: editorArg }) => {
              void submit?.(editorArg);
              return true;
            },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Ctrl-Enter': ({ editor: editorArg }) => {
              void submit?.(editorArg);
              return true;
            },
          }),
        }),
        Link.configure({
          defaultProtocol: 'https',
          protocols: ['http', 'https'],
        }),
        ...extensions,
        ...(enableMentions
          ? [
              MentionPlugin.configure({
                renderHTML: ({ node, options }) => {
                  const isCurrentUser = node.attrs.id === currentUserId;
                  const customClass = isCurrentUser ? 'mention-current-user' : 'mention';

                  return ['span', mergeAttributes(options.HTMLAttributes, { class: customClass }), `${node.attrs.label}`];
                },
                suggestion: {
                  items: ({ query }) => (query.length > 0 ? filterAndSortNames(query, suggestions ?? []) : suggestions ?? []),
                  render: () => ({
                    onKeyDown: ({ event }) => {
                      if (mentionListKeyStrokes.includes(event.key)) {
                        event.preventDefault();
                        event.stopPropagation();

                        return suggestionsListRef?.current?.onKeyDown(event);
                      }
                      return false;
                    },
                    onStart: (props) => {
                      setSuggestionProps?.(props);
                    },
                    onUpdate: (props) => {
                      setSuggestionProps?.(props);
                    },
                    onExit: () => {
                      setSuggestionProps?.(null);
                    },
                  }),
                },
              }),
            ]
          : []),
      ],
      editorProps: {
        handlePaste(_view, event, _slice) {
          if (event.clipboardData) {
            const { items } = event.clipboardData;
            for (const item of items) {
              if (item.type.includes('image')) {
                event.preventDefault();
                return true; // Prevent default image handling
              }
            }
          }
          return false; // Allow other pasting
        },
        ...editorProps,
      },
      onSelectionUpdate: ({ editor: editorArg }) => {
        cursor.current = editorArg.state.selection.anchor;
      },
      content,
      ...restOfProps,
    },
    [],
  );

  // https://github.com/ueberdosis/tiptap/issues/2403#issuecomment-2040039780
  useEffect(() => {
    editor
      ?.chain()
      .setContent(content ?? '', false, { preserveWhitespace: 'full' })
      .setTextSelection(cursor.current ?? 0)
      .run();
  }, [content, editor]);

  return editor;
};
