import type { FC } from 'react';
import React, { useMemo, useCallback, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Flex, Text } from '@lama/design-system';
import { orderBy, uniq } from 'lodash-es';
import { Avatar, Tooltip } from '@lama/app-components';
import type { UnderwritingSection } from '@lama/contracts';
import { ApplicationContext } from '../../ApplicationContext';
import { noteTypeToIcon } from '../../shared/noteTypeToIcon';
import { SideNavSectionItemContainer } from '../../../../shared/components/PageSideNav/SideNavSectionItemContainer';
import { SideNavSectionItemReviewedStatus } from '../../../../shared/components/PageSideNav/SideNavSectionItemReviewedStatus';
import type { MultipliedSection } from '../../shared/sectionUtils';
import { useUsersByPartnerQuery } from '../../../../shared/hooks/react-query/user/useUsersByPartnerQuery';

interface UnderwritingSectionListItemProps {
  section: MultipliedSection<UnderwritingSection>;
}

export const UnderwritingSideNavItem: FC<UnderwritingSectionListItemProps> = ({ section }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { opportunity } = useContext(ApplicationContext);
  const { data: users } = useUsersByPartnerQuery(opportunity.partnerId);

  const currentSectionId = searchParams.get('section');

  const selected = useMemo(() => currentSectionId === section.id, [currentSectionId, section.id]);

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      setSearchParams({ section: section.id });
      e.stopPropagation();
    },
    [section.id, setSearchParams],
  );

  const sectionNoteTypes = useMemo(
    () =>
      orderBy(
        uniq(opportunity.underwriting?.notes?.filter((note) => note.relatedUnderwritingSectionId === section.id).map(({ type }) => type)),
      ),
    [opportunity.underwriting?.notes, section.id],
  );

  const sectionAssignee = useMemo(() => {
    const assigneeId = opportunity.taskAssignees?.find(({ taskRelatedSectionId }) => taskRelatedSectionId === section.id)?.userId;
    return users?.find(({ id }) => id === assigneeId);
  }, [opportunity.taskAssignees, section.id, users]);

  return (
    <SideNavSectionItemContainer onClick={onClick} selected={selected}>
      <Flex justifyContent={'space-between'} alignItems={'center'} gap={1} flex={1}>
        <Tooltip title={section.prettyName} placement={'top'}>
          <Text variant={'body2'} color={selected ? 'primary.main' : 'text.secondary'} ellipsis data-sentry-unmask>
            {section.prettyName}
          </Text>
        </Tooltip>
        <Flex alignItems={'center'} gap={1}>
          {sectionAssignee ? <Avatar size={21} firstName={sectionAssignee.firstName} lastName={sectionAssignee.lastName} /> : null}
          {sectionNoteTypes.map((note) => (
            <Flex key={`${section.id}-icon-${note}`}>{noteTypeToIcon(note)}</Flex>
          ))}
        </Flex>
      </Flex>
      <SideNavSectionItemReviewedStatus sectionId={section.id} relatedItemType={'underwriting'} />
    </SideNavSectionItemContainer>
  );
};
