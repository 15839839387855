import type { FC } from 'react';
import React, { useMemo, useContext, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import type { MemoSection } from '@lama/contracts';
import { orderBy, uniq } from 'lodash-es';
import { Flex, Text } from '@lama/design-system';
import { ApplicationContext } from '../../../ApplicationContext';
import { noteTypeToIcon } from '../../../shared/noteTypeToIcon';
import { SideNavSectionItemContainer } from '../../../../../shared/components/PageSideNav/SideNavSectionItemContainer';
import { SideNavSectionItemReviewedStatus } from '../../../../../shared/components/PageSideNav/SideNavSectionItemReviewedStatus';

interface SectionItemProps {
  section: MemoSection;
}

export const CreditMemoSideNavItem: FC<SectionItemProps> = ({ section }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { opportunity } = useContext(ApplicationContext);

  const currentSectionId = searchParams.get('section');

  const selected = useMemo(() => currentSectionId === section.id, [currentSectionId, section.id]);

  const onClick = useCallback(() => {
    setSearchParams({ section: section.id });
  }, [setSearchParams, section]);

  const sectionNoteTypes = useMemo(
    () => orderBy(uniq(opportunity.underwriting?.notes?.filter((note) => note.relatedSection === section.id).map(({ type }) => type))),
    [opportunity.underwriting?.notes, section.id],
  );

  return (
    <SideNavSectionItemContainer selected={selected} onClick={onClick}>
      <Flex justifyContent={'space-between'} alignItems={'center'} gap={1} flex={1}>
        <Text variant={'body2'} color={selected ? 'primary.main' : 'text.secondary'} ellipsis data-sentry-unmask>
          {section.prettyName}
        </Text>
        <Flex alignItems={'center'} gap={1}>
          {sectionNoteTypes.map((note) => (
            <Flex key={`${section.id}-icon-${note}`}>{noteTypeToIcon(note)}</Flex>
          ))}
        </Flex>
      </Flex>
      <SideNavSectionItemReviewedStatus sectionId={section.id} relatedItemType={'creditMemoSection'} />
    </SideNavSectionItemContainer>
  );
};
