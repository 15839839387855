import type { FinancialData } from '@lama/contracts';
import type { FC } from 'react';
import { DocumentSourceDescription } from './DocumentSourceDescription';
import { CalculationSourceDescription } from './CalculationSourceDescription';
import { ManualOrAdjustmentSourceDescription } from './ManualOrAdjustmentSourceDescription';
import type { FieldDescriptionProps } from './types';
import { NoSourceDescription } from './NoSourceDescription';

type ValueSourceTypes = FinancialData['source']['type'] | 'NoSource';

export const valueDescriptionBySource: Record<ValueSourceTypes, FC<FieldDescriptionProps>> = {
  Document: DocumentSourceDescription,
  Adjustment: ManualOrAdjustmentSourceDescription,
  Manual: ManualOrAdjustmentSourceDescription,
  Calculation: CalculationSourceDescription,
  NoSource: NoSourceDescription,
  ChildAggregation: CalculationSourceDescription,
};
