import React from 'react';
import type { FC } from 'react';
import { AuditLog } from '../../../AuditLog';
import type { AuditComponentProps } from '../types';

export const UpdateFinancialsAuditLog: FC<AuditComponentProps> = ({ groupInfo }) => (
  <AuditLog
    {...(groupInfo.originType === 'user'
      ? { originType: groupInfo.originType, user: groupInfo.user }
      : { originType: groupInfo.originType })}
    timestamp={groupInfo.timestamp}
    text={'Updated Financials'}
    customText={groupInfo.customText}
    labels={groupInfo.labels}
  />
);
