import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { referrerDisplayName, personFullName } from '@lama/data-formatters';
import { keyBy } from 'lodash-es';
import type { AuditComponentProps } from '../types';
import { AuditLog } from '../../../AuditLog';
import { ApplicationContext } from '../../../../../../components/Application/ApplicationContext';
import { usePartnerReferrersQuery } from '../../../../../hooks/react-query/referrer/usePartnerReferrersQuery';

export const ApplicationReferrerAuditLog: FC<AuditComponentProps> = ({ audit, groupInfo }) => {
  const { opportunity } = useContext(ApplicationContext);
  const { data: referrers, isFetching: loadingReferrers } = usePartnerReferrersQuery(opportunity.partnerId);
  const [firstUpdate] = audit;

  const referrersById = useMemo(() => keyBy(referrers, (referrer) => referrer.id), [referrers]);

  return useMemo(() => {
    if (!firstUpdate?.changes?.referredBy || loadingReferrers) {
      return null;
    }

    const referrer = referrersById[firstUpdate.changes.referredBy];

    if (!referrer) {
      return null;
    }

    const auditText =
      firstUpdate.originType === 'system' || !firstUpdate.user?.firstName || !firstUpdate.user?.lastName
        ? `${referrerDisplayName({ referrer })} was set as a referrer`
        : `${personFullName(firstUpdate.user as { firstName: string; middleName?: string; lastName: string })} set ${referrerDisplayName({
            referrer,
          })} as a referrer`;

    return (
      <AuditLog
        {...(groupInfo.originType === 'user'
          ? { originType: groupInfo.originType, user: groupInfo.user }
          : { originType: groupInfo.originType })}
        text={auditText}
        timestamp={firstUpdate.timestamp}
        key={firstUpdate.id}
        customText={groupInfo.customText}
        labels={groupInfo.labels}
      />
    );
  }, [firstUpdate, groupInfo, loadingReferrers, referrersById]);
};
