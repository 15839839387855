import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Flex, Text } from '@lama/design-system';
import { getApplicationEntityByType } from '@lama/properties';
import { sumBy, zip } from 'lodash-es';
import { formatValue } from '@lama/data-formatters';
import type { PersonApiModel } from '@lama/clients';
import type { CellContent, CellHeaderContent } from '../../CreditMemo/Sections/creditMemoTable/CreditMemoTable';
import { CreditMemoTable } from '../../CreditMemo/Sections/creditMemoTable/CreditMemoTable';
import { ApplicationContext } from '../../ApplicationContext';
import { getFinancialFieldByYear } from '../../OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/financialFieldUtils';

const headers: CellHeaderContent[] = [
  {
    text: 'Assets',
    colspan: 2,
    width: '50%',
  },
  {
    text: 'Liabilities',
    colspan: 2,
    width: '50%',
  },
];

const assetsFieldNamesToFinancialAttribute: Record<string, { financialAttribute: string; displayName: string }> = {
  cash: { financialAttribute: 'Cash in Bank Accounts', displayName: 'Personal Cash' },
  securities: { financialAttribute: 'Marketable Securities', displayName: 'Personal Stocks & Bonds' },
  insurance: { financialAttribute: 'Life Insurance - Cash Surrender Value Only', displayName: 'Cash Surrender Value of Life Insurance' },
  personalIra: { financialAttribute: 'Personal IRA or Other Retirement Accounts', displayName: 'Retirement Accounts' },
  realEstateAssets: { financialAttribute: 'Personal Real Estate', displayName: 'Real Estate Owned' },
  otherAssets: { financialAttribute: 'Other Personal Assets', displayName: 'Other Assets' },
};

const liabilitiesFieldNamesToFinancialAttribute: Record<string, { financialAttribute: string; displayName: string }> = {
  creditCards: { financialAttribute: 'Personal Accounts Payable', displayName: 'Credit Cards' },
  installments: { financialAttribute: 'Other Personal Installment Accounts', displayName: 'Installment Loans' },
  realEstateLoans: { financialAttribute: 'Personal Mortgages on Real Estate', displayName: 'Real Estate Loans' },
  taxes: { financialAttribute: 'Personal Unpaid Taxes', displayName: 'Taxes Owed' },
  otherLiabilities: { financialAttribute: 'Other Personal Liabilities', displayName: 'Other Liabilities' },
};

interface SouthstatePersonalFinancialStatementSegmentProps {
  entity: any;
}

export const SouthstatePersonalFinancialStatementSegment: FC<SouthstatePersonalFinancialStatementSegmentProps> = ({ entity }) => {
  const { application, opportunity } = useContext(ApplicationContext);

  const person = useMemo(
    () => getApplicationEntityByType(application, 'person', ['all']).find(({ id }) => id === entity.id) as PersonApiModel | undefined,
    [application, entity.id],
  );

  const assetAttributeWithValues = useMemo(
    () =>
      Object.fromEntries(
        Object.values(assetsFieldNamesToFinancialAttribute).map(({ financialAttribute, displayName }) => {
          const value = getFinancialFieldByYear(person?.financials ?? [], opportunity.referenceYear, financialAttribute)?.value;
          return [financialAttribute, { financialAttribute, value, displayName }];
        }),
      ),
    [person, opportunity.referenceYear],
  );

  const liabilitiesAttributeWithValues = useMemo(
    () =>
      Object.fromEntries(
        Object.values(liabilitiesFieldNamesToFinancialAttribute).map(({ financialAttribute, displayName }) => {
          const value = getFinancialFieldByYear(person?.financials ?? [], opportunity.referenceYear, financialAttribute)?.value;
          return [financialAttribute, { financialAttribute, value, displayName }];
        }),
      ),
    [person, opportunity.referenceYear],
  );

  const totalAssets = useMemo(() => sumBy(Object.values(assetAttributeWithValues), (a) => a.value ?? 0), [assetAttributeWithValues]);
  const totalLiabilities = useMemo(
    () => sumBy(Object.values(liabilitiesAttributeWithValues), (a) => a.value ?? 0),
    [liabilitiesAttributeWithValues],
  );

  const rows: CellContent[][] = useMemo(() => {
    const assetValues = Object.values(assetAttributeWithValues).map(({ value, displayName }) => [
      { value: displayName },
      { value, type: 'currency' as const },
    ]);

    const liabilityValues = Object.values(liabilitiesAttributeWithValues).map(({ value, displayName }) => [
      { value: displayName },
      { value, type: 'currency' as const },
    ]);

    const statedValuesRows = zip(assetValues, liabilityValues)
      .map((a) => a.flat())
      .map((a) => a.map((c) => ({ ...c, value: c?.value || '' })));

    const totalsRow = [
      {
        value: 'Total Assets',
      },
      {
        value: totalAssets,
        type: 'currency',
      },

      { value: 'Total Liabilities' },
      {
        value: totalLiabilities,
        type: 'currency',
      },
    ];

    return [...statedValuesRows, totalsRow];
  }, [assetAttributeWithValues, liabilitiesAttributeWithValues, totalAssets, totalLiabilities]);

  const netWorth = useMemo(() => totalAssets - totalLiabilities, [totalAssets, totalLiabilities]);

  return (
    <Flex flexDirection={'column'} gap={4}>
      <Text variant={'body1'}>{`As of: ${formatValue(person?.personalFinancialStatementAsOfDate, 'date')}`}</Text>
      <CreditMemoTable headers={headers} rows={rows} />
      <Flex width={'100%'} justifyContent={'flex-end'}>
        <Text variant={'body1'}>{`Net Worth: ${formatValue(netWorth, 'currency')}`}</Text>
      </Flex>
    </Flex>
  );
};
