import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Flex, Text } from '@lama/design-system';
import styled from 'styled-components';
import { Divider } from '@mui/material';
import { formatValue } from '@lama/data-formatters';
import { ApplicationContext } from '../../../../../ApplicationContext';
import type { FieldDescriptionProps } from './types';

const StyledText = styled(Text)`
  white-space: pre-wrap;
`;

export const CalculationSourceDescription: FC<FieldDescriptionProps> = ({
  value: { calculationExpression, financialAttribute },
  propertyKey,
}) => {
  const { properties } = useContext(ApplicationContext);

  const property = useMemo(() => (propertyKey ? properties[propertyKey] : null), [properties, propertyKey]);

  const totalExpression = useMemo(() => {
    if (!financialAttribute || !property?.financialAttribute) {
      return null;
    }

    const formattedValue = formatValue(financialAttribute?.value, property.type);

    return `${property.financialAttribute} (${formattedValue !== '' ? formattedValue : '?'})`;
  }, [financialAttribute, property?.financialAttribute, property?.type]);

  const hasCalculationExpression = useMemo(() => !!calculationExpression && !!totalExpression, [calculationExpression, totalExpression]);

  return (
    <Flex flexDirection={'column'} gap={2} maxWidth={'400px'}>
      <Text variant={'body1'}>{'Calculation'}</Text>
      {hasCalculationExpression ? (
        <>
          <StyledText variant={'body3'}>{calculationExpression}</StyledText>
          <Divider />
          <Text variant={'body3'}>{totalExpression}</Text>
        </>
      ) : (
        <Text variant={'body3'}>{'This was calculated by consolidating the relevant data for this value'}</Text>
      )}
    </Flex>
  );
};
