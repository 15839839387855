import React, { useMemo, type FC } from 'react';
import { isNil } from 'lodash-es';
import { AuditLog } from '../../../AuditLog';
import type { AuditComponentProps } from '../types';

export const OpportunityApprovedLoanAmoutAuditLog: FC<AuditComponentProps> = ({ audit, groupInfo }) => {
  const approvedAmount: number | undefined = audit[0]?.changes?.terms?.approvedLoanAmount;
  const text = useMemo(() => `Updated the proposed loan amount to: ${approvedAmount}`, [approvedAmount]);

  if (isNil(approvedAmount)) {
    return null;
  }

  return (
    <AuditLog
      {...(groupInfo.originType === 'user'
        ? { originType: groupInfo.originType, user: groupInfo.user }
        : { originType: groupInfo.originType })}
      text={text}
      timestamp={groupInfo.timestamp}
      customText={groupInfo.customText}
      labels={groupInfo.labels}
    />
  );
};
