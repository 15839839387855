/* eslint-disable @typescript-eslint/naming-convention */
import type { FC } from 'react';
import React, { useCallback, useContext, useState, useMemo } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Tooltip, displayToast } from '@lama/app-components';
import { differenceWith, sortBy } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';
import { useAuth0 } from '@auth0/auth0-react';
import type { PartnerRequirement } from '@lama/contracts';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import type { CreateOpportunityRequirementRequestBody } from '@lama/clients';
import { Flex, greyPalette, Text } from '@lama/design-system';
import { useOpportunityRequirementsQuery } from '../../../shared/hooks/react-query/opportunity/useOpportunityRequirementsQuery';
import { ApplicationContext } from '../ApplicationContext';
import { usePartnerRequirementsQuery } from '../../../shared/hooks/react-query/product/usePartnerRequirementsQuery';
import { AddRequirementDialog } from '../OpportunityRequirements/AddRequirementDialog';
import { applicationServiceClient } from '../../../shared/clients/applicationServiceClient';
import { SubMenuHeader } from './SubMenuHeader';
import { ActionMenuSearchInput } from './ActionMenuSearchInput';

export const createOpportunityRequirementAndGetEvaluatedId = async (
  opportunityId: string,
  requirement: CreateOpportunityRequirementRequestBody,
  token: string,
) => {
  const requirementId = await applicationServiceClient.createOpportunityRequirement(opportunityId, requirement, token);
  const evaluatedRequirements = await applicationServiceClient.getOpportunityRequirements(opportunityId, token);
  const evaluatedRequirement = evaluatedRequirements?.find((req) => req.id.includes(requirementId));

  return evaluatedRequirement?.id;
};

interface CreatePartnerRequirementMenuItemProps {
  requirement: PartnerRequirement;
  onRequirementOptionClick: (referenceRequirement: PartnerRequirement) => void;
  closeMenu: () => void;
}

export const CreateOpportunityRequirementMenuItem: FC<CreatePartnerRequirementMenuItemProps> = ({
  onRequirementOptionClick,
  requirement,
}) => {
  const onClick = useCallback(() => {
    onRequirementOptionClick(requirement);
  }, [onRequirementOptionClick, requirement]);

  return (
    <Tooltip title={requirement.name}>
      <MenuItem onClick={onClick} sx={{ '&:hover': { bgcolor: 'primary.light' }, borderRadius: '4px', px: 2 }}>
        <Text noWrap>{requirement.name}</Text>
      </MenuItem>
    </Tooltip>
  );
};

export interface CreateRequirementMenuProps {
  menuProps: any;
  onBack: () => void;
  onClose: () => void;
  open: boolean;
  menuRef: React.MutableRefObject<null>;
}

export const CreateOpportunityRequirementSearchMenu: FC<CreateRequirementMenuProps> = ({ menuProps, onBack, open, menuRef, onClose }) => {
  const { opportunity, product } = useContext(ApplicationContext);
  const { id: opportunityId, partnerId } = opportunity;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [filterOptions, setFilterOptions] = useState('');
  const { data: opportunityRequirements, isPending: loadingOpportunityRequirements } = useOpportunityRequirementsQuery(opportunityId);
  const { data: partnerRequirements, isPending: loadingPartnerRequirements } = usePartnerRequirementsQuery(partnerId);
  const [requirementToCreate, setRequirementToCreate] = useState<PartnerRequirement | null>(null);

  const handleClose = useCallback(() => {
    setFilterOptions('');
    onClose();
  }, [onClose]);

  const onFilterChange = useCallback((value: string) => {
    setFilterOptions(value);
  }, []);

  const loading = useMemo(
    () => loadingOpportunityRequirements || loadingPartnerRequirements,
    [loadingOpportunityRequirements, loadingPartnerRequirements],
  );

  const requirementOptions = useMemo(() => {
    if (loading) {
      return [];
    }

    let addableRequirements = partnerRequirements ?? [];

    if (product.addableRequirementsKeys?.length) {
      const addableRequirementsSet = new Set<string>(product.addableRequirementsKeys);
      addableRequirements = addableRequirements.filter((r) => addableRequirementsSet.has(r.key));
    }

    const requirementsNotInOpportunity = differenceWith(
      addableRequirements,
      opportunityRequirements ?? [],
      (a, b) => a.key === b.key || a.name === b.name,
    );

    return sortBy(requirementsNotInOpportunity, (r) => r.name);
  }, [loading, partnerRequirements, product.addableRequirementsKeys, opportunityRequirements]);

  const filteredRequirementOptions = useMemo(
    () => requirementOptions.filter((r) => r.name.toLowerCase().includes(filterOptions.toLowerCase())),
    [filterOptions, requirementOptions],
  );

  const { mutateAsync: createOpportunityRequirement } = useMutation({
    mutationFn: async (referenceRequirement: PartnerRequirement) => {
      const token = await getAccessTokenSilently();
      const requirement: CreateOpportunityRequirementRequestBody = {
        id: uuidv4(),
        key: referenceRequirement.key,
        notifyBorrower: referenceRequirement.notifyBorrower,
      };

      return createOpportunityRequirementAndGetEvaluatedId(opportunityId, requirement, token);
    },
    onSuccess: async (requirementId) => {
      handleClose();
      await queryClient.invalidateQueries({ queryKey: ['opportunityRequirements', opportunityId] });
      if (requirementId) {
        navigate(`/pipeline/${opportunityId}/requirements?requirementId=${requirementId}`);
      }
    },
    onError: () => {
      displayToast('Something went wrong while creating the requirement. Please contact support.', 'error');
    },
  });

  const onCancelCreateRequirement = useCallback(() => {
    setRequirementToCreate(null);
  }, []);

  const onMenuItemClick = useCallback(
    (requirement: PartnerRequirement) => {
      setRequirementToCreate(requirement);
    },
    [setRequirementToCreate],
  );
  return (
    <>
      <Menu {...menuProps} anchorEl={menuRef.current} open={open} onClose={handleClose} sx={{ zIndex: 100 }}>
        <Flex flexDirection={'column'} gap={1} width={'400px'}>
          <SubMenuHeader onClick={onBack} text={'Add Requirement'} />
          <ActionMenuSearchInput onInputChange={onFilterChange} />
          <Flex flexDirection={'column'} overflow={'auto'} maxHeight={'300px'} px={1} pt={1}>
            {loading ? (
              <Text color={greyPalette[500]} pt={2} pl={2}>
                {'Loading requirements...'}
              </Text>
            ) : (
              filteredRequirementOptions.map((requirement) => (
                <CreateOpportunityRequirementMenuItem
                  key={requirement.id}
                  requirement={requirement}
                  onRequirementOptionClick={onMenuItemClick}
                  closeMenu={handleClose}
                />
              ))
            )}
          </Flex>
        </Flex>
      </Menu>
      <AddRequirementDialog
        onConfirm={createOpportunityRequirement}
        open={!!requirementToCreate}
        requirement={requirementToCreate}
        handleClose={onCancelCreateRequirement}
      />
    </>
  );
};
