import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { formatAlertMessage } from '@lama/alerts-service-client';
import { sortBy } from 'lodash-es';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useGetOpportunityAlertsQuery } from '../../../shared/hooks/react-query/opportunity/useOpportunityAlertsQuery';
import { ApplicationContext } from '../ApplicationContext';
import { OpportunityAlertView } from './OpportunityAlertView';
import { OpportunityAlertViewV2 } from './OpportunityAlertViewV2';

export const OpportunityAlerts: FC = () => {
  const {
    opportunity: { id: opportunityId },
  } = useContext(ApplicationContext);
  const { data: alerts } = useGetOpportunityAlertsQuery(opportunityId, {
    refetchInterval: 5000,
  });
  const { isHuntingtonDemo } = useFlags();

  const filteredAlerts = useMemo(
    () => (isHuntingtonDemo ? alerts?.filter((a, index) => !localStorage.getItem('dismissedAlerts')?.includes(index.toString())) : alerts),
    [alerts, isHuntingtonDemo],
  );

  const alertsWithMessages = useMemo(() => {
    if (!filteredAlerts?.length) {
      return [];
    }

    const sorted = sortBy(filteredAlerts, [(a) => !a.sticky, (a) => a.level === 'info']);

    return sorted.map((a) => ({ ...a, message: formatAlertMessage(a) }));
  }, [filteredAlerts]);

  if (!alertsWithMessages?.length) {
    return null;
  }

  return isHuntingtonDemo ? (
    <OpportunityAlertViewV2 alert={alertsWithMessages[0]!} message={alertsWithMessages[0]!.message} />
  ) : (
    <OpportunityAlertView
      params={alertsWithMessages[0]!.messageParams ?? {}}
      message={alertsWithMessages[0]!.message}
      actions={alertsWithMessages[0]!.actions ?? []}
      level={alertsWithMessages[0]!.level}
    />
  );
};
