import React, { useCallback, useContext, useMemo, useState } from 'react';
import type { FC } from 'react';
import { useDebounce } from 'react-use';
import { Button, Flex, greyPalette, Input, Text } from '@lama/design-system';
import type { DocumentWithIssues } from '@lama/document-service-client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { displayToast, useConfirmModal } from '@lama/app-components';
import { useMarkTaskCompleted } from '../shared/useMarkTaskCompleted';
import { ApplicationContext } from '../../../../ApplicationContext';

import type { ClosingTaskComponentProps } from '../closingTaskComponentProps';
import { useUploadDocumentsToNautilus } from '../../../../../../shared/hooks/react-query/nautilus/useUploadDocumentsToNautilus';
import { useApplicationDocumentsQuery } from '../../../../../../shared/hooks/react-query/application/useApplicationDocumentsQuery';
import { useUpdateOpportunityMutation } from '../../../../../../shared/hooks/react-query/opportunity/useUpdateOpportunityMutation';
import { ExportLine } from '../shared/ExportLine';
import { NautilusMappingTables } from './NautilusMappingTable';
import { AUDIT_LOG_DESCRIPTION } from './useGenerateAuditReport';
import { NOTES_LOG_DESCRIPTION } from './useGenerateNotesReport';

const DocumentsNote = ({ documents }: { documents?: DocumentWithIssues[] }) => {
  const { showDocumentDataInNautilusExport } = useFlags();
  if (!documents || documents.length === 0) {
    return (
      <Text variant={'body3'}>{'No documents to export. Please upload documents to the application before exporting to Nautilus.'}</Text>
    );
  }
  if (!showDocumentDataInNautilusExport) {
    return null;
  }
  return (
    <Flex flexDirection={'column'}>
      {documents.map((doc) => (
        <Text key={doc.id} variant={'body3'}>
          {`${doc.topic}:${doc.filename}`}
        </Text>
      ))}
    </Flex>
  );
};

export const NautilusExportTaskComponent: FC<ClosingTaskComponentProps> = ({ task }) => {
  const { opportunity } = useContext(ApplicationContext);
  const [dnaLoanNumber, setDnaLoanNumber] = useState(opportunity.externalLoanId);

  const { mutateAsync: updateOpportunity } = useUpdateOpportunityMutation(opportunity.id);
  const { data: applicationDocuments, isPending: loadingDocuments } = useApplicationDocumentsQuery({
    applicationId: opportunity.application.id,
    includeDeleted: false,
  });
  const { markAsComplete, markingTaskAsComplete } = useMarkTaskCompleted(task);
  const { mutateAsync: uploadToNautilus, isPending: uploadingDocuments } = useUploadDocumentsToNautilus(opportunity.id);

  const { showDocumentsInNautilusClosingTask } = useFlags();

  const { confirm } = useConfirmModal();

  const saveDnaLoanNumber = useCallback(async () => {
    if (dnaLoanNumber === opportunity.externalLoanId) {
      return;
    }
    await updateOpportunity(
      { externalLoanId: dnaLoanNumber },
      {
        onError: () => {
          displayToast('Failed saving external loan number. Please try again.', 'error');
        },
      },
    );
  }, [dnaLoanNumber, opportunity.externalLoanId, updateOpportunity]);

  useDebounce(saveDnaLoanNumber, 500, [dnaLoanNumber]);

  const onClickExport = useCallback(async () => {
    if (!dnaLoanNumber) {
      displayToast('Please enter a DNA Loan Number before exporting to Nautilus.', 'info');
      return;
    }

    const auditLogDocumentExists = applicationDocuments?.some((doc) => doc.description === AUDIT_LOG_DESCRIPTION);
    const notesLogDocumentExists = applicationDocuments?.some((doc) => doc.description === NOTES_LOG_DESCRIPTION);

    if (!auditLogDocumentExists || !notesLogDocumentExists) {
      const confirmed = await confirm({
        title: 'Missing System Reports',
        message: 'Are you sure you want to proceed without generating the system reports first?',
        confirmText: 'Continue',
        cancelText: 'Cancel',
      });

      if (!confirmed) {
        return;
      }
    }

    await uploadToNautilus(dnaLoanNumber);
    markAsComplete();
  }, [dnaLoanNumber, applicationDocuments, uploadToNautilus, markAsComplete, confirm]);

  const handleDnaLoanNumberChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDnaLoanNumber(e.target.value);
  }, []);

  const isLoading = useMemo(
    () => uploadingDocuments || markingTaskAsComplete || loadingDocuments,
    [uploadingDocuments, markingTaskAsComplete, loadingDocuments],
  );

  const hasDocuments = useMemo(() => applicationDocuments && applicationDocuments?.length > 0, [applicationDocuments]);

  return (
    <Flex flexDirection={'column'} gap={8}>
      <DocumentsNote documents={applicationDocuments} />
      <Flex flexDirection={'column'} gap={4}>
        <Text variant={'body1'}>{'DNA Loan Number'}</Text>
        <Input
          value={dnaLoanNumber}
          onChange={handleDnaLoanNumberChange}
          placeholder={'DNA Loan Number'}
          disabled={!applicationDocuments?.length}
        />
      </Flex>
      {!showDocumentsInNautilusClosingTask ? (
        <Flex border={`1px solid ${greyPalette[300]}`} width={'100%'} p={3} borderRadius={'4px'} flexDirection={'row'}>
          <ExportLine
            exportDisabled={!hasDocuments || isLoading}
            name={'Sync all documents in this application to Nautilus (pdf, doc, docx, xls, xlsx)'}
            onExportClick={onClickExport}
          />
        </Flex>
      ) : (
        <>
          <NautilusMappingTables />
          <Flex width={'100%'} justifyContent={'flex-end'}>
            <Button onClick={onClickExport} variant={'secondary'} disabled={!hasDocuments || isLoading}>
              {'Export to Nautilus'}
            </Button>
          </Flex>
        </>
      )}
    </Flex>
  );
};
