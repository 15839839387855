import React, { useMemo } from 'react';
import type { FC } from 'react';
import { sentenceCase } from 'change-case-all';
import { AuditLog } from '../../AuditLog';
import type { AuditComponentProps } from './types';

export const CreateEntityAuditLog: FC<AuditComponentProps> = ({ audit, groupInfo }) => {
  const createdEntities = useMemo(() => audit.map((entry) => sentenceCase(entry.entityType)).join(', '), [audit]);

  return (
    <AuditLog
      {...(groupInfo.originType === 'user'
        ? { originType: groupInfo.originType, user: groupInfo.user }
        : { originType: groupInfo.originType })}
      text={`Created ${createdEntities}`}
      timestamp={groupInfo.timestamp}
      customText={groupInfo.customText}
      labels={groupInfo.labels}
    />
  );
};
