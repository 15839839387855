import React from 'react';
import type { EvaluatedOpportunityRequirement } from '@lama/contracts';
import type { FC } from 'react';
import { RelationsListView } from './RelationsListView';
import {
  CollateralView,
  DebtView,
  OwnersView,
  RelatedCompaniesView,
  UseOfFundsView,
  GC14LoanDetailsView,
  OnlineBankingView,
  AccountOpeningView,
  VirtualAssetView,
  SourceOfFundsView,
  TechnologyPlatformAndSystemView,
} from '.';

export const customRequirementsViews: Record<string, FC<{ requirement: EvaluatedOpportunityRequirement }> | null> = {
  principals: OwnersView,
  owners: OwnersView,
  relatedCompanies: RelatedCompaniesView,
  borrowers: ({ requirement }) => <RelationsListView requirement={requirement} relation={'borrower'} />,
  guarantors: ({ requirement }) => <RelationsListView requirement={requirement} relation={'guarantor'} />,
  additionalGuarantors: ({ requirement }) => <RelationsListView requirement={requirement} relation={'guarantor'} />,
  guests: ({ requirement }) => <RelationsListView requirement={requirement} relation={'guest'} />,
  collateral: CollateralView,
  assets: CollateralView,
  useOfFundsBreakdown: UseOfFundsView,
  debt: DebtView,
  debts: DebtView,
  gc14FamilyLoanDetails: GC14LoanDetailsView,
  // Account opening demo
  onlineBanking: OnlineBankingView,
  accounts: AccountOpeningView,
  virtualAssets: VirtualAssetView,
  sourcesOfFunds: SourceOfFundsView,
  technologyPlatformAndSystems: TechnologyPlatformAndSystemView,
};
