import type { FC } from 'react';
import React, { useState } from 'react';
import type { PDFHighlightedArea } from '@lama/app-components';

export interface SpreadingDocumentHighlightContextValue {
  highlightedArea: PDFHighlightedArea | null;
  setHighlightedArea: (area: PDFHighlightedArea | null) => void;
}

export const SpreadingDocumentHighlightContext = React.createContext<PDFHighlightedArea | null>(null);
export const SpreadingDocumentSetHighlightContext = React.createContext<React.Dispatch<React.SetStateAction<PDFHighlightedArea | null>>>(
  null!,
);

export const SpreadingDocumentHighlightProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [highlightedArea, setHighlightedArea] = useState<PDFHighlightedArea | null>(null);

  return (
    <SpreadingDocumentHighlightContext.Provider value={highlightedArea}>
      <SpreadingDocumentSetHighlightContext.Provider value={setHighlightedArea}>{children}</SpreadingDocumentSetHighlightContext.Provider>
    </SpreadingDocumentHighlightContext.Provider>
  );
};
