import type { PeriodToFinancialsByEntityId } from '@lama/selectors';
import { getFinancialAttribute, type Period, getPeriodsToFinancialsByEntityId } from '@lama/selectors';
import type { SpreadRowConfiguration } from '@lama/partner-service-client';
import type { FinancialData, PropertiesRecord, Property } from '@lama/contracts';
import { compact, groupBy, isNil, keyBy, min, sortBy } from 'lodash-es';
import { getYear } from '@lama/data-formatters';
import type { ApplicationApiModel, PersonApiModel, OpportunityApiModel } from '@lama/clients';
import { type Entity } from '@lama/common-types';
import { type BusinessApiModel } from '@lama/business-service-client';
import type { SpreadRow, SpreadRowPeriodValue } from '@lama/spreads-generator-client';
import { getEntitiesForRow } from './getEntitiesForRow.js';
import { getRowDisplayName } from './getRowDisplayName.js';
import { getAttributeCalculationExpression } from './getAttributeCalculationExpression.js';
import type { EntitiesByType } from './types.js';

const getFinancialValuesByPeriods = ({
  entity,
  entityType,
  spreadPeriods,
  property,
  allProperties,
  periodToFinancialsByEntityId,
  application,
}: {
  entity: ApplicationApiModel | BusinessApiModel | OpportunityApiModel | PersonApiModel;
  entityType: Entity;
  spreadPeriods: Period[];
  property: Property;
  allProperties: PropertiesRecord;
  periodToFinancialsByEntityId: PeriodToFinancialsByEntityId;
  application: ApplicationApiModel;
}): Record<string, SpreadRowPeriodValue> =>
  Object.fromEntries(
    compact(
      spreadPeriods.map((period) => {
        const periodKey = `${period.startDate}-${period.endDate}`;

        const selectedYear = getYear(period.startDate);

        if (!property.financialAttribute) {
          return null;
        }

        const selectorContext = {
          application,
          entity,
          entityType,
          periodToFinancialsByEntityId,
          selectedPeriod: period,
          selectedYear,
        };

        const financialAttribute = getFinancialAttribute({ selectorContext, financialType: property.financialAttribute });

        const periodValue: SpreadRowPeriodValue = { financialAttribute };

        if (financialAttribute?.selectedSource.financialDataSource.type === 'Calculation') {
          const expression = getAttributeCalculationExpression({
            property,
            allProperties,
            selectorContext,
            expressionTemplate: financialAttribute.selectedSource.calculationExpressionTemplate,
          });

          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          periodValue.calculationExpression = expression ?? '';

          // temp, remove this
          delete financialAttribute.selectedSource.calculationExpressionTemplate;

          // temp, remove this
          financialAttribute.prioritizedSources
            ?.filter((s) => !isNil(s.calculationExpressionTemplate))
            .forEach((s) => delete s.calculationExpressionTemplate);
        }

        return [periodKey, periodValue];
      }),
    ),
  );

const getEarliestRowFinancialCreatedAt = (row: SpreadRow, allFinancialsById: Record<string, FinancialData>) =>
  min(Object.values(row.valueByPeriod).map(({ financialId }) => (financialId ? allFinancialsById[financialId]?.createdAt : undefined)));

const getItemsSpreadRows = ({
  parentRow,
  currentHierarchy,
  allFinancialsById,
  allFinancialsByParentFinancialAttribute,
  property,
}: {
  parentRow: SpreadRow;
  currentHierarchy: string[];
  allFinancialsById: Record<string, FinancialData>;
  allFinancialsByParentFinancialAttribute: Record<string, FinancialData[]>;
  property: Property;
}): SpreadRow[] => {
  const itemsFinancialsWithPeriods = Object.entries(parentRow.valueByPeriod).flatMap(([period, periodValue]) => {
    const financialDataSource = periodValue.financialAttribute?.selectedSource.financialDataSource;

    if (financialDataSource?.type !== 'ChildAggregation' || !property.financialAttribute) {
      return [];
    }

    return allFinancialsByParentFinancialAttribute[property.financialAttribute]?.map((financial) => ({ period, financial })) ?? [];
  });

  const groupedByItemFinancialName = groupBy(itemsFinancialsWithPeriods, ({ financial: { displayName } }) => displayName);

  const itemsSpreadRows = Object.entries(groupedByItemFinancialName).map<SpreadRow>(([itemName, periodValues]) => {
    const itemValuesByPeriod: Record<string, SpreadRowPeriodValue> = Object.fromEntries(
      periodValues.map(({ period, financial }) => [
        period,
        {
          financialAttribute: {
            value: financial.value,
            selectedSource: {
              type: financial.type,
              financialDataSource: financial.source,
              value: financial.value,
              updatedAt: financial.updatedAt ?? '',
            },
          },
        },
      ]),
    );

    const itemSpreadRow: SpreadRow = {
      attributeDisplayName: itemName,
      attributeHierarchy: [...currentHierarchy, itemName],
      entityId: parentRow.entityId,
      valueByPeriod: itemValuesByPeriod,
    };

    return itemSpreadRow;
  });

  return sortBy(itemsSpreadRows, (row) => getEarliestRowFinancialCreatedAt(row, allFinancialsById));
};

export const propertyConfigToRows = ({
  propertyConfig,
  attributeHierarchy = [],
  displayedPeriods,
  entitiesByType,
  properties,
  showEntityNames,
  periodToFinancialsByEntityId,
  application,
  allFinancialsById,
  allFinancialsByParentFinancialAttribute,
}: {
  propertyConfig: SpreadRowConfiguration;
  attributeHierarchy?: string[];
  displayedPeriods: Period[];
  entitiesByType: EntitiesByType;
  properties: PropertiesRecord;
  showEntityNames?: boolean;
  periodToFinancialsByEntityId: PeriodToFinancialsByEntityId;
  application: ApplicationApiModel;
  allFinancialsById: Record<string, FinancialData>;
  allFinancialsByParentFinancialAttribute: Record<string, FinancialData[]>;
}): SpreadRow[] => {
  const property = properties[propertyConfig.propertyKey];
  const entityType = property?.entityType;

  if (!property?.financialAttribute || !entityType || !entitiesByType[entityType]) {
    return [];
  }

  const entities = getEntitiesForRow({
    property,
    entitiesByType,
    entityGroups: propertyConfig.entityGroups ?? [],
  });

  const propertyRows: SpreadRow[] = entities.flatMap<SpreadRow>((entity) => {
    const rowDisplayName = getRowDisplayName(property, !!showEntityNames, entity);
    const currentAttributeHierarchy = [...attributeHierarchy, rowDisplayName];

    const propertyRow: SpreadRow = {
      attributeHierarchy: currentAttributeHierarchy,
      attributeDisplayName: rowDisplayName,
      entityId: entity.id,
      valueByPeriod: getFinancialValuesByPeriods({
        entity,
        entityType,
        spreadPeriods: displayedPeriods,
        property,
        allProperties: properties,
        periodToFinancialsByEntityId,
        application,
      }),
      propertyKey: propertyConfig.propertyKey,
      highlight: propertyConfig.highlight,
      adjustable: propertyConfig.adjustable,
      allowAddingChildren: propertyConfig.allowAddingChildren,
    };

    const itemsSpreadRows = getItemsSpreadRows({
      parentRow: propertyRow,
      currentHierarchy: currentAttributeHierarchy,
      allFinancialsById,
      allFinancialsByParentFinancialAttribute,
      property,
    });

    propertyRow.childrenNames = itemsSpreadRows.map(({ attributeDisplayName }) => attributeDisplayName);

    return [propertyRow, ...itemsSpreadRows];
  });

  return [
    ...propertyRows,
    ...(propertyConfig.children ?? []).flatMap((configChild) =>
      propertyConfigToRows({
        propertyConfig: configChild,
        displayedPeriods,
        attributeHierarchy: propertyRows.at(0)?.attributeHierarchy,
        entitiesByType,
        properties,
        showEntityNames,
        periodToFinancialsByEntityId,
        application,
        allFinancialsById,
        allFinancialsByParentFinancialAttribute,
      }),
    ),
  ];
};

export const getSpreadRows = ({
  spreadConfigurations,
  displayedPeriods,
  entities,
  properties,
  showEntityNames = true,
  application,
}: {
  spreadConfigurations: SpreadRowConfiguration[];
  financials?: FinancialData[];
  displayedPeriods: Period[];
  entities: EntitiesByType;
  properties: PropertiesRecord;
  application: ApplicationApiModel;
  showEntityNames?: boolean;
}) => {
  const allFinancials = [
    ...entities.business.flatMap((a) => a.business.financials ?? []),
    ...entities.person.flatMap((a) => a.person.financials ?? []),
  ];

  const allFinancialsById = keyBy(allFinancials, ({ id }) => id);
  const allFinancialsByParentFinancialAttribute = groupBy(allFinancials, ({ parentFinancialAttribute }) => parentFinancialAttribute);

  const periodToFinancialsByEntityId = getPeriodsToFinancialsByEntityId(allFinancials);

  return spreadConfigurations.flatMap((propertyConfig) =>
    propertyConfigToRows({
      displayedPeriods,
      propertyConfig,
      entitiesByType: entities,
      properties,
      showEntityNames,
      periodToFinancialsByEntityId,
      application,
      allFinancialsById,
      allFinancialsByParentFinancialAttribute,
    }),
  );
};
