/* eslint-disable react/no-array-index-key */
import { Checkbox, FormControlLabel } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compact, uniq } from 'lodash-es';
import { useToggle } from 'react-use';
import type { ApplicationApiModel, PersonApiModel, PersonUpdateApiModel, UpdateApplicationRelationParams } from '@lama/clients';
import type { BusinessApiModel, CreateBusinessModelApi, UpdateBusinessRequest } from '@lama/business-service-client';
import { Flex } from '@lama/design-system';
import { ModifyItemButton } from '../ModifyItemButton';
import type { BusinessWithOwnershipRequest } from '../Principals';
import type { BusinessWithOwnership } from '../Principals/BusinessForm';
import type { Owner } from './ownerType';
import { OwnedBusinessListItem } from './OwnedBusinessListItem';
import { NoOwnerImage } from './NoOwnerImage';
import { OwnedBusinessModal } from './OwnedBusinessModal';

export interface OwnedBusinessesProps {
  application: ApplicationApiModel;
  owner: Owner;
  createBusiness: ({ business }: { business: CreateBusinessModelApi; applicationId: string }) => Promise<void>;
  updateBusiness: ({
    businessId,
    updateBusinessPayload,
  }: {
    businessId: string;
    updateBusinessPayload: UpdateBusinessRequest;
  }) => Promise<void>;
  updatePerson: ({ personId, updatePersonPayload }: { personId: string; updatePersonPayload: PersonUpdateApiModel }) => Promise<void>;
  addApplicationRelation: (args: UpdateApplicationRelationParams) => Promise<any>;
  removeApplicationRelation: (args: UpdateApplicationRelationParams) => Promise<any>;
  loading?: boolean;
}

export const OwnedBusinesses: React.FC<OwnedBusinessesProps> = ({
  application,
  owner,
  createBusiness,
  updateBusiness,
  updatePerson,
  addApplicationRelation,
  removeApplicationRelation,
  loading,
}) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [businessIdToEdit, setBusinessIdToEdit] = useState<string | null>();
  const [noOwnedBusinesses, toggleNoOwnedBusinesses] = useToggle(!!owner.entity?.markedNoOwnedBusinesses);

  const allOwnedBusinesses = useMemo(
    () =>
      application.relatedBusinesses
        .filter(
          ({ business }) =>
            business.people.some(({ id, ownershipPercentage }) => id === owner.entity.id && (ownershipPercentage ?? 0) > 0) ||
            (business.owningBusinesses ?? []).some(
              ({ id, ownershipPercentage }) => id === owner.entity.id && (ownershipPercentage ?? 0) > 0,
            ),
        )
        .map(({ business }) => business),
    [application, owner],
  );

  const onAddBusinessClick = useCallback(() => {
    setModalOpen(true);
  }, []);

  const onEditBusinessCardClick = useCallback(
    (id: string) => {
      const ownedBusiness = application.relatedBusinesses.find((relatedBusiness) => relatedBusiness.business.id === id)?.business;
      setBusinessIdToEdit(ownedBusiness?.id);

      setModalOpen(true);
    },
    [application.relatedBusinesses],
  );

  const assignOwnershipToBusinessOwner = useCallback(
    async ({ ownedBusiness, owningBusiness }: { ownedBusiness: BusinessWithOwnershipRequest; owningBusiness: BusinessApiModel }) => {
      await updateBusiness({
        businessId: owningBusiness.id,
        updateBusinessPayload: {
          relatedBusinesses: uniq([...(owningBusiness.relatedBusinesses ?? []), ownedBusiness.id]),
        },
      });
    },
    [updateBusiness],
  );

  const getBusinessOwnershipPayload = useCallback(
    (ownedBusiness: BusinessWithOwnershipRequest, ownershipPercentage: number, owningBusiness: BusinessApiModel) => {
      const ownerPayload = { id: owningBusiness.id, ownershipPercentage };
      const currentOwningBusinesses =
        application.relatedBusinesses.find((b) => b.business.id === ownedBusiness.id)?.business?.owningBusinesses ?? [];

      return {
        owningBusinesses: currentOwningBusinesses.length
          ? currentOwningBusinesses.map((b) => (b.id === owningBusiness.id ? ownerPayload : b))
          : [ownerPayload],
        relatedBusinesses: uniq([...(ownedBusiness.relatedBusinesses ?? []), owningBusiness.id]),
      };
    },
    [application],
  );

  const getPersonOwnershipPayload = useCallback(
    (ownedBusiness: BusinessWithOwnershipRequest, ownershipPercentage: number, owningPerson: PersonApiModel) => {
      const ownerPayload = { ...owningPerson, ownershipPercentage };

      return {
        people: ownedBusiness.people?.length
          ? ownedBusiness.people.map((p) => (p.id === owningPerson.id ? ownerPayload : p))
          : [ownerPayload],
      };
    },
    [],
  );

  const assignOwnershipToOwnedBusiness = useCallback(
    async (ownedBusiness: BusinessWithOwnershipRequest, ownershipPercentage: number) => {
      if (!owner.entity) {
        return;
      }

      const ownershipPayload =
        owner.entityType === 'person'
          ? getPersonOwnershipPayload(ownedBusiness, ownershipPercentage, owner.entity)
          : getBusinessOwnershipPayload(ownedBusiness, ownershipPercentage, owner.entity);

      await updateBusiness({ businessId: ownedBusiness.id, updateBusinessPayload: ownershipPayload });
    },
    [owner, updateBusiness, getPersonOwnershipPayload, getBusinessOwnershipPayload],
  );

  const assignOwnership = useCallback(
    async (ownedBusiness: BusinessWithOwnershipRequest, ownershipPercentage: number) => {
      await assignOwnershipToOwnedBusiness(ownedBusiness, ownershipPercentage);

      if (owner.entityType === 'business') {
        await assignOwnershipToBusinessOwner({ ownedBusiness, owningBusiness: owner.entity });
      }
    },
    [owner, assignOwnershipToOwnedBusiness, assignOwnershipToBusinessOwner],
  );

  const removeOwnershipFromOwnedBusiness = useCallback(
    async (ownedBusiness: BusinessApiModel) => {
      const currentOwningBusinesses =
        application.relatedBusinesses.find((b) => b.business.id === ownedBusiness.id)?.business?.owningBusinesses ?? [];

      const ownershipPayload = {
        ...(owner.entityType === 'business'
          ? {
              owningBusinesses: currentOwningBusinesses.filter(({ id }) => id !== owner.entity.id),
              relatedBusinesses: (ownedBusiness.relatedBusinesses ?? []).filter((id) => id !== owner.entity.id),
            }
          : {}),
        ...(owner.entityType === 'person' ? { people: (ownedBusiness.people ?? []).filter(({ id }) => id !== owner.entity.id) } : {}),
      };

      await updateBusiness({
        businessId: ownedBusiness.id,
        updateBusinessPayload: ownershipPayload,
      });
    },
    [owner, application, updateBusiness],
  );

  const removeOwnershipFromOwningBusiness = useCallback(
    async ({ ownedBusinessId, owningBusiness }: { ownedBusinessId: string; owningBusiness: BusinessApiModel }) => {
      await updateBusiness({
        businessId: owningBusiness.id,
        updateBusinessPayload: {
          relatedBusinesses: (owningBusiness.relatedBusinesses ?? []).filter((id) => id !== ownedBusinessId),
        },
      });
    },
    [updateBusiness],
  );

  const removeOwnership = useCallback(
    async (ownedBusinessId: string) => {
      const businessToDelete = application.relatedBusinesses.find((b) => b.business.id === ownedBusinessId)?.business;
      if (!businessToDelete) {
        return;
      }

      await removeOwnershipFromOwnedBusiness(businessToDelete);
      if (owner.entityType === 'business') {
        await removeOwnershipFromOwningBusiness({ ownedBusinessId, owningBusiness: owner.entity });
      }
    },
    [application, owner, removeOwnershipFromOwnedBusiness, removeOwnershipFromOwningBusiness],
  );

  const editOwnedBusiness = useCallback(
    async ({ businessValues: ownedBusiness, ownershipPercentage }: BusinessWithOwnership) => {
      await updateBusiness({ businessId: ownedBusiness.id, updateBusinessPayload: ownedBusiness });
      await assignOwnership(ownedBusiness, ownershipPercentage);
    },
    [updateBusiness, assignOwnership],
  );

  const addOwnedBusiness = useCallback(
    async ({ businessValues: ownedBusiness, ownershipPercentage }: BusinessWithOwnership) => {
      await createBusiness({
        business: {
          ...ownedBusiness,
          people: [...(owner.entityType === 'person' ? [{ ...owner.entity, ownershipPercentage }] : [])],
          applicationId: application.id,
          partnerId: application.originatingPartner,
        },
        applicationId: application.id,
      });

      await addApplicationRelation({
        applicationId: application.id,
        entityId: ownedBusiness.id,
        entityType: 'business',
        relation: 'ownedBusiness' as const,
      });

      await assignOwnership(ownedBusiness, ownershipPercentage);
    },
    [createBusiness, application, owner, addApplicationRelation, assignOwnership],
  );

  const onDeleteBusinessCardClick = useCallback(
    async (businessId: string) => {
      const businessToDelete = allOwnedBusinesses.find(({ id }) => id === businessId);
      if (!businessToDelete) {
        return;
      }

      await removeOwnership(businessId);

      const ownersCount =
        businessToDelete.people.filter(({ ownershipPercentage }) => (ownershipPercentage ?? 0) > 0).length +
        (businessToDelete.owningBusinesses?.length ?? 0);

      if (ownersCount === 1) {
        await removeApplicationRelation({
          applicationId: application.id,
          entityId: businessToDelete.id,
          entityType: 'business',
          relation: 'ownedBusiness' as const,
        });
      }
    },
    [allOwnedBusinesses, removeOwnership, removeApplicationRelation, application],
  );

  const onNoRelatedCompaniesMarkClick = useCallback(async () => {
    if (owner.entityType !== 'business' && owner.entityType !== 'person') {
      return;
    }

    toggleNoOwnedBusinesses();

    await (owner.entityType === 'business'
      ? updateBusiness({ businessId: owner.entity.id, updateBusinessPayload: { markedNoOwnedBusinesses: !noOwnedBusinesses } })
      : updatePerson({ personId: owner.entity.id, updatePersonPayload: { markedNoOwnedBusinesses: !noOwnedBusinesses } }));
  }, [owner, noOwnedBusinesses, toggleNoOwnedBusinesses, updateBusiness, updatePerson]);

  const closeDialog = useCallback(() => {
    setModalOpen(false);
    setBusinessIdToEdit(null);
  }, []);

  const submit = useCallback(
    async (formValues: BusinessWithOwnership | null) => {
      if (formValues) {
        const { businessValues, ownershipPercentage } = formValues;
        const businessExists = !!application.relatedBusinesses.find(({ business: { id } }) => id === businessValues.id)?.business;

        await (businessExists
          ? editOwnedBusiness({ businessValues, ownershipPercentage })
          : addOwnedBusiness({ businessValues, ownershipPercentage }));
      }

      closeDialog();
    },
    [editOwnedBusiness, addOwnedBusiness, closeDialog, application],
  );

  const excludedBusinessesIdsForSelection = useMemo(() => {
    const existingBusinessesIds = allOwnedBusinesses.map(({ id }) => id);
    const ownerId = owner.entityType === 'business' ? owner.entity.id : undefined;

    return compact([...existingBusinessesIds, ownerId]);
  }, [allOwnedBusinesses, owner]);

  if (!owner.entity) {
    return null;
  }

  return (
    <>
      <Flex flexDirection={'column'} alignItems={'center'} flex={1} gap={4} height={'100%'}>
        {allOwnedBusinesses.length ? (
          allOwnedBusinesses?.map((ownedBusiness, index) => (
            <OwnedBusinessListItem
              ownedBusiness={ownedBusiness}
              owner={owner}
              key={`${ownedBusiness.id}_${index}`}
              onEdit={onEditBusinessCardClick}
              onDelete={onDeleteBusinessCardClick}
            />
          ))
        ) : (
          <Flex flexDirection={'column'} width={'100%'} height={'100%'} alignItems={'center'}>
            <NoOwnerImage />
          </Flex>
        )}
        {!allOwnedBusinesses.length ? (
          <FormControlLabel
            label={'No items to add'}
            control={<Checkbox checked={noOwnedBusinesses} onChange={onNoRelatedCompaniesMarkClick} />}
          />
        ) : null}
        <ModifyItemButton onClick={onAddBusinessClick} text={t('ownedBusinesses.cta.add')} disabled={noOwnedBusinesses} variant={'text'} />
      </Flex>
      {modalOpen ? (
        <OwnedBusinessModal
          application={application}
          editedBusinessId={businessIdToEdit ?? undefined}
          ownerId={owner.entity.id}
          loading={!!loading}
          excludedEntityIds={excludedBusinessesIdsForSelection}
          onSubmit={submit}
          onClose={closeDialog}
          modalOpen={modalOpen}
        />
      ) : null}
    </>
  );
};
