import type { FC } from 'react';
import React from 'react';
import { Flex, Grid, Text } from '@lama/design-system';
import type { OnlineBanking } from '@lama/contracts';
import { isNil } from 'lodash-es';
import type { InfoLineProps } from '../../Information';
import { KeyValuePair } from '../../KeyValuePair';

interface OnlineBankingItemProps {
  item: OnlineBanking;
}

export const OnlineBankingItem: FC<OnlineBankingItemProps> = ({ item: { wireCapability, wireLimit, fxWireLimit } }) => {
  const itemData: InfoLineProps[] = [
    ...(!isNil(wireCapability) ? [{ label: 'Wire Limit', values: wireLimit, type: 'currency' as const }] : []),
    ...(!isNil(fxWireLimit) ? [{ label: 'FX Wire Limit', values: fxWireLimit, type: 'currency' as const }] : []),
  ];

  return (
    <Flex gap={4} flexDirection={'column'}>
      <Text variant={'body1'} weight={700} color={'secondary'}>
        {wireCapability}
      </Text>
      <Grid columns={3}>
        {itemData.map((line) => (
          <KeyValuePair key={line.label} name={line.label} value={line.values} type={line.type} />
        ))}
      </Grid>
    </Flex>
  );
};
