import tinyColor from 'tinycolor2';
import { bluePalette, greyPalette, redPalette } from '../../../theme/colors';
import type { ColorOverrides } from '../../../theme/types';
import { getTextContrastColor } from '../../../utils/getTextContrastColor.js';

export const getButtonLightTheme = (colors: ColorOverrides) => ({
  focused: {
    shadowColor: bluePalette[400],
  },
  variants: {
    primary: {
      disabled: {
        iconColor: '#C5C5C5',
        textColor: '#C5C5C5',
        backgroundColor: '#E0E0E0',
      },
      colors: {
        primary: {
          borderColor: 'none',
          iconColor: getTextContrastColor(colors.primary.main),
          textColor: getTextContrastColor(colors.primary.main),
          backgroundColor: colors.primary.main,
          hover: {
            backgroundColor: tinyColor(colors.primary.main).darken(8).toString(),
          },
          active: {
            backgroundColor: tinyColor(colors.primary.main).brighten(4).toString(),
          },
        },
        // UNIMPLEMENTED
        neutral: {
          iconColor: '',
          textColor: '',
          borderColor: 'none',
          backgroundColor: 'none',
          hover: {
            backgroundColor: 'none',
          },
          active: {
            backgroundColor: 'none',
          },
        },
        danger: {
          iconColor: 'white',
          textColor: 'white',
          borderColor: 'none',
          backgroundColor: redPalette[400],
          hover: {
            backgroundColor: redPalette[700],
          },
          active: {
            backgroundColor: redPalette[800],
          },
        },
      },
    },
    secondary: {
      disabled: {
        iconColor: '#C5C5C5',
        backgroundColor: greyPalette[50],
        borderColor: greyPalette[300],
        border: '2px',
        textColor: '#C5C5C5',
      },
      colors: {
        primary: {
          backgroundColor: 'transparent',
          textColor: colors.primary.main,
          borderColor: colors.primary.main,
          iconColor: colors.primary.main,

          hover: {
            backgroundColor: colors.primary.light,
          },
          active: {
            backgroundColor: tinyColor(colors.primary.light).darken(8).toString(),
          },
          selected: {
            backgroundColor: tinyColor(colors.primary.light).darken(4).toString(),
          },
        },
        danger: {
          backgroundColor: 'white',
          textColor: '#00000099',
          borderColor: redPalette[100],
          iconColor: redPalette[400],

          hover: {
            backgroundColor: '#FFF8F9',
          },
          active: {
            backgroundColor: redPalette[50],
          },
          selected: {
            backgroundColor: redPalette[100],
          },
        },
        neutral: {
          backgroundColor: 'white',
          textColor: '#00000099',
          borderColor: greyPalette[300],
          iconColor: '#00000099',

          hover: {
            backgroundColor: greyPalette[100],
          },
          active: {
            backgroundColor: greyPalette[200],
          },
          selected: {
            backgroundColor: greyPalette[200],
          },
        },
      },
    },
    tertiary: {
      disabled: {
        iconColor: '#C5C5C5',
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        textColor: '#C5C5C5',
      },
      colors: {
        primary: {
          backgroundColor: 'transparent',
          textColor: colors.primary.main,
          borderColor: 'transparent',
          iconColor: colors.primary.main,
          hover: {
            textColor: '#3B28DA',
          },
          active: {
            border: bluePalette[400],
            backgroundColor: tinyColor(colors.primary.light).darken(8).toString(),
          },
          selected: {
            backgroundColor: tinyColor(colors.primary.light).darken(4).toString(),
            textColor: colors.primary.dark,
          },
        },
        danger: {
          backgroundColor: 'transparent',
          textColor: '#EF5350',
          borderColor: 'transparent',
          iconColor: '#EF5350',
          hover: {
            textColor: redPalette[700],
          },
          active: {
            border: 'transparent',
            backgroundColor: redPalette[50],
          },
          selected: {
            backgroundColor: redPalette[100],
            textColor: redPalette[400],
          },
        },
        neutral: {
          backgroundColor: 'transparent',
          textColor: greyPalette[700],
          borderColor: 'transparent',
          iconColor: greyPalette[500],
          hover: {
            textColor: greyPalette[700],
          },
          active: {
            border: 'transparent',
            backgroundColor: greyPalette[200],
          },
          selected: {
            backgroundColor: greyPalette[200],
            textColor: greyPalette[700],
          },
        },
      },
    },
  },
});
