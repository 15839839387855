import React, { useCallback, useContext, useMemo, useState, type FC } from 'react';
import { Insights, OpenInNew, ErrorOutlineRounded } from '@mui/icons-material';
import { Button, Flex, Modal, ModalContent, ModalFooter, Text, colors } from '@lama/design-system';
import { compact, isNil } from 'lodash-es';
import { titleCase } from 'change-case-all';
import { DocumentPreviewModal } from '@lama/app-components';
import type { AlertV2 } from '@lama/alerts-service-client';
import { useApplicationDocumentsQuery } from '../../../../shared/hooks/react-query/application/useApplicationDocumentsQuery';
import { ApplicationContext } from '../../ApplicationContext';
import { SectionAlertContainer } from './SectionAlertContainer';

interface SectionAlertProps {
  alert: AlertV2;
  alertIndex: number;
}

const alertTypeToIcon = (type: AlertV2['type'], dismissed?: boolean) => {
  const iconCommonStyle = { borderRadius: 21, p: '2px' };
  switch (type) {
    case 'addBack': {
      return (
        <Insights
          color={dismissed ? 'disabled' : 'primary'}
          sx={{ backgroundColor: dismissed ? colors.grey[50] : colors.primary.light, ...iconCommonStyle }}
        />
      );
    }
    case 'validation': {
      return (
        <ErrorOutlineRounded
          color={dismissed ? 'disabled' : 'warning'}
          sx={{ backgroundColor: dismissed ? colors.grey[50] : colors.amber[50], ...iconCommonStyle }}
        />
      );
    }
  }
};

export const SectionAlert: FC<SectionAlertProps> = ({ alert, alertIndex }) => {
  const [openModal, setOpenModal] = useState(false);
  const [openDocumentPreview, setOpenDocumentPreview] = useState(false);
  const [dismissed, setDismissed] = useState(localStorage.getItem('dismissedAlerts')?.includes(alertIndex.toString()));
  const {
    application: { id: applicationId },
  } = useContext(ApplicationContext);

  const { data: documents } = useApplicationDocumentsQuery({ applicationId });

  const alertRelatedDocument = useMemo(
    () => documents?.find((doc) => doc.id === alert.alertDetails?.relatedDocumentId),
    [documents, alert.alertDetails?.relatedDocumentId],
  );
  const alertRelatedDocumentPage = useMemo(
    () => (alert.alertDetails?.page ? Number(alert.alertDetails?.page) - 1 : 0),
    [alert.alertDetails],
  );

  const alertDetailsForModal = useMemo(
    () => (alert.alertDetails ? Object.entries(alert.alertDetails).filter(([key]) => key !== 'relatedDocumentId') : []),
    [alert.alertDetails],
  );

  const onClose = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  const onClick = useCallback(() => {
    setOpenModal(true);
  }, [setOpenModal]);

  const onDismiss = useCallback(() => {
    const dismissedAlerts = localStorage.getItem('dismissedAlerts') ?? '';
    localStorage.setItem('dismissedAlerts', compact([...dismissedAlerts.split(','), alertIndex]).join(','));
    setDismissed(true);
    setOpenModal(false);
  }, [alertIndex]);

  const onOpenDocumentClick = useCallback(() => {
    if (!alertRelatedDocument?.previewUrl?.toLowerCase().includes('.pdf')) {
      open(alertRelatedDocument?.previewUrl);
    }
    setOpenModal(false);
    setOpenDocumentPreview(true);
  }, [alertRelatedDocument]);

  const onCloseDocument = useCallback(() => {
    setOpenDocumentPreview(false);
  }, []);

  return (
    <>
      <SectionAlertContainer onClick={onClick} disabled={!!dismissed}>
        <Flex key={`icon-${alert.name}`} pr={3}>
          {alertTypeToIcon(alert.type, dismissed)}
        </Flex>
        <Text variant={'body2'} color={dismissed ? 'disabled' : 'secondary'}>
          {`${alert?.messageParams?.customMessage} ${alert.entityName ? `•  ${alert.entityName}` : ''}`}
        </Text>
      </SectionAlertContainer>
      <Modal title={alert.name} onClose={onClose} open={openModal} size={'l'}>
        <ModalContent>
          <Flex flexDirection={'column'} gap={4}>
            <Text variant={'body2'} color={'secondary'}>
              {alert?.messageParams?.customMessage}
            </Text>
            {alertDetailsForModal.map(([key, value]) => (
              <Flex key={key} flexDirection={'row'} justifyContent={'space-between'}>
                <Text variant={'body1'}>{titleCase(key)}</Text>
                <Text variant={'body1'}>{value}</Text>
              </Flex>
            ))}
          </Flex>
        </ModalContent>
        <ModalFooter>
          <Flex flexDirection={'row-reverse'} justifyContent={'space-between'} width={'100%'}>
            <Flex>
              <Button variant={'tertiary'} color={'primary'} onClick={onDismiss}>
                {'Dismiss'}
              </Button>
              {alert.type === 'addBack' ? <Button>{'Adjust'}</Button> : null}
            </Flex>
            {!isNil(alertRelatedDocument) ? (
              <Button variant={'tertiary'} color={'primary'} endIcon={<OpenInNew />} onClick={onOpenDocumentClick}>
                {'Open Document'}
              </Button>
            ) : null}
          </Flex>
        </ModalFooter>
      </Modal>
      <DocumentPreviewModal
        onClose={onCloseDocument}
        open={openDocumentPreview}
        initialPage={alertRelatedDocumentPage}
        document={alertRelatedDocument}
      />
    </>
  );
};
