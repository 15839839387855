import React, { useMemo } from 'react';
import type { Person } from '@lama/contracts';
import type { FC } from 'react';
import { AuditLog } from '../../../AuditLog';
import type { AuditComponentProps } from '../types';

export const CreatePersonAuditLog: FC<AuditComponentProps> = ({ audit, groupInfo }) => {
  const createdPeople = useMemo(
    () =>
      audit
        .filter((entry) => (entry.context.entity as Person).id !== groupInfo.user?.personId)
        .map((entry) => entry.entityName)
        .join(', '),
    [audit, groupInfo.user?.personId],
  );

  return (
    <AuditLog
      {...(groupInfo.originType === 'user'
        ? { originType: groupInfo.originType, user: groupInfo.user }
        : { originType: groupInfo.originType })}
      text={`Registered ${createdPeople}`}
      timestamp={groupInfo.timestamp}
      customText={groupInfo.customText}
      labels={groupInfo.labels}
    />
  );
};
