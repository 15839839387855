import React, { useCallback, useState } from 'react';
import { Flex, Modal } from '@lama/design-system';
import { Link } from '@mui/material';
import styled from 'styled-components';
import { DemoEmailContent } from './DemoEmailContent';
import { DemoApplicationDetails } from './DemoApplicationDetails';

const RightPane = styled(Flex)`
  border-left: 1px solid ${({ theme }) => theme.colors.grey[300]};
  width: 500px;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;
  gap: 16px;
`;

export const OmniCaptureEmailDemo = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const onButtonClick = useCallback(() => {
    setModalOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  return (
    <>
      <Modal padding={0} pl={4} open={modalOpen} fullscreen onClose={onClose} alignTitle={'center'} title={''} fullWidth>
        <Flex height={'100%'} width={'100%'} pt={4} pl={4}>
          <Flex flex={1} flexDirection={'column'} gap={8}>
            <DemoEmailContent />
          </Flex>
          <RightPane>
            <DemoApplicationDetails />
          </RightPane>
        </Flex>
      </Modal>
      <Link href={'#'} underline={'none'} onClick={onButtonClick}>
        {'View Email'}
      </Link>
    </>
  );
};
