import { useContext, useMemo } from 'react';
import type { FinancialData, GetAttributesResponse } from '@lama/contracts';
import type { DocumentPage } from '@lama/document-service-client';
import { useGetFormTypesQuery } from '../../Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/hooks/useGetFormTypesQuery';
import { getFormAttributesWithFinancialData, isFinancialRelatedToFormPage } from '../Forms/TaxReturns/formFinancialsUtils';
import { SpreadingDocumentContext } from '../SpreadingDocumentContext';

import { useGetAllFinancialsForCurrentDocument } from './useGetAllFinancialsForCurrentDocument';

export const getRelevantFinancialsForFormType = (
  documentId: string,
  financials: FinancialData[],
  formType: string,
  formPageNumbers: number[],
) => financials?.filter((financial) => formType && isFinancialRelatedToFormPage(financial.source, documentId, formPageNumbers));

export const useGetAllDocumentAttributesWithFinancialData = (
  formsPages: DocumentPage[][],
  documentAttributes: GetAttributesResponse['attributes'],
) => {
  const { currentDocument, getPeriodByFormType } = useContext(SpreadingDocumentContext);
  const { data: allFormTypes, isPending: formTypesLoading } = useGetFormTypesQuery();
  const { entityFinancials, fetchingData } = useGetAllFinancialsForCurrentDocument();
  const formAttributesWithFinancialData = useMemo(() => {
    if (formTypesLoading || !documentAttributes || fetchingData) {
      return [];
    }

    return formsPages.flatMap((formDocumentPages) => {
      const currentFormType = allFormTypes?.formTypes.find(({ id }) => id === formDocumentPages.at(0)!.formType);
      if (!currentFormType) {
        return [];
      }

      const formDocumentPageNumbers = formDocumentPages.map(({ page }) => page);
      const existingFormPages = new Set(formDocumentPages.map(({ formPage }) => formPage));

      const relevantFinancials = getRelevantFinancialsForFormType(
        currentDocument.id,
        entityFinancials ?? [],
        currentFormType.id,
        formDocumentPageNumbers,
      );

      const formData = getFormAttributesWithFinancialData(
        documentAttributes.filter(
          ({ formId, page }) => formId === currentFormType.id && (page === '*' || existingFormPages.has(Number(page))),
        ),
        relevantFinancials ?? [],
        formDocumentPages,
        currentFormType,
        getPeriodByFormType(currentFormType.id),
      );
      return { formData, formDocumentPages };
    });
  }, [
    formTypesLoading,
    documentAttributes,
    fetchingData,
    formsPages,
    allFormTypes?.formTypes,
    currentDocument.id,
    entityFinancials,
    getPeriodByFormType,
  ]);
  return { formAttributesWithFinancialData, loading: fetchingData };
};
