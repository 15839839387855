import type { FC } from 'react';
import React, { useCallback, useContext, useMemo } from 'react';
import { groupBy, keyBy } from 'lodash-es';
import { SpreadingDocumentContext } from '../../../SpreadingDocumentContext';

import type { SpreadingFormByDocumentTypeProps } from '../../../SpreadingDialog/Content/SpreadingFormByType';
import { sortFormAttributesByPage } from '../sortAttributes';
import { useGetAttributesDocumentFunction } from '../../../hooks/useGetAttributesDocumentFunction';
import { SpreadingFormCard } from '../../../SpreadingDialog/Content/SpreadingFormCard';
import { SpreadingDocumentSetHighlightContext } from '../../../SpreadingDocumentHighlightContext';
import { TaxReturnsSchedule } from './TaxReturnsSpreadingForm/TaxReturnsSchedule';

export const TaxReturnsSpreadingForm: FC<SpreadingFormByDocumentTypeProps> = ({
  formDocumentPages,
  formData: formAttributesWithFinancialData,
}) => {
  const setHighlightedArea = useContext(SpreadingDocumentSetHighlightContext);
  const { currentPage, setJumpToPage, allFormTypes } = useContext(SpreadingDocumentContext);

  const formType = useMemo(() => formDocumentPages.at(0)?.formType, [formDocumentPages]);

  const currentFormTypeData = useMemo(() => allFormTypes?.find(({ id }) => id === formType), [allFormTypes, formType]);

  const attributesById = useMemo(
    () => keyBy(formAttributesWithFinancialData, (attribute) => attribute.id),
    [formAttributesWithFinancialData],
  );

  const getAttributeDocumentPage = useGetAttributesDocumentFunction();
  const onFieldFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const { id } = event.target;
      const attribute = attributesById[id];

      if (attribute) {
        const pageToFocus = getAttributeDocumentPage(attribute);
        if (pageToFocus && currentPage !== pageToFocus - 1) {
          setJumpToPage(pageToFocus - 1);
        }

        const fieldSource = attribute.financialData?.source;
        if (fieldSource?.type === 'Document' && fieldSource?.boundingBox) {
          setHighlightedArea({ ...fieldSource.boundingBox, pageIndex: fieldSource.page });
        }
      }

      event.target.focus();
    },
    [attributesById, currentPage, getAttributeDocumentPage, setHighlightedArea, setJumpToPage],
  );

  const formAttributesBySchedule = useMemo(
    () =>
      Object.entries(groupBy(sortFormAttributesByPage(formAttributesWithFinancialData ?? []), ({ attribute }) => attribute.schedule ?? '')),
    [formAttributesWithFinancialData],
  );

  const header = useMemo(
    () => (currentFormTypeData ? currentFormTypeData.displayName ?? currentFormTypeData.id : 'Unknown form'),
    [currentFormTypeData],
  );

  if (!formAttributesBySchedule.length) {
    return null;
  }

  return (
    <SpreadingFormCard header={header} formType={currentFormTypeData?.id}>
      {formAttributesBySchedule.map(([schedule, attributes]) => (
        <TaxReturnsSchedule key={schedule} financialAttributes={attributes} onFieldFocus={onFieldFocus} scheduleName={schedule} />
      ))}
    </SpreadingFormCard>
  );
};
