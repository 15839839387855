import React, { useMemo } from 'react';
import type { FunctionComponent } from 'react';
import { Avatar, Tooltip } from '@lama/app-components';
import { Badge, Box } from '@mui/material';
import { Flex, Text, greyPalette } from '@lama/design-system';
import { formatValue } from '@lama/data-formatters';
import { differenceInDays, formatDistanceToNow } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { EngagementAuditLog } from '../../../components/Application/EngagementHubDemo/EngagementAuditLog';
import { getAssetUrl } from '../../utils/getAssetUrl';
import type { AuditLogProps } from './AuditLogProps';

const lamaAuditAvatar = getAssetUrl('lamaAuditAvatar.png');

export const auditLogClassName = 'audit-log';
export const auditConnectorClassName = 'audit-connector';

const AuditConnector = () => (
  <Box
    className={'audit-connector'}
    sx={{
      mt: 1,
      position: 'absolute',
      right: '50%',
      top: '100%',
      height: '100%',
      borderLeft: '1px solid lightgrey',
    }}
  />
);
export const AuditLog: FunctionComponent<AuditLogProps> = (props) => {
  const location = useLocation();
  // Hack in order to use the existing audit components
  const isEngagementScreenDemo = location.pathname.includes('/engagement');

  const { text, user, timestamp, badge, originType } = props;

  const userName = useMemo(
    () => (originType === 'system' ? 'Lama AI' : `${user?.firstName ?? ''} ${user?.lastName ?? ''}`),
    [originType, user?.firstName, user?.lastName],
  );

  const absoluteDateTime = useMemo(() => formatValue(timestamp, 'datetime'), [timestamp]);
  const absoluteDate = useMemo(
    () =>
      differenceInDays(new Date(), new Date(timestamp)) < 3
        ? formatDistanceToNow(new Date(timestamp), { addSuffix: true })
        : formatValue(timestamp, 'date'),
    [timestamp],
  );

  if (originType === 'user' && (!user.firstName || !user.lastName)) {
    return null;
  }

  if (isEngagementScreenDemo) {
    return <EngagementAuditLog {...props} />;
  }

  return (
    <Flex flexDirection={'row'} alignItems={'center'} gap={6} width={'100%'} my={4} className={'audit-log'}>
      <Flex flex={1} flexDirection={'column'} position={'relative'}>
        <Badge overlap={'circular'} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} badgeContent={badge}>
          <Avatar
            imgSrc={originType === 'system' ? lamaAuditAvatar : undefined}
            firstName={userName.split(' ')[0] ?? ''}
            lastName={userName.split(' ')[1] ?? ''}
            alt={userName}
          />
        </Badge>
        <AuditConnector />
      </Flex>
      <Flex flexDirection={'row'} justifyContent={'space-between'} width={'100%'} overflow={'hidden'}>
        <Flex flexDirection={'column'} gap={1} flex={'0 1 90%'} overflow={'hidden'}>
          <Text variant={'body2'}>{userName}</Text>
          <Text variant={'body2'} color={greyPalette[500]} ellipsis>
            {text}
          </Text>
        </Flex>
        <Flex flex={'0 1 20%'} justifyContent={'flex-end'} flexShrink={0}>
          <Tooltip title={absoluteDateTime} placement={'top'}>
            <Text variant={'body2'} color={'disabled'}>
              {absoluteDate}
            </Text>
          </Tooltip>
        </Flex>
      </Flex>
    </Flex>
  );
};
