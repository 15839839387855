import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { groupBy, uniqBy } from 'lodash-es';
import { AuditLog } from '../../AuditLog';
import { getAuditUpdatedPropertyRepresentation } from '../mapAuditRequirements';
import { useOpportunityRequirementsQuery } from '../../../../hooks/react-query/opportunity/useOpportunityRequirementsQuery';
import { ApplicationContext } from '../../../../../components/Application/ApplicationContext';
import type { AuditComponentProps } from './types';

const UNKNOWN = 'unknown';

export const UpdateEntityAuditLog: FC<AuditComponentProps> = ({ audit, groupInfo }) => {
  const { opportunity, application } = useContext(ApplicationContext);
  const { data: requirements } = useOpportunityRequirementsQuery(opportunity.id);

  const propertiesRepresentationByTarget = useMemo(() => {
    const uniqueProperties = uniqBy(getAuditUpdatedPropertyRepresentation(audit, requirements ?? [], application), (p) => p.name);

    return groupBy(uniqueProperties, (p) => p.entity || UNKNOWN);
  }, [audit, requirements, application]);

  const updatedPropertiesText = useMemo(
    () =>
      Object.entries(propertiesRepresentationByTarget)
        .map(([entity, properties]) => {
          if (!properties.length) {
            return '';
          }

          const propertiesText = properties.map((p) => p.name).join(', ');
          const propertiesTextWithTarget = entity === UNKNOWN ? `${propertiesText}` : `${propertiesText} for ${entity}`;

          return `${propertiesTextWithTarget}`;
        })
        .join(', '),
    [propertiesRepresentationByTarget],
  );

  return updatedPropertiesText.length ? (
    <AuditLog
      {...(groupInfo.originType === 'user'
        ? { originType: groupInfo.originType, user: groupInfo.user }
        : { originType: groupInfo.originType })}
      text={`Updated ${updatedPropertiesText}`}
      timestamp={groupInfo.timestamp}
      customText={groupInfo.customText}
      labels={groupInfo.labels}
    />
  ) : null;
};
