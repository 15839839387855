/* eslint-disable react/jsx-no-useless-fragment */
import React, { useCallback } from 'react';
import type { FC } from 'react';
import { Flex, Text } from '@lama/design-system';
import { ClickAwayListener, TextField } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import type { RequirementProperty } from '@lama/contracts';
import { componentRestrictoreContext } from './ComponentRestrictorContext';

interface ComponentRestrictorProps {
  restricted?: boolean;
  children: React.ReactNode;
  componentType?: 'input' | 'textField';
  displayName?: string;
  property?: RequirementProperty;
  required?: boolean;
}

const ComponentRestrictorInner: FC<ComponentRestrictorProps> = ({ children, displayName, componentType, restricted, required }) => {
  const [valueVisible, setValueVisible] = React.useState<boolean>(!restricted);
  const { isAllowedToSeeValueHandler, onValueExposed, overrideRestriction } = React.useContext(componentRestrictoreContext);
  const onClick = useCallback(async () => {
    if (isAllowedToSeeValueHandler) {
      const allowed = await isAllowedToSeeValueHandler();
      if (allowed && onValueExposed) {
        onValueExposed();
      }
      setValueVisible(allowed);
    }
  }, [isAllowedToSeeValueHandler, onValueExposed]);
  const handleOnBlur = useCallback(() => {
    if (!!overrideRestriction || !restricted) {
      return;
    }
    setValueVisible(false);
  }, [overrideRestriction, restricted]);
  if (!overrideRestriction && !valueVisible) {
    if (componentType === 'input') {
      return (
        <TextField
          fullWidth
          required={required}
          type={'text'}
          onClick={onClick}
          label={displayName}
          variant={'outlined'}
          data-testid={'restricted-input'}
          slotProps={{
            input: {
              startAdornment: (
                <Flex style={{ color: '#00000099' }} pr={1}>
                  <LockIcon />
                </Flex>
              ),
            },
          }}
          minRows={3}
          maxRows={8}
          value={'*****'}
          role={'input'}
        />
      );
    }
    return (
      <Text data-testid={'restricted-label'} onClick={onClick} variant={'body2'}>
        {'****'}
      </Text>
    );
  }

  return (
    <ClickAwayListener onClickAway={handleOnBlur}>
      <Flex style={{ cursor: 'unset' }} flexGrow={1} flexShrink={1} flexBasis={'0%'} minWidth={0} flexDirection={'column'}>
        {children}
      </Flex>
    </ClickAwayListener>
  );
};

const PropertyComponentRestrictor: FC<ComponentRestrictorProps> = ({ children, restricted, componentType, property, displayName }) => (
  <ComponentRestrictorInner displayName={displayName} restricted={restricted} componentType={componentType} required={!property?.optional}>
    {children}
  </ComponentRestrictorInner>
);

const ValueComponentRestrictor: FC<ComponentRestrictorProps> = ({ children, restricted, componentType, displayName, required }) => (
  <ComponentRestrictorInner required={required} displayName={displayName} restricted={restricted} componentType={componentType}>
    {children}
  </ComponentRestrictorInner>
);

export const ComponentRestrictor: FC<ComponentRestrictorProps> = ({ children, displayName, restricted, componentType, property }) => {
  if (property?.fieldName) {
    return (
      <PropertyComponentRestrictor displayName={displayName} restricted={restricted} componentType={componentType} property={property}>
        {children}
      </PropertyComponentRestrictor>
    );
  }
  return (
    <ValueComponentRestrictor restricted={restricted} componentType={componentType}>
      {children}
    </ValueComponentRestrictor>
  );
};
