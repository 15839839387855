import React, { useContext, type FC, useCallback } from 'react';
import { displayToast } from '@lama/app-components';
import { format } from 'date-fns';
import writeXlsxFile from 'write-excel-file';
import type { Section1071ReportExportRow } from '@lama/reports-service-client';
import { section1071ReportableReportExcelSchema } from '@lama/reports-service-client';
import { useDownloadReportMutation } from '../../hooks/downloadReportMutation';
import { ReportsFilterContext } from '../ReportsFilterContext';
import { ReportCard } from '../ReportCard';
import { use1071ApplicableApplicationsChartData } from '../Charts/hooks/use1071ApplicableApplicationsChartData';
import { ApplicationsWeeklyChart } from '../Charts/ApplicationsWeeklyChart';

export const Section1071ReportableReport: FC = () => {
  const { endDate, startDate, productId, partnerId } = useContext(ReportsFilterContext);
  const { data: chartData, isPending: loadingChartData } = use1071ApplicableApplicationsChartData({
    endDate,
    startDate,
    productId,
    partnerId,
  });
  const { mutateAsync: download1071ApplicableApplicationsReport, isPending: downloadingReport } =
    useDownloadReportMutation('Section1071ReportableApplications');

  const onClick = useCallback(async () => {
    if (!productId) {
      displayToast("Can't download 1071 Applicable Applications report without a product selected", 'info');
      return;
    }

    if (!startDate || !endDate) {
      displayToast("Can't download 1071 Applicable Applications report without a date range selected", 'info');
      return;
    }

    if (!partnerId) {
      displayToast("Can't download 1071 Applicable Applications report without a partner id", 'info');
      return;
    }

    const filename = `Section 1071 Applicable Applications Report ${format(startDate, 'MM/dd/yy')} - ${format(endDate, 'MM/dd/yy')}.csv`;

    const data: Section1071ReportExportRow[] = await download1071ApplicableApplicationsReport({
      body: {
        partnerId,
        productId,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        format: 'application/json',
      },
      filename,
    });

    await writeXlsxFile(data, {
      schema: section1071ReportableReportExcelSchema,
      fileName: filename,
    });
  }, [download1071ApplicableApplicationsReport, endDate, partnerId, productId, startDate]);

  return (
    <ReportCard
      disableActions={loadingChartData || downloadingReport}
      actionText={'Download Report'}
      onClickAction={onClick}
      actionLoading={downloadingReport}
      title={'1071 Applicable Applications'}
      loading={loadingChartData}
    >
      <ApplicationsWeeklyChart chartData={chartData?.chartData} />
    </ReportCard>
  );
};
