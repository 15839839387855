import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as DatePickerInnerComponent } from '@mui/x-date-pickers/DatePicker';
import { useField } from 'formik';
import { isValid, setHours } from 'date-fns';
import type { DateView } from '@mui/x-date-pickers';
import type { FormikInputProps } from './PropertyFormikInput';
import { HelperText } from './HelperTexts/HelperText';
import { FormikMultiSourceHelperText } from './HelperTexts/FormikMultiSourceHelperText';
import { useSelectedPropertyValue } from './hooks/useSelectedValueSource';

export const FormikDatePicker: FC<FormikInputProps & { disableFuture?: boolean; views?: DateView[] }> = ({
  name,
  label,
  disableFuture = true,
  views = ['year', 'month', 'day'],
  required,
  sourcesValues,
  helperText,
}) => {
  const [{ value }, { touched, error }, { setValue: setFormikValue }] = useField<string>(name);
  const selectedPropertyValue = useSelectedPropertyValue(sourcesValues, value);

  const onChange = useCallback(
    async (pickedDate: Date | null) => {
      // this is a workaround for timezone issues. we only care about the date, not the time.
      if (pickedDate && isValid(pickedDate)) {
        pickedDate = setHours(pickedDate, 12);
      }
      void setFormikValue(pickedDate?.toISOString() ?? '');
    },
    [setFormikValue],
  );

  const hasError = useMemo(() => touched && !!error, [touched, error]);

  const inputHelperText = useMemo(() => {
    if (hasError) {
      return error;
    }

    return (
      <HelperText>
        <FormikMultiSourceHelperText selectedPropertyValue={selectedPropertyValue} sourcesValues={sourcesValues} />
        {helperText}
      </HelperText>
    );
  }, [hasError, selectedPropertyValue, sourcesValues, helperText, error]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePickerInnerComponent
        disableFuture={disableFuture}
        label={label}
        value={value ? new Date(value) : null}
        onChange={onChange}
        slotProps={{
          textField: {
            helperText: inputHelperText,
            fullWidth: true,
            error: hasError,
            required,
          },
        }}
        views={views}
      />
    </LocalizationProvider>
  );
};
