import React, { useCallback, useState, useEffect, useMemo } from 'react';
import type { FC } from 'react';
import { capitalCase } from 'change-case-all';
import { Checkbox } from '@mui/material';
import { Flex, Text } from '@lama/design-system';
import type { AuditLabel } from '@lama/audit-service-client';
import { AuditLabelColorDot } from './AuditLabelColorDot';

const AuditLabelFilter: FC<{ checked: boolean; label: AuditLabel; onCheck: (label: AuditLabel) => void }> = ({
  checked,
  label,
  onCheck,
}) => {
  const handleChange = useCallback(() => {
    onCheck(label);
  }, [label, onCheck]);

  const labelText = useMemo(() => capitalCase(label), [label]);

  return (
    <Flex alignItems={'center'} justifyContent={'center'}>
      <Checkbox size={'small'} checked={checked} onChange={handleChange} />
      <Flex gap={2} alignItems={'center'} justifyContent={'center'}>
        <AuditLabelColorDot label={label} size={'10px'} />
        <Text>{labelText}</Text>
      </Flex>
    </Flex>
  );
};

export const AuditLabelsFilters: FC<{
  labels: AuditLabel[];
  onLabelsChange: (checkedLabels: AuditLabel[]) => void;
}> = ({ labels, onLabelsChange }) => {
  const [checkedLabels, setCheckedLabels] = useState<AuditLabel[]>(labels);

  useEffect(() => {
    onLabelsChange(checkedLabels);
  }, [checkedLabels, onLabelsChange]);

  const handleCheckLabel = useCallback(
    (label: AuditLabel) => {
      if (checkedLabels.includes(label)) {
        setCheckedLabels(checkedLabels.filter((checkedLabel) => checkedLabel !== label));
      } else {
        setCheckedLabels([...checkedLabels, label]);
      }
    },
    [setCheckedLabels, checkedLabels],
  );

  return (
    <Flex
      gap={4}
      flexDirection={'column'}
      alignItems={'start'}
      border={'1px solid #E0E0E0'}
      borderRadius={'6px'}
      width={'fit-content'}
      height={'fit-content'}
      p={6}
    >
      {labels.map((label) => (
        <AuditLabelFilter key={label} label={label} checked={checkedLabels.includes(label)} onCheck={handleCheckLabel} />
      ))}
    </Flex>
  );
};
