import type { AccountData } from '@lama/contracts';
import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Flex } from '@lama/design-system';
import { isWithinInterval } from 'date-fns';
import { AccountsFilterContext } from '../AccountsFilterContext';
import { parsePlaidDate } from '../AccountCard';
import { categoriesDesign } from './types';
import { TansactionsCategory } from './TransactionCategory';

const categories = new Set(categoriesDesign.map((category) => category.name));

interface TransactionsCategoriesProps {
  accounts: AccountData[];
}

export const TransactionsCategories: FC<TransactionsCategoriesProps> = ({ accounts }) => {
  const { startDate, endDate } = useContext(AccountsFilterContext);

  const relevantTransactions = useMemo(
    () =>
      accounts
        .flatMap(({ transactions }) => transactions)
        .filter(({ date }) => {
          if (!date) {
            return false;
          }

          const transactionDate = parsePlaidDate(date);

          return isWithinInterval(transactionDate, { start: startDate, end: endDate });
        }),
    [accounts, startDate, endDate],
  );

  const transactionsByCategory = useMemo(
    () =>
      relevantTransactions.reduce<Record<string, { profit: number; loss: number }>>((acc, transaction) => {
        if (transaction.category?.length) {
          const [category] = transaction.category;

          if (category && categories.has(category)) {
            if (!acc[category]) {
              acc[category] = { profit: 0, loss: 0 };
            }

            if (transaction.amount > 0) {
              acc[category]!.profit += transaction.amount;
            } else {
              acc[category]!.loss += transaction.amount;
            }
          }
        }
        return acc;
      }, {}),
    [relevantTransactions],
  );

  return (
    <Flex flexDirection={'row'} flexWrap={'wrap'} gap={4}>
      {Object.entries(transactionsByCategory).map(({ 0: category, 1: grouped }) => (
        <TansactionsCategory
          key={category}
          category={categoriesDesign.find((categoryDesign) => categoryDesign.name === category)!}
          profit={grouped?.profit ?? 0}
          loss={grouped?.loss ?? 0}
        />
      ))}
    </Flex>
  );
};
