/* eslint-disable @typescript-eslint/naming-convention */
import type { FC } from 'react';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import { Flex, greenPalette, greyPalette, redPalette, Text } from '@lama/design-system';
import styled from 'styled-components';
import type { MenuProps } from '@mui/material';
import { Divider, Menu, MenuItem } from '@mui/material';
import type { ApplicationStatus } from '@lama/contracts';
import { useConfirmModal } from '@lama/app-components';
import { inactiveApplicationStatuses } from '@lama/clients';
import { isOpportunityAutoRejectedSelector } from '@lama/data-formatters';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { statusToChip } from '../../../../shared/components/ApplicationStatusChip';
import { ApplicationContext } from '../../ApplicationContext';
import { UserDetailsContext } from '../../../../shared/context/UserDetailsContext';
import { useUpdateApplicationMutation } from '../../../../shared/hooks/react-query/application/useUpdateApplication';
import { useOpportunityClosingTasksQuery } from '../../../../shared/hooks/react-query/opportunity/useOpportunityClosingTasksQuery';
import { useUserPartner } from '../../../../shared/hooks/useCurrentPartner';
import { SetStatusMenu } from './SetStatusMenu';
import { DismissApplicationDialog } from './DismissApplicationDialog';
import { DownwardArrow } from './assets/DownwardArrow';
import { isUserDecisionMaker, isUserRecommender } from './isUserDecisionMaker';

export type StatusTransition = {
  actionLabel: string;
  relevantPermission: 'all' | 'decisionMakers' | 'nonDecisionMakers';
} & (
  | {
      action: 'transition';
      target: ApplicationStatus;
    }
  | { action: 'dismiss' }
);

export const workflowTransitions: Record<ApplicationStatus, StatusTransition[]> = {
  Draft: [
    { action: 'transition', target: 'OnboardingCompleted', actionLabel: 'Pre-Qualify', relevantPermission: 'all' },
    { action: 'dismiss', actionLabel: 'Request to Dismiss', relevantPermission: 'nonDecisionMakers' },
    { action: 'dismiss', actionLabel: 'Dismiss', relevantPermission: 'decisionMakers' },
  ],
  OnboardingCompleted: [
    { action: 'transition', target: 'InReview', actionLabel: 'Move to Review', relevantPermission: 'all' },
    { action: 'dismiss', actionLabel: 'Request to Dismiss', relevantPermission: 'nonDecisionMakers' },
    { action: 'dismiss', actionLabel: 'Dismiss', relevantPermission: 'decisionMakers' },
  ],
  InReview: [
    { action: 'transition', target: 'PendingApproval', actionLabel: 'Request Approval', relevantPermission: 'nonDecisionMakers' },
    { action: 'dismiss', actionLabel: 'Request to Dismiss', relevantPermission: 'nonDecisionMakers' },
    { action: 'dismiss', actionLabel: 'Dismiss', relevantPermission: 'decisionMakers' },
  ],
  PendingLost: [
    { action: 'transition', target: 'PendingApproval', actionLabel: 'Request Approval', relevantPermission: 'nonDecisionMakers' },
    { action: 'dismiss', actionLabel: 'Dismiss', relevantPermission: 'decisionMakers' },
  ],
  PendingApproval: [
    { action: 'dismiss', actionLabel: 'Request to Dismiss', relevantPermission: 'nonDecisionMakers' },
    { action: 'dismiss', actionLabel: 'Dismiss', relevantPermission: 'decisionMakers' },
  ],
  Incomplete: [],
  Rejected: [],
  ExpresslyWithdrawn: [],
  Cancelled: [],
  ApprovedNotAccepted: [],
  WithdrawalOfApprovedApplication: [],
  Disqualified: [],
  Closing: [
    { action: 'transition', target: 'Closed', actionLabel: 'Mark as Closed', relevantPermission: 'decisionMakers' },
    { action: 'dismiss', actionLabel: 'Request to Dismiss', relevantPermission: 'nonDecisionMakers' },
    { action: 'dismiss', actionLabel: 'Dismiss', relevantPermission: 'decisionMakers' },
  ],
  Closed: [],
  Deleted: [],
};

const Chip = styled(Flex)<{ bgcolor: string; borderColor: string }>`
  cursor: pointer;
  padding: 0px 12px;
  border-radius: 16px;
  background-color: ${({ bgcolor }) => bgcolor};
  border: 1px solid ${({ borderColor }) => borderColor};
`;

const menuProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'right' as const,
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'right' as const,
  },
  PaperProps: {
    elevation: 0,
    sx: {
      width: '300px',
      maxWidth: '100%',
      border: 0,
      borderWidth: '1px',
      borderColor: greyPalette[300],
      boxShadow: '0px 11px 15px 0px #DBDBDB26',
    },
  },
};

const MenuItemAction: FC<{ onClick: () => void; label: string; icon?: React.ReactNode; disabled?: boolean }> = ({
  onClick,
  label,
  icon,
  disabled,
}) => (
  <MenuItem onClick={onClick} sx={{ '&:hover': { bgcolor: greyPalette[100] } }} disabled={disabled}>
    <Flex alignItems={'center'} gap={2}>
      {icon}
      <Text variant={'body2'}>{label}</Text>
    </Flex>
  </MenuItem>
);

const OverrideStatusMenuItem: FC<{ onClick: () => void; disabled: boolean }> = ({ onClick, disabled }) => (
  <MenuItemAction
    onClick={onClick}
    label={'Status Override'}
    icon={<EditOutlinedIcon sx={{ color: greyPalette[700] }} />}
    disabled={disabled}
  />
);

const StatusTransitionMenuItem: FC<{
  onItemClick: () => void;
  transition: StatusTransition;
  onDismissalTransition: () => void;
  setMenuOpen: (open: boolean) => void;
}> = ({ onItemClick, transition, onDismissalTransition, setMenuOpen }) => {
  const {
    application: { id: applicationId },
    opportunity: { id: opportunityId },
  } = useContext(ApplicationContext);
  const partner = useUserPartner();
  const { mutateAsync: updateApplication, isPending: updatingApplication } = useUpdateApplicationMutation(applicationId, opportunityId);
  const { data: closingTasks, isPending: loadingClosingTasks } = useOpportunityClosingTasksQuery(opportunityId);
  const { confirm } = useConfirmModal();

  const onClick = useCallback(async () => {
    onItemClick();

    if (transition.action === 'dismiss') {
      onDismissalTransition();
      return;
    }

    let message = '';

    if (transition.target === 'Closed' && closingTasks?.some((t) => t.status !== 'Complete')) {
      message = 'Please note that some closing tasks are not yet complete.';
    }

    setMenuOpen(false);
    const confirmed = await confirm({
      title: `Are you sure you want to move this application to ${partner.applicationStatusDisplayNameMapping[transition.target]}?`,
      message,
    });

    if (!confirmed) {
      setMenuOpen(true);
      return;
    }

    await updateApplication({ updateApplicationPayload: { status: transition.target } });
  }, [
    partner.applicationStatusDisplayNameMapping,
    closingTasks,
    confirm,
    onDismissalTransition,
    onItemClick,
    setMenuOpen,
    transition,
    updateApplication,
  ]);

  const icon = useMemo(() => {
    const color = transition.action === 'transition' ? greenPalette[700] : redPalette[700];
    return transition.action === 'transition' ? <ArrowForwardIcon sx={{ color }} /> : <CloseIcon sx={{ color }} />;
  }, [transition.action]);

  return (
    <MenuItemAction onClick={onClick} label={transition.actionLabel} icon={icon} disabled={updatingApplication || loadingClosingTasks} />
  );
};

const isTransitionRelevantForCurrentUser = (
  transition: StatusTransition,
  isCurrentUserDecisionMaker: boolean,
  isCurrentUserRecommendationMaker: boolean,
) => {
  if (transition.relevantPermission === 'all') {
    return true;
  }
  if (!isCurrentUserDecisionMaker && !isCurrentUserRecommendationMaker) {
    return false;
  }

  return transition.relevantPermission === 'decisionMakers' ? isCurrentUserDecisionMaker : !isCurrentUserDecisionMaker;
};

export const ApplicationStatusWorkflowChip: FC = () => {
  const {
    application: { status },
    opportunity,
    product,
  } = useContext(ApplicationContext);
  const { showPermissionsDisclaimer } = useFlags();
  const { permissionsOverrides } = opportunity;
  const { userId, userData: user } = useContext(UserDetailsContext);
  const partner = useUserPartner();
  const isApplicationAutoDeclined = useMemo(
    () => status === 'Rejected' && isOpportunityAutoRejectedSelector(opportunity),
    [opportunity, status],
  );
  const {
    label,
    style: { bgcolor, borderColor, color },
  } = useMemo(
    () => statusToChip(status, partner.applicationStatusDisplayNameMapping, isApplicationAutoDeclined),
    [partner.applicationStatusDisplayNameMapping, isApplicationAutoDeclined, status],
  );
  const [open, setOpen] = useState(false);
  const [overrideSubMenuOpen, setOverrideSubMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const isCurrentUserDecisionMaker = useMemo(
    () => (user ? isUserDecisionMaker({ user, opportunity, product }) : false),
    [opportunity, product, user],
  );

  const isCurrentUserRecommendationMaker = useMemo(
    () => (user ? isUserRecommender({ user, opportunity, product }) : false),
    [opportunity, product, user],
  );
  const userHasNoPermissions = useMemo(
    () => !isCurrentUserDecisionMaker && !isCurrentUserRecommendationMaker,
    [isCurrentUserDecisionMaker, isCurrentUserRecommendationMaker],
  );
  const [dismissMode, setDismissMode] = useState<'decision' | 'recommendation' | null>(null);

  const handleCloseMenu = useCallback(() => {
    setOpen(false);
  }, []);

  const handleCloseSubMenu = useCallback(() => {
    setOverrideSubMenuOpen(false);
  }, []);

  const onClick = useCallback(() => {
    setOpen(true);
  }, []);

  const onOverrideStatusClicked = useCallback(() => {
    setOverrideSubMenuOpen(true);
  }, []);

  const onSubMenuBack = useCallback(() => {
    handleCloseSubMenu();
  }, [handleCloseSubMenu]);

  const onSubMenuClose = useCallback(() => {
    handleCloseSubMenu();
    handleCloseMenu();
  }, [handleCloseMenu, handleCloseSubMenu]);

  const onDismissApplicationModalDone = useCallback(() => {
    setDismissMode(null);
  }, []);

  const onDismissalTransition = useCallback(() => {
    if (isCurrentUserDecisionMaker) {
      setDismissMode('decision');
      return;
    }

    if (isCurrentUserRecommendationMaker) {
      setDismissMode('recommendation');
      return;
    }
  }, [isCurrentUserDecisionMaker, isCurrentUserRecommendationMaker]);

  const menuItemsForStatus = useMemo(() => {
    const transitions = workflowTransitions[status];
    return transitions
      .filter((t) => isTransitionRelevantForCurrentUser(t, isCurrentUserDecisionMaker, isCurrentUserRecommendationMaker))
      .map((t) => (
        <StatusTransitionMenuItem
          key={t.actionLabel}
          onItemClick={handleCloseMenu}
          transition={t}
          onDismissalTransition={onDismissalTransition}
          setMenuOpen={setOpen}
        />
      ));
  }, [status, isCurrentUserDecisionMaker, handleCloseMenu, onDismissalTransition, isCurrentUserRecommendationMaker]);

  const overrideStatusDisabled = useMemo(
    () => !isCurrentUserDecisionMaker && inactiveApplicationStatuses.includes(status),
    [isCurrentUserDecisionMaker, status],
  );
  const disclaimerText = useMemo(() => {
    if (isCurrentUserDecisionMaker || !showPermissionsDisclaimer) {
      return null;
    }
    if (userHasNoPermissions) {
      if (userId && permissionsOverrides?.[userId] === false) {
        return 'You are not permitted to make a decision, because you viewed restricted information.';
      }
      return 'You are not permitted to make a decision.';
    }
    if (isCurrentUserRecommendationMaker) {
      return 'You can make a suggestion, but an approval from an authorized user is required.';
    }
    return null;
  }, [
    isCurrentUserDecisionMaker,
    showPermissionsDisclaimer,
    userHasNoPermissions,
    isCurrentUserRecommendationMaker,
    userId,
    permissionsOverrides,
  ]);

  return (
    <>
      <Chip gap={2} alignItems={'center'} onClick={onClick} bgcolor={bgcolor} borderColor={borderColor} ref={menuRef}>
        <Text variant={'body3'} color={color} noWrap>
          {label}
        </Text>
        <DownwardArrow color={borderColor} />
      </Chip>
      <Menu {...menuProps} anchorEl={menuRef.current} open={open} onClose={handleCloseMenu}>
        {menuItemsForStatus}
        {menuItemsForStatus.length ? <Divider /> : null}
        <OverrideStatusMenuItem onClick={onOverrideStatusClicked} disabled={overrideStatusDisabled} />
        {disclaimerText ? (
          <MenuItem onClick={onClick} sx={{ '&:hover': { bgcolor: greyPalette[100] }, textWrap: 'auto' }} disabled>
            <Flex alignItems={'center'} gap={2}>
              <InfoOutlined
                sx={{
                  color: greyPalette[700],
                  height: '16px',
                  width: '16px',
                  alignSelf: 'flex-start',
                  marginTop: '3px',
                  marginLeft: '3px',
                }}
              />
              <Text variant={'body3'}>{disclaimerText}</Text>
            </Flex>
          </MenuItem>
        ) : null}
      </Menu>
      <SetStatusMenu
        open={overrideSubMenuOpen}
        menuProps={menuProps}
        onBack={onSubMenuBack}
        onClose={onSubMenuClose}
        menuRef={menuRef}
        isCurrentUserDecisionMaker={isCurrentUserDecisionMaker}
      />
      <DismissApplicationDialog open={!!dismissMode} onClose={onDismissApplicationModalDone} mode={dismissMode} />
    </>
  );
};
