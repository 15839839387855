import type { FC } from 'react';
import React, { useCallback } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { Search } from '@mui/icons-material';

const onKeyDown = (e: React.KeyboardEvent) => {
  if (e.key !== 'Escape') {
    e.stopPropagation();
  }
};

export const ActionMenuSearchInput: FC<{ onInputChange: (value: string) => void }> = ({ onInputChange }) => {
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onInputChange(e.target.value);
    },
    [onInputChange],
  );

  return (
    <TextField
      autoFocus
      placeholder={'Search'}
      variant={'standard'}
      sx={{ width: '100%', px: 2, pt: 1 }}
      onChange={onChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position={'start'}>
            <Search />
          </InputAdornment>
        ),
      }}
      onKeyDown={onKeyDown}
    />
  );
};
