import { loanDisplayNameSelector } from '@lama/data-formatters';
import { useCallback, useMemo } from 'react';
import writeXlsxFile from 'write-excel-file';
import type { ApplicationApiModel } from '@lama/clients';
import { v4 as uuidv4 } from 'uuid';
import { auditExportExcelSchema } from '../../../../ActionsMenu/auditExportExcelSchema';
import { useOpportunityAuditV2Mutation } from '../../../../../../shared/hooks/react-query/audit/getOpportunityAuditV2Mutation';
import { useUploadDocumentsMutation } from '../../../../../../shared/components/DocumentBox/hooks/useUploadDocumentsQuery';

export const AUDIT_LOG_DESCRIPTION = 'Audit Log';

export const useGenerateAuditReport = ({ opportunityId, application }: { opportunityId: string; application: ApplicationApiModel }) => {
  const { mutateAsync: getAudit, isPending: loadingAudit } = useOpportunityAuditV2Mutation();
  const { mutateAsync: uploadAuditLogDocument, isPending: isUploadingAuditLogDocument } = useUploadDocumentsMutation({
    applicationId: application.id,
    opportunityId,
  });

  const generateAuditReport = useCallback(async () => {
    const data = await getAudit({ opportunityId });

    const blob = await writeXlsxFile(data, {
      schema: auditExportExcelSchema,
    });

    const file = new File([blob], `${loanDisplayNameSelector(application)}_audit.xlsx`);

    await uploadAuditLogDocument([
      {
        exportConfiguration: { nautilusDocumentType: 'COMM-Application CIP' },
        applicationId: application.id,
        description: AUDIT_LOG_DESCRIPTION,
        entityId: application.id,
        entityType: 'application',
        file,
        topic: null,
        documentId: uuidv4(),
      },
    ]);
  }, [application, getAudit, opportunityId, uploadAuditLogDocument]);

  const loading = useMemo(() => loadingAudit || isUploadingAuditLogDocument, [loadingAudit, isUploadingAuditLogDocument]);

  return { generateAuditReport, loading };
};
