import type { CSSProperties, FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { ChevronRightOutlined } from '@mui/icons-material';
import { Flex, Text } from '@lama/design-system';
import { isSameDay } from 'date-fns';
import DatePicker from 'react-datepicker';
import { getUTCDateFromZonedTime, parseUTCDateToString } from '@lama/data-formatters';
import 'react-datepicker/dist/react-datepicker.css';
import { StyledDatePickerWrapper } from './StyledDatePicker';

export interface DateRangePickerProps {
  startDate?: Date;
  endDate?: Date;
  loading: boolean;
  onChange: (startDate: Date, endDate: Date) => void;
  style?: CSSProperties;
}

export const DateRangePicker: FC<DateRangePickerProps> = ({ startDate: initialStartDate, endDate: initialEndDate, loading, onChange }) => {
  const [startDate, setStartDate] = useState<Date | null>(initialStartDate ? getUTCDateFromZonedTime(initialStartDate) : null);
  const [endDate, setEndDate] = useState<Date | null>(initialEndDate ? getUTCDateFromZonedTime(initialEndDate) : null);

  const displayStartDate = useMemo(() => (startDate ? parseUTCDateToString(startDate) : ''), [startDate]);
  const displayEndDate = useMemo(() => (endDate ? parseUTCDateToString(endDate) : ''), [endDate]);

  const startDateDirty = useMemo(
    () => startDate && initialStartDate && !isSameDay(startDate, initialStartDate),
    [startDate, initialStartDate],
  );

  const endDateDirty = useMemo(() => endDate && initialEndDate && !isSameDay(endDate, initialEndDate), [endDate, initialEndDate]);

  const onChangeInner = useCallback(
    async (dates: [Date | null, Date | null]) => {
      const [timezoneStartDate, timezonedEndDate] = dates;

      if (!timezoneStartDate) {
        return;
      }

      const startDateChange = getUTCDateFromZonedTime(timezoneStartDate);
      const endDateChange = timezonedEndDate ? getUTCDateFromZonedTime(timezonedEndDate) : null;

      setStartDate(startDateChange);
      setEndDate(endDateChange);

      if (startDateChange && endDateChange) {
        onChange(startDateChange, endDateChange);
      }
    },
    [onChange],
  );

  return (
    <Flex flexDirection={'column'} gap={2} paddingTop={2}>
      <Flex flexDirection={'row'} gap={1} alignItems={'center'} p={1} justifyContent={'center'}>
        <Flex
          flexDirection={'column'}
          gap={1}
          alignItems={'center'}
          justifyContent={'center'}
          py={2}
          border={!startDateDirty && !endDateDirty ? '1px dashed #714EF5' : undefined}
          borderRadius={'4px'}
        >
          <Flex minWidth={'80px'} alignItems={'center'} justifyContent={'center'}>
            <Text variant={'body2'} color={'secondary'}>
              {startDate ? displayStartDate : 'N/A'}
            </Text>
          </Flex>
        </Flex>
        <ChevronRightOutlined color={'disabled'} />
        <Flex
          flexDirection={'column'}
          gap={1}
          alignItems={'center'}
          justifyContent={'center'}
          py={2}
          border={startDateDirty && !endDateDirty ? '1px dashed #714EF5' : undefined}
          borderRadius={'4px'}
        >
          <Flex minWidth={'80px'} alignItems={'center'} justifyContent={'center'}>
            <Text variant={'body2'} color={'secondary'}>
              {endDate ? displayEndDate : 'N/A'}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <StyledDatePickerWrapper>
        <DatePicker
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          disabled={loading}
          onChange={onChangeInner}
          dropdownMode={'select'}
          showMonthDropdown
          showYearDropdown
          selectsRange
          inline
        />
      </StyledDatePickerWrapper>
    </Flex>
  );
};
