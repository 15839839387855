import type { DataNode } from '@lama/design-system';
import { Flex, Text } from '@lama/design-system';
import type { FC } from 'react';
import React, { useContext } from 'react';
import { orderBy } from 'lodash-es';
import { ApplicationContext } from '../Application/ApplicationContext';
import { DocumentBox } from '../../shared/components/DocumentBox/DocumentBox';
import { UploadArbitraryDocumentButton } from '../../shared/components/DocumentBox/UploadArbitraryDocumentButton';
import { ArbitraryClosingTaskDocumentUpload } from '../Application/Closing/ClosingTasks/ArbitraryClosingTaskDocumentUpload';
import type { DocumentBoxDetails } from './types';

interface DocumentListSubGroupProps {
  subgroupName: string;
  subgroupDocumentBoxes: DocumentBoxDetails[];
  moveToTreeData: DataNode[];
}
export const DocumentListSubGroup: FC<DocumentListSubGroupProps> = React.memo(({ subgroupName, subgroupDocumentBoxes, moveToTreeData }) => {
  const {
    application: { id: applicationId },
    opportunity: { id: opportunityId },
  } = useContext(ApplicationContext);

  if (!subgroupDocumentBoxes.length) {
    return null;
  }

  const orderedDocumentBoxes = orderBy(subgroupDocumentBoxes, (box) => box.topic);

  return (
    <Flex flexDirection={'column'} gap={3} data-testid={`subgroup-${subgroupName}`}>
      {subgroupName && subgroupName !== 'Miscellaneous Documents' ? (
        <Text variant={'body2'} color={'secondary'} px={2}>
          {subgroupName}
        </Text>
      ) : null}
      <Flex flexDirection={'column'} gap={3}>
        {orderedDocumentBoxes.map((documentBoxDetails) => (
          <DocumentBox
            applicationId={applicationId}
            key={(documentBoxDetails.id || `${documentBoxDetails.name}-${documentBoxDetails.entityId}`) ?? ''}
            document={documentBoxDetails.document}
            description={documentBoxDetails.name}
            dismissed={documentBoxDetails.dismissalData}
            entityId={documentBoxDetails.entityId}
            entityType={documentBoxDetails.entityType}
            requirement={documentBoxDetails.requirement}
            topic={documentBoxDetails.topic}
            sharedRequirementId={documentBoxDetails.requirement?.referenceRequirementId}
            moveToTreeData={moveToTreeData}
            closingTask={documentBoxDetails.closingTask}
            extractable={documentBoxDetails.extractable}
          />
        ))}
        {subgroupDocumentBoxes[0]?.requirement ? (
          <UploadArbitraryDocumentButton
            applicationId={applicationId}
            entityId={subgroupDocumentBoxes[0]?.requirement.entityId}
            entityType={subgroupDocumentBoxes[0]?.requirement.entityType}
            requirementId={subgroupDocumentBoxes[0]?.requirement.id}
            sharedRequirementId={subgroupDocumentBoxes[0]?.requirement.referenceRequirementId}
            requirementKey={subgroupDocumentBoxes[0]?.requirement.key}
            extractable={subgroupDocumentBoxes[0]?.requirement.sources.uploadFilesSource?.some(({ extractable }) => extractable)}
          />
        ) : null}
        {subgroupDocumentBoxes[0]?.closingTask && !subgroupDocumentBoxes[0]?.closingTask.hideAdditionalDocumentsSection ? (
          <ArbitraryClosingTaskDocumentUpload
            opportunityId={opportunityId}
            applicationId={applicationId}
            taskId={subgroupDocumentBoxes[0].id}
            relatedEntityId={applicationId}
            relatedEntityType={'application'}
          />
        ) : null}
      </Flex>
    </Flex>
  );
});
