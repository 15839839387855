import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { Grid } from '@lama/design-system';
import type { SourcedProperty } from '@lama/properties';
import { getSourcedProperty } from '@lama/properties';
import type { InformationTypes } from '@lama/app-components';
import { GenericPropertiesContext, KeyValuePair, shouldShowConditionedProperty } from '@lama/app-components';
import type { LabeledValue } from '@lama/contracts';
import { isString } from 'lodash-es';
import { ApplicationContext } from '../../ApplicationContext';
import type { PropertyKeyWithFormConfig } from '../../../../shared/utils/getFormProperties';
import { getFormProperties } from '../../../../shared/utils/getFormProperties';
import { SegmentContainer } from '../../shared/SegmentContainer';

const getLabelByValueIfExists = (
  sourceToValues: Record<string, LabeledValue[] | string[] | readonly string[]>,
  source: string,
  value: any,
) => {
  const valuesMapping = sourceToValues[source];
  if (!valuesMapping) {
    return value;
  }
  // search only in labeled values, if sources is a list of strings we should show the given value
  if (valuesMapping.every(isString)) {
    return value;
  }
  const labeledValue = valuesMapping.find((v) => v.value === value);
  if (!labeledValue) {
    return value;
  }
  return labeledValue.label;
};

export const GenericDataGridSegment: FC<{
  entity: Record<string, any> & { id: string };
  propertyDefinitions: PropertyKeyWithFormConfig[];
  columns?: number;
  title?: string;
}> = ({ entity, propertyDefinitions, columns = 3, title }) => {
  const { application, opportunity, properties } = useContext(ApplicationContext);
  const genericPropertiesContext = useContext(GenericPropertiesContext);
  const propertiesWithDecidedSource = useMemo<SourcedProperty[]>(
    () =>
      getFormProperties(propertyDefinitions, properties)
        .map((p) => getSourcedProperty({ property: p, entity, application, referenceYear: opportunity.referenceYear }))
        .filter((p) => shouldShowConditionedProperty(p, entity, {}, application, opportunity))
        .map((p) => {
          if (p.selectedPropertyValue && p.valuesSource && genericPropertiesContext?.sourceToValues[p.valuesSource]) {
            return {
              ...p,
              selectedPropertyValue: {
                ...p.selectedPropertyValue,
                value: getLabelByValueIfExists(genericPropertiesContext?.sourceToValues, p.valuesSource, p.selectedPropertyValue.value),
              },
            };
          }
          return p;
        }),
    [opportunity, propertyDefinitions, properties, entity, application, genericPropertiesContext?.sourceToValues],
  );

  return (
    <SegmentContainer title={title}>
      <Grid columns={columns}>
        {propertiesWithDecidedSource.map(
          ({ propertyKey, displayName, displayNameOverride, selectedPropertyValue, type, displayType, restricted }) => (
            <KeyValuePair
              key={propertyKey}
              name={displayNameOverride ?? displayName}
              value={selectedPropertyValue?.value}
              type={(displayType as InformationTypes) ?? type}
              restricted={!!restricted}
            />
          ),
        )}
      </Grid>
    </SegmentContainer>
  );
};
