import React, { useMemo, useRef } from 'react';
import type { User } from '@lama/user-service-client';
import { grey } from '@mui/material/colors';
import type { FC } from 'react';
import { useToggle } from 'react-use';
import PersonAddIcon from '@mui/icons-material/PersonAddRounded';
import { Flex } from '@lama/design-system';
import { AddAssigneeIconButton } from './AddAssigneeIcon';
import { AssigneeGroup } from './AssigneeGroup';
import { AssignOpportunityOwnersMenu } from './AssignOpportunityOwnersMenu';

export const menuProps = {
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'right' as const,
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'right' as const,
  },
  PaperProps: {
    elevation: 0,
    sx: {
      width: '268px',
      maxWidth: '100%',
      border: 0,
      py: 0,
      mt: 1,
      borderWidth: '1px',
      borderColor: grey[300],
      boxShadow: '0px 11px 15px 0px #DBDBDB26',
    },
  },
};

interface ApplicationAssigneesProps {
  assignees: User[];
  users: User[];
  allowMultipleAssignees: boolean;
  onUpdateAssignees: (assigneeIds: string[]) => Promise<void>;
}

export const ApplicationAssignees: FC<ApplicationAssigneesProps> = ({ assignees, users, allowMultipleAssignees, onUpdateAssignees }) => {
  const [assignOwnerOpen, toggleAssignOwnerOpen] = useToggle(false);
  const menuRef = useRef(null);

  const assigneeIds = useMemo(() => assignees.map((assignee) => assignee.id), [assignees]);

  return (
    <>
      <Flex gap={2} alignItems={'center'} ref={menuRef}>
        <AssigneeGroup assignees={assignees} />
        <AddAssigneeIconButton menuRef={menuRef} onClick={toggleAssignOwnerOpen}>
          <PersonAddIcon sx={{ fontSize: '16px', color: grey[500] }} />
        </AddAssigneeIconButton>
      </Flex>
      <AssignOpportunityOwnersMenu
        allowMultipleAssignees={allowMultipleAssignees}
        open={assignOwnerOpen}
        menuProps={menuProps}
        menuRef={menuRef}
        users={users}
        assigneeIds={assigneeIds}
        onClose={toggleAssignOwnerOpen}
        onUpdateAssignees={onUpdateAssignees}
      />
    </>
  );
};
