import type { FC, ReactNode } from 'react';
import React, { createContext, useMemo, useState } from 'react';
import { endOfMonth, startOfMonth, sub } from 'date-fns';

interface AccountsFilterContextProps {
  startDate: Date;
  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  endDate: Date;
  setEndDate: React.Dispatch<React.SetStateAction<Date>>;
}

export const AccountsFilterContext = createContext<AccountsFilterContextProps>({} as AccountsFilterContextProps);

export const AccountsFilterProvider: FC<{ children: ReactNode; endDate?: Date }> = ({ children, endDate: initialEndDate }) => {
  const defaultEndDate = endOfMonth(sub(initialEndDate ?? new Date(), { months: 1 }));
  const defaultStartDate = startOfMonth(sub(defaultEndDate, { months: 5 }));

  const [startDate, setStartDate] = useState<Date>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date>(defaultEndDate);

  const value = useMemo(() => ({ startDate, endDate, setStartDate, setEndDate }), [startDate, endDate]);

  return <AccountsFilterContext.Provider value={value}>{children}</AccountsFilterContext.Provider>;
};
