import type { FC } from 'react';
import React from 'react';
import type { UnderwritingNote } from '@lama/contracts';
import type { User } from '@lama/user-service-client';

import { NoteCard } from './NoteCard';

interface FlagCardProps {
  flag: UnderwritingNote;
  user?: User;
  showSectionCrumbs?: boolean;
}

export const FlagCard: FC<FlagCardProps> = ({ flag, user, showSectionCrumbs }) => (
  <NoteCard
    note={flag}
    user={user}
    deleteEnabled={false}
    changeNoteTypeButtonsProps={[
      { changeNoteTypeCTA: 'Mark Exception', noteTypeTarget: 'exception' },
      {
        changeNoteTypeCTA: 'Clear',
        noteTypeTarget: 'cleared' as const,
        confirmMessage: 'Are you sure you want to clear this flag?',
      },
    ]}
    showSectionCrumbs={showSectionCrumbs}
  />
);
