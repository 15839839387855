import type { FC } from 'react';
import React, { useMemo } from 'react';
import type { AuditEntryApiModel } from '@lama/audit-service-client';
import { ErrorBoundary } from '@sentry/react';
import { ApplicationAuditLog } from './AuditEntities/Application/ApplicationAuditLog';
import { DocumentAuditLog } from './AuditEntities/Document/DocumentAudit';
import { PersonAuditLog } from './AuditEntities/Person/PersonAudit';
import { BusinessAuditLog } from './AuditEntities/Business/BusinessAuditLog';
import { OpportunityRequirementAuditLog } from './AuditEntities/OpportunityRequirement/OpportunityRequirementAudit';
import { FinancialsAuditLog } from './AuditEntities/Financials/FinancialsAuditLog';
import { CreditReportAuditLog } from './AuditEntities/CreditReport/CreditReportAuditLog';
import { EmailTrackingAuditLog } from './AuditEntities/EmailTracking/EmailTrackingAuditLog';
import { OpportunityAuditLog } from './AuditEntities/Opportunity/OpportunityAuditLog';
import type { AuditGroupInfo } from './types';

export interface EntityAuditLogProps {
  audit: AuditEntryApiModel[];
  groupInfo: AuditGroupInfo;
}

const auditComponentByEntityType: Record<string, FC<EntityAuditLogProps> | null> = {
  application: ApplicationAuditLog,
  business: BusinessAuditLog,
  document: DocumentAuditLog,
  requirement: OpportunityRequirementAuditLog,
  person: PersonAuditLog,
  financials: FinancialsAuditLog,
  opportunity: OpportunityAuditLog,
  creditReport: CreditReportAuditLog,
  emailTracking: EmailTrackingAuditLog,
};

export const GroupedAudit: FC<{ groupedAudits: AuditEntryApiModel[] }> = ({ groupedAudits }) => {
  const groupInfo: AuditGroupInfo | null = useMemo(() => {
    const firstAudit = groupedAudits.at(0);

    if (!firstAudit) {
      return null;
    }

    return {
      type: firstAudit.action,
      entityType: firstAudit.entityType,
      timestamp: firstAudit.timestamp,
      entityId: firstAudit.entityId,
      customText: firstAudit.customText,
      labels: firstAudit.labels,
      ...(firstAudit.originType === 'user'
        ? {
            user: firstAudit.user!,
            originType: 'user',
          }
        : {
            user: undefined,
            originType: 'system',
          }),
    };
  }, [groupedAudits]);

  const Component = useMemo(() => (groupInfo ? auditComponentByEntityType[groupInfo.entityType] : null), [groupInfo]);

  if (!Component || !groupInfo) {
    return null;
  }

  return (
    <ErrorBoundary>
      <Component audit={groupedAudits} groupInfo={groupInfo} />
    </ErrorBoundary>
  );
};
