import type { EvaluatedOpportunityRequirement } from '@lama/contracts';
import React, { useContext, useMemo } from 'react';
import { Flex, Text } from '@lama/design-system';
import { relatedBusinessesByIdsSelector } from '@lama/data-formatters';
import { ApplicationContext } from '../../../Application/ApplicationContext';
import { RequirementPrintContainer } from '../RequirementPrintContainer';
import { RelatedCompanySegment } from './Segments/RelatedCompanySegment';

export const RelatedCompaniesView = ({ requirement }: { requirement: EvaluatedOpportunityRequirement }) => {
  const { application } = useContext(ApplicationContext);

  const business = useMemo(
    () => application.relatedBusinesses.find((b) => b.business.id === requirement.entityId)?.business,
    [application.relatedBusinesses, requirement.entityId],
  );

  const relatedCompanies = useMemo(
    () =>
      business
        ? relatedBusinessesByIdsSelector(application, [...business.sisterCompaniesIds, ...business.subsidiariesIds]).map((b) => b.business)
        : [],
    [application, business],
  );

  if (!relatedCompanies.length) {
    return (
      <RequirementPrintContainer title={requirement.name}>
        <Text variant={'body2'}>{'No information found'}</Text>
      </RequirementPrintContainer>
    );
  }

  return (
    <RequirementPrintContainer title={requirement.name}>
      <Flex flexDirection={'column'} gap={4}>
        {relatedCompanies.map((relatedCompany) => (
          <RelatedCompanySegment key={relatedCompany.id} business={business} relatedCompany={relatedCompany} />
        ))}
      </Flex>
    </RequirementPrintContainer>
  );
};
