/* eslint-disable @typescript-eslint/naming-convention */
import type { MutableRefObject, FC } from 'react';
import React, { useCallback, useContext, useState } from 'react';
import { Menu, MenuItem, Stack } from '@mui/material';
import { Text } from '@lama/design-system';
import { useConfirmModal } from '@lama/app-components';
import { useSendIncompletenessLetterMutation } from '../../../shared/hooks/react-query/useSendIncompletenessLetterMutation';
import { ApplicationContext } from '../ApplicationContext';
import { SendEmailDialog } from '../../../shared/components/SendEmailDialog/SendEmailDialog';
import { useUpdateOpportunityMutation } from '../../../shared/hooks/react-query/opportunity/useUpdateOpportunityMutation';
import { useUpdateApplicationMutation } from '../../../shared/hooks/react-query/application/useUpdateApplication';
import { SubMenuHeader } from './SubMenuHeader';

interface CommunicationMenuProps {
  menuProps: any;
  onBack: () => void;
  open: boolean;
  menuRef: MutableRefObject<null>;
  onClose: () => void;
}

export const CommunicationMenu: FC<CommunicationMenuProps> = ({ menuProps, onBack, open, menuRef, onClose }) => {
  const {
    opportunity: { id: opportunityId },
    application: { id: applicationId },
  } = useContext(ApplicationContext);
  const { mutateAsync: send } = useSendIncompletenessLetterMutation(opportunityId);
  const { confirm } = useConfirmModal();
  const { mutate: updateOpportunity } = useUpdateOpportunityMutation(opportunityId);
  const { mutate: updateApplication } = useUpdateApplicationMutation(applicationId, opportunityId);
  const [emailDialogOpen, setEmailDialogOpen] = useState<boolean>(false);

  const onMarkPhysicalLOISent = useCallback(async () => {
    onClose();
    const confirmed = await confirm({
      title: 'Are you sure?',
      confirmText: 'Mark As Sent',
    });
    if (!confirmed) {
      return;
    }

    updateOpportunity({ physicalLetterOfIncompletenessSent: true });
    updateApplication({ updateApplicationPayload: { status: 'Incomplete' } });
  }, [confirm, onClose, updateOpportunity, updateApplication]);

  const openEmailDialog = useCallback(() => {
    onClose();
    setEmailDialogOpen(true);
  }, [onClose]);

  const closeEmailDialog = useCallback(() => {
    setEmailDialogOpen(false);
  }, []);

  const sendLetterOfIncompleteness = useCallback(
    async ({ preview }: { preview: boolean }) => {
      await send({ preview });
      if (!preview) {
        updateApplication({ updateApplicationPayload: { status: 'Incomplete' } });
      }
    },
    [send, updateApplication],
  );

  return (
    <>
      <Menu {...menuProps} anchorEl={menuRef.current} open={open} onClose={onClose}>
        <Stack gap={1}>
          <SubMenuHeader onClick={onBack} text={'Communication'} />
          <Stack pt={1} px={1} sx={{ maxHeight: '300px', overflow: 'auto' }}>
            <MenuItem onClick={openEmailDialog} sx={{ '&:hover': { bgcolor: 'primary.light' }, borderRadius: '4px', px: 2 }}>
              <Stack direction={'row'} gap={2} alignItems={'center'}>
                <Text variant={'body2'} ellipsis>
                  {'Send Letter of Incompleteness'}
                </Text>
              </Stack>
            </MenuItem>
            <MenuItem onClick={onMarkPhysicalLOISent} sx={{ '&:hover': { bgcolor: 'primary.light' }, borderRadius: '4px', px: 2 }}>
              <Stack direction={'row'} gap={2} alignItems={'center'}>
                <Text variant={'body2'} ellipsis>
                  {'Mark Physical Letter of Incompleteness Sent'}
                </Text>
              </Stack>
            </MenuItem>
          </Stack>
        </Stack>
      </Menu>
      {emailDialogOpen ? (
        <SendEmailDialog
          open={emailDialogOpen}
          onClose={closeEmailDialog}
          sendEmail={sendLetterOfIncompleteness}
          title={'Send Letter of Incompleteness'}
        />
      ) : null}
    </>
  );
};
