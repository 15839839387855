import styled from '@emotion/styled';

const labelToColor = {
  borrowerEngagement: '#71aeea',
  lamaBotChat: '#a488f5',
  dataSearch: '#fccc64',
  reminders: '#41d37e',
  compliance: '#f2b1b0',
  planned: '#e9e9e9',
};

// Taken from StyledStatusBadge in RequirementStatusBadge.tsx - make it generic and reusable
export const AuditLabelColorDot = styled.div<{ label: keyof typeof labelToColor; size: string }>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  background-color: ${({ label }) => labelToColor[label]};
  border-radius: 50%;
  display: inline-block;
`;
