import React, { useCallback, useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Button, Flex, Text } from '@lama/design-system';
import { useApplicationDocumentsQuery } from '../../../../../../shared/hooks/react-query/application/useApplicationDocumentsQuery';
import type { CellContent, CellHeaderContent } from '../../../../CreditMemo/Sections/creditMemoTable/CreditMemoTable';
import { CreditMemoTable } from '../../../../CreditMemo/Sections/creditMemoTable/CreditMemoTable';
import { ApplicationContext } from '../../../../ApplicationContext';
import { NautilusMappingPicker, underwritingOption } from './NautilusMappingPicker';
import { NOTES_LOG_DESCRIPTION, useGenerateNotesReport } from './useGenerateNotesReport';
import { AUDIT_LOG_DESCRIPTION, useGenerateAuditReport } from './useGenerateAuditReport';

const headers: CellHeaderContent[] = [
  { text: 'Document', width: '60%' },
  { text: 'Mapping', width: '40%' },
];

export const NautilusMappingTables: FC = () => {
  const { opportunity, application } = useContext(ApplicationContext);
  const { data: applicationDocuments, isPending: loadingDocuments } = useApplicationDocumentsQuery({
    applicationId: application.id,
    includeDeleted: false,
  });

  const { generateNotesReport, loading: isGeneratingNotesReport } = useGenerateNotesReport({ opportunityId: opportunity.id, application });
  const { generateAuditReport, loading: isGeneratingAuditReport } = useGenerateAuditReport({ opportunityId: opportunity.id, application });

  const auditLogDocument = useMemo(
    () => applicationDocuments?.find((doc) => doc.description === AUDIT_LOG_DESCRIPTION),
    [applicationDocuments],
  );
  const notesLogDocument = useMemo(
    () => applicationDocuments?.find((doc) => doc.description === NOTES_LOG_DESCRIPTION),
    [applicationDocuments],
  );

  const documentRows: CellContent[][] = useMemo(
    () =>
      applicationDocuments
        ?.filter((d) => d.id !== auditLogDocument?.id && d.id !== notesLogDocument?.id)
        .map((d) => [
          { value: d.description ? `${d.description} - ${d.filename}` : d.filename },
          {
            padding: 0,
            customComponent: (
              <NautilusMappingPicker
                applicationId={application.id}
                opportunityId={opportunity.id}
                documentId={d.id}
                nautilusMapping={d.exportConfiguration?.nautilusDocumentType ?? underwritingOption}
              />
            ),
          },
        ]) ?? [],
    [applicationDocuments, auditLogDocument?.id, notesLogDocument?.id, application.id, opportunity.id],
  );

  const onClickGenerateSystemReports = useCallback(async () => {
    if (!auditLogDocument) {
      await generateAuditReport();
    }

    if (!notesLogDocument) {
      await generateNotesReport();
    }
  }, [auditLogDocument, notesLogDocument, generateAuditReport, generateNotesReport]);

  const systemReportsRows = useMemo<CellContent[][]>(
    () => [
      [
        { value: AUDIT_LOG_DESCRIPTION },
        {
          padding: 0,
          customComponent: (
            <NautilusMappingPicker
              applicationId={application.id}
              opportunityId={opportunity.id}
              documentId={auditLogDocument?.id}
              nautilusMapping={auditLogDocument?.exportConfiguration?.nautilusDocumentType ?? underwritingOption}
              readonly={!auditLogDocument}
            />
          ),
        },
      ],
      [
        { value: NOTES_LOG_DESCRIPTION },
        {
          padding: 0,
          customComponent: (
            <NautilusMappingPicker
              applicationId={application.id}
              opportunityId={opportunity.id}
              documentId={notesLogDocument?.id}
              nautilusMapping={notesLogDocument?.exportConfiguration?.nautilusDocumentType ?? underwritingOption}
              readonly={!notesLogDocument}
            />
          ),
        },
      ],
    ],
    [application.id, auditLogDocument, notesLogDocument, opportunity.id],
  );

  const isGeneratingSystemReports = useMemo(
    () => isGeneratingAuditReport || isGeneratingNotesReport,
    [isGeneratingAuditReport, isGeneratingNotesReport],
  );

  if (loadingDocuments) {
    return null;
  }

  return (
    <Flex flexDirection={'column'} gap={8}>
      <Flex width={'100%'} flexDirection={'column'} gap={4}>
        <Text variant={'body1'}>{'Application Documents'}</Text>
        <CreditMemoTable headers={headers} rows={documentRows} />
      </Flex>
      <Flex width={'100%'} flexDirection={'column'} gap={4}>
        <Flex width={'100%'} justifyContent={'space-between'} pr={3} alignContent={'center'}>
          <Text variant={'body1'}>{'System Generated Reports'}</Text>
          <Button
            variant={'tertiary'}
            onClick={onClickGenerateSystemReports}
            loading={isGeneratingSystemReports}
            disabled={isGeneratingSystemReports || (!!auditLogDocument && !!notesLogDocument)}
          >
            {'Generate'}
          </Button>
        </Flex>
        <CreditMemoTable headers={headers} rows={systemReportsRows} />
      </Flex>
    </Flex>
  );
};
