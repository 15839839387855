import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import type { AuditComponentProps } from '../types';
import { AuditLog } from '../../../AuditLog';
import { ApplicationContext } from '../../../../../../components/Application/ApplicationContext';

export const OpportunityFlagClearedAuditLog: FC<AuditComponentProps> = ({ audit, groupInfo }) => {
  const { opportunity } = useContext(ApplicationContext);

  const auditNotesChanges = useMemo(
    () =>
      audit.map((a) => ({
        noteUpdate: a.changes?.underwriting?.notes?.[0],
        note: opportunity.underwriting?.notes?.find(({ id }) => a.changes?.underwriting.notes[0].id === id),
        timestamp: a.timestamp,
      })),
    [audit, opportunity.underwriting?.notes],
  );

  return (
    <>
      {auditNotesChanges.map(({ note, noteUpdate, timestamp }) =>
        (note?.text || note?.title) && noteUpdate?.clearReason ? (
          <AuditLog
            key={note.id}
            {...(groupInfo.originType === 'user'
              ? { originType: groupInfo.originType, user: groupInfo.user }
              : { originType: groupInfo.originType })}
            text={`Cleared a flag for ${note?.text || note?.title}. Comments: "${noteUpdate.clearReason}".`}
            timestamp={timestamp}
            customText={groupInfo.customText}
            labels={groupInfo.labels}
          />
        ) : null,
      )}
    </>
  );
};
