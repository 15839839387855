import React, { useCallback, useContext, useState } from 'react';
import type { FC } from 'react';
import type { DocumentLineItem } from '@lama/document-service-client';
import { Button, Flex } from '@lama/design-system';
import type { MRT_Column } from 'material-react-table';
import { useConfirmModal } from '@lama/app-components';
import { useUpdateDocumentLineItemsMutation } from '../../../../../shared/hooks/react-query/document/lineItems/useUpdateDocumentLineItemsMutation';
import { SpreadingDocumentContext } from '../../../SpreadingDocumentContext';
import type { ColumnMeta } from '../utils/createTableColumns';
import { removeFinancialsFromLineItemInDateRange } from '../utils/getUpdatedLineItemValues';
import type { YearColumnDateRangeSelectProps } from './YearColumnDateRangeSelect';
import { YearColumnDateRangeSelect } from './YearColumnDateRangeSelect';

const useDeletePeriodColumnClickHandler = (
  numberOfPeriods: number,
  column: MRT_Column<DocumentLineItem>,
  lineItems: DocumentLineItem[],
) => {
  const { currentDocument } = useContext(SpreadingDocumentContext);
  const { confirm } = useConfirmModal();
  const { mutateAsync: updateDocumentLineItems, isPending: savingLineItems } = useUpdateDocumentLineItemsMutation();
  const updateLineItemDates = useCallback(async () => {
    const confirmed = await confirm({
      title: 'Delete Period',
      message: 'Are you sure you want to delete this period? This action cannot be undone.',
    });

    if (!confirmed) {
      return;
    }
    const { startDate: columnStartDate, endDate: columnEndDate } = column.columnDef.meta as ColumnMeta;

    if (!columnStartDate || !columnEndDate) {
      return;
    }

    const lineItemsUpdates = lineItems.map((lineItem) => ({
      id: lineItem.id,
      values: removeFinancialsFromLineItemInDateRange(lineItem, columnStartDate.toISOString(), columnEndDate.toISOString()),
    }));

    await updateDocumentLineItems({
      documentId: currentDocument.id,
      lineItemUpdateBody: {
        lineItemUpdates: lineItemsUpdates,
      },
    });
  }, [confirm, column.columnDef.meta, lineItems, updateDocumentLineItems, currentDocument.id]);
  return { updateLineItemDates, savingLineItems, deleteEnabled: numberOfPeriods > 1 };
};

export const YearSelectionMenuItem: FC<YearColumnDateRangeSelectProps> = (props: YearColumnDateRangeSelectProps) => {
  const [dateRangePickerOpen, setDateRangePickerOpen] = useState(false);
  const { column, lineItems, numberOfPeriods, closeMenu, onDeletePeriod } = props;
  const { updateLineItemDates, savingLineItems, deleteEnabled } = useDeletePeriodColumnClickHandler(numberOfPeriods, column, lineItems);

  const onChangePeriodClicked = useCallback(() => {
    setDateRangePickerOpen(true);
  }, []);

  const onDeletePeriodClicked = useCallback(async () => {
    closeMenu();
    await updateLineItemDates();

    const { startDate, endDate } = column.columnDef.meta as ColumnMeta;
    if (startDate && endDate) {
      onDeletePeriod?.({ startDate, endDate });
    }
  }, [closeMenu, column.columnDef.meta, onDeletePeriod, updateLineItemDates]);

  if (dateRangePickerOpen) {
    return <YearColumnDateRangeSelect {...props} />;
  }

  return (
    <Flex flexDirection={'column'} justifyContent={'center'} gap={2}>
      <Button variant={'primary'} color={'primary'} onClick={onChangePeriodClicked} mx={2} disabled={savingLineItems}>
        {'Change Period'}
      </Button>
      <Button
        aria-label={`Delete ${column.columnDef.header} column`}
        key={column.id}
        variant={'primary'}
        onClick={onDeletePeriodClicked}
        color={'danger'}
        mx={2}
        disabled={!deleteEnabled}
      >
        {'Delete Period'}
      </Button>
    </Flex>
  );
};
