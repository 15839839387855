/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Button, Flex } from '@lama/design-system';
import { useToggle } from 'react-use';
import { v4 as uuidv4 } from 'uuid';
import { useConfirmModal } from '@lama/app-components';
import { isNil } from 'lodash-es';
import { ApplicationContext } from '../../../../../ApplicationContext';
import { useSubmitFinancialsMutation } from '../../../../../OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/hooks/useSubmitFinancialsMutation';
import type { FinancialPayload } from '../../../../../OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/types';
import { UserDetailsContext } from '../../../../../../../shared/context/UserDetailsContext';
import { useCreateNoteMutation } from '../../../../../../../shared/hooks/react-query/opportunity/useCreateNoteMutation';
import { useUpdateNoteMutation } from '../../../../../../../shared/hooks/react-query/opportunity/useUpdateNoteMutation';
import { useAdjustmentNote } from '../../../hooks/useAdjustmentNote';
import { FinancialChildDialog } from './FinancialChildDialog';
import type { SpreadTooltipFooterProps } from './SpreadTooltipFooter';

type FinancialChildFooterActionsProps = Pick<
  SpreadTooltipFooterProps,
  | 'allowAddingChildren'
  | 'childrenNames'
  | 'currentValue'
  | 'endDate'
  | 'entityId'
  | 'hideTooltipCallback'
  | 'opportunityId'
  | 'parentPropertyKey'
  | 'propertyKey'
  | 'rowDisplayName'
  | 'startDate'
>;

const ChildFooterActions: FC<FinancialChildFooterActionsProps> = ({
  entityId,
  opportunityId,
  currentValue,
  hideTooltipCallback,
  parentPropertyKey,
  rowDisplayName,
  childrenNames,
}) => {
  const { opportunity, properties } = useContext(ApplicationContext);
  const parentProperty = useMemo(() => properties[parentPropertyKey ?? ''] ?? null, [parentPropertyKey, properties]);

  const { confirm } = useConfirmModal();

  const [childFinancialModalOpen, toggleChildFinancialModalOpen] = useToggle(false);

  const { isPending: savingFinancials, mutateAsync: updateFinancialData } = useSubmitFinancialsMutation(
    entityId,
    parentProperty?.entityType,
    opportunityId,
  );
  const currentAdjustmentNote = useAdjustmentNote(opportunity, currentValue?.financialId);
  const { mutateAsync: createNote } = useCreateNoteMutation(opportunityId, entityId);
  const { mutateAsync: updateNote } = useUpdateNoteMutation(opportunityId, entityId);

  const updateChildFinancial = useCallback(
    async (childFinancial?: { value: number; displayName: string; note: string }) => {
      if (!childFinancial || !currentValue?.financialId) {
        return;
      }

      await updateFinancialData([
        {
          action: 'update',
          id: currentValue.financialId,
          value: childFinancial.value,
        },
      ]);

      await (currentAdjustmentNote?.id
        ? updateNote({
            noteId: currentAdjustmentNote?.id,
            notePayload: {
              text: childFinancial.note,
            },
          })
        : createNote({
            id: uuidv4(),
            type: 'adjustment',
            text: childFinancial.note,
            relatedFinancialAdjustmentId: currentValue.financialId,
          }));
    },
    [currentValue?.financialId, updateFinancialData, currentAdjustmentNote?.id, updateNote, createNote],
  );

  const onDialogClose = useCallback(
    async (childFinancial?: { value: number; displayName: string; note: string }) => {
      await updateChildFinancial(childFinancial);

      toggleChildFinancialModalOpen();
      hideTooltipCallback?.();
    },
    [updateChildFinancial, toggleChildFinancialModalOpen, hideTooltipCallback],
  );

  const onDelete = useCallback(async () => {
    if (!currentValue?.financialId) {
      return;
    }

    hideTooltipCallback?.();

    const result = await confirm({
      title: 'Delete Item',
      message: 'Are you sure you want to delete this item?',
    });

    if (!result) {
      return;
    }

    const payload: FinancialPayload = {
      action: 'delete',
      id: currentValue.financialId,
    };
    await updateFinancialData([payload]);
  }, [confirm, currentValue?.financialId, hideTooltipCallback, updateFinancialData]);

  const initialValue = useMemo(
    () => ({ value: currentValue?.financialAttribute?.value ?? undefined, displayName: rowDisplayName, note: currentAdjustmentNote?.text }),
    [currentAdjustmentNote, currentValue?.financialAttribute?.value, rowDisplayName],
  );

  if (!currentValue?.financialId || !parentProperty) {
    return null;
  }

  return (
    <>
      <Flex gap={2} flex={1} justifyContent={'flex-end'}>
        {isNil(currentValue?.financialAttribute?.value) ? null : (
          <Button variant={'tertiary'} color={'danger'} size={'m'} onClick={onDelete}>
            {'Delete'}
          </Button>
        )}
        <Button variant={'tertiary'} size={'m'} onClick={toggleChildFinancialModalOpen}>
          {'Edit'}
        </Button>
      </Flex>
      {childFinancialModalOpen ? (
        <FinancialChildDialog
          open={childFinancialModalOpen}
          onClose={onDialogClose}
          isLoading={savingFinancials}
          initialValue={initialValue}
          existingRowNames={childrenNames}
        />
      ) : null}
    </>
  );
};

const ParentFooterActions: FC<FinancialChildFooterActionsProps> = ({
  propertyKey,
  startDate,
  endDate,
  entityId,
  opportunityId,
  allowAddingChildren,
  hideTooltipCallback,
  childrenNames,
}) => {
  const { userId } = useContext(UserDetailsContext);
  const { properties } = useContext(ApplicationContext);
  const property = useMemo(() => properties[propertyKey ?? ''] ?? null, [properties, propertyKey]);

  const [childFinancialModalOpen, toggleChildFinancialModalOpen] = useToggle(false);

  const { isPending: savingFinancials, mutateAsync: updateFinancialData } = useSubmitFinancialsMutation(
    entityId,
    property?.entityType,
    opportunityId,
  );
  const { mutateAsync: createNote } = useCreateNoteMutation(opportunityId, entityId);

  const addChildFinancial = useCallback(
    async (childFinancial?: { value: number; displayName: string; note: string }) => {
      if (!childFinancial || !userId || !property?.financialAttribute) {
        return;
      }

      const createFinancialId = uuidv4();

      await updateFinancialData([
        {
          action: 'create',
          id: createFinancialId,
          type: '',
          entityType: property.entityType,
          entityId,
          startDate,
          endDate,
          source: {
            type: 'Manual',
            user: {
              type: 'Lender',
              userId,
            },
          },
          value: childFinancial.value,
          displayName: childFinancial.displayName,
          parentFinancialAttribute: property.financialAttribute,
        },
      ]);

      if (childFinancial.note) {
        await createNote({
          id: uuidv4(),
          type: 'adjustment',
          text: childFinancial.note,
          relatedFinancialAdjustmentId: createFinancialId,
        });
      }
    },
    [userId, property?.financialAttribute, property?.entityType, updateFinancialData, entityId, startDate, endDate, createNote],
  );

  const onDialogClose = useCallback(
    async (childFinancial?: { value: number; displayName: string; note: string }) => {
      await addChildFinancial(childFinancial);

      toggleChildFinancialModalOpen();
      hideTooltipCallback?.();
    },
    [addChildFinancial, toggleChildFinancialModalOpen, hideTooltipCallback],
  );

  if (!allowAddingChildren) {
    return null;
  }

  return (
    <>
      <Flex justifyContent={'flex-end'}>
        <Button variant={'tertiary'} size={'m'} onClick={toggleChildFinancialModalOpen}>
          {'Add Item'}
        </Button>
      </Flex>
      {childFinancialModalOpen ? (
        <FinancialChildDialog
          open={childFinancialModalOpen}
          onClose={onDialogClose}
          isLoading={savingFinancials}
          existingRowNames={childrenNames}
        />
      ) : null}
    </>
  );
};

export const FinancialChildFooterActions: FC<FinancialChildFooterActionsProps> = (props) =>
  props.parentPropertyKey ? <ChildFooterActions {...props} /> : <ParentFooterActions {...props} />;
