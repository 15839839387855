import type { FC } from 'react';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useAsyncFn } from 'react-use';
import { EditorContent } from '@tiptap/react';
import { compact, uniq } from 'lodash-es';
import type { Editor, EditorEvents, JSONContent } from '@tiptap/react';
import type { SuggestionProps } from '@tiptap/suggestion';
import styled, { useTheme } from 'styled-components';
import { Flex } from '../Flex';
import { Button } from '../Button';
import { Spinner } from '../Spinner/Spinner.component';
import { greyPalette } from '../../theme';
import { Text } from '../Text';
import { MenuBar } from './MenuBar';
import { MentionSuggestions } from './MentionSuggestions/MentionSuggestions';
import type { InputValue, RichInputProps } from './types';
import { SendIcon } from './assets/SendIcon';
import { StyledEditorContainer } from './RichInput.styles';
import { useRichEditor } from './useRichEditor';

interface EditModeTextProps
  extends Pick<
    RichInputProps,
    | 'allowEmptyValue'
    | 'currentUserId'
    | 'enableMentions'
    | 'hasUnsavedChanged'
    | 'minHeight'
    | 'onCancel'
    | 'onChange'
    | 'onSubmit'
    | 'padding'
    | 'suggestions'
    | 'value'
  > {
  toggleEditMode: () => void;
  onImageSelected?: (fileContents: File) => Promise<{ src: string }>;
}

const StyledSendButton = styled(Flex)`
  &:focus {
    outline-color: black;
  }
`;

const extractUniqMentionIds = (doc?: JSONContent): string[] =>
  // recursivly look at the paragraphs and extract the mention ids
  compact(
    uniq(
      doc?.content?.map((node) => {
        if (node.type === 'mention') {
          return node.attrs?.id;
        }
        if (node.content) {
          return extractUniqMentionIds(node);
        }
        return null;
      }),
    ),
  ).flat() as string[];

const formatHtmlValue = (value: string) => (value === '<p></p>' ? '' : value);

const getEditorFormattedValue = (editor: Editor) => formatHtmlValue(editor.getHTML());

export const EditMode: FC<EditModeTextProps> = ({
  onSubmit,
  value,
  enableMentions,
  toggleEditMode,
  suggestions = [],
  currentUserId,
  onCancel,
  onImageSelected,
  allowEmptyValue = false,
  onChange,
  hasUnsavedChanged,
}) => {
  const theme = useTheme();
  const [isEmpty, setIsEmpty] = useState(!value || !formatHtmlValue(value));

  const [suggestionProps, setSuggestionProps] = useState<SuggestionProps | null>(null);
  const suggestionsListRef = useRef<any>(null);

  const [{ loading: submitting }, internalOnSubmit] = useAsyncFn(
    async (submitEditor: Editor) => {
      const mentions = extractUniqMentionIds(submitEditor?.getJSON());

      const formattedValue = getEditorFormattedValue(submitEditor);
      if (!formattedValue && !allowEmptyValue) {
        return;
      }

      const returnValue: InputValue = {
        value: formattedValue,
        mentionedIds: mentions,
      };

      await onSubmit?.(returnValue);
      toggleEditMode();
    },
    [onSubmit, toggleEditMode],
  );

  const onEditorContentChange = useCallback(
    ({ editor }: EditorEvents['update']) => {
      const formattedValue = getEditorFormattedValue(editor);
      setIsEmpty(!formattedValue);
      onChange?.(formattedValue);
    },
    [onChange],
  );

  const editor = useRichEditor({
    enableMentions,
    currentUserId,
    suggestions,
    suggestionsListRef,
    setSuggestionProps,
    content: value,
    autofocus: true,
    submit: internalOnSubmit,
    onUpdate: onEditorContentChange,
    parseOptions: {
      preserveWhitespace: 'full',
    },
  });

  const onCancelInternal = useCallback(() => {
    toggleEditMode();
    onCancel?.();
  }, [onCancel, toggleEditMode]);

  const onSubmitClick = useCallback(() => {
    if (editor) {
      void internalOnSubmit(editor);
    }
  }, [editor, internalOnSubmit]);

  const submitDisabled = useMemo(() => !allowEmptyValue && isEmpty, [allowEmptyValue, isEmpty]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        onSubmitClick();
      }
    },
    [onSubmitClick],
  );

  if (!editor) {
    return null;
  }

  return (
    <StyledEditorContainer flexDirection={'column'} gap={2} width={'100%'} px={2}>
      <EditorContent role={'textbox'} editor={editor} />
      <Flex justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
        <MenuBar onImageSelected={onImageSelected} editor={editor} />
        <Flex alignItems={'center'} gap={1}>
          {hasUnsavedChanged ? (
            <Text variant={'body4'} color={greyPalette[500]}>
              {'Unsaved changes'}
            </Text>
          ) : null}
          <Button tabIndex={-1} type={'button'} variant={'tertiary'} color={'neutral'} onClick={onCancelInternal} size={'s'}>
            {'Cancel'}
          </Button>
          <StyledSendButton
            height={'26px'}
            width={'26px'}
            alignItems={'center'}
            justifyContent={'center'}
            p={1}
            bg={submitDisabled ? greyPalette[300] : theme.colors.primary.main}
            borderRadius={'8px'}
            onClick={submitDisabled ? undefined : onSubmitClick}
            onKeyDown={submitDisabled ? undefined : onKeyDown}
            style={{ cursor: submitDisabled ? 'default' : 'pointer' }}
            aria-label={'submit-comment'}
            tabIndex={0}
          >
            {submitting ? <Spinner size={'18px'} color={'white'} /> : <SendIcon />}
          </StyledSendButton>
        </Flex>
      </Flex>
      {suggestionProps ? <MentionSuggestions {...suggestionProps} ref={suggestionsListRef} /> : null}
    </StyledEditorContainer>
  );
};

export default EditMode;
