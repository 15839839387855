/* eslint-disable @typescript-eslint/naming-convention */
import type { MutableRefObject, FC } from 'react';
import React, { useCallback, useContext, useState, useMemo } from 'react';
import { Checkbox, Menu, MenuItem } from '@mui/material';
import { Avatar, useConfirmModal } from '@lama/app-components';
import { useAsyncFn } from 'react-use';
import { Flex, Spinner, Text } from '@lama/design-system';
import { grey } from '@mui/material/colors';
import { personFullName } from '@lama/data-formatters';
import { sentenceCase } from 'change-case-all';
import type { User } from '@lama/user-service-client';
import { UserDetailsContext } from '../../../shared/context/UserDetailsContext';
import { SubMenuHeader } from '../ActionsMenu/SubMenuHeader';
import { ActionMenuSearchInput } from '../ActionsMenu/ActionMenuSearchInput';

interface AssignOpportunityOwnersMenuProps {
  menuProps: any;
  open: boolean;
  menuRef: MutableRefObject<null>;
  allowMultipleAssignees: boolean;
  assigneeIds: string[];
  users: User[];
  onClose: () => void;
  onUpdateAssignees: (assigneeIds: string[]) => Promise<void>;
}

export const AssignOpportunityOwnersMenu: FC<AssignOpportunityOwnersMenuProps> = ({
  menuProps,
  open,
  menuRef,
  allowMultipleAssignees,
  assigneeIds,
  users,
  onClose,
  onUpdateAssignees,
}) => {
  const { confirm } = useConfirmModal();
  const [filterOptions, setFilterOptions] = useState('');
  const { email } = useContext(UserDetailsContext);
  const [modfiedAssigneeId, setModfiedAssigneeId] = useState<string | null>(null);

  const [{ loading }, updateAssignees] = useAsyncFn(
    async (event: React.MouseEvent<HTMLElement>) => {
      const userId = event.currentTarget.getAttribute('value');

      if (!userId) {
        return;
      }

      setModfiedAssigneeId(userId);

      if (assigneeIds?.includes(userId)) {
        const confirmed =
          allowMultipleAssignees ||
          (await confirm({
            title: `Are you sure you want to unassign ${users?.find((user) => user.id === userId)?.firstName}?`,
          }));
        if (confirmed) {
          await onUpdateAssignees(assigneeIds.filter((id) => id !== userId));
          if (!allowMultipleAssignees) {
            onClose();
          }
        }
      } else {
        await onUpdateAssignees([...(allowMultipleAssignees ? assigneeIds ?? [] : []), userId]);
        if (!allowMultipleAssignees) {
          onClose();
        }
      }

      setModfiedAssigneeId(null);
    },
    [assigneeIds, allowMultipleAssignees, confirm, onClose, onUpdateAssignees, users],
  );

  const handleClose = useCallback(() => {
    setFilterOptions('');
    onClose();
  }, [onClose]);

  const onFilterChange = useCallback((value: string) => {
    setFilterOptions(value);
  }, []);

  const userOptions = useMemo(() => {
    const filteredUsers = users?.filter((user) =>
      `${user.firstName.toLocaleLowerCase()} ${user.lastName.toLocaleLowerCase()}`.includes(filterOptions.toLocaleLowerCase()),
    );

    if (email?.includes('@lama.ai')) {
      return filteredUsers?.map((user) => ({ user, id: user.id })) ?? [];
    }
    return filteredUsers?.filter((user) => !user.email.includes('@lama.ai')).map((user) => ({ user, id: user.id })) ?? [];
  }, [email, filterOptions, users]);

  return (
    <Menu {...menuProps} transitionDuration={0} anchorEl={menuRef.current} open={open} onClose={handleClose}>
      <Flex flexDirection={'column'} gap={2}>
        <SubMenuHeader text={'Assign Owner'} />
        <ActionMenuSearchInput onInputChange={onFilterChange} />
        <Flex flexDirection={'column'} gap={2} pt={2} px={2} maxHeight={'300px'} overflow={'auto'}>
          {userOptions.map(({ id, user }) => (
            <MenuItem
              key={id}
              value={id}
              onClick={updateAssignees}
              sx={{
                backgroundColor: assigneeIds?.includes(id) ? 'primary.light' : '',
                '&:hover': { bgcolor: grey[100] },
                borderRadius: '4px',
                px: allowMultipleAssignees ? 0 : 2,
              }}
            >
              <Flex flex={1} flexDirection={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                <Flex maxWidth={'90%'} flexDirection={'row'} gap={2} alignItems={'center'}>
                  {allowMultipleAssignees ? (
                    <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} alignSelf={'flex-start'}>
                      <Checkbox
                        sx={{ '&.Mui-disabled': { color: assigneeIds?.includes(user.id) ? '#714EF5' : '#00000099' } }}
                        color={'primary'}
                        checked={assigneeIds?.includes(user.id)}
                        disabled
                      />
                      <Avatar firstName={user.firstName} lastName={user.lastName} />
                    </Flex>
                  ) : (
                    <Avatar firstName={user.firstName} lastName={user.lastName} />
                  )}
                  <Flex maxWidth={allowMultipleAssignees ? '70%' : '100%'} flexDirection={'column'} justifyContent={'center'}>
                    <Text variant={'body2'} ellipsis>
                      {personFullName(user)}
                    </Text>
                    {allowMultipleAssignees ? (
                      <Text color={'#9E9E9E'} variant={'body3'} ellipsis>
                        {user.roles?.[0] ? sentenceCase(user.roles?.[0]) : ''}
                      </Text>
                    ) : null}
                  </Flex>
                </Flex>
                <Flex flex={0.2} flexDirection={'row'} alignItems={'center'} justifyContent={'flex-end'}>
                  {modfiedAssigneeId === id && loading ? <Spinner size={'s'} /> : null}
                </Flex>
              </Flex>
            </MenuItem>
          ))}
        </Flex>
      </Flex>
    </Menu>
  );
};
