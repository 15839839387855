import type {
  FinancialData,
  FinancialDataSource,
  TopicAnalysisParams,
  DocumentDataAttribute,
  GetAttributesResponse,
} from '@lama/contracts';
import { getYear } from '@lama/data-formatters';
import type { DocumentPage } from '@lama/document-service-client';
import { max } from 'lodash-es';
import type { Period } from '@lama/selectors';
import { getFinancialFieldByPeriod } from '../../../Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/financialFieldUtils';
import type { AttributeWithFinancialData } from '../FinancialStatements/utils/financialAttribute';

export const isFinancialRelatedToFormPage = (source: FinancialDataSource, curerntDocumentId: string, formDocumentPageNumbers: number[]) => {
  const financialDocument = source.type === 'Document' ? source.documentId : source.type === 'Manual' ? source.relatedDocumentId : null;
  const financialPage = source.type === 'Document' ? source.page : source.type === 'Manual' ? source.relatedDocumentPage : null;

  if (financialDocument !== curerntDocumentId) {
    return false;
  }
  if (!financialPage) {
    return false;
  }
  if (!formDocumentPageNumbers.includes(financialPage)) {
    return false;
  }

  return true;
};

export type FormAttributesWithFinanacialData = AttributeWithFinancialData & {
  attribute: { formTypeId?: string; formStartPage?: number; formEndPage?: number };
};

export const getAttributePeriod = (attribute: Omit<DocumentDataAttribute, 'extractionData'>, period?: Period) => {
  if (!period) {
    return { startDate: attribute.financialData!.startDate, endDate: attribute.financialData!.endDate };
  }

  if (attribute.financialData?.isPreviousYear) {
    const periodStartYear = getYear(period.startDate);
    const previousYearStartDate = period.startDate.replace(periodStartYear.toString(), (periodStartYear - 1).toString());

    const periodEndYear = getYear(period.endDate);
    const previousYearEndDate = period.endDate.replace(periodEndYear.toString(), (periodEndYear - 1).toString());
    return { startDate: previousYearStartDate, endDate: previousYearEndDate };
  }

  return period;
};

export const getFormAttributesWithFinancialData = (
  formAttributes: GetAttributesResponse['attributes'],
  formFinancials: FinancialData[],
  formDocumentPages: DocumentPage[],
  currentFormType?: TopicAnalysisParams,
  period?: Period,
): FormAttributesWithFinanacialData[] =>
  formAttributes
    .filter((attribute) => attribute.financialData)
    .map(({ formId, ...attribute }) => {
      const { type } = attribute.financialData!;
      const attributePage = Number(attribute.page);
      const attributePeriod = getAttributePeriod(attribute, period);
      const year = getYear(attributePeriod.startDate);
      const formLastPage = max(formDocumentPages.map(({ page }) => page));

      const financialPage = Number.isNaN(attributePage)
        ? formLastPage
        : formDocumentPages.find(({ formPage }) => attributePage === formPage)?.page;

      return {
        id: `${type}_${year}_${financialPage}`,
        financialPage,
        attribute: {
          ...attribute,
          formId: currentFormType?.displayName ?? formId,
          formTypeId: currentFormType?.id ?? formId,
        },
        financialData: getFinancialFieldByPeriod(formFinancials ?? [], attributePeriod, type),
      };
    });
