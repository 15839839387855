import { useIsMobile, ConfirmLeave, FormikMoneyInputField, ButtonWithSaveIndication } from '@lama/app-components';
import type { FC } from 'react';
import React, { useMemo, useContext, useCallback } from 'react';
import type { FormikHelpers } from 'formik';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import type { FinancialData } from '@lama/contracts';
import { getApplicationEntityByIdSelector } from '@lama/data-formatters';
import { Flex } from '@lama/design-system';
import { ApplicationContext } from '../../../../ApplicationContext';
import { UserDetailsContext } from '../../../../../../shared/context/UserDetailsContext';
import type { RequirementScreenProps } from '../types';
import { getFinancialPayload, getFinancialFieldByYear } from './financialFieldUtils';
import { useSubmitFinancialsMutation } from './hooks/useSubmitFinancialsMutation';

const financialAttribute = 'Property EBIDA';

interface FormValues {
  propertyEBIDA: string;
}

const validationSchema = yup.object({
  propertyEBIDA: yup.number().min(0),
});

export const PropertyStatedFinancialsScreen: FC<RequirementScreenProps> = ({ requirement }) => {
  const {
    application,
    opportunity: { referenceYear, id: opportunityId },
  } = useContext(ApplicationContext);
  const { userId } = useContext(UserDetailsContext);
  const isMobile = useIsMobile();
  const {
    isPending: savingFinancials,
    mutateAsync: updateFinancialData,
    isSuccess: financialsSuccessfullyUpdated,
  } = useSubmitFinancialsMutation(requirement.entityId, requirement.entityType, opportunityId);

  const entityFinancials = useMemo<FinancialData[]>(
    () => (requirement.entityId ? getApplicationEntityByIdSelector(application, requirement.entityId)?.financials ?? [] : []),
    [application, requirement.entityId],
  );

  const initialValues: FormValues = useMemo(
    () => ({
      propertyEBIDA: getFinancialFieldByYear(entityFinancials, referenceYear, financialAttribute)?.value?.toString() ?? '',
    }),
    [entityFinancials, referenceYear],
  );

  const submitFinancial = useCallback(
    async (newValues: FormValues, { resetForm }: FormikHelpers<any>) => {
      const { propertyEBIDA } = newValues;

      resetForm({ values: newValues });

      const payload = userId
        ? getFinancialPayload({
            entityId: requirement.entityId,
            financials: entityFinancials,
            year: referenceYear,
            fieldName: financialAttribute,
            value: propertyEBIDA,
            userId,
          })
        : [];

      if (payload.length) {
        await updateFinancialData(payload);
      }
    },
    [userId, requirement.entityId, entityFinancials, referenceYear, updateFinancialData],
  );

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitFinancial}>
      {({ dirty, isValid, handleSubmit }) => (
        <ConfirmLeave shouldBlock={dirty}>
          <Form onSubmit={handleSubmit}>
            <Flex flexDirection={'column'} gap={8}>
              <Flex gap={6} flexDirection={isMobile ? 'column' : 'row'}>
                <FormikMoneyInputField name={'propertyEBIDA'} label={'Property EBIDA'} />
              </Flex>
              <ButtonWithSaveIndication
                loading={savingFinancials}
                onSave={handleSubmit}
                disabled={!dirty || !isValid}
                hasBeenSaved={financialsSuccessfullyUpdated}
              />
            </Flex>
          </Form>
        </ConfirmLeave>
      )}
    </Formik>
  );
};
