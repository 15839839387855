import { Button, Flex } from '@lama/design-system';
import React, { useMemo } from 'react';
import { CheckIcon } from '../../icons/CheckIcon';

const mapStateToTextAndIcon = {
  save: { text: 'Save', icon: undefined },
  saving: { text: 'Saving', icon: undefined },
  saved: { text: 'Saved', icon: CheckIcon },
};

const getButtonState = (loading: boolean, disabled: boolean, hasBeenSaved: boolean) => {
  if (loading) {
    return 'saving';
  }

  if (disabled) {
    return hasBeenSaved ? 'saved' : 'save';
  }

  return 'save';
};

export const ButtonWithSaveIndication = ({
  disabled,
  loading,
  hasBeenSaved,
  onSave,
  width,
}: {
  disabled: boolean;
  loading: boolean;
  hasBeenSaved: boolean;
  width?: string;
  onSave: (event: React.FormEvent<HTMLFormElement>) => void;
}) => {
  const { text, icon } = useMemo(() => {
    const state = getButtonState(loading, disabled, hasBeenSaved);

    return mapStateToTextAndIcon[state];
  }, [loading, disabled, hasBeenSaved]);

  return (
    <Flex justifyContent={'flex-end'} width={'100%'}>
      <Button
        type={'submit'}
        disabled={disabled}
        color={'primary'}
        variant={'primary'}
        loading={loading}
        width={width ?? '130px'}
        height={'40px'}
        onClick={onSave}
        startIcon={icon}
        gap={loading ? undefined : 0}
      >
        {text}
      </Button>
    </Flex>
  );
};
