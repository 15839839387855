import React, { useCallback, useContext, useMemo } from 'react';
import { Close } from '@mui/icons-material';
import { Autocomplete, IconButton, TextField } from '@mui/material';
import type { FC } from 'react';
import { Flex, Text } from '@lama/design-system';
import { sortBy } from 'lodash-es';
import { SpreadingDocumentContext } from '../../SpreadingDocumentContext';
import { SpreadingToolbarActions } from './SpreadingToolbarActions';

interface DocumentOption {
  label: string;
  value: string;
  group?: string;
}

interface SpreadingDialogToolbarProps {
  onClose?: () => void;
}

const renderInput = (placeholder: string, customParams?: any) => (params: any) =>
  <TextField variant={'standard'} {...params} {...customParams} placeholder={placeholder} />;

const renderDocumentInput = renderInput('Document');

const getGroupBy = (option: DocumentOption) => option.group ?? '';

export const SpreadingToolbar: FC<SpreadingDialogToolbarProps> = ({ onClose }) => {
  const { currentDocument, setCurrentDocument, documents } = useContext(SpreadingDocumentContext);

  const documentOptions = useMemo(
    () =>
      sortBy(
        documents.map<DocumentOption>((document) => ({
          label: [document.description, document.filename].filter(Boolean).join(' - '),
          value: document.id,
          group: document.context || 'Miscellaneous',
        })),
        [(o) => (o.group === 'Miscellaneous' ? 1 : 0), (o) => o.group],
      ),
    [documents],
  );

  const onDocumentChange = useCallback(
    (event: React.SyntheticEvent, value: DocumentOption | null) => {
      if (value) {
        setCurrentDocument(value.value);
      }
    },
    [setCurrentDocument],
  );

  const currentDocumentOption = useMemo(
    () => documentOptions.find((option) => option.value === currentDocument.id),
    [documentOptions, currentDocument],
  );

  return (
    <Flex px={4} py={1}>
      <Flex gap={1} width={'100%'} justifyContent={'space-between'}>
        <Flex gap={4} alignItems={'center'}>
          {onClose ? (
            <IconButton edge={'start'} onClick={onClose}>
              <Close />
            </IconButton>
          ) : null}
          <Flex flex={1} gap={1} flexDirection={'column'}>
            <Autocomplete
              fullWidth
              value={currentDocumentOption}
              options={documentOptions}
              groupBy={getGroupBy}
              onChange={onDocumentChange}
              renderInput={renderDocumentInput}
              disableClearable
              sx={{
                minWidth: '400px',
              }}
            />
            {currentDocument.context ? (
              <Text variant={'body3'} color={'secondary'}>
                {currentDocument.context}
              </Text>
            ) : null}
          </Flex>
        </Flex>
        <SpreadingToolbarActions />
      </Flex>
    </Flex>
  );
};
