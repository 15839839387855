import { useCallback, useMemo } from 'react';
import type { ApplicationApiModel } from '@lama/clients';
import writeXlsxFile from 'write-excel-file';
import { loanDisplayNameSelector } from '@lama/data-formatters';
import { v4 as uuidv4 } from 'uuid';
import { convert } from 'html-to-text';
import { useOpportunityCommentsMutation } from '../../../../../../shared/hooks/react-query/comments/useOpportunityCommentsMutation';
import { commentsExportExcelSchema } from '../../../../ActionsMenu/commentsExportExcelSchema';
import { useUploadDocumentsMutation } from '../../../../../../shared/components/DocumentBox/hooks/useUploadDocumentsQuery';

export const NOTES_LOG_DESCRIPTION = 'Notes Export';

export const useGenerateNotesReport = ({ opportunityId, application }: { opportunityId: string; application: ApplicationApiModel }) => {
  const { mutateAsync: getComments, isPending: loadingComments } = useOpportunityCommentsMutation();
  const { mutateAsync: uploadNotesLogDocument, isPending: isUploadingNotesLogDocument } = useUploadDocumentsMutation({
    applicationId: application.id,
    opportunityId,
  });

  const generateNotesReport = useCallback(async () => {
    // why?

    const data = await getComments({ opportunityId });

    const convertedComments = data.map((comment) => ({
      ...comment,
      content: convert(comment.content),
    }));

    const blob = await writeXlsxFile(convertedComments, {
      schema: commentsExportExcelSchema,
    });

    const file = new File([blob], `${loanDisplayNameSelector(application)}_comments.xlsx`);

    await uploadNotesLogDocument([
      {
        exportConfiguration: { nautilusDocumentType: 'COMM-Correspondence' },
        applicationId: application.id,
        description: NOTES_LOG_DESCRIPTION,
        entityId: application.id,
        entityType: 'application',
        file,
        topic: null,
        documentId: uuidv4(),
      },
    ]);
  }, [application, getComments, opportunityId, uploadNotesLogDocument]);

  const loading = useMemo(() => loadingComments || isUploadingNotesLogDocument, [loadingComments, isUploadingNotesLogDocument]);

  return {
    generateNotesReport,
    loading,
  };
};
