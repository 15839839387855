import { KeyValuePair, LoadingPage } from '@lama/app-components';
import { Grid } from '@lama/design-system';
import { application1071UniversalLoanIdentifier } from '@lama/data-formatters';
import React, { useContext, useMemo } from 'react';
import { ApplicationContext } from '../../ApplicationContext';
import { useGetPartnerQuery } from '../../../../shared/hooks/react-query/partner/useGetPartnerQuery';

const UNIQUE_IDENTIFIER_LABEL = 'Unique Identifier';
const SUBMITTED_AT_LABEL = 'Submitted At';
export const Section1071ReportApplicationDetails = () => {
  const { opportunity } = useContext(ApplicationContext);
  const { data: partner, isLoading: loadingPartner } = useGetPartnerQuery(opportunity.partnerId);
  const applciationUniqueIdentifier = useMemo(
    () =>
      partner?.hmdaLegalEntityIdentifier ? application1071UniversalLoanIdentifier(opportunity, partner.hmdaLegalEntityIdentifier) : null,
    [opportunity, partner],
  );
  const applciationSubmittedAt = useMemo(() => opportunity.application.submittedAt, [opportunity]);
  if (loadingPartner) {
    return <LoadingPage />;
  }

  return (
    <Grid columns={3}>
      <KeyValuePair key={UNIQUE_IDENTIFIER_LABEL} name={UNIQUE_IDENTIFIER_LABEL} value={applciationUniqueIdentifier} />
      <KeyValuePair key={SUBMITTED_AT_LABEL} name={SUBMITTED_AT_LABEL} value={applciationSubmittedAt} />
    </Grid>
  );
};
