import React from 'react';
import type { FC } from 'react';
import { CollateralEmptyState } from './listEmptyStates/CollateralEmptyState';
import { DebtEmptyState } from './listEmptyStates/DebtEmptyState';
import { UseOfFundsBreakdownEmptyState } from './listEmptyStates/UseOfFundsBreakdownEmptyState';
import { UseOfFundsBreakdownItem } from './ListItems/UseOfFundsBreakdownItem';
import { CollateralItem } from './ListItems/CollateralItem';
import { OtherIncomeItem } from './ListItems/OtherIncomeItem';
import { FinancialReferenceItem } from './ListItems/FinancialReferenceItem';
import { ClosingItemItem } from './ListItems/ClosingItemItem';
import { FeeItem } from './ListItems/FeeItem';
import { DebtItem } from './ListItems/DebtItem';
import { TransactionEstimateItem } from './ListItems/TransactionEstimateItem';
import { AccountItem } from './ListItems/AccountItem';
import { SourceOfFundsItem } from './ListItems/SourceOfFundsItem';
import { TechnologyPlatformAndSystemItem } from './ListItems/TechnologyPlatformAndSystemItem';
import { OnlineBankingItem } from './ListItems/OnlineBankingItem';
import { VirtualAssetItem } from './ListItems/VirtualAssetItem';

interface RequirementListItemProps {
  item: any;
}

export const requirementToListItemComponent: Record<string, FC<RequirementListItemProps>> = {
  useOfFundsBreakdown: UseOfFundsBreakdownItem,
  debt: DebtItem,
  collateral: CollateralItem,
  transactionEstimates: TransactionEstimateItem,
  fees: FeeItem,
  closingItems: ClosingItemItem,
  otherIncomes: OtherIncomeItem,
  financialReferences: FinancialReferenceItem,
  accounts: AccountItem,
  sourcesOfFunds: SourceOfFundsItem,
  technologyPlatformAndSystems: TechnologyPlatformAndSystemItem,
  onlineBanking: OnlineBankingItem,
  virtualAssets: VirtualAssetItem,
};

export const listRequirementToEmptyStateImage: Record<string, FC> = {
  collateral: () => <CollateralEmptyState />,
  debt: () => <DebtEmptyState />,
  useOfFundsBreakdown: () => <UseOfFundsBreakdownEmptyState />,
  transactionEstimates: () => <UseOfFundsBreakdownEmptyState />,
  fees: () => <UseOfFundsBreakdownEmptyState />,
  accountOpening: () => <UseOfFundsBreakdownEmptyState />,
  closingItems: () => <UseOfFundsBreakdownEmptyState />,
  accounts: () => <UseOfFundsBreakdownEmptyState />,
  sourcesOfFunds: () => <UseOfFundsBreakdownEmptyState />,
  technologyPlatformAndSystems: () => <UseOfFundsBreakdownEmptyState />,
  onlineBanking: () => <UseOfFundsBreakdownEmptyState />,
  virtualAssets: () => <UseOfFundsBreakdownEmptyState />,
};
