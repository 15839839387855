import type { EvaluatedOpportunityRequirement } from '@lama/contracts';
import React, { useContext, useMemo } from 'react';
import { Stack, Typography } from '@mui/material';

import type { FC } from 'react';
import { Flex, Grid } from '@lama/design-system';
import type { InfoLineProps } from '@lama/app-components';
import { KeyValuePair } from '@lama/app-components';
import type { SourceOfFunds } from '@lama/business-service-client';
import { SegmentContainer } from '../../../Application/shared/SegmentContainer';
import { ApplicationContext } from '../../../Application/ApplicationContext';
import { RequirementPrintContainer } from '../RequirementPrintContainer';

const SourceOfFundsSegment: FC<{ sourceOfFunds: SourceOfFunds }> = ({ sourceOfFunds }) => {
  const { bankName, fundsSource } = sourceOfFunds;
  const info: InfoLineProps[] = [
    {
      label: 'Funds Source',
      values: fundsSource,
    },
  ];

  return (
    <SegmentContainer title={bankName}>
      <Flex flexDirection={'column'} gap={4}>
        <Flex flexDirection={'column'} gap={4}>
          <Grid columns={1}>
            {info.map((line) => (
              <KeyValuePair key={line.label} name={line.label} value={line.values} type={line.type} />
            ))}
          </Grid>
        </Flex>
      </Flex>
    </SegmentContainer>
  );
};

export const SourceOfFundsView = ({ requirement }: { requirement: EvaluatedOpportunityRequirement }) => {
  const { application } = useContext(ApplicationContext);

  const business = useMemo(
    () => application.relatedBusinesses?.find((b) => b.business.id === requirement.entityId)?.business,
    [application.relatedBusinesses, requirement.entityId],
  );

  if (!business?.sourcesOfFunds) {
    return (
      <RequirementPrintContainer title={requirement.name}>
        <Typography variant={'body2'}>{'No information found'}</Typography>
      </RequirementPrintContainer>
    );
  }

  return (
    <RequirementPrintContainer title={requirement.name}>
      <Stack gap={2}>
        {business.sourcesOfFunds?.map((sourceOfFunds) => (
          <SourceOfFundsSegment key={sourceOfFunds.id} sourceOfFunds={sourceOfFunds} />
        ))}
      </Stack>
    </RequirementPrintContainer>
  );
};
