import React, { useCallback, useMemo } from 'react';
import { Flex, Button } from '@lama/design-system';
import type { FC } from 'react';
import { BaseDialog, FormikMoneyInputField, PropertyFormikInput } from '@lama/app-components';
import { capitalCase } from 'change-case-all';
import { Formik } from 'formik';
import * as yup from 'yup';
import { TextField } from '@mui/material';

interface FinancialChildDialogProps {
  open: boolean;
  onClose: (childFinancial?: { value: number; displayName: string; note: string }) => void;
  isLoading: boolean;
  initialValue?: { value?: number; displayName: string; note?: string };
  existingRowNames?: string[];
}

interface FormValues {
  value: string;
  displayName: string;
  note: string;
}

export const FinancialChildDialog: FC<FinancialChildDialogProps> = ({ onClose, open, isLoading, initialValue, existingRowNames }) => {
  const internalOnClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const onSubmit = useCallback(
    ({ value, displayName, note }: FormValues) => {
      onClose({ value: +value, displayName, note });
    },
    [onClose],
  );

  const mode = useMemo(() => (initialValue ? 'edit' : 'add'), [initialValue]);

  const initialValues: FormValues = useMemo(
    () => ({
      value: initialValue?.value?.toString() ?? '',
      displayName: initialValue?.displayName ?? '',
      note: initialValue?.note ?? '',
    }),
    [initialValue],
  );

  const validationSchema = useMemo(
    () =>
      yup.object({
        displayName: yup
          .string()
          .required('Name is required')
          .notOneOf(existingRowNames ?? [], 'Name already exists'),
        value: yup.number().required('Value is required'),
      }),
    [existingRowNames],
  );

  return (
    <BaseDialog title={`${capitalCase(mode)} Item`} open={open} onClose={internalOnClose} maxWidth={'xs'} disablePortal>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ handleSubmit, isValid, dirty, getFieldProps }) => (
          <Flex flexDirection={'column'} width={'100%'}>
            <Flex flexDirection={'column'} gap={4}>
              <PropertyFormikInput name={'displayName'} label={'Name'} fullWidth disabled={mode === 'edit'} />
              <FormikMoneyInputField name={'value'} label={'Value'} />
              <TextField label={'Note'} maxRows={10} minRows={3} sx={{ minHeight: 150 }} multiline fullWidth {...getFieldProps('note')} />
            </Flex>
            <Flex justifyContent={'flex-end'} gap={2}>
              <Button variant={'tertiary'} size={'m'} color={'neutral'} onClick={internalOnClose}>
                {'Cancel'}
              </Button>
              <Button
                variant={'primary'}
                color={'primary'}
                size={'m'}
                onClick={handleSubmit}
                disabled={!dirty || !isValid}
                loading={isLoading}
              >
                {'Save'}
              </Button>
            </Flex>
          </Flex>
        )}
      </Formik>
    </BaseDialog>
  );
};
