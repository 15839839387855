import React, { useMemo } from 'react';
import type { FC } from 'react';
import { LoadingPage } from '@lama/app-components';
import { Flex } from '@lama/design-system';
import { max } from 'date-fns';
import { useBankingDataQuery } from '../../../../../shared/hooks/react-query/bank/useBankingDataQuery';
import type { RequirementScreenProps } from '../../OpportunityRequirements/RequirementScreens/types';
import { DocumentRequirementScreen } from '../../OpportunityRequirements/RequirementScreens/DocumentRequirementScreen';
import { AccountCard } from './AccountCard';
import { TransactionsCategories } from './Transactions/TransactionsCategories';
import { AccountsFilterProvider } from './AccountsFilterContext';
import { TimeRangeFilter } from './AccountsTimeRangeFilters';

export const BankRequirement: FC<RequirementScreenProps> = ({ requirement }) => {
  const { data: accounts, isPending: loadingAccounts } = useBankingDataQuery(requirement.entityId, requirement.entityType);

  const lastUpdatedAt = useMemo(
    () => (accounts?.length ? max(accounts.map(({ updatedAt }) => new Date(updatedAt))) : undefined),
    [accounts],
  );

  if (loadingAccounts) {
    return <LoadingPage />;
  }

  if (!accounts?.length || !lastUpdatedAt) {
    return <DocumentRequirementScreen requirement={requirement} />;
  }

  return (
    <AccountsFilterProvider endDate={lastUpdatedAt}>
      <Flex flexDirection={'column'} gap={8}>
        <TimeRangeFilter lastUpdatedAt={lastUpdatedAt} />
        <Flex flexDirection={'column'} gap={4}>
          {accounts.map((account, index) => (
            <AccountCard key={account.accountId} account={account} institution={account.institutionDetails} expand={index === 0} />
          ))}
        </Flex>
        <TransactionsCategories accounts={accounts} />
      </Flex>
    </AccountsFilterProvider>
  );
};
