import { Flex, Text } from '@lama/design-system';
import { InsertDriveFileRounded } from '@mui/icons-material';
import { Divider } from '@mui/material';
import React from 'react';

export const DemoEmailContent = () => (
  <>
    <Flex flex={1} flexDirection={'column'} gap={4}>
      <Flex justifyContent={'space-between'}>
        <Flex flexDirection={'column'} gap={4}>
          <Text variant={'h5'}>{'Loan Request for Moore, Bernier and Bednar'}</Text>
          <Flex flexDirection={'column'} gap={2}>
            <Flex>
              <Text variant={'body1'}>
                {'from: '}
                <Text weight={700}>{'Jane Bernier'}</Text> <a href={'mailto:jane.b@mooreberbed.com'}>{' jane.b@mooreberbed.com'}</a>
              </Text>
            </Flex>
            <Flex>
              <Text variant={'body1'}>
                {'to: '}
                <Text weight={700}>{'Emma Johnson'}</Text> <a href={'mailto:emma.j@huntingtonbank.com'}>{' emma.j@huntingtonbank.com'}</a>
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex pr={10} pt={2}>
          <Text>{'02/01/25, 12:30PM'}</Text>
        </Flex>
      </Flex>
      <Divider />
      <Flex flexDirection={'column'} gap={2} pt={2}>
        <Text variant={'body2'}>{'Hi Emma, '}</Text>
        <br />
        <Text variant={'body2'}>{'As disucussed, I want to apply for a loan of $250,000 for equipment financing.'}</Text>
        <Text variant={'body2'}>{'I have a co-borrower named John Doe.'}</Text>
        <br />
        <Text variant={'body2'}>{"I've attached the files we discussed for your reference."}</Text>
        <br />
        <Text variant={'body2'}>{'Let me know if you need anything else.'}</Text>
        <br />
        <Text variant={'body2'}>{'Thanks,'}</Text>
        <Text variant={'body2'}>{'Jane Bernier'}</Text>
        <Text variant={'body2'}>{'CEO'}</Text>
        <Text variant={'body2'}>{'Moore, Bernier and Bednar'}</Text>
      </Flex>
    </Flex>
    <Flex flex={1} flexDirection={'column'} gap={2}>
      <Text variant={'body1'} weight={700}>
        {'3 attachments'}
      </Text>
      <Flex gap={2}>
        <InsertDriveFileRounded fontSize={'small'} />
        <Text variant={'body2'}>{'Driver License.png'}</Text>
      </Flex>
      <Flex gap={2}>
        <InsertDriveFileRounded fontSize={'small'} />
        <Text variant={'body2'}>{'Credit Report.pdf'}</Text>
      </Flex>
      <Flex gap={2}>
        <InsertDriveFileRounded fontSize={'small'} />
        <Text variant={'body2'}>{'Financial Statement.pdf'}</Text>
      </Flex>
    </Flex>
  </>
);
