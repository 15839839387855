import { Button, Flex, Grid, Text } from '@lama/design-system';
import { Checkbox } from '@mui/material';
import { orderBy } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';
import type { FC } from 'react';

interface CustomizeSummaryProps {
  optionalTitles: string[];
  onGenerate: (chosenTitles: string[]) => Promise<void>;
}

export const CustomizeSummary: FC<CustomizeSummaryProps> = ({ optionalTitles, onGenerate }) => {
  const sortedTitles = useMemo(() => orderBy(optionalTitles, (x) => x, 'asc'), [optionalTitles]);
  const [chosenTitles, setChosenTitles] = React.useState<string[]>(optionalTitles);

  const handleTitleClick = useCallback((title: string) => {
    setChosenTitles((prev) => {
      if (prev.includes(title)) {
        return prev.filter((t) => t !== title);
      }
      return [...prev, title];
    });
  }, []);

  const generateBasedOnChosenTitles = useCallback(() => {
    void onGenerate(chosenTitles);
  }, [chosenTitles, onGenerate]);

  return (
    <Flex pl={2} flexDirection={'column'} gap={3} minWidth={'480px'} maxWidth={'480px'}>
      <Text variant={'body1'} color={'secondary'}>
        {'Summary sections'}
      </Text>
      <Grid columns={2}>
        {sortedTitles.map((title) => (
          <Flex
            flexDirection={'row'}
            key={title}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => {
              handleTitleClick(title);
            }}
            alignItems={'center'}
            marginLeft={'-8px'}
          >
            <Checkbox checked={chosenTitles.includes(title)} size={'small'} />
            <Text variant={'body2'} ellipsis>
              {title}
            </Text>
          </Flex>
        ))}
      </Grid>
      <Flex flexDirection={'row'} justifyContent={'flex-end'}>
        <Button onClick={generateBasedOnChosenTitles} disabled={chosenTitles.length === 0}>
          {'Generate'}
        </Button>
      </Flex>
    </Flex>
  );
};
