import type { FC } from 'react';
import React from 'react';
import { Flex, Grid, Text } from '@lama/design-system';
import type { SourceOfFunds } from '@lama/business-service-client';
import { isNil } from 'lodash-es';
import { KeyValuePair } from '../../KeyValuePair';
import type { InfoLineProps } from '../../Information';

interface SourceOfFundsItemProps {
  item: SourceOfFunds;
}

export const SourceOfFundsItem: FC<SourceOfFundsItemProps> = ({ item: { bankName, fundsSource } }) => {
  const itemData: InfoLineProps[] = [
    ...(!isNil(fundsSource) ? [{ label: 'Funds Source', values: fundsSource, type: 'string' as const }] : []),
  ];

  return (
    <Flex gap={4} flexDirection={'column'}>
      <Text variant={'body1'} weight={700} color={'secondary'}>
        {bankName}
      </Text>
      <Grid columns={3}>
        {itemData.map((line) => (
          <KeyValuePair key={line.label} name={line.label} value={line.values} type={line.type} />
        ))}
      </Grid>
    </Flex>
  );
};
