import React from 'react';
import type { FC } from 'react';
import { Box } from '@mui/material';
import { greyPalette } from '@lama/design-system';

export const CheckIcon: FC<{ color?: string }> = ({ color }) => (
  <Box sx={{ height: '24px', width: '24px' }}>
    <svg width={'24'} height={'24'} viewBox={'0 0 24 24'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <mask id={'mask0_16174_180455'} style={{ maskType: 'alpha' }} maskUnits={'userSpaceOnUse'} x={'0'} y={'0'} width={'24'} height={'24'}>
        <rect width={'24'} height={'24'} fill={'#E2FBF3'} />
      </mask>
      <g mask={'url(#mask0_16174_180455)'}>
        <path d={'M10 16.4012L6 12.4012L7.4 11.0012L10 13.6012L16.6 7.00122L18 8.40122L10 16.4012Z'} fill={color ?? greyPalette[500]} />
      </g>
    </svg>
  </Box>
);
