import type { FC } from 'react';
import React, { useContext, useMemo, useState } from 'react';
import { Formik } from 'formik';
import { Button, Flex } from '@lama/design-system';
import { LoadingPage } from '@lama/app-components';
import { compact, isNil, uniq } from 'lodash-es';
import { attributesById } from '@lama/spreading';
import type { Period } from '@lama/selectors';
import { SpreadingDocumentContext } from '../../SpreadingDocumentContext';
import { useGetDocumentAttributes } from '../../../Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/hooks/useGetDocumentAttributes';

import { useGetAllDocumentAttributesWithFinancialData } from '../../hooks/useGetAllDocumentAttributesWithFinancialData';
import { useGetInitialValues } from '../../hooks/useGetInitialValues';
import { useSubmitDocumentFinancials } from '../../hooks/useSubmitDocumentFinancials';
import { useGroupedDocumentPagesByFormInstance } from '../../hooks/useGetGroupedDocumentPAgesByFormInstance';
import { useSyncSpreadingSample } from '../../hooks/useSyncSpreadingSample';
import { financialsFormTypes } from '../../../Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/utils/financialsFormTypes';
import { SpreadingFormByType } from './SpreadingFormByType';
import type { FieldFilter } from './FieldFilterButton';
import { FieldFilters } from './FieldFilters';

export const isFinancialStatement = (formType: string) => financialsFormTypes.has(formType);

const accuracyThreshold = 0.99;

export const SpreadingForms: FC = () => {
  const { saving, loading: contextLoading, currentDocument, getPeriodByFormType } = useContext(SpreadingDocumentContext);
  const [fieldFilter, setFieldFilter] = useState<FieldFilter>('all');

  const { data: documentAttributes, isPending: documentAttributesPending } = useGetDocumentAttributes();
  const attributesForEntity = useMemo(
    () => documentAttributes?.filter((d) => d.entityType === currentDocument.relatedEntityType) ?? [],
    [currentDocument.relatedEntityType, documentAttributes],
  );
  const groupedDocumentPagesByFormInstance = useGroupedDocumentPagesByFormInstance();

  const { formAttributesWithFinancialData: formWithFinancialData } = useGetAllDocumentAttributesWithFinancialData(
    groupedDocumentPagesByFormInstance,
    attributesForEntity ?? [],
  );

  const missingFormAttributesWithFinancialData = useMemo(
    () =>
      compact(
        formWithFinancialData.map((o) => {
          const formType = o.formDocumentPages.at(0)?.formType ?? '';
          if (isFinancialStatement(formType)) {
            return null;
          }

          return {
            ...o,
            formData: o.formData.filter(({ financialData }) => isNil(financialData?.value)),
          };
        }),
      ),
    [formWithFinancialData],
  );

  const lowConfidenceFormAttributesWithFinancialData = useMemo(
    () =>
      compact(
        formWithFinancialData.map((o) => {
          const formType = o.formDocumentPages.at(0)?.formType ?? '';
          if (isFinancialStatement(formType)) {
            return null;
          }

          return {
            ...o,
            formData: o.formData.filter(({ attribute: { id: attributeId } }) => {
              const matchingAttribute = attributesById[attributeId];

              return matchingAttribute?.accuracy && matchingAttribute.accuracy < accuracyThreshold;
            }),
          };
        }),
      ),
    [formWithFinancialData],
  );

  const totalFieldsCount = useMemo(() => formWithFinancialData.flatMap(({ formData }) => formData).length, [formWithFinancialData]);
  const missingFieldsCount = useMemo(
    () => missingFormAttributesWithFinancialData.flatMap(({ formData }) => formData).length,
    [missingFormAttributesWithFinancialData],
  );
  const lowConfidenceFieldsCount = useMemo(
    () => lowConfidenceFormAttributesWithFinancialData.flatMap(({ formData }) => formData).length,
    [lowConfidenceFormAttributesWithFinancialData],
  );

  const filters = useMemo(
    () => [
      { filter: 'all' as const, text: 'All Fields', count: totalFieldsCount },
      { filter: 'missing' as const, text: 'Missing Fields', count: missingFieldsCount },
      { filter: 'lowConfidence' as const, text: 'Low Confidence Fields', count: lowConfidenceFieldsCount },
    ],
    [totalFieldsCount, missingFieldsCount, lowConfidenceFieldsCount],
  );

  const filteredFormAttributesWithFinancialData = useMemo(() => {
    switch (fieldFilter) {
      case 'all': {
        return formWithFinancialData;
      }
      case 'missing': {
        return missingFormAttributesWithFinancialData;
      }
      case 'lowConfidence': {
        return lowConfidenceFormAttributesWithFinancialData;
      }
    }
  }, [fieldFilter, formWithFinancialData, lowConfidenceFormAttributesWithFinancialData, missingFormAttributesWithFinancialData]);

  const flatValues = useMemo(
    () => filteredFormAttributesWithFinancialData.flatMap(({ formData }) => formData),
    [filteredFormAttributesWithFinancialData],
  );

  const financialsValues = useGetInitialValues(flatValues);
  const formTypeToPeriod = useMemo(() => {
    const formTypes = compact(uniq(currentDocument.documentPages?.map((page) => page.formType ?? null)));

    return Object.fromEntries(formTypes.map((formType) => [formType, getPeriodByFormType(formType)]));
  }, [getPeriodByFormType, currentDocument.documentPages]);

  const initalValues: Record<string, any> & { formTypeToPeriod: Record<string, Period | undefined> } = useMemo(
    () => ({
      ...financialsValues,
      formTypeToPeriod,
    }),
    [formTypeToPeriod, financialsValues],
  );

  const syncSpreadingSample = useSyncSpreadingSample(currentDocument.applicationId);

  const onSubmit = useSubmitDocumentFinancials(flatValues, fieldFilter, null, syncSpreadingSample);

  if (documentAttributesPending || contextLoading) {
    return <LoadingPage />;
  }

  return (
    <Formik initialValues={initalValues} onSubmit={onSubmit} enableReinitialize enableInitialize>
      {({ submitForm, dirty }) => (
        <>
          <Flex zIndex={1000} p={1} position={'fixed'} right={6}>
            <Button variant={'primary'} onClick={submitForm} loading={saving} disabled={!dirty}>
              {'Save'}
            </Button>
          </Flex>
          <Flex flexDirection={'column'} gap={8}>
            <FieldFilters currentFilter={fieldFilter} setFieldFilter={setFieldFilter} filters={filters} />
            {filteredFormAttributesWithFinancialData?.map(({ formData, formDocumentPages }) => (
              <SpreadingFormByType
                key={`${formDocumentPages.at(0)?.formType ?? ''}-${formDocumentPages.at(0)?.page ?? ''}`}
                formDocumentPages={formDocumentPages}
                formData={formData}
              />
            ))}
          </Flex>
        </>
      )}
    </Formik>
  );
};
