import type { FinancialData, SpreadsSetting } from '@lama/contracts';
import { getPeriodsToFinancialsByEntityId, getPeriodsFromKeys, sortPeriodsByYear } from '@lama/selectors';
import { getDefaultSpreadPeriods } from '../utils/getSpreadPeriods';

const getPeriods = (financials: FinancialData[], spreadsSettings: SpreadsSetting[], spreadName: string, defaultYears: number[]) => {
  const savedPeriods = spreadsSettings?.find((s) => s.spreadName === spreadName)?.selectedPeriods;

  if (savedPeriods?.length) {
    return savedPeriods;
  }

  const financialPeriods = financials?.length ? getPeriodsFromKeys(getPeriodsToFinancialsByEntityId(financials)) : [];

  return getDefaultSpreadPeriods(financialPeriods, defaultYears);
};

export const getDisplayedPeriods = (
  financials: FinancialData[],
  spreadsSettings: SpreadsSetting[],
  spreadName: string,
  defaultYears: number[],
) => {
  const periods = getPeriods(financials, spreadsSettings, spreadName, defaultYears);
  return sortPeriodsByYear(periods);
};
