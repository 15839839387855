import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { OpportunityRequirementUpdateApiModelV2 } from '@lama/clients';
import { applicationServiceClient } from '../../../clients/applicationServiceClient';

export const useUpdateRequirement = (opportunityId: string, requirementId: string) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async ({ updateRequirementPayload }: { updateRequirementPayload: OpportunityRequirementUpdateApiModelV2 }) => {
      const token = await getAccessTokenSilently();
      await applicationServiceClient.updateOpportunityRequirementV2(opportunityId, requirementId, updateRequirementPayload, token);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['opportunityRequirements', opportunityId],
        }),
      ]);
    },
  });
};
