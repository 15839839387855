import React, { Suspense, useMemo } from 'react';
import type { FC } from 'react';
import { Box } from '@mui/material';
import { orderBy } from 'lodash-es';
import { format } from 'date-fns';
import { Flex, Spinner, Text } from '@lama/design-system';
import styled from 'styled-components';
import { useOpportunityAuditQueryV2 } from '../../../hooks/react-query/audit/getOpportunityAuditV2Query';
import { auditConnectorClassName, auditLogClassName } from '../AuditLog';
import { consecutiveGroupBy } from '../consecutiveGroupBy';
import { GroupedAudit } from './GroupedAudit';

interface OpportunityAuditFeedProps {
  opportunityId: string;
}

export const AuditConnectorLine = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      borderLeft: '1px solid lightgrey',
      height: '24px',
      marginY: '4px',
      marginLeft: '16px',
    }}
  />
);

export const StyledAuditFeedContainer = styled(Flex)`
  // eslint-disable-next-line @typescript-eslint/naming-convention
  .${auditLogClassName}:nth-last-of-type(1) .${auditConnectorClassName} {
    display: none;
  }
`;

export const AuditLoading = (
  <Flex flexDirection={'row'} justifyContent={'center'} alignItems={'center'} width={'100%'}>
    <Spinner size={'s'} />
  </Flex>
);

export const OpportunityAuditFeedV2: FC<OpportunityAuditFeedProps> = ({ opportunityId }) => {
  const { data: auditEntries } = useOpportunityAuditQueryV2(opportunityId, { staleTime: 30_000 });

  const groupedAudits = useMemo(() => {
    if (!auditEntries?.length) {
      return [];
    }
    return orderBy(
      Object.values(
        consecutiveGroupBy(auditEntries, (auditEntry) =>
          [
            format(new Date(auditEntry.timestamp), 'yyyy/MM/dd:HH'),
            auditEntry.action,
            auditEntry.originType === 'user' ? auditEntry.user?.id : 'system',
            auditEntry.entityId,
          ].join('_'),
        ),
      ).map((groupedAuditEntries) => orderBy(groupedAuditEntries, (x) => x.timestamp, 'desc')),
      (x) => x[0]?.timestamp,
      'desc',
    );
  }, [auditEntries]);

  if (!auditEntries?.length) {
    return null;
  }

  return (
    <Suspense fallback={AuditLoading}>
      <Flex flexDirection={'column'} pt={12} gap={6}>
        <Text variant={'body1'}>{'Audit'}</Text>
        <StyledAuditFeedContainer flexDirection={'column'}>
          {groupedAudits.map((hourAudits) => (
            <GroupedAudit key={hourAudits[0]?.id} groupedAudits={hourAudits} />
          ))}
        </StyledAuditFeedContainer>
      </Flex>
    </Suspense>
  );
};
