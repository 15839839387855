import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Flex } from '@lama/design-system';
import { LoadingButton } from '@mui/lab';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import type { FC } from 'react';
import { Divider, Grid } from '@mui/material';
import { FormikMoneyInputField, FormikPicker, PropertyFormikInput, ConfirmLeave } from '@lama/app-components';
import type { FinancialData } from '@lama/contracts';
import { employmentStatuses, occupations, residentalStatuses } from '@lama/contracts';
import { getSourcedProperty } from '@lama/properties';
import { isEmpty, isNil } from 'lodash-es';
import type { PersonUpdateApiModel } from '@lama/clients';
import { allApplicationPeopleSelector } from '@lama/data-formatters';
import { ApplicationContext } from '../../../../ApplicationContext';
import type { RequirementScreenProps } from '../types';
import { useSubmitFinancialsMutation } from '../financials/hooks/useSubmitFinancialsMutation';
import { getFinancialPayload } from '../financials/financialFieldUtils';
import { UserDetailsContext } from '../../../../../../shared/context/UserDetailsContext';
import { useUpdatePerson } from '../../../../../../shared/hooks/react-query/people/useUpdatePerson';

interface FinancialProperty {
  propertyKey: string;
  formFieldName: string;
  attributeName: string;
}

const financialPropertiesData: FinancialProperty[] = [
  {
    propertyKey: 'personalAnnualIncome',
    formFieldName: 'personalAnnualIncome',
    attributeName: 'Annual Income',
  },
  {
    propertyKey: 'monthlyDebtPayments',
    formFieldName: 'monthlyDebtPayments',
    attributeName: 'Monthly Debt Payments',
  },
];

const getFinancialsPayload = ({
  entityId,
  referenceYear,
  formFields,
  sourcedFinancialProperties,
  initialValues,
  entityFinancials,
  userId,
}: {
  entityId: string;
  referenceYear: number;
  formFields: Record<string, string>;
  sourcedFinancialProperties: (FinancialProperty & { sourcedProperty: any })[];
  initialValues: Record<string, any>;
  entityFinancials: FinancialData[];
  userId: string;
}) => {
  const financialsPayload = Object.entries(formFields).flatMap(([fieldName, fieldValue]) => {
    const financialProperty = sourcedFinancialProperties.find(({ formFieldName }) => formFieldName === fieldName);

    if (!financialProperty || fieldValue === initialValues[fieldName]) {
      return [];
    }

    return getFinancialPayload({
      entityId,
      financials: entityFinancials,
      year: referenceYear,
      fieldName: financialProperty.attributeName,
      value: fieldValue as number | string | undefined,
      userId,
    });
  });

  return financialsPayload;
};

export const PersonalFinancials: FC<RequirementScreenProps> = ({ requirement: { entityId, entityType } }) => {
  const [submitted, setSubmitted] = useState(false);
  const { application, opportunity, properties } = useContext(ApplicationContext);
  const { userId } = useContext(UserDetailsContext);

  const { mutateAsync: updatePerson, isPending: isUpdatingPerson } = useUpdatePerson(opportunity.id);
  const { mutateAsync: updateFinancialData, isPending: isUpdatingFinancials } = useSubmitFinancialsMutation(
    entityId,
    entityType,
    opportunity.id,
  );

  const person = useMemo(() => allApplicationPeopleSelector(application).find((p) => p.id === entityId), [application, entityId]);

  const sourcedFinancialProperties = useMemo(
    () =>
      financialPropertiesData.flatMap((propertyData) => {
        const property = properties[propertyData.propertyKey];
        return property
          ? [
              {
                ...propertyData,
                sourcedProperty: getSourcedProperty({ property, entity: person, application, referenceYear: opportunity.referenceYear }),
              },
            ]
          : [];
      }),
    [properties, person, application, opportunity.referenceYear],
  );

  const limitSourcedProperty = useMemo(
    () =>
      properties.totalCreditLinesLastYear
        ? getSourcedProperty({
            property: properties.totalCreditLinesLastYear,
            entity: person,
            application,
            referenceYear: opportunity.referenceYear,
          })
        : null,
    [application, person, properties.totalCreditLinesLastYear, opportunity.referenceYear],
  );

  const balanceSourcedProperty = useMemo(
    () =>
      properties.creditLineBalanceLastYear
        ? getSourcedProperty({
            property: properties.creditLineBalanceLastYear,
            entity: person,
            application,
            referenceYear: opportunity.referenceYear,
          })
        : null,
    [application, person, properties.creditLineBalanceLastYear, opportunity.referenceYear],
  );

  const initialValues = useMemo(() => {
    const nonFinancialInitialValues = {
      employmentStatus: person?.employment?.employmentStatus ?? '',
      occupation: person?.employment?.occupation ?? '',
      employer: person?.employment?.employer ?? '',
      jobTitle: person?.employment?.jobTitle ?? '',
      yearsEmployed: person?.employment?.yearsEmployed?.toString() ?? '',
      timeAtCurrentAddress: person?.residenceInformation?.timeAtCurrentAddress?.toString() ?? '',
      numberOfDependents: person?.residenceInformation?.numberOfDependents ?? '',
      rentOrMortgagePayment: person?.residenceInformation?.rentOrMortgagePayment ?? '',
      residentialStatus: person?.residenceInformation?.residentialStatus ?? '',
      comments: person?.residenceInformation?.comments ?? '',
      totalRevolvingCreditLimit: limitSourcedProperty?.selectedPropertyValue?.value,
      currentRevolvingDebt: balanceSourcedProperty?.selectedPropertyValue?.value,
    };

    const financialInitialValues = Object.fromEntries(
      sourcedFinancialProperties.map(({ formFieldName, sourcedProperty: { selectedPropertyValue } }) => [
        formFieldName,
        selectedPropertyValue?.value ?? '',
      ]),
    );

    return {
      ...nonFinancialInitialValues,
      ...financialInitialValues,
    } as Record<string, any> & typeof nonFinancialInitialValues;
  }, [
    person?.employment?.employmentStatus,
    person?.employment?.occupation,
    person?.employment?.employer,
    person?.employment?.jobTitle,
    person?.employment?.yearsEmployed,
    person?.residenceInformation?.timeAtCurrentAddress,
    person?.residenceInformation?.numberOfDependents,
    person?.residenceInformation?.rentOrMortgagePayment,
    person?.residenceInformation?.residentialStatus,
    person?.residenceInformation?.comments,
    limitSourcedProperty?.selectedPropertyValue?.value,
    balanceSourcedProperty?.selectedPropertyValue?.value,
    sourcedFinancialProperties,
  ]);

  const onSubmit = useCallback(
    async (values: typeof initialValues, { resetForm }: FormikHelpers<typeof initialValues>) => {
      resetForm({ values });

      const {
        employmentStatus,
        occupation,
        employer,
        jobTitle,
        yearsEmployed,
        timeAtCurrentAddress,
        numberOfDependents,
        rentOrMortgagePayment,
        residentialStatus,
        comments,
        totalRevolvingCreditLimit,
        currentRevolvingDebt,
        ...restOfFormFields
      } = values;

      if (person) {
        const personUpdate: PersonUpdateApiModel = {
          employment: {
            employmentStatus,
            occupation,
            employer,
            jobTitle,
            yearsEmployed: yearsEmployed ? Number(yearsEmployed) : null,
          },
          residenceInformation: {
            timeAtCurrentAddress: timeAtCurrentAddress ? Number(timeAtCurrentAddress) : null,
            numberOfDependents: numberOfDependents !== '' ? Number(numberOfDependents) : null,
            rentOrMortgagePayment: rentOrMortgagePayment !== '' ? Number(rentOrMortgagePayment) : null,
            residentialStatus,
            comments,
          },
        };

        if (totalRevolvingCreditLimit !== initialValues.totalRevolvingCreditLimit) {
          personUpdate.totalRevolvingCreditLimit =
            !isNil(totalRevolvingCreditLimit) && totalRevolvingCreditLimit !== '' ? Number(totalRevolvingCreditLimit) : null;
        }

        if (currentRevolvingDebt !== initialValues.currentRevolvingDebt) {
          personUpdate.currentRevolvingDebt =
            !isNil(currentRevolvingDebt) && currentRevolvingDebt !== '' ? Number(currentRevolvingDebt) : null;
        }

        await updatePerson({
          personId: person.id,
          updatePersonPayload: personUpdate,
        });
      }

      if (!isEmpty(restOfFormFields) && userId) {
        const financialsPayload = getFinancialsPayload({
          entityId,
          referenceYear: opportunity.referenceYear,
          formFields: restOfFormFields,
          sourcedFinancialProperties,
          initialValues,
          entityFinancials: person?.financials ?? [],
          userId,
        });

        if (financialsPayload.length) {
          await updateFinancialData(financialsPayload);
        }
      }

      setSubmitted(true);
    },
    [entityId, initialValues, opportunity.referenceYear, person, sourcedFinancialProperties, updateFinancialData, updatePerson, userId],
  );

  if (!person) {
    return null;
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ dirty, isValid, handleSubmit }) => (
        <ConfirmLeave shouldBlock={dirty}>
          <Grid container spacing={2}>
            {sourcedFinancialProperties.length ? (
              <>
                {sourcedFinancialProperties.map(
                  ({ formFieldName, sourcedProperty: { selectedPropertyValue, propertyValues, displayName } }) => (
                    <Grid item xs={6} key={formFieldName}>
                      <FormikMoneyInputField
                        name={formFieldName}
                        label={displayName}
                        required
                        highlight={submitted}
                        fullWidth
                        value={selectedPropertyValue?.value ?? ''}
                        sourcesValues={propertyValues}
                      />
                    </Grid>
                  ),
                )}
                <Grid item xs={6}>
                  <FormikMoneyInputField
                    name={'currentRevolvingDebt'}
                    label={'Revolving Credit Balance'}
                    required
                    highlight={submitted}
                    fullWidth
                    sourcesValues={balanceSourcedProperty?.propertyValues}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormikMoneyInputField
                    name={'totalRevolvingCreditLimit'}
                    label={'Revolving Credit Limit'}
                    required
                    highlight={submitted}
                    fullWidth
                    sourcesValues={limitSourcedProperty?.propertyValues}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </>
            ) : null}
            <Grid item xs={6}>
              <FormikPicker
                name={'employmentStatus'}
                label={'Employment Status'}
                values={employmentStatuses}
                required
                highlight={submitted}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <FormikPicker name={'occupation'} label={'Occupation'} values={occupations} required highlight={submitted} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <PropertyFormikInput name={'employer'} label={'Employer Name'} required highlight={submitted} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <PropertyFormikInput name={'jobTitle'} label={'Job Title'} required highlight={submitted} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <PropertyFormikInput
                name={'yearsEmployed'}
                label={'Numbers of Years Employed'}
                type={'number'}
                required
                highlight={submitted}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <PropertyFormikInput
                name={'timeAtCurrentAddress'}
                label={'Time at Current Address (Months)'}
                type={'number'}
                required
                highlight={submitted}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <PropertyFormikInput
                name={'numberOfDependents'}
                label={'Number of Dependents'}
                type={'number'}
                required
                highlight={submitted}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <FormikMoneyInputField
                name={'rentOrMortgagePayment'}
                label={'Rent / Mortgage Payment'}
                required
                highlight={submitted}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <FormikPicker
                name={'residentialStatus'}
                label={'Residential Status'}
                values={residentalStatuses}
                required
                highlight={submitted}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <PropertyFormikInput name={'comments'} label={'Comments'} highlight={submitted} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Flex justifyContent={'flex-end'} width={'100%'}>
                <LoadingButton
                  disabled={!dirty || !isValid}
                  color={'primary'}
                  sx={{ width: '130px', height: '40px', cursoer: 'pointer' }}
                  variant={'contained'}
                  disableElevation
                  loading={isUpdatingPerson || isUpdatingFinancials}
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {'Save'}
                </LoadingButton>
              </Flex>
            </Grid>
          </Grid>
        </ConfirmLeave>
      )}
    </Formik>
  );
};
