import React from 'react';
import type { FC } from 'react';
import type { PropertiesRecord, UnderwritingNote } from '@lama/contracts';
import type { User } from '@lama/user-service-client';
import { formatValue, personFullName } from '@lama/data-formatters';
import { compact } from 'lodash-es';
import type { CellClassParams, ColDef } from '@ag-grid-community/core';
import type { Period } from '@lama/selectors';
import { Flex, greyPalette } from '@lama/design-system';
import type { SpreadRow, FinancialAttribute, FinancialAttributeSource } from '@lama/spreads-generator-client';
import type { PeriodDisplayFormat, SpreadUnitType } from '../types';
import type { OpenDocumentParams } from '../components/SpreadTable/types';
import type { SpreadTooltipProps } from '../components/SpreadValueTooltip/SpreadTooltip';
import { SpreadTooltip } from '../components/SpreadValueTooltip/SpreadTooltip';
import { SpreadValueCell } from '../components/SpreadValueTooltip/SpreadValueCell';
import { getShortPeriodName } from './getShortPeriodName';
import { formatSpreadCellValue } from './formatSpreadCellValue';
import { getPeriodColumnTitle, getFormTypeColumnTitle, getPeriodMonthsTitle } from './getSpreadColumnTitle';

export interface FinancialValueInputParams {
  startDate: string;
  endDate: string;
}

const PeriodHeaderComponent: FC<{
  period: Period;
  columnData: FinancialAttributeSource[];
  periodDisplayFormat: 'balance sheet' | 'income statement';
}> = ({ period, columnData, periodDisplayFormat }) => (
  <Flex flexDirection={'column'} style={{ width: '100%' }} alignItems={'flex-end'} gap={0.5}>
    <Flex style={{ fontSize: '14px', color: '#0C2007' }}>{getPeriodColumnTitle(period, periodDisplayFormat)}</Flex>
    <Flex style={{ fontSize: '10px', color: greyPalette[700] }}>{getPeriodMonthsTitle(period, periodDisplayFormat)}</Flex>
    <Flex style={{ fontSize: '10px', color: greyPalette[700] }}>{getFormTypeColumnTitle(period, columnData)}</Flex>
  </Flex>
);

export const getPeriodColumns = (
  applicationId: string,
  openDocument: (openDocumentParams: OpenDocumentParams) => void,
  financialPeriods: Period[],
  spreadRows: SpreadRow[],
  periodDisplayFormat: 'balance sheet' | 'income statement',
  properties: PropertiesRecord,
  spreadUnitType?: SpreadUnitType,
): ColDef<SpreadRow>[] =>
  financialPeriods.map((period) => {
    const { startDate, endDate: endDateString } = period;
    const columnId = `${startDate}-${endDateString}`;

    const columnSources = compact(spreadRows.map((row) => row.valueByPeriod[columnId]?.financialAttribute?.selectedSource));
    const columnHeaderExportText = `${getPeriodColumnTitle(period, periodDisplayFormat)}\n${getFormTypeColumnTitle(period, columnSources)}`;

    return {
      colId: columnId,
      headerName: columnHeaderExportText,
      headerComponent: PeriodHeaderComponent,
      headerComponentParams: { period, periodDisplayFormat, columnData: columnSources },
      tooltipField: 'valueByPeriod',
      type: 'numericColumn',
      valueFormatter: (params) => formatSpreadCellValue(params.value, properties[params.data?.propertyKey ?? '']?.type, spreadUnitType),
      cellRenderer: SpreadValueCell,
      valueGetter: (row) => row.data?.valueByPeriod[columnId]?.financialAttribute?.value,
      tooltipComponent: SpreadTooltip,
      tooltipComponentParams: {
        applicationId,
        openDocument,
        endDate: endDateString,
        startDate,
      } as SpreadTooltipProps,
      minWidth: 150,
      flex: 1,
    };
  });

const getAdjustmentNoteText = ({
  periodId,
  financialAttribute,
  periods,
  users,
  notes,
}: {
  periodId: string;
  financialAttribute?: FinancialAttribute | null;
  periods: Period[];
  users: User[];
  notes: UnderwritingNote[];
}) => {
  const selectedSource = financialAttribute?.selectedSource.financialDataSource;
  if (selectedSource?.type !== 'Adjustment') {
    return null;
  }
  const period = periods.find((p) => `${p.startDate}-${p.endDate}` === periodId);
  const user = users?.find((u) => u.id === selectedSource.user.userId);

  const adjustmentFinancial = financialAttribute?.selectedSource.adjustment;
  const note = adjustmentFinancial ? notes?.find((n) => n.relatedFinancialAdjustmentId === adjustmentFinancial.id) : null;

  const originalValue = financialAttribute?.prioritizedSources?.find((source) => source.financialDataSource.type !== 'Adjustment')?.value;

  if (!period) {
    return null;
  }

  return `${getShortPeriodName(period)} value was modified ${user ? `by ${personFullName(user)}` : ''}: ${
    note ? `"${note.text}"` : ''
  } (Original value: ${originalValue ? formatValue(originalValue, 'currency') : 'N/A'})`;
};

export const getAdjustmentNotesColumn = (periods: Period[], users: User[], notes: UnderwritingNote[]): ColDef<SpreadRow> => ({
  colId: 'notes',
  headerName: 'Notes',
  hide: true,
  rowGroup: true,
  valueGetter: (row) =>
    compact(
      Object.entries(row.data?.valueByPeriod ?? []).map(([periodId, { financialAttribute }]) =>
        getAdjustmentNoteText({ periodId, financialAttribute, periods, users, notes }),
      ),
    ).join(' '),
});

export const getSpreadTableColumnDefs = (
  applicationId: string,
  openDocument: (openDocumentParams: OpenDocumentParams) => void,
  financialPeriods: Period[],
  spreadRows: SpreadRow[],
  periodDisplayFormat: PeriodDisplayFormat,
  properties: PropertiesRecord,
  spreadUnitType?: SpreadUnitType,
): ColDef<SpreadRow>[] => {
  const periodColumns = getPeriodColumns(
    applicationId,
    openDocument,
    financialPeriods,
    spreadRows,
    periodDisplayFormat,
    properties,
    spreadUnitType,
  );

  return periodColumns.map<ColDef<SpreadRow>>((column) => ({
    sortable: false,
    cellClassRules: {
      adjustedValue: (params: CellClassParams<SpreadRow>) =>
        Object.entries(params.data?.valueByPeriod ?? {}).find(([periodKey]) => periodKey === params.column.getColId())?.[1]
          .financialAttribute?.selectedSource.financialDataSource.type === 'Adjustment',
    },
    ...column,
  }));
};
