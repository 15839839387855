import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import type { DocumentLineItem } from '@lama/document-service-client';
import {
  balanceSheetAttributes,
  incomeStatementAttributes,
  attachmentsAttributes,
  personalFinancialStatementAttributes,
  invoiceAttributes,
} from '@lama/spreading';
import type { MRT_Cell, MRT_Column, MRT_ColumnDef, MRT_Row, MRT_TableInstance } from 'material-react-table';
import { Text } from '@lama/design-system';
import { formatValue } from '@lama/data-formatters';
import { format } from 'date-fns';
import { Tooltip } from '@lama/app-components';
import type { Period } from '../components/FinancialStatementsSpreadingContent';
import { YearSelectionMenuItem } from '../components/YearColumnActionsMenu';
import { NumericEditCell } from '../components/NumericEditCell';
import { FinancialSpreadingCellPicker } from '../components/FinancialSpreadingCellPicker';

interface EditCellProps {
  cell: MRT_Cell<DocumentLineItem>;
  column: MRT_Column<DocumentLineItem>;
  row: MRT_Row<DocumentLineItem>;
  table: MRT_TableInstance<DocumentLineItem>;
}

export interface ColumnMeta {
  startDate?: Date;
  endDate?: Date;
}

export const CellWithFocus: FC<{
  cell: MRT_Cell<DocumentLineItem>;
  cellValueFormatter?: (value: string) => number | string;
  onCellFocus: (cell: MRT_Cell<DocumentLineItem>) => void;
}> = ({ cell, cellValueFormatter, onCellFocus }) => {
  const formattedCellValue = useMemo(() => {
    const cellValue = cell.getValue<string>();

    return cellValueFormatter?.(cellValue) ?? cellValue;
  }, [cell, cellValueFormatter]);

  const onClick = useCallback(() => {
    onCellFocus(cell);
  }, [cell, onCellFocus]);

  return <Text onClick={onClick}>{formattedCellValue}</Text>;
};

export const createAttributeColumns = (
  documentLineItems: DocumentLineItem[],
  formType: string,
  onCellFocus: (cell: MRT_Cell<DocumentLineItem>) => void,
  updateCellValue: (cell: MRT_Cell<DocumentLineItem>, value: any) => void,
  formDocumentPageNumbers: number[],
): MRT_ColumnDef<DocumentLineItem>[] => {
  const attributeOptions =
    formType === 'Balance Sheet'
      ? balanceSheetAttributes
      : formType === 'Income Statement'
      ? incomeStatementAttributes
      : formType === 'Personal Financial Statement'
      ? personalFinancialStatementAttributes
      : formType === 'Invoice'
      ? invoiceAttributes
      : formType === 'Attachments'
      ? attachmentsAttributes
      : [];
  const attributeOptionsLabeledValues = attributeOptions.map((option) => ({ label: option, value: option }));

  const parentOptions = documentLineItems.map((lineItem) => ({
    label: lineItem.text,
    value: lineItem.id,
  }));

  const attributeColumns: MRT_ColumnDef<DocumentLineItem>[] = [
    {
      id: 'page',
      accessorKey: 'page',
      header: 'Page',
      size: 1,
      Cell: ({ cell }: { cell: MRT_Cell<DocumentLineItem> }) => <CellWithFocus cell={cell} onCellFocus={onCellFocus} />,
      Edit: ({ cell, row }: EditCellProps) => (
        <FinancialSpreadingCellPicker
          row={row}
          cell={cell}
          label={'Page'}
          options={formDocumentPageNumbers.map((pageNumber) => ({ label: pageNumber.toString(), value: pageNumber }))}
          onValueChanged={updateCellValue}
          disableClear
          initialValue={1}
        />
      ),
    },
    {
      id: 'text',
      accessorKey: 'text',
      header: 'Line Item',
      muiEditTextFieldProps: ({ cell }) => ({
        onBlur: async (event) => {
          updateCellValue(cell, event.target.value);
        },
        onFocus: () => {
          onCellFocus(cell);
        },
      }),
      Cell: ({ cell }: { cell: MRT_Cell<DocumentLineItem> }) => <CellWithFocus cell={cell} onCellFocus={onCellFocus} />,
    },
    {
      id: 'attribute',
      accessorKey: 'attribute',
      header: 'Attribute',
      Cell: ({ cell }: { cell: MRT_Cell<DocumentLineItem> }) => <CellWithFocus cell={cell} onCellFocus={onCellFocus} />,
      Edit: ({ cell, row }: EditCellProps) => (
        <FinancialSpreadingCellPicker
          row={row}
          cell={cell}
          label={'Attribute'}
          options={attributeOptionsLabeledValues}
          onValueChanged={updateCellValue}
        />
      ),
    },
    {
      id: 'parentId',
      header: 'Parent',
      accessorKey: 'parentId',
      Cell: ({ cell }: { cell: MRT_Cell<DocumentLineItem> }) => (
        <CellWithFocus
          cell={cell}
          // eslint-disable-next-line react/jsx-no-bind
          cellValueFormatter={(value) => parentOptions.find((item) => item.value === value)?.label ?? ''}
          onCellFocus={onCellFocus}
        />
      ),
      Edit: ({ cell, row }: EditCellProps) => (
        <FinancialSpreadingCellPicker row={row} cell={cell} label={'Parent'} options={parentOptions} onValueChanged={updateCellValue} />
      ),
    },
  ];

  return attributeColumns;
};

const getFormattedPeriod = (startDate: Date, endDate: Date, formatString: string) =>
  `${format(startDate, formatString)} - ${format(endDate, formatString)}`;

export const createPeriodColumns = (
  lineItems: DocumentLineItem[],
  allPeriods: Period[],
  onCellFocus: (cell: MRT_Cell<DocumentLineItem>) => void,
  onCellBlur: (cell: MRT_Cell<DocumentLineItem>, value: string) => Promise<void>,
  onDeletePeriod?: (period: Period) => void,
) =>
  allPeriods
    .sort((a, b) => a.startDate.getTime() - b.startDate.getTime())
    .map<MRT_ColumnDef<DocumentLineItem>>(({ startDate, endDate }) => ({
      meta: {
        startDate,
        endDate,
      },
      id: `${startDate}-${endDate}`,
      renderColumnActionsMenuItems: (props) => [
        <YearSelectionMenuItem
          key={props.column.id}
          numberOfPeriods={allPeriods.length}
          lineItems={lineItems}
          startDate={startDate}
          endDate={endDate}
          onDeletePeriod={onDeletePeriod}
          {...props}
        />,
      ],
      muiColumnActionsButtonProps(props) {
        return {
          ...props,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'aria-label': `Open ${startDate.toISOString()} - ${endDate.toISOString()} column actions menu`,
        };
      },
      header: '', // required for types, but Header is used instead
      Header: (
        <Tooltip title={getFormattedPeriod(startDate, endDate, 'MM/dd/yyyy')} placement={'top'}>
          <span>{getFormattedPeriod(startDate, endDate, 'MM/yyyy')}</span>
        </Tooltip>
      ),
      Edit: ({ cell, row, column }: EditCellProps) => (
        <NumericEditCell cell={cell} row={row} column={column} onCellBlur={onCellBlur} onCellFocus={onCellFocus} />
      ),
      Cell: ({ cell }) => (
        // eslint-disable-next-line react/jsx-no-bind
        <CellWithFocus cell={cell} onCellFocus={onCellFocus} cellValueFormatter={() => formatValue(cell.getValue(), 'currency')} />
      ),
      accessorFn: (lineItem) => {
        const value = lineItem.values?.find((lineValue) => lineValue.startDate.toString() === startDate.toISOString());
        return value?.value;
      },
    }));
