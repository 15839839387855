import React, { useMemo, useCallback, useContext } from 'react';
import type { FC } from 'react';
import { Button, Flex } from '@lama/design-system';
import { useToggle } from 'react-use';
import type { FinancialData } from '@lama/contracts';
import { useDeleteFinancialsMutation } from '../../../hooks/useDeleteFinancialsMutation';
import { ApplicationContext } from '../../../../../ApplicationContext';
import { AdjustFinancialValueDialog } from './AdjustFinancialValueDialog';
import type { SpreadTooltipFooterProps } from './SpreadTooltipFooter';

type AdjustmentFooterActionsProps = Pick<
  SpreadTooltipFooterProps,
  | 'allowRowAdjustments'
  | 'applicationId'
  | 'colDef'
  | 'currentValue'
  | 'endDate'
  | 'entityId'
  | 'hideTooltipCallback'
  | 'opportunityId'
  | 'propertyKey'
  | 'startDate'
> & {
  existingAdjustment?: FinancialData;
};

export const AdjustmentFooterActions: FC<AdjustmentFooterActionsProps> = ({
  existingAdjustment,
  propertyKey,
  colDef,
  applicationId,
  endDate,
  startDate,
  entityId,
  currentValue,
  hideTooltipCallback,
  allowRowAdjustments,
  opportunityId,
}) => {
  const { properties } = useContext(ApplicationContext);

  const property = useMemo(() => (propertyKey ? properties[propertyKey] : null), [properties, propertyKey]);

  const [adjustModalOpen, toggleAdjustModalOpen] = useToggle(false);

  const disableAdjustments = useMemo(
    () => property?.entityType === 'application' || !allowRowAdjustments,
    [property?.entityType, allowRowAdjustments],
  );

  const { mutateAsync: deleteFinancials, isPending } = useDeleteFinancialsMutation();

  const onClickDeleteAdjustment = useCallback(async () => {
    if (!existingAdjustment?.id || !property?.entityType) {
      return;
    }

    await deleteFinancials(
      {
        financialIds: [existingAdjustment?.id],
        entityId,
        entityType: property.entityType,
        opportunityId,
      },
      {
        onSettled: () => {
          hideTooltipCallback?.();
        },
      },
    );
  }, [deleteFinancials, entityId, existingAdjustment?.id, hideTooltipCallback, property?.entityType, opportunityId]);

  if (!colDef?.headerName || disableAdjustments || !property) {
    return null;
  }

  return (
    <>
      <Flex gap={2} flex={1} justifyContent={'flex-end'}>
        {existingAdjustment?.id ? (
          <Button variant={'tertiary'} size={'m'} onClick={onClickDeleteAdjustment} loading={isPending}>
            {'Reset'}
          </Button>
        ) : null}
        <Button variant={'tertiary'} size={'m'} onClick={toggleAdjustModalOpen}>
          {'Edit'}
        </Button>
      </Flex>
      <AdjustFinancialValueDialog
        columnDate={colDef.headerName}
        property={property}
        open={adjustModalOpen}
        onClose={toggleAdjustModalOpen}
        applicationId={applicationId}
        entityId={entityId}
        endDate={endDate}
        startDate={startDate}
        currentValue={currentValue}
      />
    </>
  );
};
