import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { Flex, Popover, PopoverContent, PopoverTrigger, Text } from '@lama/design-system';
import { IconButton } from '@mui/material';
import { parseUTCDateToString } from '@lama/data-formatters';
import { EditOutlined } from '@mui/icons-material';
import type { Period } from '@lama/selectors';
import { useField } from 'formik';
import { DateRangePicker } from '../../FinancialStatements/components/DateRangePicker';

export const FormikFormPeriod: FC<{ formType: string }> = ({ formType }) => {
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [{ value: formTypeToPeriod }, , { setValue: setFormikValue }] = useField<Record<string, Period | undefined>>('formTypeToPeriod');

  const [startDate, endDate] = useMemo(() => {
    const period = formTypeToPeriod[formType];

    return period ? [new Date(period.startDate), new Date(period.endDate)] : [null, null];
  }, [formType, formTypeToPeriod]);

  const closeDatePicker = useCallback(() => {
    setDatePickerOpen(false);
  }, []);

  const openDatePicker = useCallback(() => {
    setDatePickerOpen(true);
  }, []);

  const onChange = useCallback(
    async (_startDate: Date, _endDate: Date) => {
      closeDatePicker();

      await setFormikValue({
        ...formTypeToPeriod,
        [formType]: { startDate: _startDate.toISOString(), endDate: _endDate.toISOString() },
      });
    },
    [setFormikValue, closeDatePicker, formType, formTypeToPeriod],
  );

  if (!startDate || !endDate) {
    return null;
  }

  return (
    <Flex gap={2} alignItems={'center'}>
      <Text variant={'body2'} color={'secondary'} textAlign={'inherit'}>
        {`${parseUTCDateToString(startDate)} - ${parseUTCDateToString(endDate)}`}
      </Text>
      <Flex>
        <Popover open={datePickerOpen} placement={'bottom-start'} onOpenChange={closeDatePicker}>
          <PopoverTrigger>
            <IconButton onClick={openDatePicker} sx={{ width: '25px', height: '25px', color: 'secondary' }} size={'small'}>
              <EditOutlined sx={{ fontSize: '18px' }} />
            </IconButton>
          </PopoverTrigger>
          <PopoverContent zIndex={9999}>
            <DateRangePicker onChange={onChange} startDate={startDate} endDate={endDate} loading={false} />
          </PopoverContent>
        </Popover>
      </Flex>
    </Flex>
  );
};
