import type { EvaluatedOpportunityRequirement, OpportunityClosingTask } from '@lama/contracts';
import type { DocumentWithIssues } from '@lama/document-service-client';
import { useContext, useMemo } from 'react';
import { getApplicationEntityByType } from '@lama/properties';
import { keyBy, orderBy } from 'lodash-es';
import { entityNameByIdSelector } from '@lama/data-formatters';
import type { DocumentBoxDetails, GroupingOptions } from '../types';
import { ApplicationContext } from '../../Application/ApplicationContext';

export const useDocumentBoxesDetails = ({
  allDocuments,
  closingTasks,
  allRequirements,
  grouping,
}: {
  allDocuments: DocumentWithIssues[];
  closingTasks: OpportunityClosingTask[];
  allRequirements: EvaluatedOpportunityRequirement[];
  grouping: GroupingOptions;
}) => {
  const { application } = useContext(ApplicationContext);

  const requirementsById = useMemo(() => keyBy(allRequirements, (r) => r.id), [allRequirements]);

  return useMemo<DocumentBoxDetails[]>(() => {
    const documentsWithGroups = allDocuments.map<DocumentBoxDetails>((doc) => {
      const relatedClosingTask = closingTasks?.find((task) => task.documentIds?.includes(doc.id));
      const relatedRequirement = requirementsById[doc.requirementId ?? ''];

      return {
        topicName:
          doc.status === 'Deleted'
            ? 'Deleted Documents'
            : relatedRequirement?.name ?? relatedClosingTask?.name ?? 'Miscellaneous Documents',
        entityName:
          doc.status === 'Deleted'
            ? ''
            : relatedRequirement || relatedClosingTask
            ? entityNameByIdSelector(doc.relatedEntityId, doc.relatedEntityType ?? '', application) ?? ''
            : 'Miscellaneous Documents',
        requirement: relatedRequirement,
        closingTask: relatedClosingTask,
        fileName: doc.filename,
        topic: doc.topic,
        name: doc.description,
        document: doc,
        entityId: doc.relatedEntityId,
        entityType: doc.relatedEntityType,
        viewGroup: doc.status === 'Deleted' ? 'deleted' : relatedRequirement || relatedClosingTask ? 'requirement' : 'miscellaneous',
        id: doc.id,
      };
    });

    const documentsByTopicAndEntityId = keyBy(
      allDocuments.filter((r) => r.status !== 'Deleted'),
      (doc) => `${doc.topic}-${doc.relatedEntityId}`,
    );

    const emptyPlaceholdersFromRequirements = allRequirements.flatMap<DocumentBoxDetails>(
      (req) =>
        req.sources.uploadFilesSource
          ?.filter((s) => !documentsByTopicAndEntityId[`${s.topic}-${req.entityId ?? ''}`])
          .map((s) => ({
            topicName: req.name,
            entityName: entityNameByIdSelector(req.entityId ?? '', req.entityType ?? '', application) ?? '',
            requirement: req,
            topic: s.topic,
            viewGroup: 'requirement',
            fileName: '',
            name: s.name,
            id: req.id + s.topic + req.entityId,
            entityId: req.entityId ?? '',
            entityType: req.entityType ?? '',
            dismissalData: s.dismissDataByEntity?.[req.entityId],
            extractable: !!s.extractable,
          })) ?? [],
    );

    const emptyPlaceholdersFromClosingTasks =
      closingTasks?.flatMap<DocumentBoxDetails>(
        (closingTask) =>
          closingTask.requiredDocuments
            ?.flatMap((r) =>
              getApplicationEntityByType(application, closingTask.entityType ?? 'application', closingTask.entityGroups ?? []).map(
                (entity) => ({
                  ...r,
                  entityId: entity.id,
                }),
              ),
            )
            ?.filter((sourceWithEntityId) => !documentsByTopicAndEntityId[`${sourceWithEntityId.topic}-${sourceWithEntityId.entityId}`])
            .map((sourceWithEntityId) => ({
              topicName: closingTask.name,
              entityName: closingTask.entityType
                ? entityNameByIdSelector(sourceWithEntityId.entityId, closingTask.entityType, application) ?? ''
                : '',
              closingTask,
              displayText: sourceWithEntityId.name,
              topic: sourceWithEntityId.topic,
              fileName: '',
              name: sourceWithEntityId.name,
              id: closingTask.id + sourceWithEntityId.topic + sourceWithEntityId.entityId,
              viewGroup: 'requirement',
              entityId: sourceWithEntityId.entityId,
              entityType: closingTask.entityType,
            })) ?? [],
      ) ?? [];

    const result = [...documentsWithGroups, ...emptyPlaceholdersFromRequirements, ...emptyPlaceholdersFromClosingTasks];

    if (grouping === 'recent') {
      return orderBy(
        result,
        (r) => r.document?.createdAt ?? '1970-01-01', // random date that is very old
        ['desc'],
      );
    }

    return result;
  }, [allDocuments, allRequirements, closingTasks, grouping, requirementsById, application]);
};
