import React, { useCallback, useMemo, useContext, useState } from 'react';
import type { FC } from 'react';
import type { MRT_Cell, MRT_Row } from 'material-react-table';
import { isNil, uniqBy } from 'lodash-es';
import { LoadingPage } from '@lama/app-components';
import type { DocumentLineItem, UpdateDocumentLineItemsBody } from '@lama/document-service-client';
import { parseDateWithoutTime } from '@lama/data-formatters';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDocumentLineItemsQuery } from '../../../../../shared/hooks/react-query/document/lineItems/useDocumentLineItemsQuery';
import { SpreadingDocumentContext } from '../../../SpreadingDocumentContext';

import { useCreateDocumentLineItemMutation } from '../../../../../shared/hooks/react-query/document/lineItems/useCreateDocumentLineItemMutation';
import { useDeleteDocumentLineItemMutation } from '../../../../../shared/hooks/react-query/document/lineItems/useDeleteDocumentLineItemMutation';
import { createNewLineItemFromRow } from '../utils/createNewRow';
import { sortDocumentLineItems } from '../utils/sortDocumentLineItems';
import type { SpreadingFormByDocumentTypeProps } from '../../../SpreadingDialog/Content/SpreadingFormByType';
import { usePageRangeText } from '../../../hooks/useFormTypePageRangeText';
import { UserDetailsContext } from '../../../../../shared/context/UserDetailsContext';
import { useUpdateDocumentLineItemsMutation } from '../../../../../shared/hooks/react-query/document/lineItems/useUpdateDocumentLineItemsMutation';
import { getUpdatedLineItemValues } from '../utils/getUpdatedLineItemValues';
import type { ColumnMeta } from '../utils/createTableColumns';
import { SpreadingFormCard } from '../../../SpreadingDialog/Content/SpreadingFormCard';
import { FinancialStatementTable } from './FinancialStatementTable';

export interface Period {
  startDate: Date;
  endDate: Date;
}

const isEqualPeriod = (period1: Period, period2: Period) => period1.startDate === period2.startDate && period1.endDate === period2.endDate;

export const FinancialStatementsSpreadingForm: FC<SpreadingFormByDocumentTypeProps> = ({ formDocumentPages }) => {
  const { currentDocument, setSaving } = useContext(SpreadingDocumentContext);
  const { userId } = useContext(UserDetailsContext);
  const { spreadingBackOfficeMode } = useFlags<{
    spreadingBackOfficeMode: boolean;
  }>();
  const { data: documentLineItems, isPending: loadingDocumentLineItems } = useDocumentLineItemsQuery(
    currentDocument.id,
    spreadingBackOfficeMode,
  );

  const [manuallyAddedPeriods, setManuallyAddedPeriods] = useState<Period[]>([]);

  const { mutateAsync: updateDocumentLineItems } = useUpdateDocumentLineItemsMutation();
  const { mutateAsync: createDocumentLineItem } = useCreateDocumentLineItemMutation();
  const { mutateAsync: deleteDocumentLineItem } = useDeleteDocumentLineItemMutation();

  const formType = useMemo(() => formDocumentPages.at(0)?.formType ?? '', [formDocumentPages]);

  const formDocumentPageNumbers = useMemo(() => formDocumentPages.map(({ page }) => page), [formDocumentPages]);

  const currentFormLineItems = useMemo(() => {
    const documentLineItemsWithValues = documentLineItems?.map((lineItem) => ({
      ...lineItem,
      values: lineItem.values.filter(({ value }) => !isNil(value)),
    }));

    return sortDocumentLineItems(
      documentLineItemsWithValues?.filter((lineItem) => lineItem.page && formDocumentPageNumbers.includes(lineItem.page)) ?? [],
    );
  }, [documentLineItems, formDocumentPageNumbers]);

  const pageRangeText = usePageRangeText(currentDocument, formDocumentPages.at(0)!);

  const tablePeriods = useMemo(
    () =>
      uniqBy(
        [
          ...currentFormLineItems.flatMap((lineItem) =>
            lineItem.values.map((value) => ({
              startDate: parseDateWithoutTime(value.startDate),
              endDate: parseDateWithoutTime(value.endDate),
            })),
          ),
          ...manuallyAddedPeriods,
        ],
        ({ startDate, endDate }) => `${startDate}-${endDate}`,
      ),
    [currentFormLineItems, manuallyAddedPeriods],
  );

  const saveLineItems = useCallback(
    async (lineItemUpdates: UpdateDocumentLineItemsBody['lineItemUpdates']) => {
      if (!lineItemUpdates.length) {
        return;
      }

      setSaving(true);
      await updateDocumentLineItems(
        { documentId: currentDocument.id, lineItemUpdateBody: { lineItemUpdates } },
        {
          onSettled: () => {
            setSaving(false);
          },
        },
      );
    },
    [currentDocument.id, setSaving, updateDocumentLineItems],
  );

  const deleteLineItem = useCallback(
    async (lineItemId: string) => {
      setSaving(true);
      await deleteDocumentLineItem(
        { documentId: currentDocument.id, lineItemId },
        {
          onSettled: () => {
            setSaving(false);
          },
        },
      );
    },
    [currentDocument.id, deleteDocumentLineItem, setSaving],
  );

  const addPeriod = useCallback(
    (period: Period) => {
      setManuallyAddedPeriods([...manuallyAddedPeriods, period]);
    },
    [manuallyAddedPeriods],
  );

  const createRow = useCallback(
    async (_row: MRT_Row<DocumentLineItem>, values: Partial<DocumentLineItem>) => {
      const newLineItem = createNewLineItemFromRow(values, currentDocument, formType, tablePeriods);

      setSaving(true);

      await createDocumentLineItem(
        { documentId: currentDocument.id, lineItem: newLineItem },
        {
          onSettled: () => {
            setSaving(false);
          },
        },
      );
    },
    [createDocumentLineItem, currentDocument, formType, setSaving, tablePeriods],
  );

  const getLineItemValuesUpdatedPayload = useCallback(
    (cell: MRT_Cell<DocumentLineItem>, value: string) => {
      const lineItem = cell.row.original;
      const newValue = value ? Number.parseFloat(value.replaceAll(',', '')) : undefined;

      const { startDate: cellStartDate, endDate: cellEndDate } = cell.column.columnDef.meta as ColumnMeta;
      cellStartDate?.setUTCHours(0, 0, 0, 0);
      cellEndDate?.setUTCHours(0, 0, 0, 0);

      return getUpdatedLineItemValues(lineItem, cellStartDate?.toISOString(), cellEndDate?.toISOString(), userId, newValue);
    },
    [userId],
  );

  const onDeletePeriod = useCallback((deletedPeriod: Period) => {
    setManuallyAddedPeriods((prev) => prev.filter((prevPeriod) => !isEqualPeriod(prevPeriod, deletedPeriod)));
  }, []);

  const header = useMemo(() => `${formType} ${pageRangeText ?? ''}`, [formType, pageRangeText]);

  if (loadingDocumentLineItems) {
    return <LoadingPage />;
  }
  return (
    <SpreadingFormCard header={header}>
      <FinancialStatementTable
        tableData={currentFormLineItems}
        tablePeriods={tablePeriods}
        updateRows={saveLineItems}
        deleteRow={deleteLineItem}
        createRow={createRow}
        addColumn={addPeriod}
        getDateCellUpdatePayload={getLineItemValuesUpdatedPayload}
        formType={formType}
        formDocumentPageNumbers={formDocumentPageNumbers}
        onDeletePeriod={onDeletePeriod}
      />
    </SpreadingFormCard>
  );
};
