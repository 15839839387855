import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { Text } from '@lama/design-system';
import { businessDebtSelector, getLongestPeriodByYearForEntity, getPeriodToFinancialsByEntityId } from '@lama/selectors';
import type { BusinessApiModel } from '@lama/business-service-client';
import { DebtScheduleTableSegment } from '../../../shared/Debt/DebtScheduleTableSegment';
import { ApplicationContext } from '../../../ApplicationContext';

export const DebtScheduleSegment: FC<{ business: BusinessApiModel }> = ({ business }) => {
  const { application } = useContext(ApplicationContext);

  const selectedYear = new Date().getUTCFullYear();
  const periodToFinancialsByEntityId = getPeriodToFinancialsByEntityId(application);
  const businessDebt = useMemo(
    () =>
      businessDebtSelector({
        application,
        entity: business,
        entityType: 'business',
        periodToFinancialsByEntityId,
        selectedPeriod: getLongestPeriodByYearForEntity({
          periodToFinancialsByEntityId,
          selectedYear,
          entity: business,
        }),
        selectedYear,
      }),
    [business, application, periodToFinancialsByEntityId, selectedYear],
  );

  if (!businessDebt) {
    return <Text variant={'body1'}>{'No debt found'}</Text>;
  }

  if (!businessDebt.length) {
    return <Text variant={'body1'}>{'Applicant marked no debt'}</Text>;
  }

  return <DebtScheduleTableSegment debt={businessDebt} />;
};
