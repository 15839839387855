import type { FC } from 'react';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Flex } from '@lama/design-system';
import {
  formValuesToEntityPartial,
  GenericPropertiesGrid,
  GenericPropertiesProvider,
  getInitialFormValues,
  getValidationSchema,
  ConfirmLeave,
  ButtonWithSaveIndication,
} from '@lama/app-components';
import { getSourcedProperty } from '@lama/properties';
import { Formik } from 'formik';
import type { PropertyKeyWithFormConfig } from '../../../../../shared/utils/getFormProperties';
import { getFormProperties } from '../../../../../shared/utils/getFormProperties';
import { ApplicationContext } from '../../../ApplicationContext';
import { useUpdateApplicationMutation } from '../../../../../shared/hooks/react-query/application/useUpdateApplication';
import { FIXED_RATE_TYPE, PRIME_RATE_TYPE } from '../../../shared/LoanTerms/termsUtils';
import type { RequirementScreenProps } from './types';
import { customComponents } from './customComponentsMap';

export const gc14FamilyLoanDetailsApplicationProperties: PropertyKeyWithFormConfig[] = [
  { propertyKey: 'application_requestedAmount', group: 'a' },
  { propertyKey: 'application_loanType', group: 'a' },
  {
    propertyKey: 'application_termMonths',
    group: 'a',
  },
  {
    group: 'a',
    topic: 'principal-term',
    propertyKey: 'application_principalTerm',
    visibilityCondition: {
      fieldName: 'loanType',
      value: ['Res RE Balloon (1-4 Family)', 'Comm RE Balloon'],
    },
  },
  {
    group: 'a',
    propertyKey: 'application_howApplicationWasTaken',
    topic: 'how-application-was-taken',
  },
  {
    propertyKey: 'application_loanDescription',
    customComponent: 'description',
    size: 'large',
    helperText: 'Loan Description',
    group: 'a',
  },
  {
    propertyKey: 'application_paymentPeriod',
    group: 'a',
  },
  {
    propertyKey: 'application_rateType',
    group: 'a',
    displayNameOverride: 'Rate Type',
    valueOptions: [FIXED_RATE_TYPE, PRIME_RATE_TYPE],
  },
  {
    propertyKey: 'application_rate',
    group: 'a',
    displayNameOverride: 'Rate',
    visibilityCondition: {
      entityType: 'application',
      fieldName: 'requestedRateType',
      type: 'equals',
      value: FIXED_RATE_TYPE,
    },
  },
  {
    propertyKey: 'application_spread',
    group: 'a',
    displayNameOverride: 'Spread',
    visibilityCondition: {
      entityType: 'application',
      fieldName: 'requestedRateType',
      type: 'equals',
      value: PRIME_RATE_TYPE,
    },
  },
];

export const GatecityLoanDetailsRequirementScreen: FC<RequirementScreenProps> = () => {
  const [submitted, setSubmitted] = useState(false);

  const { application, opportunity, properties, product } = useContext(ApplicationContext);

  const {
    mutateAsync: updateApplication,
    isPending: updatingApplication,
    isSuccess,
  } = useUpdateApplicationMutation(application.id, opportunity.id);

  const sourcedApplicationProperties = useMemo(
    () =>
      getFormProperties(gc14FamilyLoanDetailsApplicationProperties, properties).map((p) =>
        getSourcedProperty({ property: p, entity: application, application, referenceYear: opportunity.referenceYear }),
      ),
    [application, properties, opportunity.referenceYear],
  );

  const initialValues = useMemo(
    () => getInitialFormValues(sourcedApplicationProperties, application),
    [application, sourcedApplicationProperties],
  );

  const validationSchema = useMemo(() => getValidationSchema({ properties: sourcedApplicationProperties }), [sourcedApplicationProperties]);

  const onSubmit = useCallback(
    async (values: Record<string, any>) => {
      const applicationNewValues = Object.fromEntries(Object.entries(values).filter(([key]) => key in initialValues));

      const applicationUpdate = formValuesToEntityPartial(applicationNewValues, initialValues, sourcedApplicationProperties);
      applicationUpdate.requestedRateIsFixed = applicationNewValues.requestedRateType === FIXED_RATE_TYPE;

      await Promise.all([updateApplication({ updateApplicationPayload: applicationUpdate })]);

      setSubmitted(true);
    },
    [initialValues, sourcedApplicationProperties, updateApplication],
  );

  return (
    <GenericPropertiesProvider customComponents={customComponents} customSourceToValues={product.customOptionsLists ?? {}}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} enableReinitialize onSubmit={onSubmit}>
        {({ handleSubmit, dirty, isValid }) => (
          <ConfirmLeave shouldBlock={dirty}>
            <Flex flexDirection={'column'} gap={8}>
              <GenericPropertiesGrid
                properties={[...sourcedApplicationProperties]}
                entityType={'opportunity'}
                submitted={submitted}
                entity={opportunity}
                application={application}
              />
              <ButtonWithSaveIndication
                loading={updatingApplication}
                onSave={handleSubmit}
                disabled={!dirty || !isValid}
                hasBeenSaved={isSuccess}
              />
            </Flex>
          </ConfirmLeave>
        )}
      </Formik>
    </GenericPropertiesProvider>
  );
};
