import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { add } from 'date-fns';
import { Grid } from '@lama/design-system';
import type { InfoLineProps } from '@lama/app-components';
import { KeyValuePair } from '@lama/app-components';
import { annualRateSelector, monthlyPaymentSelector } from '@lama/selectors';
import { proposedLoanAmount as proposedLoanAmountSelector } from '@lama/data-formatters';
import { isNil } from 'lodash-es';
import { ApplicationContext } from '../../../../ApplicationContext';
import { getRateType, FIXED_RATE_TYPE } from '../../../../shared/LoanTerms/termsUtils';
import { SegmentContainer } from '../../../../shared/SegmentContainer';

export const GrasshopperLoanTermsTermsViewSegment: FC = () => {
  const { opportunity, product, application } = useContext(ApplicationContext);

  const proposedLoanAmount = useMemo(() => proposedLoanAmountSelector(application), [application]);

  const proposedLoanMonthlyPayment = useMemo(() => {
    const annualRate = annualRateSelector(application);

    if (isNil(annualRate) || !opportunity.terms?.termLength || isNil(proposedLoanAmount)) {
      return null;
    }

    if (annualRate === 0) {
      return proposedLoanAmount / opportunity.terms.termLength;
    }

    return monthlyPaymentSelector(annualRate, opportunity.terms.termLength, proposedLoanAmount);
  }, [application, opportunity.terms?.termLength, proposedLoanAmount]);

  const termsData = useMemo<InfoLineProps[]>(() => {
    const rateType = getRateType(opportunity.terms?.rate);

    return [
      {
        label: 'Payment',
        values: proposedLoanMonthlyPayment,
        type: 'currency',
      },
      {
        label: 'Loan Type',
        values: opportunity.loanType,
      },
      {
        label: 'Origination Date',
        values: opportunity.originationDate,
        type: 'date',
      },
      {
        label: 'Maturity Date',
        values: opportunity.originationDate
          ? add(new Date(opportunity.originationDate), { months: opportunity.terms?.termLength ?? 0 }).toISOString()
          : null,
        type: 'date',
      },
      {
        label: 'Review Year',
        values: opportunity.referenceYear,
        type: 'string',
      },
      {
        label: 'SBA Guaranty Percentage',
        values: opportunity.underwriting?.additionalData?.sbaGuarantyPercentage,
        type: 'percent',
      },
      {
        label: 'Loan Sold on Secondary Market',
        values: opportunity.underwriting?.additionalData?.loanSoldOnSecondaryMarket,
        type: 'boolean',
      },
      {
        label: 'Rate Type',
        values: rateType,
      },
      {
        label: rateType === FIXED_RATE_TYPE ? 'Rate' : 'Spread',
        values: opportunity.terms?.rate?.spread,
      },
      {
        label: 'Length of Stream',
        values: opportunity.terms?.termLength,
      },
      {
        label: 'Frequency',
        values: opportunity.terms?.paymentPeriod,
      },
      {
        label: 'Policy Exception',
        values: opportunity.underwriting?.isPolicyException,
      },
      {
        label: 'Annual Servicing Period',
        values: opportunity.annualServicingPeriod,
      },
      {
        label: 'Product',
        values: product.prettyName,
      },
      {
        label: 'Prepayment Penalty',
        values: application.prepaymentPenalty,
      },
      {
        label: 'Primary Payment Source',
        values: application.primaryPaymentSource,
      },
      {
        label: 'Secondary Payment Source',
        values: application.secondaryPaymentSource,
      },
      {
        label: 'Tertiary Payment Source',
        values: application.tertiaryPaymentSource,
      },
      {
        label: 'Amortized Term (Months)',
        values: application.amortizedTermInMonths,
      },
    ];
  }, [
    application.amortizedTermInMonths,
    application.prepaymentPenalty,
    application.primaryPaymentSource,
    application.secondaryPaymentSource,
    application.tertiaryPaymentSource,
    opportunity.annualServicingPeriod,
    opportunity.loanType,
    opportunity.originationDate,
    opportunity.referenceYear,
    opportunity.terms?.paymentPeriod,
    opportunity.terms?.rate,
    opportunity.terms?.termLength,
    opportunity.underwriting?.additionalData?.loanSoldOnSecondaryMarket,
    opportunity.underwriting?.additionalData?.sbaGuarantyPercentage,
    opportunity.underwriting?.isPolicyException,
    product.prettyName,
    proposedLoanMonthlyPayment,
  ]);

  return (
    <SegmentContainer title={'Payment Stream'}>
      <Grid columns={3}>
        {termsData.map((data) => (
          <KeyValuePair key={data.label} name={data.label} value={data.values} type={data.type} />
        ))}
      </Grid>
    </SegmentContainer>
  );
};
