import React, { type FC, useContext, useEffect, useMemo, useState } from 'react';
import { LoadingPage } from '@lama/app-components';
import { Button, Flex, greyPalette, Text } from '@lama/design-system';
import { useToggle } from 'react-use';
import { sortBy } from 'lodash-es';

import { ApplicationContext } from '../../ApplicationContext';
import { useGetOpportunityAlertsQuery } from '../../../../shared/hooks/react-query/opportunity/useOpportunityAlertsQuery';
import { SectionAlert } from './SectionAlert';

export const DemoSectionAlerts: FC<{ sectionId: string }> = ({ sectionId }) => {
  const [showDismissed, toggleShowDismissed] = useToggle(true);
  const { opportunity } = useContext(ApplicationContext);
  const { data: alerts, isLoading } = useGetOpportunityAlertsQuery(opportunity?.id);

  const alertsWithIndex = useMemo(() => alerts?.map((alert, index) => ({ ...alert, index })), [alerts]);

  const [dismissedAlerts, setDismissedAlerts] = useState(localStorage.getItem('dismissedAlerts')?.split(',').map(Number));

  useEffect(() => {
    // Load the todos on mount
    const dismissedAlertsString = localStorage.getItem('dismissedAlerts');
    if (dismissedAlertsString) {
      const updatedDismissedAlerts = dismissedAlertsString.split(',').map(Number);
      setDismissedAlerts(updatedDismissedAlerts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem('dismissedAlerts')]);

  const sectionAlerts = useMemo(
    () =>
      sortBy(
        alertsWithIndex?.filter((alert) => alert.sectionId === sectionId),
        (a) => dismissedAlerts?.includes(a.index),
      ),
    [alertsWithIndex, dismissedAlerts, sectionId],
  );

  const filteredAlerts = useMemo(
    () =>
      showDismissed
        ? sectionAlerts
        : sortBy(
            sectionAlerts?.filter((alert) => !dismissedAlerts?.includes(alert.index)),
            (a) => dismissedAlerts?.includes(a.index),
          ),
    [sectionAlerts, dismissedAlerts, showDismissed],
  );

  if (!sectionAlerts?.length) {
    return null;
  }

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <Flex flexDirection={'column'} gap={2} border={`1px solid ${greyPalette[300]}`} borderRadius={'10px'} p={3}>
      <Flex flexDirection={'row'} gap={1} justifyContent={'space-between'}>
        <Text variant={'h6'}>{'Insights'}</Text>
        <Button variant={'tertiary'} color={'neutral'} onClick={toggleShowDismissed} size={'s'}>
          {showDismissed ? 'Hide Dismissed' : 'Show Dismissed'}
        </Button>
      </Flex>
      {filteredAlerts.map((alert) => (
        <SectionAlert key={`${alert.name}_${alert.index}`} alert={alert} alertIndex={alert.index} />
      ))}
    </Flex>
  );
};
