import React from 'react';
import type { FC } from 'react';
import type { UnderwritingNote } from '@lama/contracts';
import type { User } from '@lama/user-service-client';

import { NoteCard } from './NoteCard';

interface ExceptionCardProps {
  note: UnderwritingNote;
  user?: User;
  showSectionCrumbs?: boolean;
  deleteEnabled?: boolean;
}

export const DismissedFlagsCard: FC<ExceptionCardProps> = ({ note, user, showSectionCrumbs, deleteEnabled = false }) => (
  <NoteCard
    note={note}
    user={user}
    deleteEnabled={deleteEnabled}
    changeNoteTypeButtonsProps={[{ changeNoteTypeCTA: 'Flag Issue', noteTypeTarget: 'flag' }]}
    showSectionCrumbs={showSectionCrumbs}
  />
);
