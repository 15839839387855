import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import type { Collateral } from '@lama/contracts';
import { Flex, Grid, Text, greyPalette } from '@lama/design-system';
import { applicationCollateralSelector } from '@lama/data-formatters';
import type { InfoLineProps } from '@lama/app-components';
import { KeyValuePair } from '@lama/app-components';
import { ApplicationContext } from '../../ApplicationContext';
import { SegmentContainer } from '../SegmentContainer';
import { getInfoLine, getRealEstateInfo, getVehicleInfo, getInsuranceInfo } from './getCollateralDetails';

const CollateralItem: FC<{ collateral: Collateral }> = ({ collateral }) => {
  const { totalValue, type, description, insuranceDetails, id, cdDetails } = collateral;

  const collateralInfo = useMemo<InfoLineProps[]>(() => {
    if (cdDetails) {
      return [
        {
          label: 'Value',
          values: totalValue,
          type: 'currency',
        },
        {
          label: 'CD Account Number',
          values: cdDetails.cdNumber,
        },
        {
          label: 'CD Maturity Date',
          values: cdDetails.cdMaturityDate,
          type: 'date',
        },
      ];
    }

    const info = getInfoLine(collateral);

    if (insuranceDetails) {
      info.push(...getInsuranceInfo(collateral));
    }

    if (type === 'Commercial RE' || type === 'Personal Home') {
      return [...info, ...getRealEstateInfo(collateral)];
    } else if (type === 'Commercial Vehicle' || type === 'Personal Vehicle') {
      return [...info, ...getVehicleInfo(collateral)];
    }

    return info;
  }, [cdDetails, collateral, insuranceDetails, totalValue, type]);

  return (
    <SegmentContainer title={type}>
      <Flex flexDirection={'column'} gap={4}>
        <Grid columns={3}>
          {collateralInfo.map((line) => (
            <KeyValuePair key={`${id}_${line.label}`} name={line.label} value={line.values} type={line.type} />
          ))}
        </Grid>
        <KeyValuePair name={'Description'} value={description} />
      </Flex>
    </SegmentContainer>
  );
};

export const CollateralBreakdownSegment: FC = () => {
  const { application } = useContext(ApplicationContext);

  const collateral = useMemo(() => applicationCollateralSelector(application), [application]);

  if (!collateral) {
    return <Text variant={'body2'}>{'No information found'}</Text>;
  }

  if (!collateral.length) {
    return <Text variant={'body1'}>{'Applicant marked no collateral'}</Text>;
  }

  return (
    <Flex flexDirection={'column'} gap={4}>
      <Text variant={'h6'} color={greyPalette[500]}>
        {'Breakdown'}
      </Text>
      <Flex flexDirection={'column'} gap={12}>
        {collateral.map((c) => (
          <CollateralItem key={c.id} collateral={c} />
        ))}
      </Flex>
    </Flex>
  );
};
