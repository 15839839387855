/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useContext, useMemo } from 'react';
import type { Entity } from '@lama/common-types';
import type { EvaluatedRequirementSharedData, OpportunityClosingTask } from '@lama/contracts';
import type { DocumentUpdateModel, DocumentWithIssues } from '@lama/document-service-client';
import type { DocumentBoxProps as InnerDocumentBoxProps, OnMetadataUpdateProps } from '@lama/app-components';
import { ConfirmLeave, DocumentBox as DocumentBoxInner, useConfirmModal } from '@lama/app-components';
import type { DataNode } from '@lama/design-system';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useUpdateRequirement } from '../../hooks/react-query/requirement/useUpdateRequirement';
import { UserDetailsContext } from '../../context/UserDetailsContext';
import { useUpdateDocumentMutation } from '../../../components/Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/hooks/useUpdateDocumentMutation';
import { ApplicationContext } from '../../../components/Application/ApplicationContext';
import { useAddDocumentsToClosingTaskMutation } from '../../../components/Application/Closing/ClosingTasks/useAddDocumentsToClosingTaskMutation';
import { useRemoveDocumentFromClosingTaskMutation } from '../../../components/Application/Closing/ClosingTasks/useRemoveDocumentFromClosingTaskMutation';
import { useDeleteDocumentQuery } from './hooks/useDeleteDocumentQuery';
import { useUploadDocumentsMutation } from './hooks/useUploadDocumentsQuery';

interface DocumentBoxProps extends Partial<InnerDocumentBoxProps> {
  description?: string;
  topic?: DocumentWithIssues['topic'];
  document?: DocumentWithIssues;
  requirement?: EvaluatedRequirementSharedData;
  closingTask?: OpportunityClosingTask;
  entityId?: string;
  entityType?: Entity;
  applicationId?: string;
  sharedRequirementId?: string;
  requirementKey?: string | undefined;
  moveToTreeData?: DataNode[];
  extractable?: boolean;
  onClickMoveTo?: (moveToNode: DataNode) => Promise<void>;
  onUploadCompleted?: (documentId: string) => void;
  relatedItemId?: string;
  relatedItemType?: string;
}

export interface MoveToDataNode {
  requirement?: EvaluatedRequirementSharedData;
  closingTask?: OpportunityClosingTask;
  entityId?: string;
  entityType?: Entity;
  documentId?: string;
  topic?: string;
}

export const DocumentBox: React.FC<DocumentBoxProps> = ({
  requirement: { id: requirementId, sources } = {},
  topic: topicProp,
  entityId,
  entityType,
  document,
  dismissed,
  sharedRequirementId,
  requirementKey,
  description,
  moveToTreeData,
  extractable,
  onUploadCompleted,
  relatedItemId,
  relatedItemType,
  closingTask: { id: closingTaskId } = {},
}) => {
  const { showExtractionStatus } = useFlags();
  const {
    opportunity: { id: opportunityId },
    application: { id: applicationId },
  } = useContext(ApplicationContext);
  const { firstName, lastName } = useContext(UserDetailsContext);
  const { mutateAsync: uploadDocuments, isPending: uploadingDocuments } = useUploadDocumentsMutation({ applicationId, opportunityId });
  const { mutateAsync: deleteDocument, isPending: deletingDocument } = useDeleteDocumentQuery({
    applicationId,
    opportunityId,
  });
  const { mutateAsync: updateRequirement, isPending: updatingRequirement } = useUpdateRequirement(opportunityId ?? '', requirementId ?? '');
  const { mutateAsync: updateDocument, isPending: updatingDocument } = useUpdateDocumentMutation({ applicationId, opportunityId });
  const { mutateAsync: addOpportunityClosingTaskDocuments } = useAddDocumentsToClosingTaskMutation(opportunityId);
  const { mutateAsync: removeDocumentFromClosingTask } = useRemoveDocumentFromClosingTaskMutation(opportunityId);

  const { confirm } = useConfirmModal();

  const onClickMoveTo = useCallback(
    async (moveToNode: DataNode<MoveToDataNode>) => {
      if (!document) {
        return;
      }

      const needsReprocessing = document.relatedEntityId !== moveToNode.entityId && !!document.extractable;

      const message = needsReprocessing
        ? "You're about to move this document to a different entity. Please note that any data previously extracted from the document will be deleted, and the document will need to be reprocessed. Do you want to proceed?"
        : `Are you sure you want to move this document to ${moveToNode.title?.toString()}?`;

      const confirmed = await confirm({
        title: 'Move Document',
        cancelText: 'Cancel',
        confirmText: 'Move',
        message,
      });

      if (!confirmed) {
        return;
      }

      if (moveToNode.closingTask) {
        await addOpportunityClosingTaskDocuments({
          taskId: moveToNode.closingTask.id,
          documentIds: [document.id],
        });
      }

      if (closingTaskId && (!moveToNode.closingTask || closingTaskId !== moveToNode.closingTask?.id)) {
        await removeDocumentFromClosingTask({
          taskId: closingTaskId,
          documentId: document.id,
        });
      }

      const updateDocumentPayload: DocumentUpdateModel = {
        description: moveToNode.requirement?.name,
        topic: moveToNode.topic,
        relatedEntityId: moveToNode.entityId,
        relatedEntityType: moveToNode.entityType,
        requirementId: moveToNode.requirement?.id ?? '',
        sharedRequirementId: moveToNode.requirement?.referenceRequirementId ?? '',
        requirementKey: moveToNode.requirement?.key ?? '',
        closingTaskId: moveToNode.closingTask?.id,
      };

      if (needsReprocessing) {
        updateDocumentPayload.extracted = false;
        updateDocumentPayload.status = 'Uploaded';
      }

      await updateDocument({
        documentId: document.id,
        payload: updateDocumentPayload,
      });
    },
    [addOpportunityClosingTaskDocuments, closingTaskId, confirm, document, removeDocumentFromClosingTask, updateDocument],
  );

  const innerDescription = useMemo(() => (description || document?.description) ?? '', [description, document?.description]);
  const innerTopic = useMemo(() => (topicProp || document?.topic) ?? '', [topicProp, document?.topic]);
  const documentProcessing = useMemo(
    () => uploadingDocuments || deletingDocument || updatingDocument || updatingRequirement,
    [uploadingDocuments, deletingDocument, updatingDocument, updatingRequirement],
  );

  const onUploadDocument = useCallback(
    async (file: File) => {
      if (documentProcessing) {
        return;
      }

      if (!applicationId || !entityId || !entityType || !innerTopic || !innerDescription) {
        throw new Error(' required when adding a document, contact support');
      }

      const documentId = uuidv4();

      if (closingTaskId) {
        await addOpportunityClosingTaskDocuments({
          taskId: closingTaskId,
          documentIds: [documentId],
        });
      }

      await uploadDocuments([
        {
          documentId,
          file,
          topic: innerTopic,
          entityId,
          entityType,
          description: innerDescription,
          requirementId,
          applicationId,
          extractable,
          sharedRequirementId,
          requirementKey,
          relatedItemId,
          relatedItemType,
          closingTaskId,
        },
      ]);

      onUploadCompleted?.(documentId);
    },
    [
      documentProcessing,
      applicationId,
      entityId,
      entityType,
      innerTopic,
      innerDescription,
      closingTaskId,
      uploadDocuments,
      requirementId,
      extractable,
      sharedRequirementId,
      requirementKey,
      relatedItemId,
      relatedItemType,
      onUploadCompleted,
      addOpportunityClosingTaskDocuments,
    ],
  );

  const onDocumentMetadataChange = useCallback(
    async (metadata: OnMetadataUpdateProps) => {
      if (documentProcessing) {
        return;
      }

      const documentFilenameExtension = document?.filename?.split('.')?.at(-1);
      const updatedFileName = `${metadata.filename}.${documentFilenameExtension}`;

      const documentDescriptionChanged = document?.description !== metadata.description;
      const documentFilenameChanged = updatedFileName !== metadata.filename;

      const documentUpdatePayload = {
        ...(documentDescriptionChanged ? { description: metadata.description } : {}),
        ...(documentFilenameChanged ? { filename: updatedFileName } : {}),
      };

      if (document && Object.keys(documentUpdatePayload).length) {
        await updateDocument({ documentId: document.id, payload: documentUpdatePayload });
      }

      if (documentDescriptionChanged) {
        const uploadFileSourceUpdate = sources?.uploadFilesSource?.map((s) =>
          s.name === description ? { ...s, name: metadata.description } : s,
        );

        if (!uploadFileSourceUpdate) {
          toast.error('Error updating document description, please contact support');
          return;
        }

        await updateRequirement({
          updateRequirementPayload: {
            uploadFileSources: uploadFileSourceUpdate,
          },
        });
      }
    },
    [documentProcessing, document, updateDocument, sources?.uploadFilesSource, updateRequirement, description],
  );

  const onDeleteDocument = useCallback(async () => {
    if (document) {
      if (documentProcessing) {
        return;
      }

      const result = await confirm({
        title: 'Delete Document',
        message: 'Are you sure you want to delete this document?',
        cancelText: 'Cancel',
        confirmText: 'Delete',
        type: 'danger',
      });

      if (!result) {
        return;
      }

      await deleteDocument({ documentId: document?.id });
    }
  }, [deleteDocument, document, confirm, documentProcessing]);

  const onDismissDocument = useCallback(async () => {
    if (documentProcessing) {
      return;
    }

    if (!applicationId || !entityId) {
      throw new Error('required when dismissing a document, contact support');
    }

    const confirmed = await confirm({
      title: 'Dismiss Document',
      message: 'Are you sure this document is not needed for this application?',
    });

    if (!confirmed) {
      return;
    }

    const dismissal = {
      reason: 'Other',
      description: `Dismissed by ${firstName && lastName ? `${firstName} ${lastName}` : 'lender'}`,
    };

    const payload =
      relatedItemId && relatedItemType
        ? {
            dismissedDataByItemAndTopic: {
              [relatedItemId]: {
                [innerTopic]: dismissal,
              },
            },
          }
        : {
            dismissedDocumentsByTopic: {
              [innerTopic]: dismissal,
            },
          };

    await updateRequirement({
      updateRequirementPayload: {
        dataByEntity: {
          [entityId]: payload,
        },
      },
    });
  }, [
    documentProcessing,
    applicationId,
    entityId,
    confirm,
    firstName,
    lastName,
    relatedItemId,
    relatedItemType,
    updateRequirement,
    innerTopic,
  ]);

  const onUndismissDocument = useCallback(async () => {
    if (!entityId) {
      return;
    }

    const payload =
      relatedItemId && relatedItemType
        ? {
            dismissedDataByItemAndTopic: {
              [relatedItemId]: {
                [innerTopic]: null,
              },
            },
          }
        : {
            dismissedDocumentsByTopic: {
              [innerTopic]: {},
            },
          };

    await updateRequirement({
      updateRequirementPayload: {
        dataByEntity: {
          [entityId]: payload,
        },
      },
    });
  }, [entityId, innerTopic, relatedItemId, relatedItemType, updateRequirement]);

  return (
    <ConfirmLeave shouldBlock={documentProcessing}>
      <DocumentBoxInner
        description={innerDescription}
        document={document}
        dismissed={dismissed}
        onMetadataUpdate={onDocumentMetadataChange}
        onDocumentUpload={onUploadDocument}
        onDocumentRemoved={onDeleteDocument}
        onDismissSource={onDismissDocument}
        onUndismissSource={onUndismissDocument}
        moveToTreeData={moveToTreeData}
        onMoveToClick={onClickMoveTo}
        dismissible
        showExtractionStatus={showExtractionStatus}
      />
    </ConfirmLeave>
  );
};
