import React, { useCallback, useMemo } from 'react';
import { FormikAutoSave, FormikPicker } from '@lama/app-components';
import { Formik } from 'formik';
import type { FC } from 'react';
import { useUpdateDocumentMutation } from '../../../../OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/hooks/useUpdateDocumentMutation';

interface MappingPickerProps {
  documentId?: string;
  readonly?: boolean;
  nautilusMapping: string;
  applicationId: string;
  opportunityId: string;
}
export const underwritingOption = 'COMM-Underwriting';
const nautilusOptions = [
  'COMM-Agreement to Provide Insurance',
  'COMM-Application CIP',
  'COMM-Appraisal Environmental',
  'COMM-Approvals',
  'CUST-Business Tax Returns',
  'COMM-Collateral Filings and Searches',
  'COMM-Commercial Security Agreement',
  'COMM-Correspondence',
  'COMM-Credit Bureau',
  'COMM-Disclosures',
  'CUST-Financial Statements',
  'COMM-Flood',
  'COMM-Hazard Insurance',
  'COMM-Insurance and Tax Information',
  'COMM-Internal Documents',
  'COMM-Loan Documents',
  'COMM-Loan Information',
  'COMM-Miscellaneous',
  'COMM-Mortgage',
  'CUST-Organizational Documents',
  'COMM-Other Real Estate Documents',
  'COMM-Payoff Quote',
  'CUST-Personal Financial Info',
  'COMM-Purchase Agreements Invoices',
  'COMM-Title Work RE',
  underwritingOption,
];

export const NautilusMappingPicker: FC<MappingPickerProps> = ({ documentId, nautilusMapping, readonly }) => {
  const initialValues = useMemo(() => ({ nautilusMapping }), [nautilusMapping]);

  const { mutate: updateDocument } = useUpdateDocumentMutation();

  const onSubmit = useCallback(
    async (values: typeof initialValues) => {
      if (documentId) {
        updateDocument({
          documentId,
          payload: {
            exportConfiguration: {
              nautilusDocumentType: values.nautilusMapping,
            },
          },
        });
      }
    },
    [documentId, updateDocument],
  );

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {() => (
        <>
          <FormikAutoSave />
          <FormikPicker
            name={'nautilusMapping'}
            values={nautilusOptions}
            fullWidth
            // eslint-disable-next-line @typescript-eslint/naming-convention
            autoCompleteSx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
            disabled={readonly}
          />
        </>
      )}
    </Formik>
  );
};
