import React, { useCallback, useContext, useMemo } from 'react';
import type { FC } from 'react';
import { getApplicationEntityByType } from '@lama/properties';
import { mapValues } from 'lodash-es';
import { ButtonWithSaveIndication, ConfirmLeave, FormikDatePicker, FormikMoneyInputField } from '@lama/app-components';
import type { FormikHelpers } from 'formik';
import { Formik, type FormikProps } from 'formik';
import * as yup from 'yup';
import { Flex, Grid, Text } from '@lama/design-system';
import type { PersonApiModel } from '@lama/clients';
import { ApplicationContext } from '../../../ApplicationContext';
import { UserDetailsContext } from '../../../../../shared/context/UserDetailsContext';
import { useUpdatePerson } from '../../../../../shared/hooks/react-query/people/useUpdatePerson';
import { getFinancialFieldByYear, getFinancialPayload } from './financials/financialFieldUtils';
import type { RequirementScreenProps } from './types';
import { useSubmitFinancialsMutation } from './financials/hooks/useSubmitFinancialsMutation';

const assetsFieldNamesToFinancialAttribute: Record<string, { financialAttribute: string; displayName: string }> = {
  cash: { financialAttribute: 'Cash in Bank Accounts', displayName: 'Personal Cash' },
  securities: { financialAttribute: 'Marketable Securities', displayName: 'Personal Stocks & Bonds' },
  insurance: { financialAttribute: 'Life Insurance - Cash Surrender Value Only', displayName: 'Cash Surrender Value of Life Insurance' },
  personalIra: { financialAttribute: 'Personal IRA or Other Retirement Accounts', displayName: 'Retirement Accounts' },
  realEstateAssets: { financialAttribute: 'Personal Real Estate', displayName: 'Real Estate Owned' },
  otherAssets: { financialAttribute: 'Other Personal Assets', displayName: 'Other Assets' },
};

const liabilitiesFieldNamesToFinancialAttribute: Record<string, { financialAttribute: string; displayName: string }> = {
  creditCards: { financialAttribute: 'Personal Accounts Payable', displayName: 'Credit Cards' },
  installments: { financialAttribute: 'Other Personal Installment Accounts', displayName: 'Installment Loans' },
  realEstateLoans: { financialAttribute: 'Personal Mortgages on Real Estate', displayName: 'Real Estate Loans' },
  taxes: { financialAttribute: 'Personal Unpaid Taxes', displayName: 'Taxes Owed' },
  otherLiabilities: { financialAttribute: 'Other Personal Liabilities', displayName: 'Other Liabilities' },
};

interface SouthStatePersonalFinancialStatementScreenInnerFormProps {
  formikProps: FormikProps<any>;
  savingFinancial: boolean;
  financialsSuccessfullyUpdated: boolean;
}

const SouthStatePersonalFinancialStatementScreenInnerForm: FC<SouthStatePersonalFinancialStatementScreenInnerFormProps> = ({
  formikProps: { dirty, isValid, handleSubmit },
  savingFinancial,
  financialsSuccessfullyUpdated,
}) => (
  <ConfirmLeave shouldBlock={dirty}>
    <Flex flexDirection={'column'} gap={8}>
      <Flex flexDirection={'column'} gap={4}>
        <Flex width={'20%'}>
          <FormikDatePicker name={'asOf'} label={'As of'} />
        </Flex>
        <Text variant={'h6'}>{'Assets'} </Text>
        <Grid columns={3}>
          {Object.entries(assetsFieldNamesToFinancialAttribute).map(([fieldName, { displayName }]) => (
            <FormikMoneyInputField key={fieldName} name={fieldName} label={displayName} />
          ))}
        </Grid>
      </Flex>
      <Flex flexDirection={'column'} gap={4}>
        <Text variant={'h6'}>{'Liabilities'} </Text>
        <Grid columns={3}>
          {Object.entries(liabilitiesFieldNamesToFinancialAttribute).map(([fieldName, { displayName }]) => (
            <FormikMoneyInputField key={fieldName} name={fieldName} label={displayName} />
          ))}
        </Grid>
      </Flex>
      <ButtonWithSaveIndication
        loading={savingFinancial}
        onSave={handleSubmit}
        disabled={!dirty || !isValid}
        hasBeenSaved={financialsSuccessfullyUpdated}
      />
    </Flex>
  </ConfirmLeave>
);

export const SouthStatePersonalFinancialStatementScreen: FC<RequirementScreenProps> = ({ requirement }) => {
  const {
    application,
    opportunity: { referenceYear, id: opportunityId },
  } = useContext(ApplicationContext);

  const { userId } = useContext(UserDetailsContext);
  const {
    isPending: savingFinancials,
    mutateAsync: updateFinancialData,
    isSuccess: financialsSuccessfullyUpdated,
  } = useSubmitFinancialsMutation(requirement.entityId, requirement.entityType, opportunityId);
  const { mutateAsync: updatePerson, isPending: isUpdatingPerson } = useUpdatePerson(application.id);

  const person = useMemo(
    () =>
      getApplicationEntityByType(application, 'person', ['all']).find(({ id }) => id === requirement?.entityId) as
        | PersonApiModel
        | undefined,
    [application, requirement?.entityId],
  );

  const validationSchema = useMemo(() => yup.object(mapValues(assetsFieldNamesToFinancialAttribute, () => yup.number().nullable())), []);

  const initialValues = useMemo(
    () => ({
      ...mapValues(
        { ...assetsFieldNamesToFinancialAttribute, ...liabilitiesFieldNamesToFinancialAttribute },
        ({ financialAttribute }) =>
          getFinancialFieldByYear(person?.financials ?? [], referenceYear, financialAttribute)?.value?.toString() ?? '',
      ),
      asOf: person?.personalFinancialStatementAsOfDate ?? '',
    }),
    [person?.financials, person?.personalFinancialStatementAsOfDate, referenceYear],
  );

  const submitFinancial = useCallback(
    async (newValues: typeof initialValues, { resetForm }: FormikHelpers<any>) => {
      resetForm({ values: newValues });

      if (!userId) {
        return;
      }

      const financialsPayload = Object.entries(newValues)
        .filter(([fieldName]) => fieldName !== 'asOf')
        .flatMap(([fieldName, value]) =>
          getFinancialPayload({
            entityId: requirement.entityId,
            financials: person?.financials ?? [],
            year: referenceYear,
            fieldName:
              (assetsFieldNamesToFinancialAttribute[fieldName]?.financialAttribute ||
                liabilitiesFieldNamesToFinancialAttribute[fieldName]?.financialAttribute) ??
              '',
            value,
            userId,
            startDate: `${referenceYear}-01-01T00:00:00Z`,
            endDate: `${referenceYear}-12-31T00:00:00Z`,
          }),
        );

      if (financialsPayload.length) {
        await updateFinancialData(financialsPayload);
      }

      if (newValues.asOf && person?.id) {
        await updatePerson({ personId: person.id, updatePersonPayload: { personalFinancialStatementAsOfDate: newValues.asOf } });
      }
    },
    [userId, person?.id, person?.financials, requirement.entityId, referenceYear, updateFinancialData, updatePerson],
  );

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitFinancial}>
      {(formikProps) => (
        <SouthStatePersonalFinancialStatementScreenInnerForm
          formikProps={formikProps}
          savingFinancial={savingFinancials || isUpdatingPerson}
          financialsSuccessfullyUpdated={financialsSuccessfullyUpdated}
        />
      )}
    </Formik>
  );
};
