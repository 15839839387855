import { TextField, Autocomplete } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import type { MRT_Cell, MRT_Row } from 'material-react-table';
import type { DocumentLineItem } from '@lama/document-service-client';
import { useEffectOnce } from 'react-use';
import { isNil } from 'lodash-es';
import { FitToContentPopper } from '../../../../../shared/components/FitToContentPopper';

interface FinancialSpreadingCellPickerProps {
  onValueChanged: (cell: MRT_Cell<DocumentLineItem>, value: any) => void;
  options: { label: string; value: any }[];
  label: string;
  disableClear?: boolean;
  row: MRT_Row<DocumentLineItem>;
  cell: MRT_Cell<DocumentLineItem>;
  initialValue?: any;
}

export const FinancialSpreadingCellPicker: React.FC<FinancialSpreadingCellPickerProps> = ({
  onValueChanged,
  options,
  label,
  disableClear,
  row,
  cell,
  initialValue,
}) => {
  const [value, setValue] = React.useState(cell.getValue());

  const updateValue = useCallback(
    (newValue: any) => {
      row._valuesCache[cell.column.id] = newValue;
      setValue(newValue);
      onValueChanged(cell, newValue ?? null);
    },
    [cell, onValueChanged, row._valuesCache],
  );

  const onBlur = useCallback(
    async (event: React.FocusEvent<HTMLInputElement>) => {
      // MUI Autocomplete returns the label of the selected option in OnBlur event, instead of the value
      const selectedValue = options.find((item) => item.label === event.target.value)?.value;
      updateValue(selectedValue);
    },
    [options, updateValue],
  );

  useEffectOnce(() => {
    if ((isNil(value) || value === '') && !isNil(initialValue)) {
      updateValue(initialValue);
    }
  });

  const currentLabeledValue = useMemo(() => options.find((item) => item.value === value) ?? null, [value, options]);

  const renderInput = useCallback((params: any) => <TextField variant={'standard'} {...params} label={label} />, [label]);

  return (
    <Autocomplete
      PopperComponent={FitToContentPopper}
      options={options}
      value={currentLabeledValue}
      renderInput={renderInput}
      disableClearable={disableClear}
      onBlur={onBlur}
    />
  );
};
