import React, { useMemo } from 'react';
import type { OpportunityRequirement } from '@lama/contracts';
import type { FC } from 'react';
import { AuditLog } from '../../../AuditLog';
import type { AuditComponentProps } from '../types';

export const CreateOpportunityRequirementAuditLog: FC<AuditComponentProps> = ({ audit, groupInfo }) => {
  const createdRequirements = useMemo(
    () =>
      audit
        .map((entry) => {
          const opportunityRequirement = entry.context.entity as OpportunityRequirement;

          return opportunityRequirement.name;
        })
        .join(', '),
    [audit],
  );

  return (
    <AuditLog
      {...(groupInfo.originType === 'user'
        ? { originType: groupInfo.originType, user: groupInfo.user }
        : { originType: groupInfo.originType })}
      text={`Added requirement${createdRequirements.length ? 's' : ''} ${createdRequirements}`}
      timestamp={groupInfo.timestamp}
      customText={groupInfo.customText}
      labels={groupInfo.labels}
    />
  );
};
