import type { FC, ReactNode } from 'react';
import React, { Suspense, useCallback, useContext, useEffect, useMemo } from 'react';
import { LoadingPage, ComponentRestrictorContextProvider, useConfirmModal } from '@lama/app-components';
import { Outlet, useParams } from 'react-router-dom';
import { Flex, Text } from '@lama/design-system';
import mixpanel from 'mixpanel-browser';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isNil } from 'lodash-es';
import { getApprovalAndRecommendingPermissions, userHasAnyRoles } from '@lama/approval-flows';
import { useProductQuery } from '../../shared/hooks/react-query/product/useProductQuery';
import { useGetOpportunityQuery } from '../../shared/hooks/react-query/opportunity/useGetOpportunityQuery';
import { BackLink } from '../../shared/components/BackLink';
import { useOpportunityPropertiesQuery } from '../../shared/hooks/react-query/opportunity/useOpportunityPropertiesQuery';
import { ProductionApplicationIndication } from '../../shared/components/ProductionApplicationIndication';
import { NotificationCenter } from '../../shared/components/NotificationCenter/NotificationCenter';
import { UserDetailsContext } from '../../shared/context/UserDetailsContext';
import { useUpdateOpportunityMutation } from '../../shared/hooks/react-query/opportunity/useUpdateOpportunityMutation';
import { useUserPartner } from '../../shared/hooks/useCurrentPartner';
import { ApplicationContext, ApplicationProvider } from './ApplicationContext';
import { ApplicationTabs } from './ApplicationTabs';
import { ApplicationHeader } from './ApplicationHeader/ApplicationHeader';
import { SmartAssistant } from './shared/SmartAssistant/SmartAssistant';
import { SearchButton } from './ApplicationSearch/SearchButton';

declare global {
  interface Window {
    opportunity?: {
      opportunityId: string;
      applicationId: string;
      productId: string;
      partnerId: string;
    };
  }
}
const revokePermissionsOnApprovalModal = {
  title: 'You are about to access information restricted by Regulation 1071',
  message:
    'Accessing this information disqualifies you from making decisions on this application, or on any current or future applications submitted by this applicant.',
};

const doNotRevokePermissionsOnApprovalModal = {
  title: 'Please read the following statement to the applicant',
  message:
    'While you are not required to provide this information, we encourage you to do so. Importantly, our staff are not permitted to discourage you in any way from responding to these questions. Filling out this form will help to ensure that ALL small business owners are treated fairly.',
};

const OpportunityDataHelper: FC = () => {
  const { opportunity } = useContext(ApplicationContext);

  useEffect(() => {
    if (window.opportunity?.opportunityId !== opportunity.id) {
      window.opportunity = {
        opportunityId: opportunity.id,
        applicationId: opportunity.application.id,
        productId: opportunity.productId,
        partnerId: opportunity.partnerId,
      };
    }
  }, [opportunity]);

  return null;
};

const ApplicationLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const { searchApplication } = useFlags();

  return (
    <Flex flexDirection={'column'} px={10} pt={10} pb={24} gap={4}>
      <Flex justifyContent={'space-between'} alignItems={'center'}>
        <BackLink title={'Back to Pipeline'} to={'/pipeline'} state={{ preserved: true }} />
        <Flex gap={4}>
          {searchApplication ? <SearchButton /> : null}
          <NotificationCenter />
        </Flex>
      </Flex>
      <Flex flexDirection={'column'} gap={5}>
        {children}
      </Flex>
    </Flex>
  );
};

export const OpportunityPage: FC = () => {
  const { opportunityId } = useParams();
  const { confirm } = useConfirmModal();
  const { userData: user } = useContext(UserDetailsContext);
  const partner = useUserPartner();
  const { data: opportunity, isPending: loadingOpportunity } = useGetOpportunityQuery(opportunityId);
  const { data: properties, isPending: loadingProperties } = useOpportunityPropertiesQuery(opportunityId);
  const { data: product, isPending: loadingProduct } = useProductQuery(opportunity?.productId);
  const { mutate: updateOpportunity } = useUpdateOpportunityMutation(opportunityId);
  const section1071ValueExposureRevokesPermissions = useMemo(
    () => partner?.featureConfigurations?.section1071ValueExposureRevokesPermissions,
    [partner],
  );
  const onValueExposed = useCallback(async () => {
    if (!user?.id) {
      return;
    }
    updateOpportunity({
      permissionsOverrides: { ...opportunity?.permissionsOverrides, [user?.id]: !section1071ValueExposureRevokesPermissions },
    });
  }, [opportunity?.permissionsOverrides, updateOpportunity, user?.id, section1071ValueExposureRevokesPermissions]);

  const isAllowedToSeeValueHandler = useCallback(async () => {
    if (!opportunity || !product || !user) {
      return false;
    }
    const approvalPermissionsForOpportunity = getApprovalAndRecommendingPermissions(opportunity.partnerId, { opportunity, product });
    if (userHasAnyRoles(user, approvalPermissionsForOpportunity)) {
      const userConfirmedPreviousDataExposure = !isNil(opportunity.permissionsOverrides?.[user.id]);
      // If we already made a decision about the user's permissions for this application, we don't need to ask again
      if (userConfirmedPreviousDataExposure) {
        return true;
      }
      const confirmationMessage =
        partner?.restrictedDataExposureDisclaimer ??
        (section1071ValueExposureRevokesPermissions ? revokePermissionsOnApprovalModal : doNotRevokePermissionsOnApprovalModal);
      return confirm(confirmationMessage);
    }
    return true;
  }, [opportunity, product, user, partner?.restrictedDataExposureDisclaimer, section1071ValueExposureRevokesPermissions, confirm]);

  if (loadingOpportunity || loadingProduct || loadingProperties) {
    return <LoadingPage />;
  }

  if (!opportunity || !product || !properties) {
    return (
      <ApplicationLayout>
        <Flex justifyContent={'center'} alignItems={'center'} height={'100%'}>
          <Text variant={'h4'}>{'Application not found'}</Text>
        </Flex>
      </ApplicationLayout>
    );
  }

  mixpanel.track_pageview({
    applicationId: opportunity.application.id,
    opportunityId,
    productId: opportunity.productId,
  });
  return (
    <ApplicationProvider product={product} opportunity={opportunity} properties={properties}>
      <ComponentRestrictorContextProvider value={{ isAllowedToSeeValueHandler, onValueExposed }}>
        <OpportunityDataHelper />
        <ApplicationLayout>
          <ProductionApplicationIndication />
          <ApplicationHeader />
          <Flex flexDirection={'column'} gap={8}>
            <ApplicationTabs />
            <Suspense fallback={<LoadingPage />}>
              <Outlet />
            </Suspense>
          </Flex>
          <SmartAssistant />
        </ApplicationLayout>
      </ComponentRestrictorContextProvider>
    </ApplicationProvider>
  );
};
