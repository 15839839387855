import type { FC } from 'react';
import React from 'react';
import { Flex, Grid, Text } from '@lama/design-system';
import type { VirtualAssets } from '@lama/contracts';
import { isNil } from 'lodash-es';
import type { InfoLineProps } from '../../Information';
import { KeyValuePair } from '../../KeyValuePair';

interface VirtualAssetItemProps {
  item: VirtualAssets;
}

export const VirtualAssetItem: FC<VirtualAssetItemProps> = ({ item: { virtualAssetsType, walletAddresses, howDigitalAssetsStored } }) => {
  const itemData: InfoLineProps[] = [
    ...(!isNil(walletAddresses) ? [{ label: 'Wallet Addresses', values: walletAddresses, type: 'string' as const }] : []),
    ...(!isNil(howDigitalAssetsStored)
      ? [{ label: 'Digital Assets Storage', values: howDigitalAssetsStored, type: 'string' as const }]
      : []),
  ];

  return (
    <Flex gap={4} flexDirection={'column'}>
      <Text variant={'body1'} weight={700} color={'secondary'}>
        {virtualAssetsType}
      </Text>
      <Grid columns={2}>
        {itemData.map((line) => (
          <KeyValuePair key={line.label} name={line.label} value={line.values} type={line.type} />
        ))}
      </Grid>
    </Flex>
  );
};
