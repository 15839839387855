import { Flex, Text } from '@lama/design-system';
import styled from 'styled-components';
import React, { useCallback } from 'react';
import type { FC } from 'react';

export type FieldFilter = 'all' | 'lowConfidence' | 'missing';

const StyleFieldFilterContainer = styled(Flex)<{ active: boolean }>`
  &:hover {
    cursor: pointer;
    background-color: #f5f5f5;
  }
  background-color: ${({ active }) => (active ? '#F5F5F5' : 'transparent')};
`;

export const FieldFilterButton: FC<{
  filter: FieldFilter;
  active: boolean;
  text: string;
  count?: number;
  setFilter: (filter: FieldFilter) => void;
}> = ({ filter, count, active, setFilter, text }) => {
  const onClick = useCallback(() => {
    setFilter(filter);
  }, [filter, setFilter]);

  return (
    <StyleFieldFilterContainer onClick={onClick} flexDirection={'column'} active={active} px={1} py={0.5}>
      <Text variant={'body4'} color={'secondary'}>
        {`${text} ${count ? `(${count})` : ''}`}
      </Text>
    </StyleFieldFilterContainer>
  );
};
