import React, { useContext, useMemo } from 'react';
import type { Entity } from '@lama/common-types';
import type { OpportunityClosingTask } from '@lama/contracts';
import { Flex } from '@lama/design-system';
import { getEnabledFileUploadSources } from '@lama/evaluations';
import type { Document } from '@lama/document-service-client';
import { useIndependentDocumentsMoveToTree } from '../../../DocumentHub/hooks/useIndependentDocumentsMoveToTree';
import { ApplicationContext } from '../../ApplicationContext';
import { DocumentBox } from '../../../../shared/components/DocumentBox/DocumentBox';
import { ArbitraryClosingTaskDocumentUpload } from './ArbitraryClosingTaskDocumentUpload';

interface AdditionalDocumentsSectionProps {
  documents: Document[];
  taskDocumentRequirements: OpportunityClosingTask['requiredDocuments'];
  relatedEntityId?: string;
  relatedEntityType?: Entity;
  closingTask: OpportunityClosingTask;
}

export const AdditionalDocumentsSection = ({
  documents,
  taskDocumentRequirements,
  relatedEntityId,
  relatedEntityType,
  closingTask,
}: AdditionalDocumentsSectionProps) => {
  const { opportunity, application } = useContext(ApplicationContext);
  const moveToTreeData = useIndependentDocumentsMoveToTree({
    applicationId: application.id,
    opportunityId: opportunity.id,
    grouping: 'topic',
  });
  const entityDocuments = useMemo(
    () => documents.filter((document) => (relatedEntityId ? document.relatedEntityId === relatedEntityId : true)),
    [documents, relatedEntityId],
  );

  const documentBoxes = useMemo(() => {
    const enabledSources = getEnabledFileUploadSources({ sources: taskDocumentRequirements ?? [], entityId: relatedEntityId, application });

    const predefinedDocumentsComponents = enabledSources.map(({ name: documentName, topic }) => (
      <DocumentBox
        key={documentName}
        description={documentName}
        topic={topic}
        document={entityDocuments.find((document) => document.topic === topic)}
        entityId={relatedEntityId}
        entityType={relatedEntityType}
        applicationId={application.id}
        closingTask={closingTask}
        moveToTreeData={moveToTreeData}
      />
    ));

    const topics = new Set(enabledSources.map((requirement) => requirement.topic));
    const arbitraryDocumentsComponents = documents
      .filter(
        (document) =>
          (!document.topic || !topics.has(document.topic)) && (!relatedEntityId || document.relatedEntityId === relatedEntityId),
      )
      .map((document) => (
        <DocumentBox
          key={document.id}
          description={document.filename}
          topic={document.topic}
          document={document}
          entityId={relatedEntityId}
          entityType={relatedEntityType}
          applicationId={application.id}
          closingTask={closingTask}
          moveToTreeData={moveToTreeData}
        />
      ));

    return [...predefinedDocumentsComponents, ...arbitraryDocumentsComponents];
  }, [application, closingTask, documents, entityDocuments, moveToTreeData, relatedEntityId, relatedEntityType, taskDocumentRequirements]);

  return (
    <Flex flexDirection={'column'} gap={4}>
      {documentBoxes}
      <Flex p={1} borderRadius={'8px'}>
        <ArbitraryClosingTaskDocumentUpload
          opportunityId={opportunity.id}
          applicationId={application.id}
          taskId={closingTask.id}
          relatedEntityId={relatedEntityId}
          relatedEntityType={relatedEntityType}
        />
      </Flex>
    </Flex>
  );
};
