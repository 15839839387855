import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import type { SpreadRow } from '@lama/spreads-generator-client';
import type { Period } from '@lama/selectors';
import { Text } from '@lama/design-system';
import { formatSpreadCellValue } from '../../utils/formatSpreadCellValue';
import { ApplicationContext } from '../../../../ApplicationContext';

export const PrintSpreadRow: FC<{ spreadRow: SpreadRow; displayedPeriods: Period[] }> = ({
  spreadRow: { entityId, attributeDisplayName, attributeHierarchy, highlight, propertyKey, valueByPeriod },
  displayedPeriods,
}) => {
  const { properties } = useContext(ApplicationContext);

  const property = useMemo(() => (propertyKey ? properties[propertyKey] : null), [properties, propertyKey]);

  const indentLevel = attributeHierarchy.length > 1 ? attributeHierarchy.length - 1 : 0;
  const rowKey = `${attributeDisplayName}-${entityId}`;

  const paddingLeft = `${8 + (indentLevel > 0 ? indentLevel * 16 : 0)}px`;

  return (
    <tr key={rowKey} className={`table-row ${highlight ? 'highlight' : ''}`}>
      <td style={{ paddingLeft }}>{attributeDisplayName}</td>
      {displayedPeriods.map((period) => {
        const periodKey = `${period.startDate}-${period.endDate}`;
        const financialAttribute = valueByPeriod[periodKey]?.financialAttribute;

        return (
          <td key={periodKey}>
            <Text variant={'body3'}>
              {financialAttribute !== null ? formatSpreadCellValue(financialAttribute?.value, property?.type) : '-'}
            </Text>
          </td>
        );
      })}
    </tr>
  );
};

export const PrintSpreadTableBody = ({ spreadRows, displayedPeriods }: { spreadRows: SpreadRow[]; displayedPeriods: Period[] }) => (
  <tbody>
    {spreadRows.map((spreadRow) => (
      <PrintSpreadRow
        key={`${spreadRow.attributeDisplayName}-${spreadRow.entityId}`}
        spreadRow={spreadRow}
        displayedPeriods={displayedPeriods}
      />
    ))}
  </tbody>
);
