import React, { useCallback, useContext } from 'react';
import type { FC } from 'react';
import { Flex, greyPalette, Text } from '@lama/design-system';
import { isBefore, startOfMonth, endOfMonth, isAfter } from 'date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker as DatePickerInnerComponent } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AccountsFilterContext } from './AccountsFilterContext';

export const TimeRangeFilter: FC<{ lastUpdatedAt?: Date }> = ({ lastUpdatedAt = new Date() }) => {
  const { startDate, setStartDate, endDate, setEndDate } = useContext(AccountsFilterContext);

  const onStartDateChange = useCallback(
    (date: Date | null) => {
      if (!date) {
        return;
      }

      const startOfMonthDate = startOfMonth(date);
      setStartDate(startOfMonthDate);

      if (isAfter(startOfMonthDate, endDate)) {
        const endOfMonthDate = endOfMonth(startOfMonthDate);
        setEndDate(endOfMonthDate);
      }
    },
    [endDate, setEndDate, setStartDate],
  );

  const onEndDateChange = useCallback(
    (date: Date | null) => {
      if (!date) {
        return;
      }

      const endOfMonthDate = endOfMonth(date);
      setEndDate(endOfMonthDate);

      if (isBefore(endOfMonthDate, startDate)) {
        const startOfMonthDate = startOfMonth(endOfMonthDate);
        setStartDate(startOfMonthDate);
      }
    },
    [setEndDate, setStartDate, startDate],
  );

  return (
    <Flex flexDirection={'column'} gap={2}>
      <Flex gap={8}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePickerInnerComponent
            disableFuture
            label={'Start Date'}
            value={startDate}
            onChange={onStartDateChange}
            slotProps={{
              textField: {
                fullWidth: true,
              },
            }}
            maxDate={startOfMonth(lastUpdatedAt)}
            views={['year', 'month']}
            openTo={'month'}
          />
          <DatePickerInnerComponent
            disableFuture
            label={'End Date'}
            value={endDate}
            onChange={onEndDateChange}
            slotProps={{
              textField: {
                fullWidth: true,
              },
            }}
            maxDate={endOfMonth(lastUpdatedAt)}
            views={['year', 'month']}
            openTo={'month'}
          />
        </LocalizationProvider>
      </Flex>
      <Text variant={'body3'} color={greyPalette[500]}>
        {`Plaid data will be filtered for the chosen time range. Data was last updated at ${lastUpdatedAt.toLocaleDateString()}.`}
      </Text>
    </Flex>
  );
};
