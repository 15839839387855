import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { isNil } from 'lodash-es';
import { proposedLoanAmount as proposedLoanAmountSelector } from '@lama/data-formatters';
import { annualRateSelector, monthlyPaymentSelector } from '@lama/selectors';
import { Grid } from '@lama/design-system';
import { KeyValuePair } from '@lama/app-components';
import { ApplicationContext } from '../../ApplicationContext';
import { SegmentContainer } from '../SegmentContainer';

export const GCLoanTermsDetailsSegment: FC = () => {
  const { application, opportunity } = useContext(ApplicationContext);

  const proposedLoanAmount = useMemo(() => proposedLoanAmountSelector(application), [application]);

  const proposedLoanMonthlyPayment = useMemo(() => {
    const annualRate = annualRateSelector(application);

    if (isNil(annualRate) || !opportunity.terms?.termLength || isNil(proposedLoanAmount)) {
      return null;
    }

    if (annualRate === 0) {
      return proposedLoanAmount / opportunity.terms.termLength;
    }

    return monthlyPaymentSelector(annualRate, opportunity.terms.termLength, proposedLoanAmount);
  }, [application, opportunity.terms?.termLength, proposedLoanAmount]);

  return (
    <SegmentContainer title={'Details'}>
      <Grid columns={2}>
        <KeyValuePair name={'Proposed Loan Amount'} value={proposedLoanAmount} type={'currency'} />
        <KeyValuePair name={'Monthly Payment'} value={proposedLoanMonthlyPayment} type={'currency'} />
      </Grid>
    </SegmentContainer>
  );
};
