import type { DocumentLineItem } from '@lama/document-service-client';
import { sortBy } from 'lodash-es';

const minY = (lineItem: DocumentLineItem): number =>
  lineItem.textBoundingBox?.top ?? Math.min(...lineItem.values.map((value) => value.boundingBox?.top ?? 0));

export const sortDocumentLineItems = (documentLineItems: DocumentLineItem[]): DocumentLineItem[] =>
  sortBy(
    documentLineItems,
    ({ page }) => page,
    ({ lineIndex }) => lineIndex,
    minY,
  );
