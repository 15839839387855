import React, { useState, useCallback, useMemo, useContext } from 'react';
import { useConfirmModal } from '@lama/app-components';
import { Flex, Button } from '@lama/design-system';
import type {
  DocumentLineItem,
  UpdateDocumentLineItemsBody,
  UpdateDocumentLineItemBody,
  DocumentLineItemValue,
} from '@lama/document-service-client';
import { Edit } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { compact, groupBy, isNil } from 'lodash-es';
import type { MRT_Row, MRT_Cell, MRT_ColumnDef, MRT_TableInstance, MRT_TableOptions } from 'material-react-table';
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';
import { createAttributeColumns, createPeriodColumns } from '../utils/createTableColumns';
import { SpreadingDocumentContext } from '../../../SpreadingDocumentContext';
import { SpreadingDocumentSetHighlightContext } from '../../../SpreadingDocumentHighlightContext';
import { AddColumnButton } from './AddColumnButton';
import { AddRowButton } from './AddRowButton';
import type { Period } from './FinancialStatementsSpreadingContent';

export const FinancialStatementTable: FC<{
  tableData: DocumentLineItem[];
  tablePeriods: Period[];
  updateRows: (updatePayload: UpdateDocumentLineItemsBody['lineItemUpdates']) => Promise<void>;
  createRow: (row: MRT_Row<DocumentLineItem>, values: Partial<DocumentLineItem>) => Promise<void>;
  deleteRow: (id: string) => Promise<void>;
  addColumn: (period: Period) => void;
  getDateCellUpdatePayload: (cell: MRT_Cell<DocumentLineItem>, value: string) => DocumentLineItemValue[] | null;
  formType: string;
  formDocumentPageNumbers: number[];
  onDeletePeriod?: (period: Period) => void;
}> = ({
  tableData: tableLineItems,
  tablePeriods,
  updateRows,
  deleteRow,
  createRow,
  formType,
  formDocumentPageNumbers,
  addColumn,
  getDateCellUpdatePayload,
  onDeletePeriod,
}) => {
  const updateHighlightArea = useContext(SpreadingDocumentSetHighlightContext);
  const { saving } = useContext(SpreadingDocumentContext);
  const [updateLineItemsPayload, setUpdateLineItemsPayload] = useState<UpdateDocumentLineItemsBody['lineItemUpdates']>([]);
  const [editMode, toggleEditMode] = useToggle(false);
  const [creatingRow, toggleCreatingRow] = useToggle(false);
  const { confirm } = useConfirmModal();
  const { t } = useTranslation();

  const onCellFocus = useCallback(
    (cell: MRT_Cell<DocumentLineItem>) => {
      const lineItem = cell.row.original;

      if (!lineItem.id) {
        return;
      }

      updateHighlightArea({ ...lineItem.textBoundingBox, pageIndex: lineItem.page });

      const focusedDateColumnCell = lineItem.values.find(({ value }) => value === cell.getValue());

      if (focusedDateColumnCell) {
        updateHighlightArea({ ...focusedDateColumnCell?.boundingBox, pageIndex: lineItem.page });
      }
    },
    [updateHighlightArea],
  );

  const onEditLineItem = useCallback(
    (lineItemId: string, updateLineItemPayload: UpdateDocumentLineItemBody) => {
      const lineItemPreviousUpdate = updateLineItemsPayload.find((item) => item.id === lineItemId) ?? {};
      const otherLineItems = updateLineItemsPayload.filter((item) => item.id !== lineItemId);
      setUpdateLineItemsPayload([...otherLineItems, { id: lineItemId, ...lineItemPreviousUpdate, ...updateLineItemPayload }]);
      updateHighlightArea(null);
    },
    [updateLineItemsPayload, updateHighlightArea],
  );

  const onEditLineItemValue = useCallback(
    async (cell: MRT_Cell<DocumentLineItem>, value: string) => {
      if (!creatingRow) {
        const updatedValues = getDateCellUpdatePayload(cell, value);
        if (updatedValues) {
          onEditLineItem(cell.row.original.id, { values: updatedValues });
        }
      }

      updateHighlightArea(null);
    },
    [creatingRow, updateHighlightArea, getDateCellUpdatePayload, onEditLineItem],
  );

  const updateCellValue = useCallback(
    async (cell: MRT_Cell<DocumentLineItem>, value: any) => {
      const { id } = cell.row.original;
      const attribute = cell.column.id;
      const lineItem = tableLineItems.find((item) => item.id === id);
      if (lineItem) {
        onEditLineItem(id, { [attribute]: value });
      }
    },
    [onEditLineItem, tableLineItems],
  );

  const columns = useMemo<MRT_ColumnDef<DocumentLineItem>[]>(() => {
    const lineItemAttributeColumns = createAttributeColumns(
      tableLineItems,
      formType,
      onCellFocus,
      updateCellValue,
      formDocumentPageNumbers,
    );
    const periodColumns = createPeriodColumns(tableLineItems, tablePeriods, onCellFocus, onEditLineItemValue, onDeletePeriod);

    return [...lineItemAttributeColumns, ...periodColumns];
  }, [formDocumentPageNumbers, formType, onCellFocus, onDeletePeriod, onEditLineItemValue, tableLineItems, tablePeriods, updateCellValue]);

  const onCreateLineItem = useCallback(
    async ({
      row,
      values,
      table,
    }: {
      table: MRT_TableInstance<DocumentLineItem>;
      row: MRT_Row<DocumentLineItem>;
      values: Partial<DocumentLineItem>;
    }) => {
      await createRow(row, values);
      table.setCreatingRow(null);
      toggleCreatingRow(false);
      toggleEditMode(false);
    },
    [createRow, toggleCreatingRow, toggleEditMode],
  );

  const onEditModeClick = useCallback(async () => {
    if (editMode) {
      await updateRows(updateLineItemsPayload);
      setUpdateLineItemsPayload([]);
    }
    toggleEditMode();
  }, [editMode, toggleEditMode, updateRows, updateLineItemsPayload]);

  const onAddRowClick = useCallback(() => {
    toggleCreatingRow();
    toggleEditMode(true);
  }, [toggleCreatingRow, toggleEditMode]);

  const renderToolbarCustomActions = useCallback(
    ({ table }: { table: MRT_TableInstance<DocumentLineItem> }) => (
      <Flex>
        <Button variant={'tertiary'} startIcon={<Edit />} onClick={onEditModeClick} loading={saving}>
          {editMode ? 'Save & Exit' : 'Edit'}
        </Button>
        <AddRowButton table={table} disabled={creatingRow} onClick={onAddRowClick} />
        <AddColumnButton table={table} onAddColumn={addColumn} />
      </Flex>
    ),
    [onEditModeClick, saving, editMode, creatingRow, onAddRowClick, addColumn],
  );

  const onDeleteLineItem = useCallback(
    async ({ row }: { row: MRT_Row<DocumentLineItem> }) => {
      const childrenOfThisLineItem = tableLineItems?.filter((lineItem) => lineItem.parentId === row.original?.id);

      const result = await confirm({
        title: t('deleteDocumentLineItemConfirmModal.title'),
        message: `This line item has ${childrenOfThisLineItem?.length} children. Are you sure you want to delete it?`,
        cancelText: t('deleteDocumentLineItemConfirmModal.cancelText'),
        confirmText: t('deleteDocumentLineItemConfirmModal.confirmText'),
      });

      if (!result) {
        return;
      }

      await deleteRow(row.original.id);
    },
    [tableLineItems, confirm, t, deleteRow],
  );

  const renderRowActionMenuItems = useCallback(
    ({ row, closeMenu }: { row: MRT_Row<DocumentLineItem>; closeMenu: () => void }) => [
      <MenuItem
        key={'delete'}
        // eslint-disable-next-line react/jsx-no-bind
        onClick={() => {
          closeMenu();
          return onDeleteLineItem({ row });
        }}
      >
        {'Delete'}
      </MenuItem>,
    ],
    [onDeleteLineItem],
  );

  const muiRowDragHandleProps = useCallback(
    ({ table }: { table: MRT_TableInstance<DocumentLineItem> }) => ({
      onDragEnd: async () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          const draggingIndex = draggingRow.index;
          const hoveredIndex = (hoveredRow as MRT_Row<DocumentLineItem>).index;

          const updatedTableLines = [...tableLineItems];
          const [draggedRow] = updatedTableLines.splice(draggingIndex, 1);

          if (!draggedRow) {
            return;
          }

          // Insert the draggingRow after the hoveringRow
          const newIndex = hoveredIndex < draggingIndex ? hoveredIndex + 1 : hoveredIndex;
          draggingRow.index = newIndex;
          updatedTableLines.splice(newIndex, 0, draggedRow);

          const updateLineItemPayloadWithNewOrderIndex = compact(
            Object.entries(groupBy(updatedTableLines, ({ page }) => page)).flatMap(([page, pageLineItems]) =>
              !isNil(page)
                ? pageLineItems.map((lineItem, index) => (index !== lineItem.lineIndex ? { id: lineItem.id, lineIndex: index } : null))
                : [],
            ),
          );

          await updateRows(updateLineItemPayloadWithNewOrderIndex);
        }
      },
    }),
    [tableLineItems, updateRows],
  );

  const tableOptions: MRT_TableOptions<DocumentLineItem> = useMemo(
    () => ({
      autoResetPageIndex: false,
      renderRowActionMenuItems,
      positionActionsColumn: 'last',
      enableRowActions: true,
      onCreatingRowSave: onCreateLineItem,
      onCreatingRowCancel: toggleCreatingRow,
      columns,
      data: tableLineItems,
      createDisplayMode: 'row',
      editDisplayMode: 'table',
      muiTableContainerProps: {
        sx: { overflow: 'auto', maxHeight: '500px' },
      },
      enableTableFooter: false,
      enableStickyHeader: true,
      enableStickyFooter: false,
      enablePagination: false,
      enableSorting: false,
      enableRowOrdering: !editMode,
      renderTopToolbarCustomActions: renderToolbarCustomActions,
      enableEditing: editMode,
      muiRowDragHandleProps,
    }),
    [
      renderRowActionMenuItems,
      onCreateLineItem,
      toggleCreatingRow,
      columns,
      tableLineItems,
      editMode,
      renderToolbarCustomActions,
      muiRowDragHandleProps,
    ],
  );

  const table = useMaterialReactTable<DocumentLineItem>(tableOptions);

  return <MaterialReactTable table={table} />;
};
