/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unstable-nested-components */
import React, { useCallback, useContext, useMemo, useState } from 'react';
import type { FC } from 'react';
import { Button, Flex, Text, bluePalette, yellowPalette } from '@lama/design-system';
import { Cancel, ErrorOutlineRounded, MailOutline } from '@mui/icons-material';
import { useConfirmModal } from '@lama/app-components';
import { useTranslation } from 'react-i18next';
import { ApplicationContext } from '../ApplicationContext';
import { useSendIncompletenessLetterMutation } from '../../../shared/hooks/react-query/useSendIncompletenessLetterMutation';
import { useSendRejectionEmailMutation } from '../../../shared/hooks/react-query/useSendRejectionEmailMutation';
import { useUpdateOpportunityMutation } from '../../../shared/hooks/react-query/opportunity/useUpdateOpportunityMutation';
import { usePullCreditReport } from '../../../shared/hooks/react-query/creditReport/usePullCreditReport';
import { useSubmitApplicationMutation } from '../../../shared/hooks/react-query/application/useSubmitApplicationMutation';
import { SendEmailDialog } from '../../../shared/components/SendEmailDialog/SendEmailDialog';
import { DismissApplicationDialog } from '../ApplicationHeader/ApplicationWorkflow/DismissApplicationDialog';
import { UserDetailsContext } from '../../../shared/context/UserDetailsContext';
import { isUserDecisionMaker, isUserRecommender } from '../ApplicationHeader/ApplicationWorkflow/isUserDecisionMaker';
import { useUpdateApplicationMutation } from '../../../shared/hooks/react-query/application/useUpdateApplication';
import WithdrawIcon from './withdrawIcon.svg';
import { GrantCompleteApplicationExtensionModal } from './GrantCompleteApplicationExtensionModal';
import { ClearFraudulentActivityModal } from './OpportunityAlerts/ClearFraudulentActivityModal';

interface OpportunityAlertProps {
  message: string;
  actions: string[];
  level?: string;
  params: Record<string, any>;
}

export const OpportunityAlertView: FC<OpportunityAlertProps> = ({ message, actions, level, params }) => {
  const {
    application: { id: applicationId, status },
    opportunity,
    product,
  } = useContext(ApplicationContext);
  const { userData: user } = useContext(UserDetailsContext);

  const { id: opportunityId } = opportunity;
  const { mutateAsync: sendLetterOfIncompleteness, isPending: isSendingLetterOfIncompleteness } =
    useSendIncompletenessLetterMutation(opportunityId);
  const { mutateAsync: sendRejectionEmail, isPending: isSendingRejectionEmail } = useSendRejectionEmailMutation(
    applicationId,
    opportunityId,
  );
  const { mutate: updateOpportunity } = useUpdateOpportunityMutation(opportunityId);
  const { mutate: updateApplication } = useUpdateApplicationMutation(applicationId, opportunityId);
  const { mutate: repullCredit, isPending: repullingCreditReport } = usePullCreditReport(opportunityId);
  const { mutate: submitApplication } = useSubmitApplicationMutation(applicationId, opportunityId);
  const { confirm } = useConfirmModal();
  const { t } = useTranslation();
  const [extensionModalOpen, setExtensionModalOpen] = useState<boolean>(false);
  const [clearFradulentActivityModalOpen, setClearFradulentActivityModalOpen] = useState<boolean>(false);
  const [adverseActionEmailDialogOpen, setAdverseActionEmailDialogOpen] = useState<boolean>(false);
  const [letterOfIncompletenessEmailDialogOpen, setLetterOfIncompletenessEmailDialogOpen] = useState<boolean>(false);

  const isCurrentUserDecisionMaker = useMemo(
    () => (user ? isUserDecisionMaker({ user, opportunity, product }) : false),
    [opportunity, product, user],
  );
  const isCurrentUserRecommendationMaker = useMemo(
    () => (user ? isUserRecommender({ user, opportunity, product }) : false),
    [opportunity, product, user],
  );

  const userDecisionPermission = useMemo<'decision' | 'recommendation' | null>(() => {
    if (!isCurrentUserDecisionMaker && !isCurrentUserRecommendationMaker) {
      return null;
    }

    if (isCurrentUserDecisionMaker) {
      return 'decision';
    }

    return 'recommendation';
  }, [isCurrentUserDecisionMaker, isCurrentUserRecommendationMaker]);

  const [dismissMode, setDismissMode] = useState<'decision' | 'recommendation' | null>(null);

  const adverseActionText = useMemo(() => (status === 'Rejected' ? 'Send Adverse Action' : 'Send Notice of Withdrawl'), [status]);

  const closeGrantExtensionModal = useCallback(() => {
    setExtensionModalOpen(false);
  }, []);

  const openGrantExtensionModal = useCallback(() => {
    setExtensionModalOpen(true);
  }, []);

  const openClearFaudulentActivityModal = useCallback(() => {
    setClearFradulentActivityModalOpen(true);
  }, []);

  const closeClearFaudulentActivityModal = useCallback(() => {
    setClearFradulentActivityModalOpen(false);
  }, []);

  const openAdverseActionEmailDialog = useCallback(() => {
    setAdverseActionEmailDialogOpen(true);
  }, []);

  const closeAdverseActionEmailDialog = useCallback(() => {
    setAdverseActionEmailDialogOpen(false);
  }, []);

  const openLetterOfIncompletenessEmailDialog = useCallback(() => {
    setLetterOfIncompletenessEmailDialogOpen(true);
  }, []);

  const closeLetterOfIncompletenessEmailDialog = useCallback(() => {
    setLetterOfIncompletenessEmailDialogOpen(false);
  }, []);

  const onAdverseActionEmailSent = useCallback(
    async ({ preview }: { preview: boolean }) => {
      await sendRejectionEmail({ preview });
    },
    [sendRejectionEmail],
  );

  const onLetterOfIncompletenessEmailSent = useCallback(
    async ({ preview }: { preview: boolean }) => {
      await sendLetterOfIncompleteness({ preview });
      updateApplication({ updateApplicationPayload: { status: 'Incomplete' } });
    },
    [sendLetterOfIncompleteness, updateApplication],
  );

  const alertActionToComponent: Record<string, React.FC> = useMemo(
    () => ({
      grantCompleteApplicationExtension: () => (
        <Button variant={'secondary'} color={'neutral'} onClick={openGrantExtensionModal}>
          {'30 days extension'}
        </Button>
      ),
      withdraw: () => {
        if (!userDecisionPermission) {
          return null;
        }

        return (
          <Button
            variant={'secondary'}
            startIcon={<WithdrawIcon />}
            iconColor={'#9E9E9E'}
            color={'neutral'}
            onClick={() => {
              setDismissMode(userDecisionPermission);
            }}
          >
            {'Withdraw'}
          </Button>
        );
      },
      decline: () => {
        if (!userDecisionPermission) {
          return null;
        }

        return (
          <Button
            variant={'secondary'}
            startIcon={<Cancel />}
            iconColor={'#EF5350'}
            color={'neutral'}
            onClick={() => {
              setDismissMode(userDecisionPermission);
            }}
          >
            {'Decline'}
          </Button>
        );
      },
      dismiss: () => {
        if (!userDecisionPermission) {
          return null;
        }

        return (
          <Button
            variant={'secondary'}
            startIcon={<Cancel />}
            iconColor={'#EF5350'}
            color={'neutral'}
            onClick={() => {
              setDismissMode(userDecisionPermission);
            }}
          >
            {userDecisionPermission === 'decision' ? 'Dismiss Application' : 'Request to Dismiss'}
          </Button>
        );
      },
      letterOfIncompleteness: () => (
        <Button
          variant={'secondary'}
          startIcon={<MailOutline />}
          iconColor={'#9E9E9E'}
          color={'neutral'}
          loading={isSendingLetterOfIncompleteness}
          onClick={openLetterOfIncompletenessEmailDialog}
        >
          {'Send Letter of Incompleteness'}
        </Button>
      ),
      adverseActionEmail: () => (
        <Button
          variant={'secondary'}
          startIcon={<MailOutline />}
          iconColor={'#9E9E9E'}
          color={'neutral'}
          loading={isSendingRejectionEmail}
          onClick={openAdverseActionEmailDialog}
        >
          {adverseActionText}
        </Button>
      ),
      sendPhysicalLetterOfIncompleteness: () => (
        <Button
          variant={'tertiary'}
          color={'neutral'}
          onClick={async () => {
            const confirmed = await confirm({
              title: 'Physical copy sent?',
              message: 'Marking as sent will dismiss the alert',
              confirmText: 'Mark As Sent',
            });
            if (!confirmed) {
              return;
            }

            updateOpportunity({ physicalLetterOfIncompletenessSent: true });
          }}
        >
          {'Mark Physical Letter Sent'}
        </Button>
      ),
      sendPhysicalAdverseActionEmail: () => (
        <Button
          variant={'tertiary'}
          color={'neutral'}
          onClick={async () => {
            const confirmed = await confirm({
              title: 'Physical copy sent?',
              message: 'Marking as sent will dismiss the alert',
              confirmText: 'Mark As Sent',
            });
            if (!confirmed) {
              return;
            }

            updateOpportunity({ physicalAdverseActionNoticeSent: true });
          }}
        >
          {'Mark Physical Letter Sent'}
        </Button>
      ),
      repullCredit: () => (
        <Button
          variant={'secondary'}
          color={'neutral'}
          onClick={async () => {
            const confirmed = await confirm({
              title: t('creditReport.dialog.title'),
              message: t('creditReport.dialog.message'),
              confirmText: t('creditReport.dialog.confirmText'),
            });
            if (!confirmed) {
              return;
            }

            repullCredit();
          }}
          loading={repullingCreditReport}
        >
          {t('creditReport.cta')}
        </Button>
      ),
      repullAndSubmit: () => (
        <Button
          variant={'secondary'}
          color={'neutral'}
          onClick={async () => {
            const confirmed = await confirm({
              title: t('creditReport.dialog.title'),
              message: t('creditReport.dialog.message'),
              confirmText: t('creditReport.dialog.confirmText'),
            });
            if (!confirmed) {
              return;
            }

            submitApplication();
          }}
          loading={repullingCreditReport}
        >
          {t('creditReport.cta')}
        </Button>
      ),
      clearFraudulentActivity: () => (
        <Button variant={'secondary'} color={'neutral'} onClick={openClearFaudulentActivityModal}>
          {'Clear'}
        </Button>
      ),
    }),
    [
      adverseActionText,
      confirm,
      isSendingLetterOfIncompleteness,
      isSendingRejectionEmail,
      openAdverseActionEmailDialog,
      openClearFaudulentActivityModal,
      openGrantExtensionModal,
      openLetterOfIncompletenessEmailDialog,
      repullCredit,
      repullingCreditReport,
      submitApplication,
      t,
      updateOpportunity,
      userDecisionPermission,
    ],
  );

  const onDismissApplicationModalDone = useCallback(() => {
    setDismissMode(null);
  }, []);

  return (
    <>
      <Flex
        alignItems={'center'}
        justifyContent={'space-between'}
        gap={8}
        width={'100%'}
        p={4}
        borderRadius={'4px'}
        height={'64px'}
        backgroundColor={level === 'info' ? bluePalette[50] : yellowPalette[50]}
      >
        <Flex alignItems={'center'} gap={4}>
          <ErrorOutlineRounded color={level === 'info' ? 'info' : 'warning'} />
          <Text variant={'body1'}>{message}</Text>
        </Flex>
        <Flex gap={4}>
          {actions?.map((actionName) => {
            const Comp = alertActionToComponent[actionName];
            if (!Comp) {
              return null;
            }
            return <Comp key={actionName} />;
          })}
        </Flex>
      </Flex>
      {dismissMode ? <DismissApplicationDialog open={!!dismissMode} onClose={onDismissApplicationModalDone} mode={dismissMode} /> : null}
      {extensionModalOpen ? (
        <GrantCompleteApplicationExtensionModal opportunityId={opportunityId} onClose={closeGrantExtensionModal} />
      ) : null}
      {clearFradulentActivityModalOpen ? (
        <ClearFraudulentActivityModal personId={params.personId} opportunity={opportunity} onClose={closeClearFaudulentActivityModal} />
      ) : null}
      {adverseActionEmailDialogOpen ? (
        <SendEmailDialog
          open={adverseActionEmailDialogOpen}
          onClose={closeAdverseActionEmailDialog}
          sendEmail={onAdverseActionEmailSent}
          title={adverseActionText}
        />
      ) : null}
      {letterOfIncompletenessEmailDialogOpen ? (
        <SendEmailDialog
          open={letterOfIncompletenessEmailDialogOpen}
          onClose={closeLetterOfIncompletenessEmailDialog}
          sendEmail={onLetterOfIncompletenessEmailSent}
          title={'Send Letter of Incompleteness'}
        />
      ) : null}
    </>
  );
};
