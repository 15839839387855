import type { FC, ReactNode } from 'react';
import React, { useCallback, useContext } from 'react';
import type { MRT_Column, MRT_TableInstance } from 'material-react-table';
import type { DocumentLineItem } from '@lama/document-service-client';
import { toast } from 'react-toastify';
import { getUpdateLineItemDates } from '../utils/getUpdatedLineItemValues';
import { SpreadingDocumentContext } from '../../../SpreadingDocumentContext';
import { useUpdateDocumentLineItemsMutation } from '../../../../../shared/hooks/react-query/document/lineItems/useUpdateDocumentLineItemsMutation';
import 'react-datepicker/dist/react-datepicker.css';
import { UserDetailsContext } from '../../../../../shared/context/UserDetailsContext';
import type { ColumnMeta } from '../utils/createTableColumns';
import { DateRangePicker } from './DateRangePicker';
import type { Period } from './FinancialStatementsSpreadingContent';

export interface YearColumnDateRangeSelectProps {
  closeMenu: () => void;
  column: MRT_Column<DocumentLineItem>;
  internalColumnMenuItems: ReactNode[];
  table: MRT_TableInstance<DocumentLineItem>;
  numberOfPeriods: number;
  lineItems: DocumentLineItem[];
  startDate?: Date;
  endDate?: Date;
  onDeletePeriod?: (period: Period) => void;
}

export const YearColumnDateRangeSelect: FC<YearColumnDateRangeSelectProps> = ({
  column,
  lineItems,
  startDate: initialStartDate,
  endDate: initialEndDate,
  closeMenu,
}) => {
  const { currentDocument } = useContext(SpreadingDocumentContext);
  const { userId } = useContext(UserDetailsContext);
  const { mutateAsync: updateDocumentLineItems, isPending: savingLineItemDates } = useUpdateDocumentLineItemsMutation();

  const updateLineItemDates = useCallback(
    async (startISODate: string, endISODate: string) => {
      const { startDate: columnStartDate, endDate: columnEndDate } = column.columnDef.meta as ColumnMeta;

      if (!startISODate || !endISODate || !userId || !columnStartDate || !columnEndDate) {
        return;
      }

      const lineItemsUpdates = lineItems.map((lineItem) => ({
        id: lineItem.id,
        values: getUpdateLineItemDates(
          lineItem,
          columnStartDate.toISOString(),
          columnEndDate.toISOString(),
          startISODate,
          endISODate,
          userId,
        ),
      }));

      await updateDocumentLineItems({
        documentId: currentDocument.id,
        lineItemUpdateBody: {
          lineItemUpdates: lineItemsUpdates,
        },
      });
    },
    [column.columnDef.meta, userId, lineItems, updateDocumentLineItems, currentDocument.id],
  );

  const saveLineItemDates = useCallback(
    async (startDate: Date, endDate: Date) => {
      try {
        await updateLineItemDates(startDate.toISOString(), endDate.toISOString());
      } catch {
        toast.error('Failed to update. Please contact support.');
      } finally {
        closeMenu();
      }
    },
    [updateLineItemDates, closeMenu],
  );

  return (
    <DateRangePicker onChange={saveLineItemDates} startDate={initialStartDate} endDate={initialEndDate} loading={savingLineItemDates} />
  );
};
