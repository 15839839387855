/* eslint-disable @typescript-eslint/naming-convention */
import type { ApplicationApiModel, OpportunityApiModel } from '@lama/clients';
import type { Collateral, PaymentPeriod } from '@lama/contracts';
import type { LenderProfile } from '@lama/user-service-client';
import { stateNameToAbbreviation } from '@lama/contracts';
import {
  relatedPeopleByRelationSelector,
  applicationBorrowingBusinessSelector,
  guarantorsSelector,
  applicationBorrowingRelatedBusinessesSelector,
  proposedLoanAmount as proposedLoanAmountSelector,
} from '@lama/data-formatters';
import { format } from 'date-fns';
import { isNil } from 'lodash-es';

type LaserProEntityType = 'A' | 'C' | 'G' | 'I' | 'L' | 'P' | 'T';

type LaserProGuarantorGuarantyType = 'Amount of Note' | 'Limited' | 'Unlimited';

type LaserProContinuingGuarantyType = 'N' | 'Y';

type LaserProCollateralType = 'O' | 'P' | 'R' | 'T' | 'U';

type LaserProCollateralSubType =
  | ''
  | '1-4 Family'
  | 'Construction'
  | 'Farm Land'
  | 'Land'
  | 'Lot'
  | 'Motor Vehicle'
  | 'Multi-Family'
  | 'Office'
  | 'Receipts/Bills'
  | 'Warehouse';

type LaserProLoanType = '1' | '2' | '3';

type LaserProLoanClass = '1' | '2' | '3';

type LaserProPaymentType = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8';

type LaserProPrepaidInterest = '' | '1' | '2' | '3';

type LaserProFixedRateOption = '' | '1' | '2' | '3' | '4' | '5';

type LaserProInterestMethod = '' | '1' | '2' | '3' | '4' | '5';

type LaserProFixedVariable = '' | 'F' | 'PF' | 'PV' | 'V';

type LaserProPaymentPeriod = '' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8';

type LaserProInterestPaymentPeriod = '' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';

type LaserProDisbursementDescriptionType = '' | 'Account' | 'Check' | 'Lender' | 'NonLoan' | 'Other' | 'Payable' | 'PayOff';

type LaserProDisbursementAccountType = '' | '1' | '2';

const businessTypeToLaserProEntityType = (businessLegalEntityType: string) => {
  switch (businessLegalEntityType) {
    case 'Sole Proprietorship': {
      return 'I';
    }
    case 'Partnership': {
      return 'P';
    }
    case 'S Corp': {
      return 'C';
    }
    case 'C Corp': {
      return 'C';
    }
    case 'LLC': {
      return 'L';
    }
    case 'Non Profit': {
      return 'C';
    }
    default: {
      return '';
    }
  }
};

const getEmptyBorrowerValues = () => Array.from({ length: 13 }, () => '');

const getBorrowerValues = (application: ApplicationApiModel) => {
  const isSoleProprietorship = applicationBorrowingBusinessSelector(application)?.legalEntityType === 'Sole Proprietorship';
  const soleOwner = applicationBorrowingBusinessSelector(application)?.people.find(
    ({ ownershipPercentage }) => ownershipPercentage === 100,
  );

  const entityType = businessTypeToLaserProEntityType(applicationBorrowingBusinessSelector(application)?.legalEntityType ?? '');
  const capacity = '';
  const firstName = isSoleProprietorship && soleOwner ? soleOwner.firstName.slice(0, 20) : '';
  const businessName = applicationBorrowingBusinessSelector(application)?.legalName?.slice(0, 30) ?? '';
  let ssnOrTin = '';
  if (isSoleProprietorship) {
    if (soleOwner?.ssn) {
      ssnOrTin = `${soleOwner.ssn.slice(0, 3)}-${soleOwner.ssn.slice(3, 5)}-${soleOwner.ssn.slice(5)}`;
    }
  } else {
    const tin = applicationBorrowingBusinessSelector(application)?.tin;
    if (tin) {
      ssnOrTin = `${tin.slice(0, 2)}-${tin.slice(2)}`;
    }
  }

  const businessAddress = applicationBorrowingBusinessSelector(application)?.addresses?.[0];
  const businessAddressLine = businessAddress?.address1?.slice(0, 30) ?? '';
  const businessAddressCity = businessAddress?.city?.slice(0, 20) ?? '';
  const businessAddressState = stateNameToAbbreviation(businessAddress?.state ?? '') ?? '';
  const businessAddressZip = businessAddress?.zip ?? '';
  const businessPhone = applicationBorrowingBusinessSelector(application)?.phoneNumber?.replace(/\D/g, '').slice(0, 10) ?? '';
  const dateOfBirth = isSoleProprietorship && soleOwner?.dateOfBirth ? format(new Date(soleOwner.dateOfBirth), 'MM/dd/yyyy') : '';
  const customerNumber = '';
  const businessNaics = applicationBorrowingBusinessSelector(application)?.naicsCodes?.[0]?.slice(0, 5) ?? '';

  return [
    entityType,
    capacity,
    firstName,
    businessName,
    ssnOrTin,
    businessAddressLine,
    businessAddressCity,
    businessAddressState,
    businessAddressZip,
    businessPhone,
    dateOfBirth,
    customerNumber,
    businessNaics,
    ...Array.from({ length: 3 }, () => getEmptyBorrowerValues()).flat(),
  ];
};

const getEmptyCosignerValues = () => Array.from({ length: 12 }, () => '');

const getCosignerValues = () => Array.from({ length: 2 }, () => getEmptyCosignerValues()).flat();

const getEmptyGuarantorValues = () => Array.from({ length: 15 }, () => '');

const getGuarantorValues = (application: ApplicationApiModel) => {
  const explicitPeopleGuarantors = relatedPeopleByRelationSelector(application, 'guarantor').map(({ person }) => person);
  const borrowingBusinessesGuarantors = applicationBorrowingRelatedBusinessesSelector(application)
    .flatMap(({ business }) => guarantorsSelector(business, application))
    ?.sort((a, b) => (b.ownershipPercentage ?? 0) - (a.ownershipPercentage ?? 0));

  const guarantorValues =
    [...borrowingBusinessesGuarantors, ...explicitPeopleGuarantors].slice(0, 4).map((guarantor) => {
      const entityType: LaserProEntityType = 'I';
      const firstName = guarantor.firstName?.slice(0, 20) ?? '';
      const lastName = guarantor.lastName?.slice(0, 20) ?? '';
      const ssn = guarantor.ssn ? `${guarantor.ssn.slice(0, 3)}-${guarantor.ssn.slice(3, 5)}-${guarantor.ssn.slice(5)}` : '';
      const addressLine = guarantor.address?.address1?.slice(0, 30) ?? '';
      const city = guarantor.address?.city?.slice(0, 20) ?? '';
      const state = stateNameToAbbreviation(guarantor.address?.state ?? '') ?? '';
      const zip = guarantor.address?.zip ?? '';
      const phoneNumber = guarantor.phoneNumber?.replace(/\D/g, '').slice(0, 10) ?? '';
      const dateOfBirth = guarantor.dateOfBirth ? format(new Date(guarantor.dateOfBirth), 'MM-dd-yyyy') : '';
      const customerNumber = '';
      const naicsCode = '';
      const guaranty: LaserProGuarantorGuarantyType = 'Amount of Note';
      const guarantyAmount = '';
      const continuingGuaranty: LaserProContinuingGuarantyType = 'Y';

      return [
        entityType,
        firstName,
        lastName,
        ssn,
        addressLine,
        city,
        state,
        zip,
        phoneNumber,
        dateOfBirth,
        customerNumber,
        naicsCode,
        guaranty,
        guarantyAmount,
        continuingGuaranty,
      ];
    }) ?? [];

  return [...guarantorValues, ...Array.from({ length: 4 - guarantorValues.length }, () => getEmptyGuarantorValues())].flat();
};

const collateralTypeToLaserProCollateralType: Record<string, LaserProCollateralType> = {
  'Invoices / AR': 'P',
  'Commercial RE': 'R',
  Inventory: 'O',
  Equipment: 'T',
  'Personal Home': 'R',
  'Personal Vehicle': 'T',
  'Commercial Vehicle': 'T',
  Other: 'O',
};

const getLaserProCollateralSubType = (collateralType?: string, loanType?: string) => {
  switch (collateralType) {
    case 'Invoices / AR': {
      return 'Receipts/Bills';
    }
    case 'Commercial RE': {
      return loanType === 'Lot Loans' ? 'Lot' : 'Office';
    }
    case 'Equipment': {
      return 'Warehouse';
    }
    case 'Personal Home': {
      return '1-4 Family';
    }
    case 'Personal Vehicle': {
      return 'Motor Vehicle';
    }
    case 'Commercial Vehicle': {
      return 'Motor Vehicle';
    }
    default: {
      return '';
    }
  }
};

const collateralToLaserProCollateralCode = (collateral: Collateral) => {
  switch (collateral.type) {
    case 'Commercial RE': {
      return 'CL70';
    }
    case 'Equipment': {
      return 'CLHE';
    }
    case 'Personal Home': {
      return 'CL18';
    }
    case 'Personal Vehicle': {
      return 'CIAT';
    }
    case 'Commercial Vehicle': {
      return collateral.condition === 'New' ? 'CLAN' : 'CLAU';
    }
    default: {
      return '';
    }
  }
};

const getEmptyCollateralValues = () => Array.from({ length: 34 }, () => '');

const getCollateralValues = (application: ApplicationApiModel) => {
  const collateralValues =
    application.collateral
      ?.sort((a, b) => (b.totalValue ?? 0) - (a.totalValue ?? 0))
      .slice(0, 4)
      .map((c) => {
        const type: LaserProCollateralType = collateralTypeToLaserProCollateralType[c.type ?? ''] ?? 'O';
        const subType: LaserProCollateralSubType = getLaserProCollateralSubType(c.type, application.loanType);
        const possessoryType = '';
        const lawState = stateNameToAbbreviation(applicationBorrowingBusinessSelector(application)?.addresses?.[0]?.state ?? '') ?? '';
        const simpleDescription = c.description?.slice(0, 50) ?? '';
        const bodyOrStyle = c.vehicleDetails?.bodyStyle?.slice(0, 10) ?? '';
        const make = c.vehicleDetails?.make?.slice(0, 10) ?? '';
        const model = c.vehicleDetails?.model?.slice(0, 10) ?? '';
        const manufacturer = '';
        const nameOfVessel = '';
        const officialNumber = '';
        const faaNumber = '';
        const accountOrLocNumber = '';
        const balanceOrAmount = '';
        const issuedBy = '';
        const issuer = '';
        const shares = '';
        const cusipNumber = '';
        const heldBy = '';
        const contractNumber = '';
        const contractDate = '';
        const locDate = '';
        const beneficiary = '';
        const customer = applicationBorrowingBusinessSelector(application)?.legalName?.slice(0, 15) ?? '';
        const vin = c.vehicleDetails?.vin?.slice(0, 25) ?? '';
        const serialNumber = c.idNumber?.slice(0, 25) ?? '';
        const year = c.vehicleDetails?.year?.toString() ?? '';
        const marketValue = c.totalValue?.toString() ?? '';
        const streetAddress = c.realEstateDetails?.address ?? '';
        const city = c.realEstateDetails?.city ?? '';
        const state = stateNameToAbbreviation(c.realEstateDetails?.state ?? '') ?? '';
        const zip = c.realEstateDetails?.zip ?? '';
        const county = '';
        const propertyTaxId = '';

        return [
          type,
          subType,
          possessoryType,
          lawState,
          simpleDescription,
          bodyOrStyle,
          make,
          model,
          manufacturer,
          nameOfVessel,
          officialNumber,
          faaNumber,
          accountOrLocNumber,
          balanceOrAmount,
          issuedBy,
          issuer,
          shares,
          cusipNumber,
          heldBy,
          contractNumber,
          contractDate,
          locDate,
          beneficiary,
          customer,
          vin,
          serialNumber,
          year,
          marketValue,
          streetAddress,
          city,
          state,
          zip,
          county,
          propertyTaxId,
        ];
      }) ?? [];

  return [...collateralValues, ...Array.from({ length: 3 - collateralValues.length }, () => getEmptyCollateralValues())].flat();
};

const paymentPeriodToLaserProPaymentPeriod: Record<PaymentPeriod, LaserProPaymentPeriod> = {
  Weekly: '1',
  'Bi-Weekly': '2',
  'Semi-Monthly': '3',
  Monthly: '4',
  'Bi-Monthly': '5',
  Quarterly: '6',
  'Semi-Annual': '7',
  Annual: '8',
};

const getTotalNumberOfPayments = (termLengthMonths: number, paymentPeriod: PaymentPeriod) => {
  switch (paymentPeriod) {
    case 'Weekly': {
      return termLengthMonths * 4;
    }
    case 'Bi-Weekly': {
      return termLengthMonths * 2;
    }
    case 'Semi-Monthly': {
      return termLengthMonths * 2;
    }
    case 'Monthly': {
      return termLengthMonths;
    }
    case 'Bi-Monthly': {
      return Math.ceil(termLengthMonths / 2);
    }
    case 'Quarterly': {
      return Math.ceil(termLengthMonths / 3);
    }
    case 'Semi-Annual': {
      return Math.ceil(termLengthMonths / 6);
    }
    case 'Annual': {
      return Math.ceil(termLengthMonths / 12);
    }
  }
};

const useOfFundsBreakdownTypeToLaserProPurposeCode: Record<string, string> = {
  'Purchase Equipment': 'PURC',
  'Purchase Real Estate': 'PURE',
  'Purchase Inventory': 'PURC',
  'Purchase Other': 'PURO',
  'Fund Working Capital': 'WORK',
  'Refinance Real Estate': 'RERE',
  'Refinance - Other': 'RNON',
  'Land/RE Development': 'IMPV',
  'Farming Activity': 'AGRI',
  Other: 'OTHR',
};

const loanTypeToLaserProLoanHostType = (loanType: string) => {
  switch (loanType) {
    case 'Purchase Vehicle': {
      return 'CML,7050*CL';
    }
    case 'Refinance Vehicle': {
      return 'CML,7050*CL';
    }
    case 'Cash Out': {
      return 'CML,7050*CL';
    }
    case 'Purchase Equipment': {
      return 'CML,7057*CL';
    }
    case 'Refinance Equipment': {
      return 'CML,7057*CL';
    }
    case 'Secured Line of Credit': {
      return 'CML,7062*CL';
    }
    case 'Unsecured Line of Credit': {
      return 'CML,7061*CL';
    }
    case 'Checking Reserve': {
      return 'CML,7065*CL';
    }
    case 'Unsecured Fixed Loan': {
      return 'CML,7060*CL';
    }
    case 'Share Loan': {
      return 'CML,7059*CL';
    }
    case 'Recreational Loan': {
      return 'CML,7052*CL';
    }
    case 'Real Estate 1st Mortgage': {
      return 'CML,7054*CL';
    }
    case 'Real Estate 2nd Mortgage': {
      return 'CML,7055*CL';
    }
    case 'Lot Loans': {
      return 'CML,7056*CL';
    }
    case 'HELOC': {
      return 'CML,7063*CL';
    }
    case 'Construction': {
      return 'CML,4003*CL';
    }
    default: {
      return '';
    }
  }
};

const getGeneralDataValues = (opportunity: OpportunityApiModel, assignedLenderProfile?: LenderProfile) => {
  const { application, terms, partnerSequenceNumber } = opportunity;

  const loanPolicy: LaserProLoanType = '2';
  const consumerOrBusiness = '';
  const loanClass: LaserProLoanClass = '1';
  const paymentType: LaserProPaymentType = '1';
  const institutionName = '';
  const branchCode = opportunity.branchCode?.slice(0, 8) ?? '';
  const standardProduct = '';
  const officerCode = assignedLenderProfile?.officerCode ?? '';
  const noteNumber = partnerSequenceNumber ? `${partnerSequenceNumber}Lama`.padStart(8, '0') : '';
  const portfolio = '';
  const purpose = application.useOfFundsBreakdown?.[0]?.type?.slice(0, 40) ?? '';
  const depositAccountNumber = '';
  const callCode = '';
  const chargeCode = '';
  const classCode = '';
  const purposeCode = useOfFundsBreakdownTypeToLaserProPurposeCode[application.useOfFundsBreakdown?.[0]?.type ?? ''] ?? '';
  const hostLoanType = application.loanType ? loanTypeToLaserProLoanHostType(application.loanType) : '';
  const firstCollateral = application.collateral?.[0];
  const collateralCode = firstCollateral ? collateralToLaserProCollateralCode(firstCollateral) : '';
  const prepaidInterest: LaserProPrepaidInterest = '1';
  const fixedRateOption: LaserProFixedRateOption = '';
  const interestMethod: LaserProInterestMethod = '2';
  const fixedVariable: LaserProFixedVariable = terms?.rate?.isFixed !== undefined ? (terms.rate.isFixed ? 'F' : 'V') : '';
  const rateCode = '';
  const interestRate = terms?.rate?.isFixed && !isNil(terms.rate.spread) ? terms.rate.spread.toString().slice(0, 7) : '';
  const floorRate = '0';
  const ceilingRate = '0';
  const variance = '';
  const margin = '0';
  const requestAmount = proposedLoanAmountSelector(application)?.toString() ?? '';
  const noteDate = application.submittedAt ? format(new Date(application.submittedAt), 'MM-dd-yyyy') : '';
  const totalNumberOfPayments =
    terms?.termLength && terms.paymentPeriod ? getTotalNumberOfPayments(terms.termLength, terms.paymentPeriod).toString() : '';
  const paymentPeriod: LaserProPaymentPeriod = terms?.paymentPeriod ? paymentPeriodToLaserProPaymentPeriod[terms.paymentPeriod] : '';
  const interestPaymentPeriod: LaserProInterestPaymentPeriod = paymentPeriod;
  const firstPaymentDate = terms?.firstPaymentDate ? format(new Date(terms.firstPaymentDate), 'MM-dd-yyyy') : '';
  const firstInterestPaymentDate = firstPaymentDate;
  const disbursementDate = terms?.disbursementDate ? format(new Date(terms.disbursementDate), 'MM-dd-yyyy') : '';

  return [
    loanPolicy,
    consumerOrBusiness,
    loanClass,
    paymentType,
    institutionName,
    branchCode,
    standardProduct,
    officerCode,
    noteNumber,
    portfolio,
    purpose,
    depositAccountNumber,
    callCode,
    chargeCode,
    classCode,
    purposeCode,
    hostLoanType,
    collateralCode,
    prepaidInterest,
    fixedRateOption,
    interestMethod,
    fixedVariable,
    rateCode,
    interestRate,
    floorRate,
    ceilingRate,
    variance,
    margin,
    requestAmount,
    noteDate,
    totalNumberOfPayments,
    paymentPeriod,
    interestPaymentPeriod,
    firstPaymentDate,
    firstInterestPaymentDate,
    disbursementDate,
  ];
};

const getEmptyDisbursementValues = () => Array.from({ length: 4 }, () => '');

const getDisbursementValues = () => {
  const description: LaserProDisbursementDescriptionType = '';
  const subDescription = '';
  const accountType: LaserProDisbursementAccountType = '';
  const amount = '';

  return [description, subDescription, accountType, amount, ...Array.from({ length: 2 }, () => getEmptyDisbursementValues()).flat()];
};

const fieldDelimiter = '|';

export const transformToLaserProFormat = (opportunity: OpportunityApiModel, assignedLenderProfile?: LenderProfile) => {
  const { application } = opportunity;

  const values = [
    getBorrowerValues(application),
    getCosignerValues(),
    getGuarantorValues(application),
    getCollateralValues(application),
    getGeneralDataValues(opportunity, assignedLenderProfile),
    getDisbursementValues(),
  ].flat();

  return values.map((v) => `${v}${fieldDelimiter}`).join('');
};
